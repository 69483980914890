<app-plain-layout pageHeading="settings.auditlog.title" [hasToolbar]="false">
  <app-auditlog
    data-cy="auditlogTable"
    [selectedWorkingAreaId]="selectedWorkingAreaId"
    [isLoading]="false"
    [datasource]="dataSource"
    [allOrganizations]="allOrganizations$ | async"
    [allWorkingAreas]="allWorkingAreas$ | async"
    plain-layout-content></app-auditlog>
</app-plain-layout>
