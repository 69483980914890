import { Injectable } from '@angular/core';
import { GuidString } from 'core/models';
import { Subject, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import { VehicleZoneUpdate } from 'core/dtos';
import { VehiclesFeatureState } from 'store-modules/vehicles-store';
import { MapPollingUnpackHelper } from '../helpers/map-polling.helper';
import { poll, PollingIntervals } from '../helpers/polling-helper';
import { SignalRNextService } from '../signalr-next.service';
import { SignalRPackedService } from '../signalr-packed.service';
import { SignalrRoutes } from '../signalr-routes';
import { vehicleLocationMapUpdatePolled } from '../store/actions/signalr.actions';

@Injectable({
  providedIn: 'root',
})
export class MapVehicleSignalRService {
  private subscription: Subscription | null = null;

  vehicleZoneUpdate = new Subject<VehicleZoneUpdate[]>();

  constructor(
    private readonly signalRNextService: SignalRNextService,
    private readonly packedService: SignalRPackedService,
    private readonly store: Store<VehiclesFeatureState>
  ) {}

  // #region Vehicle Locations
  async pollMapVehicleUpdate(waId: GuidString, mapId: GuidString): Promise<void> {
    this.stopPolling();

    if (this.subscription) {
      // eslint-disable-next-line no-console
      console.warn(`Duplicate call to ${SignalrRoutes.FetchMapData}`);
    }

    this.signalRNextService.logSignalRPollMeta(
      SignalrRoutes.FetchMapData,
      true,
      `WA ${waId}, Map ${mapId}`
    );
    this.subscription = this.startPolling(waId, mapId);

    return Promise.resolve();
  }

  leaveVehicleMapUpdate(): void {
    this.stopPolling();
  }

  // #endregion

  // #region Polling
  private stopPolling(): void {
    this.subscription?.unsubscribe();
    this.subscription = null;

    this.signalRNextService.logSignalRPollMeta(SignalrRoutes.FetchMapData, false);
  }

  private startPolling(waId: GuidString, mapId: GuidString): Subscription {
    return poll(
      PollingIntervals.MapVehicles,
      t => this.packedService.fetchMapData(waId, mapId, t),
      MapVehicleSignalRService.name
    ).subscribe(packed => {
      const unpacked = packed.map(MapPollingUnpackHelper.unpackVehicle);

      this.store.dispatch(vehicleLocationMapUpdatePolled({ vehicleUpdates: unpacked }));

      this.vehicleZoneUpdate.next(
        packed.map(item => ({
          vehicleId: item.id,
          conflictArea: MapPollingUnpackHelper.unpackConflictArea(waId, item.id, item.conflictZone),
          intersectionZone: MapPollingUnpackHelper.unpackZonePath(
            mapId,
            item.id,
            item.intersectionZonePath
          ),
        }))
      );
    });
  }
  // #endregion
}
