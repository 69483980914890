<ng-container *ngIf="isLoaded">
  <ag-grid-angular
    style="height: 100%; width: 100%"
    class="ag-theme-density zebra"
    data-cy="graphManagerParkingAndChargingConfiguration"
    [columnDefs]="translatedColumnDefs"
    [getRowId]="getRowIdForChangeDetection"
    [gridOptions]="gridOptions"
    [rowSelection]="'single'"
    (gridReady)="onGridReady($event)"
    [rowData]="rowData"
    [sideBar]="false">
  </ag-grid-angular>
</ng-container>
