import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertNowGroupDto, VehicleDto, WorkAreaSettingRecipientKeyDto } from 'core/dtos';
import { Zone } from 'core/models';
import { AtsTranslationService } from 'core/services';

@Component({
  selector: 'app-ipst-alertnow-group-view',
  templateUrl: './ipst-alertnow-group-view.component.html',
  styleUrls: ['./ipst-alertnow-group-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowGroupViewComponent {
  @Input() alertNowGroups: AlertNowGroupDto[] = [];
  @Input() zones: Zone[] = [];
  @Input() vehicles: VehicleDto[] = [];
  @Input() allRecipientKeys: WorkAreaSettingRecipientKeyDto[] = [];

  constructor(private readonly atsTranslateService: AtsTranslationService) {}

  getVehicleAlertNowHeader(index: number): string {
    return this.atsTranslateService.get(
      'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroupNum',
      {
        number: index + 1,
      }
    );
  }
}
