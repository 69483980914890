import { ColumnState } from 'ag-grid-community';
import {
  GuidString,
  NodeDetailsPanelCards,
  OrderFilter,
  VehicleDetailsPanelCards,
  VehicleType,
} from 'core/models';

import { TableColumns } from 'library/models';
import { MissionFilterViewModel } from 'modules/jobs/mission-monitoring/models/mission.models';

const activeMissionFilterKey = 'activeMissionFilter';
const historyMissionFilterKey = 'historyMissionFilter';
const selectedVehicleTypesFilterKey = 'selectedVehicleTypesFilter';
const activeOrderFilterKey = 'activeOrderFilter';
const historyOrderFilterKey = 'historyOrderFilter';
const tableColumnOrderKey = 'columnOrder';

function generateDefaultActiveMissionFilter(): MissionFilterViewModel {
  return {
    createdDateTime: [],
    hasManualSupport: undefined,
    maps: [],
    materialNumbers: [],
    missionFailureReasons: undefined,
    missionNames: [],
    processChainNames: [],
    status: [],
    triggers: [],
    vehicleNames: [],
  };
}

function generateDefaultOrderFilter(): OrderFilter {
  return {
    createdDateTimeInterval: [],
    externalSystemOrderId: [],
    orderStatus: [],
    processChainName: [],
    materialNumber: [],
    source: [],
    destination: [],
    externalSystemName: [],
  };
}

export function getTableColumnOrder(tableKey: string): TableColumns[] {
  const filterModel = localStorage.getItem(getTableColumnOrderKey(tableKey));
  if (filterModel) {
    return JSON.parse(filterModel);
  } else {
    return [];
  }
}

export function setTableColumnOrder(missionFilter: TableColumns[], tableKey: string): void {
  localStorage.setItem(getTableColumnOrderKey(tableKey), JSON.stringify(missionFilter));
}

export function setActiveMissionFilter(
  missionFilter: MissionFilterViewModel,
  workAreaId: string
): void {
  localStorage.setItem(getActiveMissionFilterKey(workAreaId), JSON.stringify(missionFilter));
}

export function setSelectedVehicleTypeFilter(
  vehicleTypes: VehicleType[],
  workAreaId: string
): void {
  localStorage.setItem(getSelectedVehicleTypesFilterKey(workAreaId), JSON.stringify(vehicleTypes));
}

export function getSelectedVehicleTypesFilter(workAreaId: string): VehicleType[] {
  const vehicleTypes = localStorage.getItem(getSelectedVehicleTypesFilterKey(workAreaId));
  return vehicleTypes ? JSON.parse(vehicleTypes) : [];
}

export function getActiveMissionFilter(workAreaId: string): MissionFilterViewModel {
  const filterModel = localStorage.getItem(getActiveMissionFilterKey(workAreaId));
  if (filterModel) {
    return JSON.parse(filterModel);
  } else {
    return generateDefaultActiveMissionFilter();
  }
}

export function setHistoryMissionFilter(
  missionFilter: MissionFilterViewModel,
  workAreaId: string
): void {
  localStorage.setItem(getHistoryMissionFilterKey(workAreaId), JSON.stringify(missionFilter));
}

export function getHistoryMissionFilter(workAreaId: string): MissionFilterViewModel {
  const filterModel = localStorage.getItem(getHistoryMissionFilterKey(workAreaId));
  if (filterModel) {
    return JSON.parse(filterModel);
  }
  return generateDefaultActiveMissionFilter();
}

export function setActiveOrderFilter(orderFilter: OrderFilter, workAreaId: string): void {
  localStorage.setItem(getActiveOrderFilterKey(workAreaId), JSON.stringify(orderFilter));
}

export function getActiveOrderFilter(workAreaId: string): OrderFilter {
  const filterModel = localStorage.getItem(getActiveOrderFilterKey(workAreaId));
  if (filterModel) {
    return JSON.parse(filterModel);
  } else {
    return generateDefaultOrderFilter();
  }
}

export function setHistoryOrderFilter(orderFilter: OrderFilter, workAreaId: string): void {
  localStorage.setItem(getHistoryOrderFilterKey(workAreaId), JSON.stringify(orderFilter));
}

export function getHistoryOrderFilter(workAreaId: string): OrderFilter {
  const filterModel = localStorage.getItem(getHistoryOrderFilterKey(workAreaId));
  if (filterModel) {
    return JSON.parse(filterModel);
  }

  return generateDefaultOrderFilter();
}

export function getFilter(
  persistKey: string,
  workAreaId?: GuidString | null
):
  | {
      [key: string]: {};
    }
  | undefined {
  let filterModel = undefined;
  const getItemParam = workAreaId ? `filter_${persistKey}_${workAreaId}` : `filter_${persistKey}`;
  const rawFilterModel = localStorage.getItem(getItemParam);
  if (rawFilterModel) {
    filterModel = JSON.parse(rawFilterModel);
  }
  return filterModel;
}

export function setFilter(
  filter: {
    [key: string]: {};
  },
  persistKey: string,
  workAreaId?: GuidString | null
): void {
  if (filter) {
    const setItemParam = workAreaId ? `filter_${persistKey}_${workAreaId}` : `filter_${persistKey}`;
    localStorage.setItem(setItemParam, JSON.stringify(filter));
  }
}

export function setColumnSetting(persistKey: string, columnState: ColumnState[]): void {
  localStorage.setItem(`column-setting_${persistKey}`, JSON.stringify(columnState));
}

export function getColumnSetting(persistKey: string): ColumnState[] | undefined {
  const currentColumnStateSettingRaw = localStorage.getItem(`column-setting_${persistKey}`);
  let columnStateSettingByTable = undefined;

  if (currentColumnStateSettingRaw) {
    columnStateSettingByTable = JSON.parse(currentColumnStateSettingRaw);
  }

  return columnStateSettingByTable;
}

export function setVehicleDetailsPanelCardOrder(columnState: VehicleDetailsPanelCards[]): void {
  localStorage.setItem('vehicle-details-panel-card-order', JSON.stringify(columnState));
}

export function setNodePanelCardOrder(columnState: NodeDetailsPanelCards[]): void {
  localStorage.setItem('node-details-panel-card-order', JSON.stringify(columnState));
}

export function getVehicleDetailsPanelCardOrder(): VehicleDetailsPanelCards[] | undefined {
  const vehicleSidepanelCardOrderRaw = localStorage.getItem('vehicle-details-panel-card-order');
  let columnStateSettingByTable = undefined;

  if (vehicleSidepanelCardOrderRaw) {
    columnStateSettingByTable = JSON.parse(vehicleSidepanelCardOrderRaw);
  }

  return columnStateSettingByTable;
}

export function getNodeDetailsPanelCardOrder(): NodeDetailsPanelCards[] | undefined {
  const nodeSidepanelCardOrderRaw = localStorage.getItem('node-details-panel-card-order');
  let columnStateSettingByTable = undefined;

  if (nodeSidepanelCardOrderRaw) {
    columnStateSettingByTable = JSON.parse(nodeSidepanelCardOrderRaw);
  }

  return columnStateSettingByTable;
}

function getActiveMissionFilterKey(workAreaId: string): string {
  return `${activeMissionFilterKey}_${workAreaId}`;
}

function getSelectedVehicleTypesFilterKey(workAreaId: string): string {
  return `${selectedVehicleTypesFilterKey}_${workAreaId}`;
}

function getHistoryMissionFilterKey(workAreaId: string): string {
  return `${historyMissionFilterKey}_${workAreaId}`;
}

function getActiveOrderFilterKey(workAreaId: string): string {
  return `${activeOrderFilterKey}_${workAreaId}`;
}

function getHistoryOrderFilterKey(workAreaId: string): string {
  return `${historyOrderFilterKey}_${workAreaId}`;
}

function getTableColumnOrderKey(tableKey: string): string {
  return `${tableColumnOrderKey}_${tableKey}`;
}
