import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { DialogHeaderColorClass } from 'core/models';
import { ModalDialogService } from 'library/components/modal-dialog/service/modal-dialog.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmModal } from './confirm-modal.model';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent implements OnDestroy {
  contentModel$: Observable<ConfirmModal>;
  emunColorsHeader = DialogHeaderColorClass;
  ngUnsubscribe = new Subject<void>();
  isDismissable = false;
  modalIsOpen = false;

  constructor(
    private readonly modalService: ModalDialogService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.contentModel$ = this.modalService.modalData$;
    this.subscribeToModalIsOpen();
  }

  private subscribeToModalIsOpen(): void {
    this.modalService.modalIsOpen$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isModalOpen => {
      this.modalIsOpen = isModalOpen;
      this.isDismissable = true;
      this.cdRef.markForCheck();
    });
  }

  onCancelButton(): void {
    this.modalIsOpen = false;
    this.modalService.confirmModalClose(false);
  }

  onConfirmButton(): void {
    this.modalIsOpen = false;
    this.modalService.confirmModalClose(true);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
