<ds-box class="mb-4x" data-cy="card">
  <ds-box-header>
    <app-labeled-input
      label="{{
        'settings.featureToggles.jobAssignmentSection.jobAssignment' | translate | uppercase
      }}"></app-labeled-input>
  </ds-box-header>
  <ds-box-content>
    <app-toggle
      data-cy="matrixOptimizationToggle"
      translationKey="settings.functions.matrixOptimization"
      description="settings.featureToggles.jobAssignmentSection.matrixOptimizationDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleMissionAssignment' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.matrixOptimization"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleMissionAssignment'"
      (saveToggle)="onChangeMatrixOptimization($event)"></app-toggle>
    <app-toggle
      data-cy="brakeTestToggle"
      translationKey="settings.functions.brakeTest"
      description="settings.featureToggles.jobAssignmentSection.brakeTestDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleBrakeTest' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.brakeTest"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleMissionAssignment'"
      (saveToggle)="onChangeBrakeTest($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-header>
    <app-labeled-input
      label="{{
        'settings.featureToggles.orderGatewaySection.header' | translate | uppercase
      }}"></app-labeled-input>
  </ds-box-header>
  <ds-box-content>
    <app-toggle
      data-cy="pktInterfaceToggle"
      translationKey="settings.functions.pktInterfaceToggle"
      description="settings.featureToggles.orderGatewaySection.pktInterfaceToggleDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.TogglePktInterface' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.pktInterfaceToggle"
      [isLoaded]="orderGatewayFeaturesLoaded"
      [requiredPermission]="'TogglePktInterface'"
      (saveToggle)="onChangeTogglePktInterface($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="includeTuggerTrainErrorsToggle"
      translationKey="settings.functions.includeTuggerTrainErrors"
      description="settings.featureToggles.fleetServiceSection.includeTuggerTrainErrorsDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleErrorMessagesTuggerTrain' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.includeTuggerTrainErrors"
      [isLoaded]="ipstServiceFeaturesLoaded"
      [requiredPermission]="'ToggleErrorMessagesTuggerTrain'"
      (saveToggle)="onChangeIncludeTuggerTrainErrors($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card" *ngIf="graphManagerEnabled && graphManagerFeaturesLoaded">
  <ds-box-header>
    <app-labeled-input
      label="{{
        'settings.functions.graphManager.title' | translate | uppercase
      }}"></app-labeled-input>
  </ds-box-header>
  <ds-box-content class="restartGraphManagerDescription">
    {{ 'settings.functions.graphManager.restartDescription' | translate }}
  </ds-box-content>
  <ds-box-content>
    <button
      data-cy="restartGraphManagerButton"
      ds-button
      icon="refresh"
      size="md"
      variant="outline-destructive"
      (click)="onRestartGraphManager()">
      {{ 'settings.functions.graphManager.restart' | translate }}
    </button>
  </ds-box-content>
</ds-box>

<!-- <ds-box class="mb-4x" data-cy="card" *ngIf="showDeveloperOptions">
  <ds-box-content>
    <app-toggle
      data-cy="newIpstToggle"
      translationKey="settings.functions.newIpst"
      description="settings.featureToggles.ipstSection.ipstToggleDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleNewIpst' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.newIpstToggle"
      [isLoaded]="ipstServiceFeaturesLoaded"
      [requiredPermission]="'TogglePktInterface'"
      (saveToggle)="onChangeNewIpstToggle($event)"></app-toggle>
  </ds-box-content>
</ds-box> -->

<app-functions-modal
  [isModalOpen]="isModalOpen"
  [modalInputData]="modalInputData"
  (confirm)="onConfirm($event)">
</app-functions-modal>
