/* eslint-disable max-lines */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import {
  BaseVehicleDto,
  FleetDto,
  MapVehicleUpdate,
  MissionTraceDto,
  VehicleAwarenessDto,
  VehicleConflictAreaDimensions,
  VehicleDetailsSignalRDto,
  VehicleDto,
  VehicleListSignalrDto,
  VehicleMapAssociation,
  VehicleSpecification,
  VehicleWaitingDto,
  WakeOtherVehiclesDto,
} from 'core/dtos';
import {
  GuidString,
  HardwareVersion,
  MissionDispatch,
  Pose2D,
  ReducedVehicle,
  VehicleMode,
  VehicleType,
  WorkingArea,
} from 'core/models';

export enum VehiclesTypes {
  LoadVehicles = '[Vehicles] Load Vehicles',
  LoadVehiclesSuccess = '[Vehicles] Load Vehicles Success',
  LoadVehiclesFailure = '[Vehicles] Load Vehicles Failure',

  AddVehicle = '[Vehicles] Add Vehicle',
  AddVehicleSuccess = '[Vehicles] Add Vehicle Success',
  AddVehicleFailure = '[Vehicles] Add Vehicle Failure',

  AddEmulatorVehicle = '[Vehicles] Add Emulator Vehicle',
  AddEmulatorVehicleSuccess = '[Vehicles] Add Emulator Vehicle Success',
  AddEmulatorVehicleFailure = '[Vehicles] Add Emulator Vehicle Failure',
  AddEmulatorVehicleToStoreSuccess = '[Vehicles] Add Emulator Vehicle To Store Success',
  UpdateEmulatorVehicle = '[Vehicles] Update Emulator Vehicle',
  UpdateEmulatorVehicleSuccess = '[Vehicles] Update Emulator Vehicle Success',
  UpdateEmulatorVehicleFailure = '[Vehicles] Update Emulator Vehicle Failure',

  DeleteVehicle = '[Vehicles] Delete Vehicle',
  DeleteEmulatorVehicle = '[Vehicles] Delete Emulator Vehicle',
  DeleteVehicleSuccess = '[Vehicles] Delete Vehicle Success',
  DeleteVehicleFailure = '[Vehicles] Delete Vehicle Failure',
  DeleteVehicleByFleetIdSuccess = '[Vehicles] Delete Vehicles By Fleet Id Success',

  UpdateFleetIds = '[Vehicles] Update Fleet Ids',

  UpdateVehicle = '[Vehicles] Update Vehicle',
  UpdateVehicleSuccess = '[Vehicles] Update Vehicle Success',
  UpdateVehicleFailure = '[Vehicles] Update Vehicle Failure',

  UngroupVehicle = '[Vehicles] Ungroup Vehicle',
  UngroupVehicleSuccess = '[Vehicles] Ungroup Vehicle Success',
  UngroupVehicleFailure = '[Vehicles] Ungroup Vehicle Failure',
  UngroupVehicleByFleetIdSuccess = '[Vehicles] Ungroup Vehicles By Fleet Id Success',

  UpdateMap = '[Vehicles] Update Map',
  UpdateMapSuccess = '[Vehicles] Update Map Success',
  UpdateMapFailure = '[Vehicles] Update Map Failure',

  UpdateZoneSet = '[Vehicles] Update Zone Set',
  UpdateZoneSetSuccess = '[Vehicles] Update Zone Set Success',
  UpdateZoneSetFailure = '[Vehicles] Update Zone Set Failure',

  LocalizeVehicle = '[Vehicles] Localize Vehicle',
  LocalizeVehicleSuccess = '[Vehicles] Localize Vehicle Success',
  LocalizeVehicleFailure = '[Vehicles] Localize Vehicle Failure',

  UpdateMaintenanceMode = '[Vehicles] Update Maintenance Mode',
  UpdateMaintenanceModeSuccess = '[Vehicles] Update Maintenance Mode Success',
  UpdateMaintenanceModeFailure = '[Vehicles] Update Maintenance Mode Failure',

  MassUpdateMaintenanceMode = '[Vehicles] Mass Update Maintenance Mode',
  MassUpdateMaintenanceModeSuccess = '[Vehicles] Mass Update Maintenance Mode Success',
  MassUpdateMaintenanceModeFailure = '[Vehicles] Mass Update Maintenance Mode Failure',

  UpdateErrorForwarding = '[Vehicles] Update Error Forwarding',
  UpdateErrorForwardingSuccess = '[Vehicles] Update Error Forwarding Success',
  UpdateErrorForwardingFailure = '[Vehicles] Update Error Forwarding Failure',

  PauseVehicle = '[Vehicles] Pause Vehicle',
  PauseVehicleSuccess = '[Vehicles] Pause Vehicle Success',
  PauseVehicleFailure = '[Vehicles] Pause Vehicle Failure',

  ResumeVehicle = '[Vehicles] Resume Vehicle',
  ResumeVehicleSuccess = '[Vehicles] Resume Vehicle Success',
  ResumeVehicleFailure = '[Vehicles] Resume Vehicle Failure',

  ResetEmulator = '[Emulators] Reset Emulator',
  ResetEmulatorSuccess = '[Emulators] Reset Emulator Success',
  ResetEmulatorFailure = '[Emulators] Reset Emulator Failure',

  SetEmulatorMode = '[Emulators] Set Emulator Mode',
  SetEmulatorModeSuccess = '[Emulators] Set Emulator Mode Success',
  SetEmulatorModeFailure = '[Emulators] Set Emulator Mode Failure',

  ToggleEmulatorPath = '[Emulators] Toggle Emulator Path',
  ToggleEmulatorPathSuccess = '[Emulators] Toggle Emulator Path Success',
  ToggleEmulatorPathFailure = '[Emulators] Toggle Emulator Path Failure',

  TriggerEmulatorError = '[Emulators] Trigger Emulator Error',
  TriggerEmulatorErrorSuccess = '[Emulators] Trigger Emulator Error Success',
  TriggerEmulatorErrorFailure = '[Emulators] Trigger Emulator Error Failure',

  TriggerClearEmulatorError = '[Emulators] Trigger Clear Emulator Error',
  TriggerClearEmulatorErrorSuccess = '[Emulators] Trigger Clear Emulator Error Success',
  TriggerClearEmulatorErrorFailure = '[Emulators] Trigger Clear Emulator Error Failure',

  RequestFactsheet = '[Vehicles] Request Factsheet',
  RequestFactsheetSuccess = '[Vehicles] Request Factsheet Success',
  RequestFactsheetFailure = '[Vehicles] Request Factsheet Failure',

  SendBrakeTest = '[Vehicles] Send Brake Test',
  SendBrakeTestSuccess = '[Vehicles] Send Brake Test Success',
  SendBrakeTestFailure = '[Vehicles] Send Brake Test Failure',

  SendMission = '[Vehicles] Send Mission',
  SendMissionSuccess = '[Vehicles] Send Mission Success',
  SendMissionFailure = '[Vehicles] Send Mission Failure',

  SendAdHocPose = '[Vehicles] Send AdHoc Pose',
  SendAdHocPoseSuccess = '[Vehicles] Send AdHoc Pose Success',
  SendAdHocPoseFailure = '[Vehicles] Send AdHoc Pose Failure',

  ChangeWorkingArea = '[Vehicles] Change Working Area Vehicle',
  ChangeWorkingAreaSuccess = '[Vehicles] Change Working Area Vehicle Success',
  ChangeWorkingAreaFailure = '[Vehicles] Change Working Area Vehicle Failure',

  SelectVehicle = '[Vehicles] Select Vehicle',

  SendWakeOtherVehicles = '[Vehicles] Send Wake Other Vehicles',
  SendWakeOtherVehiclesSuccess = '[Vehicles] Send Wake Other Vehicles Success',
  SendWakeOtherVehiclesFailure = '[Vehicles] Send Wake Other Vehicles Failure',
}

export enum SignalRVehicleTypes {
  AddVehicle = '[SignalR] Add Vehicle',
  AddVehicleSuccess = '[SignalR] Add Vehicle Success',

  DeleteVehicle = '[SignalR] Delete Vehicle',

  UpdateVehicle = '[SignalR] Update Vehicle',

  UpdateVehicleMapLocation = '[SignalR] Update Vehicle Map Location',
  UpdateVehicleListDetails = '[SignalR] Update Vehicle List Details',
  UpdateIntersectionZonePath = '[SignalR] Update Intersection Zone Path',
  UpdateVehicleAwareness = '[SignalR] Update Vehicle Awareness',
  UpdateVehicleWaiting = '[SignalR] Update Vehicle Waiting',
  UpdateVehicleConflictAreaDimensions = '[SignalR] Update Vehicle Conflict Area Dimensions',
  AddVehicleMapAssociation = '[SignalR] Add Vehicle map association',
  UpdateVehicleMapAssociation = '[SignalR] Update Vehicle map association',
  DeleteVehicleMapAssociation = '[SignalR] Delete Vehicle map association',
}

export const loadVehicles = createAction(VehiclesTypes.LoadVehicles);

export const loadVehiclesSuccess = createAction(
  VehiclesTypes.LoadVehiclesSuccess,
  props<{ vehicles: VehicleDto[] }>()
);

export const loadVehiclesFailure = createAction(
  VehiclesTypes.LoadVehiclesFailure,
  props<{ errorMessage: string }>()
);

export const selectVehicle = createAction(
  VehiclesTypes.SelectVehicle,
  props<{ vehicleId: GuidString }>()
);

export const addVehicle = createAction(
  VehiclesTypes.AddVehicle,
  props<{ newVehicle: BaseVehicleDto }>()
);

export const addVehicleSuccess = createAction(
  VehiclesTypes.AddVehicleSuccess,
  props<{ vehicleCreated: VehicleDto }>()
);

export const addVehicleFailure = createAction(
  VehiclesTypes.AddVehicleFailure,
  props<{ errorMessage: string }>()
);

export const addEmulatorVehicleToStoreSuccess = createAction(
  VehiclesTypes.AddEmulatorVehicleToStoreSuccess,
  props<{ vehicleCreated: VehicleDto }>()
);

export const addEmulatorVehicle = createAction(
  VehiclesTypes.AddEmulatorVehicle,
  props<{ newEmulatorVehicle: VehicleSpecification }>()
);

export const updateEmulatorVehicle = createAction(
  VehiclesTypes.UpdateEmulatorVehicle,
  props<{ emulatorVehicle: VehicleSpecification }>()
);

export const addEmulatorVehicleSuccess = createAction(
  VehiclesTypes.AddEmulatorVehicleSuccess,
  props<{ vehicleCreated: VehicleSpecification }>()
);

export const updateEmulatorVehicleSuccess = createAction(
  VehiclesTypes.UpdateEmulatorVehicleSuccess,
  props<{ vehicleUpdated: VehicleSpecification }>()
);

export const addEmulatorVehicleFailure = createAction(
  VehiclesTypes.AddEmulatorVehicleFailure,
  props<{ errorMessage: string }>()
);

export const updateEmulatorVehicleFailure = createAction(
  VehiclesTypes.UpdateEmulatorVehicleFailure,
  props<{ errorMessage: string }>()
);

export const deleteVehicle = createAction(
  VehiclesTypes.DeleteVehicle,
  props<{ vehicle: { id: GuidString; name: string } }>()
);

export const deleteEmulatorVehicle = createAction(
  VehiclesTypes.DeleteEmulatorVehicle,
  props<{ vehicle: { id: GuidString; name: string } }>()
);

export const deleteVehicleSuccess = createAction(
  VehiclesTypes.DeleteVehicleSuccess,
  props<{ vehicle: { id: GuidString; name: string } }>()
);

export const deleteVehicleFailure = createAction(
  VehiclesTypes.DeleteVehicleFailure,
  props<{ errorMessage: string }>()
);

export const deleteVehiclesByFleetId = createAction(
  VehiclesTypes.DeleteVehicleByFleetIdSuccess,
  props<{ fleetId: GuidString }>()
);

export const updateFleetIds = createAction(
  VehiclesTypes.UpdateFleetIds,
  props<{ fleetId: GuidString; vehicles: VehicleDto[] }>()
);

export const updateVehicle = createAction(
  VehiclesTypes.UpdateVehicle,
  props<{ vehicle: ReducedVehicle }>()
);

export const updateVehicleSuccess = createAction(
  VehiclesTypes.UpdateVehicleSuccess,
  props<{ vehicle: Update<VehicleDto> }>()
);

export const updateVehicleFailure = createAction(
  VehiclesTypes.UpdateVehicleFailure,
  props<{ errorMessage: string }>()
);

export const ungroupVehicle = createAction(
  VehiclesTypes.UngroupVehicle,
  props<{ vehicle: { id: GuidString; name: string }; fleet: FleetDto }>()
);

export const ungroupVehicleSuccess = createAction(
  VehiclesTypes.UngroupVehicleSuccess,
  props<{ vehicle: Update<VehicleDto> }>()
);

export const ungroupVehicleFailure = createAction(
  VehiclesTypes.UngroupVehicleFailure,
  props<{ errorMessage: string }>()
);

export const ungroupVehiclesByFleetId = createAction(
  VehiclesTypes.UngroupVehicleByFleetIdSuccess,
  props<{ fleetId: GuidString }>()
);

export const updateMap = createAction(
  VehiclesTypes.UpdateMap,
  props<{ vehicleId: GuidString; vehicleName: string; mapId: GuidString; mapName: string }>()
);

export const updateMapSuccess = createAction(
  VehiclesTypes.UpdateMapSuccess,
  props<{ vehicleId: GuidString; vehicleName: string; mapId: GuidString; mapName: string }>()
);

export const updateMapFailure = createAction(
  VehiclesTypes.UpdateMapFailure,
  props<{ errorMessage: string }>()
);

export const updateZoneSet = createAction(
  VehiclesTypes.UpdateZoneSet,
  props<{ vehicleId: GuidString; zoneSetId: GuidString }>()
);

export const updateZoneSetSuccess = createAction(
  VehiclesTypes.UpdateZoneSetSuccess,
  props<{ vehicleId: GuidString; zoneSetId: GuidString }>()
);

export const updateZoneSetFailure = createAction(
  VehiclesTypes.UpdateZoneSetFailure,
  props<{ errorMessage: string }>()
);

export const localizeVehicle = createAction(
  VehiclesTypes.LocalizeVehicle,
  props<{ vehicleId: GuidString; vehicleName: string; mapId: GuidString; pose: Pose2D }>()
);

export const localizeVehicleSuccess = createAction(
  VehiclesTypes.LocalizeVehicleSuccess,
  props<{ vehicleId: GuidString; vehicleName: string; mapId: GuidString; pose: Pose2D }>()
);

export const localizeVehicleFailure = createAction(
  VehiclesTypes.LocalizeVehicleFailure,
  props<{ errorMessage: string; mapId: GuidString }>()
);

export const changeWorkingArea = createAction(
  VehiclesTypes.ChangeWorkingArea,
  props<{ vehicleId: GuidString; workingAreaSelected: WorkingArea }>()
);

export const changeWorkingAreaSuccess = createAction(
  VehiclesTypes.ChangeWorkingAreaSuccess,
  props<{ transferredVehicle: VehicleDto; workingAreaSelected: WorkingArea }>()
);

export const changeWorkingAreaFailure = createAction(
  VehiclesTypes.ChangeWorkingAreaFailure,
  props<{ errorMessage: string }>()
);

export const updateMaintenanceMode = createAction(
  VehiclesTypes.UpdateMaintenanceMode,
  props<{ vehicle: ReducedVehicle }>()
);

export const updateMaintenanceModeSuccess = createAction(
  VehiclesTypes.UpdateMaintenanceModeSuccess,
  props<{ vehicle: Update<VehicleDto> }>()
);

export const updateMaintenanceModeFailure = createAction(
  VehiclesTypes.UpdateMaintenanceModeFailure,
  props<{ errorMessage: string }>()
);

export const massUpdateMaintenanceMode = createAction(
  VehiclesTypes.MassUpdateMaintenanceMode,
  props<{ vehicleIds: GuidString[] }>()
);

export const massUpdateMaintenanceModeSuccess = createAction(
  VehiclesTypes.MassUpdateMaintenanceModeSuccess,
  props<{ vehicleIds: GuidString[] }>()
);

export const massUpdateMaintenanceModeFailure = createAction(
  VehiclesTypes.MassUpdateMaintenanceModeFailure,
  props<{ errorMessage: string }>()
);

export const updateErrorForwarding = createAction(
  VehiclesTypes.UpdateErrorForwarding,
  props<{ vehicle: ReducedVehicle }>()
);

export const updateErrorForwardingSuccess = createAction(
  VehiclesTypes.UpdateErrorForwardingSuccess,
  props<{ vehicle: Update<VehicleDto> }>()
);

export const updateErrorForwardingFailure = createAction(
  VehiclesTypes.UpdateErrorForwardingFailure,
  props<{ errorMessage: string }>()
);

export const setEmulatorMode = createAction(
  VehiclesTypes.SetEmulatorMode,
  props<{ vehicleId: GuidString; mode: VehicleMode }>()
);

export const setEmulatorModeSuccess = createAction(
  VehiclesTypes.SetEmulatorModeSuccess,
  props<{ vehicleId: GuidString; mode: VehicleMode }>()
);

export const setEmulatorModeFailure = createAction(
  VehiclesTypes.SetEmulatorModeFailure,
  props<{ errorMessage: string }>()
);

export const toggleEmulatorPath = createAction(
  VehiclesTypes.ToggleEmulatorPath,
  props<{ vehicleId: GuidString; isRealPathActive: boolean }>()
);

export const toggleEmulatorPathSuccess = createAction(
  VehiclesTypes.ToggleEmulatorPathSuccess,
  props<{ vehicleId: GuidString; isRealPathActive: boolean }>()
);

export const toggleEmulatorPathFailure = createAction(
  VehiclesTypes.ToggleEmulatorPathFailure,
  props<{ errorMessage: string }>()
);

export const triggerEmulatorError = createAction(
  VehiclesTypes.TriggerEmulatorError,
  props<{ vehicleId: GuidString; vehicleType: VehicleType; hardwareVersion: HardwareVersion }>()
);

export const triggerEmulatorErrorSuccess = createAction(
  VehiclesTypes.TriggerEmulatorErrorSuccess,
  props<{ vehicleId: GuidString }>()
);

export const triggerEmulatorErrorFailure = createAction(
  VehiclesTypes.TriggerEmulatorErrorFailure,
  props<{ errorMessage: string }>()
);

export const triggerClearEmulatorError = createAction(
  VehiclesTypes.TriggerClearEmulatorError,
  props<{ vehicleId: GuidString }>()
);

export const triggerClearEmulatorErrorSuccess = createAction(
  VehiclesTypes.TriggerClearEmulatorErrorSuccess,
  props<{ vehicleId: GuidString }>()
);

export const triggerClearEmulatorErrorFailure = createAction(
  VehiclesTypes.TriggerClearEmulatorErrorFailure,
  props<{ errorMessage: string }>()
);

export const pauseVehicle = createAction(
  VehiclesTypes.PauseVehicle,
  props<{ vehicleId: GuidString }>()
);

export const pauseVehicleSuccess = createAction(
  VehiclesTypes.PauseVehicleSuccess,
  props<{ vehicleId: GuidString }>()
);

export const pauseVehicleFailure = createAction(
  VehiclesTypes.PauseVehicleFailure,
  props<{ errorMessage: string }>()
);

export const resumeVehicle = createAction(
  VehiclesTypes.ResumeVehicle,
  props<{ vehicleId: GuidString }>()
);

export const resumeVehicleSuccess = createAction(
  VehiclesTypes.ResumeVehicleSuccess,
  props<{ vehicleId: GuidString }>()
);

export const resumeVehicleFailure = createAction(
  VehiclesTypes.ResumeVehicleFailure,
  props<{ errorMessage: string }>()
);

export const resetEmulator = createAction(
  VehiclesTypes.ResetEmulator,
  props<{ vehicleId: GuidString }>()
);

export const sendBrakeTest = createAction(
  VehiclesTypes.SendBrakeTest,
  props<{ vehicleId: GuidString }>()
);

export const sendBrakeTestSuccess = createAction(
  VehiclesTypes.SendBrakeTestSuccess,
  props<{ vehicleId: GuidString }>()
);

export const sendBrakeTestFailure = createAction(
  VehiclesTypes.SendBrakeTestFailure,
  props<{ errorMessage: string }>()
);

export const requestFactsheet = createAction(
  VehiclesTypes.RequestFactsheet,
  props<{ vehicleId: GuidString }>()
);

export const requestFactsheetSuccess = createAction(
  VehiclesTypes.RequestFactsheetSuccess,
  props<{ vehicleId: GuidString }>()
);

export const requestFactsheetFailure = createAction(
  VehiclesTypes.RequestFactsheetFailure,
  props<{ errorMessage: string }>()
);

export const sendMission = createAction(
  VehiclesTypes.SendMission,
  props<{ missionDispatch: MissionDispatch }>()
);

export const sendMissionSuccess = createAction(
  VehiclesTypes.SendMissionSuccess,
  props<{ missionDispatch: MissionDispatch }>()
);

export const sendMissionFailure = createAction(
  VehiclesTypes.SendMissionFailure,
  props<{ errorMessage: string }>()
);

export const sendAdHocPose = createAction(
  VehiclesTypes.SendAdHocPose,
  props<{ vehicleId: GuidString; pose: Pose2D; mapId: GuidString }>()
);

export const sendAdHocPoseSuccess = createAction(
  VehiclesTypes.SendAdHocPoseSuccess,
  props<{ missionTrace: MissionTraceDto }>()
);

export const sendAdHocPoseFailure = createAction(
  VehiclesTypes.SendAdHocPoseFailure,
  props<{ errorMessage: string }>()
);

export const resetEmulatorSuccess = createAction(
  VehiclesTypes.ResetEmulatorSuccess,
  props<{ vehicleId: GuidString }>()
);

export const resetEmulatorFailure = createAction(
  VehiclesTypes.ResetEmulatorFailure,
  props<{ errorMessage: string }>()
);

export const signalRAddVehicle = createAction(
  SignalRVehicleTypes.AddVehicle,
  props<{ vehicle: VehicleDetailsSignalRDto }>()
);

export const signalRAddVehicleSuccess = createAction(
  SignalRVehicleTypes.AddVehicleSuccess,
  props<{ vehicleCreated: VehicleDto }>()
);

export const signalRAddVehicleMapAssociation = createAction(
  SignalRVehicleTypes.AddVehicleMapAssociation,
  props<{ vehicleMapAssociation: VehicleMapAssociation }>()
);

export const signalRDeleteVehicleMapAssociation = createAction(
  SignalRVehicleTypes.DeleteVehicleMapAssociation,
  props<{ vehicleMapAssociation: VehicleMapAssociation }>()
);

export const signalRUpdateVehicleMapAssociation = createAction(
  SignalRVehicleTypes.UpdateVehicleMapAssociation,
  props<{ vehicleMapAssociation: VehicleMapAssociation }>()
);

export const signalRDeleteVehicle = createAction(
  SignalRVehicleTypes.DeleteVehicle,
  props<{ vehicle: object }>()
);

export const signalRVehicleDetailsDeleteVehicle = createAction(
  SignalRVehicleTypes.DeleteVehicle,
  props<{ vehicle: Update<VehicleDto> }>()
);

export const signalRUpdateVehicle = createAction(
  SignalRVehicleTypes.UpdateVehicle,
  props<{ vehicle: VehicleDetailsSignalRDto }>()
);

export const signalRVehicleWaiting = createAction(
  SignalRVehicleTypes.UpdateVehicleWaiting,
  props<{ message: VehicleWaitingDto }>()
);

export const sendWakeOtherVehicles = createAction(VehiclesTypes.SendWakeOtherVehicles);

export const sendWakeOtherVehiclesSuccess = createAction(
  VehiclesTypes.SendWakeOtherVehiclesSuccess,
  props<{ response: WakeOtherVehiclesDto }>()
);

export const sendWakeOtherVehiclesFailure = createAction(
  VehiclesTypes.SendWakeOtherVehiclesFailure,
  props<{ errorMessage: string }>()
);

export const signalRUpdateVehicleConflictAreaDimensions = createAction(
  SignalRVehicleTypes.UpdateVehicleConflictAreaDimensions,
  props<{ vehicleConflictAreaDimensions: Update<VehicleConflictAreaDimensions> }>()
);

export const signalRUpdateVehicleAwareness = createAction(
  SignalRVehicleTypes.UpdateVehicleAwareness,
  props<{ vehicleLocation: Update<VehicleAwarenessDto> }>()
);

export const vehicleListMessagesReceived = createAction(
  SignalRVehicleTypes.UpdateVehicleListDetails,
  props<{ vehicleMessages: VehicleListSignalrDto[] }>()
);

export const signalRLocationMapUpdatePolled = createAction(
  SignalRVehicleTypes.UpdateVehicleMapLocation,
  props<{ vehicleUpdates: MapVehicleUpdate[] }>()
);
