import { createAction, props } from '@ngrx/store';
import { CreateNodeGroupDto, NodeGroupDto } from 'core/dtos';
import { GuidString } from 'core/models';

export enum NodeGroupActionTypes {
  LoadNodeGroups = '[GraphManager] Load Node Groups',
  LoadNodeGroupsSuccess = '[GraphManager] Load Node Groups Success',
  LoadNodeGroupsFailure = '[GraphManager] Load Node Groups Failure',

  SelectNodeGroup = '[GraphManager] Select Node Group',

  CreateNodeGroup = '[GraphManager] Create Node Group',
  CreateNodeGroupSuccess = '[GraphManager] Create Node Group Success',
  CreateNodeGroupFailure = '[GraphManager] Create Node Group Failure',

  UpdateNodeGroup = '[GraphManager] Update Node Group',
  UpdateNodeGroupSuccess = '[GraphManager] Update Node Group Success',
  UpdateNodeGroupFailure = '[GraphManager] Update Node Group Failure',

  DeleteNodeGroup = '[GraphManager] Delete Node Group',
  DeleteNodeGroupSuccess = '[GraphManager] Delete Node Group Success',
  DeleteNodeGroupFailure = '[GraphManager] Delete Node Group Failure',
}
export const loadNodeGroups = createAction(NodeGroupActionTypes.LoadNodeGroups);

export const loadNodeGroupsSuccess = createAction(
  NodeGroupActionTypes.LoadNodeGroupsSuccess,
  props<{ nodeGroups: NodeGroupDto[] }>()
);

export const loadNodeGroupsFailure = createAction(
  NodeGroupActionTypes.LoadNodeGroupsFailure,
  props<{ errorMessage: string }>()
);

export const selectNodeGroup = createAction(
  NodeGroupActionTypes.SelectNodeGroup,
  props<{ nodeGroupId: GuidString }>()
);

export const createNodeGroup = createAction(
  NodeGroupActionTypes.CreateNodeGroup,
  props<{ nodeGroup: CreateNodeGroupDto }>()
);

export const createNodeGroupSuccess = createAction(
  NodeGroupActionTypes.CreateNodeGroupSuccess,
  props<{ nodeGroup: NodeGroupDto }>()
);

export const createNodeGroupFailure = createAction(
  NodeGroupActionTypes.CreateNodeGroupFailure,
  props<{ errorMessage: string }>()
);

export const updateNodeGroup = createAction(
  NodeGroupActionTypes.UpdateNodeGroup,
  props<{ nodeGroup: NodeGroupDto }>()
);

export const updateNodeGroupSuccess = createAction(
  NodeGroupActionTypes.UpdateNodeGroupSuccess,
  props<{ nodeGroup: NodeGroupDto }>()
);

export const updateNodeGroupFailure = createAction(
  NodeGroupActionTypes.UpdateNodeGroupFailure,
  props<{ errorMessage: string }>()
);

export const deleteNodeGroup = createAction(
  NodeGroupActionTypes.DeleteNodeGroup,
  props<{ nodeGroup: NodeGroupDto }>()
);

export const deleteNodeGroupSuccess = createAction(
  NodeGroupActionTypes.DeleteNodeGroupSuccess,
  props<{ nodeGroup: NodeGroupDto }>()
);

export const deleteNodeGroupFailure = createAction(
  NodeGroupActionTypes.DeleteNodeGroupFailure,
  props<{ errorMessage: string }>()
);
