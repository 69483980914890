/* eslint-disable max-lines */
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { GraphNode, GraphNodeIcon } from 'core/models';
import { Colors } from 'library/styles';
import { MapPixiHelper } from 'modules/maps/helpers';
import { NodeGraphicConfig } from 'modules/maps/models';
import { Graphics, Sprite, Texture } from 'pixi.js';
import { calculateOffsetFromCenter } from 'shared/helpers';
import { GraphicsEx } from '../pixi';
import { BaseNodeMapItem } from './base-node-graphic';
import { NodeName, NodeStyle } from './graph-layer.constant';
import { NodeIconOptions } from './node-icon.model';

const textures: Record<GraphNodeIcon, Texture> = {
  [GraphNodeIcon.Fueling]: MapPixiHelper.createTexture(NodeIconOptions[GraphNodeIcon.Fueling]),
  [GraphNodeIcon.StoppingPoint]: MapPixiHelper.createTexture(
    NodeIconOptions[GraphNodeIcon.StoppingPoint]
  ),
  [GraphNodeIcon.Parking]: MapPixiHelper.createTexture(NodeIconOptions[GraphNodeIcon.Parking]),

  [GraphNodeIcon.Charging]: MapPixiHelper.createTexture(NodeIconOptions[GraphNodeIcon.Charging]),
};

export class NodeMapItem extends BaseNodeMapItem {
  private childIcon: Sprite | undefined;

  // #region Create Graphic
  protected getNodeConfig(node: GraphNode): NodeGraphicConfig {
    return {
      ...super.getNodeConfig(node),
    };
  }

  protected getNodeColor(node: GraphNode): number {
    if (node.isSwitchNode) return Colors.graphLayer.NodeColorSwitch;

    if (!this.isRoundGraphic(node)) return Colors.graphLayer.NodePoiColor;

    return Colors.graphLayer.NodeColor;
  }

  protected createNodePoiBackground(): Graphics {
    const nodeColor = this.getNodeColor(this.node);

    const baseBackground = new Graphics()
      .beginFill(nodeColor)
      .lineStyle(NodeStyle.NodeWidth, nodeColor)
      .drawRoundedRect(
        -NodeStyle.NodeOutlineWidth2 / 2,
        -NodeStyle.NodeOutlineLength / 2,
        NodeStyle.NodeOutlineWidth2,
        NodeStyle.NodeOutlineLength,
        NodeStyle.NodeOutlineRadius
      )
      .endFill();

    baseBackground
      .beginFill(NodeStyle.NodeHeaderBackgroundColor)
      .drawRoundedRect(
        -NodeStyle.NodeHeaderX,
        -NodeStyle.NodeHeaderY,
        NodeStyle.NodeHeaderWidth,
        NodeStyle.NodeHeaderLength,
        NodeStyle.NodeHeaderRadius
      )
      .endFill();

    return baseBackground;
  }

  protected createRoundGraphic(config: NodeGraphicConfig): GraphicsEx {
    let graphic = new GraphicsEx();
    graphic = super.createRoundGraphic(config);

    if (config.icon !== undefined) {
      graphic.addChild((this.icon = this.createIcon(config.icon)));
    }

    return graphic;
  }

  protected createSquareGraphic(config: NodeGraphicConfig): Graphics {
    const graphic = this.createNodePoiBackground();
    graphic.scale.set(NodeStyle.NodeScale);

    if (config.icon !== undefined) {
      graphic.addChild((this.childIcon = this.createIcon(config.icon)));
    }

    return graphic;
  }

  protected createIcon(icon: GraphNodeIcon): Sprite {
    return MapPixiHelper.createIcon(textures[icon], NodeIconOptions[icon]);
  }

  protected createSquareGraphicSelected(): Graphics {
    if (!this.nodeGraphic) return new Graphics();

    const selection = new Graphics()
      .lineStyle(NodeStyle.NodeBorderWidth, NodeStyle.NodeBorderColorSelected)
      .drawRoundedRect(
        NodeStyle.NodeOutlineX,
        NodeStyle.NodeOutlineY,
        NodeStyle.NodeOutlineWidth2,
        NodeStyle.NodeOutlineLength,
        NodeStyle.NodeOutlineRadius
      );

    selection.name = 'selection';
    selection.filters = [new DropShadowFilter()];
    selection.visible = this.selection?.visible ?? false;

    return selection;
  }
  // #endregion

  // #region Rotate
  rotateLabel(): void {
    const rotationRadians =
      this.container.rotation +
      (this.labelGraphic?.rotation ?? 0) +
      NodeStyle.NodeBorderWidth +
      NodeName.padding +
      (this.labelGraphic?.height ?? 0);

    const rotationOffset = calculateOffsetFromCenter(
      rotationRadians,
      this.nodeGraphic?.height ?? 0,
      this.nodeGraphic?.width ?? 0
    );

    this.labelGraphic?.pivot.set(
      this.labelGraphic?.width / 2,
      rotationOffset + NodeStyle.NodeBorderWidth / 2
    );
  }

  onMapRotation(mapRotation: number): void {
    if (this.labelGraphic) this.labelGraphic.rotation = mapRotation;

    if (this.config?.isRound) {
      if (this.icon) this.icon.rotation = mapRotation;
    } else {
      this.rotateLabel();
      if (this.childIcon) this.childIcon.rotation = mapRotation;
    }
  }
  // #endregion
}
