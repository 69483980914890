<form [formGroup]="form">
  <div class="three-columns">
    <div class="">
      <ds-form-field>
        <label ds-label class="ds-label" for="url">
          {{ 'settings.ipstAlertNowSettings.alertNowService.alertNowUrl' | translate }}</label
        >
        <input
          ds-input
          id="url"
          type="text"
          placeholder="{{ 'placeholders.define' | translate }}"
          formControlName="url"
          data-cy="urlInput" />
      </ds-form-field>
      <div
        *ngIf="alertNowFormDetails?.url?.touched && alertNowFormDetails?.url?.invalid"
        class="invalid-input-text">
        <div data-cy="invalidUrl" *ngIf="alertNowFormDetails?.url?.errors?.pattern">
          {{ 'settings.ipstAlertNowSettings.urlInvalid' | translate }}
        </div>
      </div>
    </div>
  </div>
  <ds-hint>{{ 'settings.ipstAlertNowSettings.generalAlertNowUrlInfoHint' | translate }}</ds-hint>
</form>
