import { BoxToneOptions } from '@bmw-ds/components/ds-interfaces/box.interface';

export class MenuButton {
  buttonLabel: string;
  emitterAction: number;
  visible: boolean;
  icon: string;
  tone: BoxToneOptions | undefined;
  disabled: boolean | undefined;
  disabledText: string | undefined;

  constructor(
    buttonLabel: string,
    emitterAction: number,
    visible = true,
    icon = '',
    tone?: BoxToneOptions,
    disabled?: boolean,
    disabledText?: string
  ) {
    this.buttonLabel = buttonLabel;
    this.emitterAction = emitterAction;
    this.visible = visible;
    this.icon = icon;
    this.tone = tone;
    this.disabled = disabled;
    this.disabledText = disabledText;
  }
}
