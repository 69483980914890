import { NavigationLayerResponseModel } from 'core/dtos';
import {
  GraphLayout,
  GraphNode,
  GuidString,
  MapItem,
  MapLayerType,
  VehicleGroup,
  isMapItem,
} from 'core/models';
import { MAXIMUM_OPACITY } from 'modules/maps/constants';
import { MapLayerView } from 'modules/maps/models';
import { IMapItemLayer, MapLayerBase } from '../map-layer';
import { MapLayerDrawing } from '../map-layer-drawing';
import { ActionNodeMapItem } from './action-node-graphic';
import { EdgeContainerBase } from './base-edge-container.graphic';
import { BaseNodeMapItem } from './base-node-graphic';
import { EdgeContainer } from './edge.graphic';
import { NodeMapItem } from './node.graphic';
import { SegmentContainer } from './segment.graphic';

export class GraphMapLayer extends MapLayerBase implements IMapItemLayer {
  readonly drawing: MapLayerDrawing;
  list = new Map<GuidString, BaseNodeMapItem>();

  protected edgeContainer!: EdgeContainerBase;

  constructor(
    view: MapLayerView,
    protected layout: GraphLayout,
    public navigationLayer: NavigationLayerResponseModel
  ) {
    super();

    this.drawing = view.addChild(
      new MapLayerDrawing(navigationLayer.id.toString(), MapLayerType.GraphLayer)
    );

    this.update(navigationLayer);
    this.addGraphLayerItems(layout, navigationLayer);
  }

  getLayerItem(id: GuidString): BaseNodeMapItem | undefined {
    return this.list.get(id);
  }

  update(
    layer: NavigationLayerResponseModel,
    isVisible = true,
    opacity = (layer.opacity ?? MAXIMUM_OPACITY) / MAXIMUM_OPACITY
  ): void {
    this.navigationLayer = { ...this.navigationLayer, ...layer };

    this.setVisibility(isVisible);
    this.setOpacity(opacity);
  }

  protected addGraphLayerItems(
    layout: GraphLayout,
    navigationLayer: NavigationLayerResponseModel
  ): void {
    this.edgeContainer = layout.useSegments
      ? new SegmentContainer(this.drawing, layout)
      : new EdgeContainer(this.drawing, layout);

    layout.nodes.forEach(node => {
      this.selectNodeGraphic(node, navigationLayer);
    });
  }

  selectNodeGraphic(node: GraphNode, navigationLayer: NavigationLayerResponseModel): void {
    const isNormalNode =
      navigationLayer.vehicleGroup === VehicleGroup.TuggerTrainDs ||
      navigationLayer.vehicleGroup === VehicleGroup.TuggerTrainFourAmV2 ||
      node.actions?.length === 0;

    if (isNormalNode) {
      this.list.set(node.nodeId, new NodeMapItem(this.drawing, node));
    } else {
      this.list.set(node.nodeId, new ActionNodeMapItem(this.drawing, node));
    }
  }

  setScale(scale: number, resolution?: number): void {
    this.list?.forEach(i => i.setScale(scale));
    if (scale && resolution) {
      this.edgeContainer.setScale(scale, resolution);
    }
  }

  saveNode(selectedItem: GraphNode): void {
    this.layout.saveNode(selectedItem);
  }

  updateItem(selectedItem: GraphNode): void {
    const item = this.getLayerItem(selectedItem.nodeId);
    const node = this.layout.getNode(selectedItem.nodeId);

    if (node) {
      selectedItem = {
        ...selectedItem,
        isSwitchNode: node.isSwitchNode,
        hasRule: node.hasRule,
      };
      item?.updateGraphic(selectedItem);
    }
  }

  revertItem(selectedItem: MapItem): void {
    const item = this.getLayerItem(selectedItem.id);
    const node = this.layout.getNode(selectedItem.id);

    if (node) item?.revertGraphic(node);
  }

  // #region Rotate
  rotate(ang: number): void {
    this.list.forEach(n => n.rotate(ang));
  }

  setMapRotation(orientation: number): void {
    for (const node of this.list.values()) {
      node.onMapRotation(orientation);
    }
  }

  rotateItem(itemId: GuidString, degrees: number): void {
    const selectedItem = this.getLayerItem(itemId);

    selectedItem?.rotate(degrees);
  }

  setItemRotationMode(item: MapItem, enable: boolean): void {
    const selectedItem = this.getLayerItem(item.id);

    selectedItem?.toggleRotationMode(enable);
  }
  // #endregion

  // #region Selection
  onItemSelected(item: MapItem): void {
    const selectedItem = this.getLayerItem(item.id);

    selectedItem?.toggleSelection(true);
  }

  onMapItemDeselected(unselected: MapItem | GuidString): void {
    const item = this.list.get(isMapItem(unselected) ? unselected.id : unselected);

    item?.toggleSelection(false);
  }
  // #endregion
}
