import { MapVehicle, MapVehicleUpdate, PathEdgeDto, VehicleDto } from 'core/dtos';
import { GuidString, Pos2D, Vector2D, VehicleMode } from 'core/models';
import { VehiclesTestHelper } from 'modules/vehicles/vehicles-test.helper'; // TODO: Vehicle Test data should be in Core

export const lookAheadAreaPathEdges = [
  { lineSegment: { p0: { x: 0, y: 0 }, p1: { x: 0, y: 0 } }, width: 1 },
  { lineSegment: { p0: { x: 10, y: 0 }, p1: { x: 10, y: 0 } }, width: 1 },
  { lineSegment: { p0: { x: 10, y: 10 }, p1: { x: 10, y: 10 } }, width: 1 },
];

const vehicleUpdate1: MapVehicleUpdate = {
  id: '',
  lastStateMessageProcessedUtc: '2024-09-08T08:30:00Z',
  maintenanceModeEnabled: false,
  isBusy: false,
  isCharging: false,
  isConnected: false,
  isLoaded: false,
  isPaused: false,
  isRetired: false,
  isSwitchedOff: false,
  hasError: false,
  mode: VehicleMode.Autonomous,
  name: '',
  pose2D: { x: 45, y: 12, orientation: 4 },
  path: [],
  trailers: [],
};

const vehicleUpdate2: MapVehicleUpdate = {
  id: '',
  lastStateMessageProcessedUtc: '2024-09-07T07:30:00Z',
  maintenanceModeEnabled: false,
  isBusy: false,
  isCharging: false,
  isConnected: false,
  isLoaded: false,
  isPaused: false,
  isRetired: false,
  isSwitchedOff: false,
  hasError: false,
  mode: VehicleMode.Autonomous,
  name: '',
  pose2D: { x: 55, y: 55, orientation: 0 },
  path: [],
  trailers: [],
};

export class MapVehiclesMockData {
  static getMapVehicle(): MapVehicle {
    return this.vehicleToMapVehicle(VehiclesTestHelper.getVehicleDto());
  }

  static vehicleToMapVehicle(v: VehicleDto): MapVehicle {
    const convertPath = (p?: Vector2D[]): Pos2D[] => p?.map(it => ({ theta: 0, ...it })) ?? [];
    return {
      ...v,
      path: convertPath(v.path),
      trailers: v.trailers ?? [],
    };
  }

  static vehiclesToMapVehicles(v: VehicleDto[]): MapVehicle[] {
    return v.map(it => this.vehicleToMapVehicle(it));
  }

  static getAllMapVehicles(): MapVehicle[] {
    return VehiclesTestHelper.getAllVehicleDtos().map(it => this.vehicleToMapVehicle(it));
  }

  static getMapVehicleUpdate(id?: GuidString): MapVehicleUpdate {
    return { ...vehicleUpdate1, id: id ?? '' };
  }

  static getMapVehicleUpdates(): MapVehicleUpdate[] {
    return [{ ...vehicleUpdate1 }, { ...vehicleUpdate2 }];
  }

  static getAreaPathEdges(): PathEdgeDto[] {
    return lookAheadAreaPathEdges;
  }
  // #endregion
}
