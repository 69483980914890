/*
A route is a string that represents a SignalR method name and also a template for the group name, and still contains the placeholder {0}
Groups are specific to a working area or a map, and has the placeholder replaced with the valid value for either map or working area
*/

export enum SignalrRoutes {
  // Packed Routes
  FetchVehicleList = 'FetchVehicleList',
  FetchMapData = 'FetchMapData',
  FetchActiveMissionList = 'FetchActiveMissionList',
  FetchChangedMissionTraces = 'FetchChangedMissionTraces',

  //Map Routes
  Maps = '{0}/maps',
  NavigationLayers = '{0}/navigationLayers',
  ZoneSets = '{0}/zoneSets',
  Zones = 'maps/{0}/zones',
  PillarsGrids = 'maps/{0}/pillarsGrids',
  PointsOfInterest = '{0}/pois',
  IntersectionZoneVehiclePriority = 'maps/{0}/intersectionZoneVehiclePriority',

  //Vehicle Routes
  VehicleDetails = 'vehicles/{0}',
  VehicleWaiting = 'vehicles/{0}/waiting',
  VehicleMapAssociation = '{0}/vehicleMapAssociation',
  VehicleAwarenessList = '{0}/vehicles/awarenesslistUpdate',
  VehicleDisconnections = 'vehicleDisconnections',
  UnitLoadVehicleLocations = 'maps/unitLoads/{0}',
  TuggerTrainVehicleLocations = '{0}/maps/tuggerTrains',
  ForkliftVehicleLocations = '{0}/maps/forklifts',
  VehicleFactsheet = 'factsheets/{0}',

  // Traffic Manager
  PredictedCollision = 'maps/{0}/predictedCollision',
  PredictedCollisions = 'maps/{0}/predictedCollisions',

  // Job Manager
  MissionTraces = '{0}/missionTraces',
  TransportOrders = '{0}/transportOrders',
  ZoneMembership = 'zones/{0}/zoneMembership',
  MissionTraceAbort = '{0}/missionTraceAbort',
  ProcessChainGroupTraces = '{0}/processChainGroupTraces',
  ProcessChainTraces = '{0}/processChainTraces',
  LoadType = '{0}/loadType',

  // Graph Manager
  InternalTours = '{0}/internalTours',
  NodeOccupancy = '{0}/nodeOccupancy',

  PoiDeviceOccupancy = '{0}/poiDeviceOccupancy',
  PointOfInterestGroups = '{0}/poiGroups',
  PoiOccupancy = '{0}/poiOccupancy',
  PoiBooking = '{0}/poiBooking',

  // Devices
  OpcuaDevicesNodeTelemetry = '{0}/OpcuaDevices/deviceTelemetry',
  OpcuaDevicesOnlineStatus = '{0}/OpcuaDevices/deviceOnlineStatus',
  OpcuaDevicesNotifications = '{0}/OpcuaDevices/notifications',

  InteractionZoneState = 'zones/{0}/state',
  IntersectionZoneCollision = 'maps/{0}/intersectionZoneCollision',
  IntersectionZoneCollisions = 'maps/{0}/intersectionZoneCollisions',

  //Feature Routes
  JobManagerFeatures = '{0}/admin/jobManagerFeatures',
  MapManagerFeatures = '{0}/admin/mapManagerFeatures',
  TrafficManagerFeatures = '{0}/admin/trafficManagerFeatures',
  FleetManagerFeatures = '{0}/admin/fleetManagerFeatures',

  ShutdownMode = '{0}/admin/shutdownMode',
  InfobarMessage = 'admin/infobarMessage',
  ErrorsAggregates = '{0}/errorsAggregates',

  // Permissions
  UserPermissions = 'user/{0}/permissions',
  Roles = '/roles',
}
