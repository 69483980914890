import { EMPTY_GUID, ONE_SECOND } from 'core/constants/constant';
import { AdHocTourStepsDispatch, StepDto, TourStepConfigurationDto, TourStepType } from 'core/dtos';
import {
  AdHocMissionDispatch,
  GuidString,
  StepType,
  StepTypeBackend,
  StepTypeIcon,
} from 'core/models';
import { VehicleSendNodeStepModel, VehicleSendStepModel } from './vehicle-send-steps.viewmodel';

const StepIcons = {
  [StepType.Drop]: StepTypeIcon.DROP,
  [StepType.DockToCharge]: StepTypeIcon.DOCKTOCHARGE,
  [StepType.Lift]: StepTypeIcon.LIFT,
  [StepType.Goto]: StepTypeIcon.GOTO,
  [StepType.Dock]: StepTypeIcon.DOCK,
  [StepType.StartToCharge]: StepTypeIcon.STARTTOCHARGE,
  [StepType.StopToCharge]: StepTypeIcon.STARTTOCHARGE,
  [StepType.WaitForEndOfStep]: StepTypeIcon.WAITFORENDOFSTEP,
  [StepType.GoToMapping]: StepTypeIcon.GOTO,
  [StepType.DockToMapping]: StepTypeIcon.DOCK,
  [StepType.WaitForSideButton]: StepTypeIcon.WAITFORSIDEBUTTON,
  [StepType.WaitForDevice]: StepTypeIcon.WAITFORAMA,
  [StepType.WaitForScanDestination]: StepTypeIcon.WAITFORAMA,
  [StepType.WaitForSapAcknowledgement]: StepTypeIcon.WAIT,
  [StepType.TurnDolly]: StepTypeIcon.DOCK,
  [StepType.GotoPose]: StepTypeIcon.GOTO,
};

const EmptyStepType = -1;
const RequiredFieldsByStepType = {
  [EmptyStepType]: 'pointOfInterestGroup',
  [StepType.Goto]: 'pointOfInterestGroup',
  [StepType.Dock]: 'pointOfInterestGroup',
  [StepType.TurnDolly]: 'pointOfInterestGroup',
  [StepType.DockToCharge]: 'pointOfInterestGroup',
  [StepType.Wait]: 'timeSpanS',
  [StepType.WaitForEndOfStep]: 'waitForStep',
  [StepType.Lift]: '',
  [StepType.Drop]: '',
  [StepType.StartToCharge]: '',
  [StepType.WaitForSideButton]: '',
  [StepType.WaitForDevice]: 'device',
  [StepType.WaitForScanDestination]: 'nodeName',
  [StepType.WaitForSapAcknowledgement]: '',
  [StepType.GotoPose]: '',
  [StepType.GotoAndPushSideButton]: 'pointOfInterestGroup',
};

export function getStepIcon(stepType?: StepType): string {
  return stepType != null ? StepIcons[stepType] : StepTypeIcon.WAIT;
}

export function getRequiredFieldsByStepType(stepType: StepType): string {
  return RequiredFieldsByStepType[stepType];
}

const standaloneSteps = new Set([
  StepType.Lift,
  StepType.Drop,
  StepType.StartToCharge,
  StepType.WaitForSideButton,
  StepType.WaitForTrigger,
  StepType.ReleaseForTrigger,
  StepType.SAP,
]);

export function toggleAttributeDisable(stepType: StepType): boolean {
  return standaloneSteps.has(stepType);
}

export function createStepDto(
  sendStepModel: VehicleSendStepModel,
  sequenceNumber: number
): StepDto | undefined {
  const poiStepDetails = {
    pointOfInterestName: sendStepModel.pointOfInterestName,
    pointOfInterestGroupId: sendStepModel.pointOfInterestGroupId,
    pointOfInterestId: sendStepModel.pointOfInterestId,
    trajectory: sendStepModel.waypoints,
    hasStepCompletionNotificationEnabled: false,
  };

  switch (sendStepModel.stepType) {
    case StepType.Goto:
      return { type: StepTypeBackend.Goto, ...poiStepDetails, sequenceNumber };
    case StepType.Dock:
      return { type: StepTypeBackend.Dock, ...poiStepDetails, sequenceNumber };
    case StepType.TurnDolly:
      return { type: StepTypeBackend.TurnDolly, ...poiStepDetails, sequenceNumber };
    case StepType.GotoAndPushSideButton:
      return { type: StepTypeBackend.GotoAndPushSideButton, ...poiStepDetails, sequenceNumber };
    case StepType.Wait:
      return {
        type: StepTypeBackend.Wait,
        timeSpanMillis: sendStepModel.timeSpan ? sendStepModel.timeSpan * ONE_SECOND : 0,
        sequenceNumber,
      };
    case StepType.Lift:
      return { type: StepTypeBackend.Lift, sequenceNumber };
    case StepType.Drop:
      return { type: StepTypeBackend.Drop, sequenceNumber };
    case StepType.DockToCharge:
      return { type: StepTypeBackend.DockToCharge, ...poiStepDetails, sequenceNumber };
    case StepType.StartToCharge:
      return { type: StepTypeBackend.StartToCharge, sequenceNumber };
    case StepType.StopToCharge:
      return { type: StepTypeBackend.StopToCharge, sequenceNumber };
    case StepType.WaitForSideButton:
      return { type: StepTypeBackend.WaitForSideButton, sequenceNumber };
    case StepType.WaitForDevice:
    case StepType.WaitForEndOfStep:
    case StepType.GoToMapping:
    case StepType.DockToMapping:
    case StepType.GotoPose:
    default:
      return undefined;
  }
}

export function createTourStepDto(
  sendStepModel: VehicleSendNodeStepModel,
  sequenceNumber: number
): TourStepConfigurationDto {
  const nodeDetails = {
    nodeId: sendStepModel.nodeId,
    hasArrivalNotificationEnabled: false,
    nodeGroupId: sendStepModel.nodeGroupId ?? null,
  };

  switch (sendStepModel.stepType) {
    case StepType.Goto:
      return {
        stepType: TourStepType.Goto,
        ...nodeDetails,
        sequenceId: sequenceNumber,
        raiseEventId: null,
      };
    case StepType.GotoAndPushSideButton:
      return {
        stepType: TourStepType.GotoAndPushSideButton,
        ...nodeDetails,
        sequenceId: sequenceNumber,
        raiseEventId: null,
      };
    default:
      throw new Error('Unknown step type');
  }
}

export function createMissionDispatch(
  vehicleSendSteps: VehicleSendStepModel[],
  mapId: GuidString,
  vehicleId: GuidString,
  missionName: string,
  debounceId?: string
): AdHocMissionDispatch {
  let index = 0;
  return {
    id: EMPTY_GUID,
    mapId: mapId,
    processChainId: EMPTY_GUID,
    name: missionName,
    steps: vehicleSendSteps.map(vehicleSendStep => {
      return createStepDto(vehicleSendStep, index++);
    }),
    vehicleId: vehicleId,
    debounceId: debounceId,
  } as AdHocMissionDispatch;
}

export function createTourSteps(
  tuggerTrainSendSteps: VehicleSendNodeStepModel[],
  vehicleId: GuidString,
  name: string,
  routeConfigurationId?: GuidString
): AdHocTourStepsDispatch {
  let index = 0;
  const steps: AdHocTourStepsDispatch = {
    steps: tuggerTrainSendSteps.map(step => {
      return createTourStepDto(step, index++);
    }),
    vehicleId: vehicleId,
    routeConfigurationId: routeConfigurationId,
    name: name,
  };
  return steps;
}
