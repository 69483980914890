import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import {
  AlertNowGroupDto,
  IpstAlertNowConnectionSettingsDto,
  IpstSettingsDto,
  OrganizationDto,
  RecipientKeyListDto,
  WorkAreaSettingRecipientKeyDto,
} from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { AlertNowTabDto, DateString, GuidString } from 'core/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpstSettingsService extends BaseHttpClient {
  protected apiUrl = environment.Services.Ipst;
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly ipstServicePath = API_SERVICES.Settings;

  getIpstSettings(organization?: OrganizationDto): Observable<IpstSettingsDto> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.get<IpstSettingsDto>(`${path}/${organization?.id}`);
  }

  getWaIpstSettings(
    organization?: OrganizationDto,
    workAreaId?: GuidString
  ): Observable<IpstSettingsDto> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}/workArea`;
    return this.get<IpstSettingsDto>(`${path}/${organization?.id}/${workAreaId}`);
  }

  getZoneAlertNowGroups(_id: GuidString): Observable<AlertNowGroupDto[]> {
    const path = `${this.ipstServicePath}/zoneAlertNowGroups/${_id}`;
    return this.get<AlertNowGroupDto[]>(`${path}`);
  }

  getAllIpstSettings(organization?: OrganizationDto): Observable<RecipientKeyListDto[]> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.get<RecipientKeyListDto[]>(`${path}`);
  }

  getAlertNowGroups(
    selectedOrganization: OrganizationDto,
    workAreaId: GuidString
  ): Observable<AlertNowGroupDto[]> {
    const path = `/${selectedOrganization?.name}/${selectedOrganization?.workAreas[0]?.name}${this.ipstServicePath}`;

    return this.get(`${path}/alertNow/workArea/${workAreaId}`);
  }

  updateIpstSettings(
    updatedIPSTDto: IpstSettingsDto,
    organization?: OrganizationDto
  ): Observable<void> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.put<void>(`${path}/${updatedIPSTDto.organizationId}`, updatedIPSTDto);
  }

  updateIpstAlertNowSettings(
    ipstAlertNowV2: IpstAlertNowConnectionSettingsDto,
    selectedOrganization?: OrganizationDto
  ): Observable<void> {
    const path = `/${selectedOrganization?.name}/${selectedOrganization?.workAreas[0]?.name}${this.ipstServicePath}`;

    return this.put<void>(`${path}/alertNow/org/${selectedOrganization?.id}`, ipstAlertNowV2);
  }

  updateAlertNowSettingsForWorkArea(
    ipstAlertNowV2: AlertNowTabDto,
    selectedOrganization?: OrganizationDto,
    waId?: GuidString
  ): Observable<WorkAreaSettingRecipientKeyDto[]> {
    const path = `/${selectedOrganization?.name}/${selectedOrganization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.put<WorkAreaSettingRecipientKeyDto[]>(
      `${path}/workArea/${selectedOrganization?.id}/${waId}`,
      ipstAlertNowV2
    );
  }

  updateIpstAlertNowGroup(
    alertNowGroupDtos: AlertNowGroupDto[],
    selectedOrganization: OrganizationDto,
    workAreaId: GuidString
  ): Observable<void> {
    const path = `/${selectedOrganization?.name}/${selectedOrganization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.put<void>(`${path}/alertNow/workArea/${workAreaId}`, alertNowGroupDtos);
  }

  resolveAllIpstMessages(workAreaId: GuidString, organization?: OrganizationDto): Observable<void> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.post<void>(`${path}/resolve/${workAreaId}`);
  }

  resolveVehicleIpstMessages(
    workAreaId: GuidString,
    vehicleName: string,
    organization?: OrganizationDto
  ): Observable<void> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.post<void>(`${path}/resolve/${workAreaId}/vehicle/${vehicleName}`);
  }

  getLastResolveVehicleIpstMessage(
    workAreaId: GuidString,
    vehicleName: string,
    organization?: OrganizationDto
  ): Observable<DateString> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.get<DateString>(`${path}/lastResolve/${workAreaId}/${vehicleName}`);
  }

  getLastResolveIpstMessage(
    workAreaId: GuidString,
    organization?: OrganizationDto
  ): Observable<DateString> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.get<DateString>(`${path}/lastResolve/${workAreaId}`);
  }
}
