import { NodeActionTypeGroup } from 'core/dtos';
import {
  ActionGraphNodeIcon,
  ActionGraphNodeType,
  GraphNodeIcon,
  GraphNodeType,
} from 'core/models';
import { IconOptions, MapPixiHelper } from 'modules/maps/helpers';
import { Texture } from 'pixi.js';
import { GraphLayerImages } from '../map-layer-images.constant';

const NodeIconStyle: IconOptions = {
  resourceOptions: { scale: 20 },
  resolution: window.devicePixelRatio,
  scale: 60,
};

const ActionNodeIconStyle: IconOptions = {
  resourceOptions: { scale: 20 },
  resolution: 1,
  scale: 17,
};

const NodePoiIconStyle: IconOptions = {
  resourceOptions: { scale: 20 },
  resolution: window.devicePixelRatio,
  scale: 22,
};

export const NodeIconOptions: Record<GraphNodeIcon, IconOptions> = {
  [GraphNodeIcon.Fueling]: { ...NodeIconStyle, path: GraphLayerImages.Graph_Node_Fuelling },
  [GraphNodeIcon.StoppingPoint]: {
    ...NodeIconStyle,
    scale: 50,
    path: GraphLayerImages.Graph_Node_Stopping,
  },
  [GraphNodeIcon.Parking]: { ...NodePoiIconStyle, path: GraphLayerImages.Graph_Node_Parking },
  [GraphNodeIcon.Charging]: { ...NodePoiIconStyle, path: GraphLayerImages.Graph_Node_Charging },
};

export const ActionIconOptions: Record<ActionGraphNodeIcon, IconOptions> = {
  [ActionGraphNodeIcon.PickDrop]: {
    ...ActionNodeIconStyle,
    path: GraphLayerImages.Graph_Node_PickDrop,
  },
  [ActionGraphNodeIcon.Battery]: {
    ...ActionNodeIconStyle,
    path: GraphLayerImages.Graph_Node_Battery,
  },
  [ActionGraphNodeIcon.Parking]: {
    ...ActionNodeIconStyle,
    path: GraphLayerImages.Graph_Node_Parking,
  },
  [ActionGraphNodeIcon.DetectObject]: {
    ...ActionNodeIconStyle,
    path: GraphLayerImages.Graph_Node_DetectObject,
  },
  [ActionGraphNodeIcon.WaitForTrigger]: {
    ...ActionNodeIconStyle,
    path: GraphLayerImages.Graph_Node_WaitForTrigger,
  },
  [ActionGraphNodeIcon.Custom]: {
    ...ActionNodeIconStyle,
    path: GraphLayerImages.Graph_Node_Custom,
  },
  [ActionGraphNodeIcon.MoreOptions]: {
    ...ActionNodeIconStyle,
    path: GraphLayerImages.Graph_Node_MoreOptions,
  },
};

export const NodeTypeIcon: Partial<Record<GraphNodeType, GraphNodeIcon>> = {
  [GraphNodeType.Fueling]: GraphNodeIcon.Fueling,
  [GraphNodeType.StoppingPoint]: GraphNodeIcon.StoppingPoint,
  [GraphNodeType.Charging]: GraphNodeIcon.Charging,
};

export const ActionNodeTypeIcon: Partial<Record<ActionGraphNodeType, ActionGraphNodeIcon>> = {
  [ActionGraphNodeType.PickDrop]: ActionGraphNodeIcon.PickDrop,
  [ActionGraphNodeType.Battery]: ActionGraphNodeIcon.Battery,
  [ActionGraphNodeType.Parking]: ActionGraphNodeIcon.Parking,
  [ActionGraphNodeType.DetectObject]: ActionGraphNodeIcon.DetectObject,
  [ActionGraphNodeType.WaitForTrigger]: ActionGraphNodeIcon.WaitForTrigger,
  [ActionGraphNodeType.Custom]: ActionGraphNodeIcon.Custom,
  [ActionGraphNodeType.MoreOptions]: ActionGraphNodeIcon.MoreOptions,
};

export const actionTypeToIconMap: Record<NodeActionTypeGroup, ActionGraphNodeIcon> = {
  [NodeActionTypeGroup.StartStopCharging]: ActionGraphNodeIcon.Battery,
  [NodeActionTypeGroup.PickDrop]: ActionGraphNodeIcon.PickDrop,
  [NodeActionTypeGroup.DetectObjectInitPositionFinePositioning]: ActionGraphNodeIcon.DetectObject,
  [NodeActionTypeGroup.WaitForTrigger]: ActionGraphNodeIcon.WaitForTrigger,
  [NodeActionTypeGroup.Custom]: ActionGraphNodeIcon.Custom,
};

export const textures: Record<ActionGraphNodeIcon, Texture> = {
  [ActionGraphNodeIcon.PickDrop]: MapPixiHelper.createTexture(
    ActionIconOptions[ActionGraphNodeIcon.PickDrop]
  ),
  [ActionGraphNodeIcon.Battery]: MapPixiHelper.createTexture(
    ActionIconOptions[ActionGraphNodeIcon.Battery]
  ),
  [ActionGraphNodeIcon.Parking]: MapPixiHelper.createTexture(
    ActionIconOptions[ActionGraphNodeIcon.Parking]
  ),
  [ActionGraphNodeIcon.DetectObject]: MapPixiHelper.createTexture(
    ActionIconOptions[ActionGraphNodeIcon.DetectObject]
  ),
  [ActionGraphNodeIcon.WaitForTrigger]: MapPixiHelper.createTexture(
    ActionIconOptions[ActionGraphNodeIcon.WaitForTrigger]
  ),
  [ActionGraphNodeIcon.Custom]: MapPixiHelper.createTexture(
    ActionIconOptions[ActionGraphNodeIcon.Custom]
  ),
  [ActionGraphNodeIcon.MoreOptions]: MapPixiHelper.createTexture(
    ActionIconOptions[ActionGraphNodeIcon.MoreOptions]
  ),
};
