import { ZoneSetDto, ZoneSetVehicleAction } from 'core/dtos';
import { GuidString, ReducedVehicle, ZoneSetRouteData, ZoneSetStatus } from 'core/models';
import { CapabilitiesService, CapabilityEnum } from 'core/services/capabilities.service';

const capabilities = new CapabilitiesService();
export function sortZoneSetsForDropdown(zoneSets: ZoneSetDto[]): ZoneSetDto[] {
  return zoneSets
    .sort((z1, z2) => {
      if (z1.createdOnUtc > z2.createdOnUtc) {
        return -1;
      }

      if (z1.createdOnUtc < z2.createdOnUtc) {
        return 1;
      }
      return 0;
    })
    .sort(z1 => {
      if (z1.status === ZoneSetStatus.Active) {
        return -1;
      } else {
        return 0;
      }
    });
}

export function getOverlappingZoneSetRoute(
  zoneSetId: GuidString,
  mapId: GuidString,
  zoneSetRouteData: ZoneSetRouteData
): string {
  if (zoneSetRouteData) {
    return `/${zoneSetRouteData.organisationName}/${zoneSetRouteData.workAreaName}/maps/${mapId}/${zoneSetId}`;
  }

  return '';
}

export function convertToZoneSetRouteDataModel(
  organisation: string,
  workArea: string
): ZoneSetRouteData {
  return {
    organisationName: organisation,
    workAreaName: workArea,
  };
}

export function convertToZoneSetVehicleActionModel(
  zoneSet: ZoneSetDto,
  status: string
): ZoneSetVehicleAction {
  const name = `${zoneSet.name} - ${status}`;
  return { id: zoneSet.id, name: name };
}

export function isZoneSetEnabled(vehicle: ReducedVehicle): boolean {
  return capabilities.isCapabilitySupported(CapabilityEnum.SendZoneSet, vehicle.hardwareVersion);
}
