import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ErrorForwardingModel } from 'core/models';
import { Subject, of, takeUntil } from 'rxjs';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-switch-notification-cell',
  templateUrl: './switch-notification-cell.component.html',
  styleUrls: ['./switch-notification-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchNotificationCellComponent implements ICellRendererAngularComp, OnDestroy {
  params?: ICellRendererParams<ErrorForwardingModel>;
  errorForwarding?: ErrorForwardingModel;
  canEdit?: boolean;

  isEditMode = false;
  isEditMode$ = of(false);
  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {}

  initializeSubscriptions(): void {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
    this.rootStore
      .pipe(select(fromRoot.selectIsEditMode), takeUntil(this.ngUnsubscribe))
      .subscribe(isEditMode => {
        this.isEditMode = isEditMode;
        this.cdRef.detectChanges();
      });
  }

  agInit(params: ICellRendererParams<ErrorForwardingModel>): void {
    this.initializeSubscriptions();
    this.params = params;
    this.canEdit = this.params?.context?.componentParent?.canEdit;

    this.refresh(params);
  }

  refresh(params: ICellRendererParams<ErrorForwardingModel>): boolean {
    this.errorForwarding = Object.assign({}, params.data);
    this.cdRef.markForCheck();
    return true;
  }

  onToggle(): void {
    if (this.errorForwarding) {
      this.params?.context.componentParent.updateDataToggle(this.errorForwarding);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
