/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { convertMissionTraceDtoToModel } from 'core/helpers';
import { MessageType } from 'core/models';
import * as ErrorsActions from 'store-modules/errors-store/actions/errors.actions';
import * as CollisionPointActions from 'store-modules/maps-store/actions/collision-points.actions';
import * as GraphLayerActions from 'store-modules/maps-store/actions/graph-layer.actions';
import * as IntersectionCollisionPointActions from 'store-modules/maps-store/actions/intersection-collision-points.actions';
import * as MapActions from 'store-modules/maps-store/actions/maps.actions';
import * as NavigationLayerActions from 'store-modules/maps-store/actions/navigation-layers.actions';
import * as ZoneSetActions from 'store-modules/maps-store/actions/zone-set.actions';
import * as ZoneActions from 'store-modules/maps-store/actions/zones.actions';
import * as MissionListItemActions from 'store-modules/mission-monitoring-store/actions/mission-list-item.actions';
import * as MissionTraceActions from 'store-modules/mission-monitoring-store/actions/mission-trace.actions';
import * as OrderActions from 'store-modules/mission-monitoring-store/actions/order.actions';
import * as ProcessChainGroupTraceActions from 'store-modules/mission-monitoring-store/actions/process-chain-group-trace.actions';
import * as TourActions from 'store-modules/mission-monitoring-store/actions/tour.actions';
import * as OpcuaDeviceActions from 'store-modules/opcua-devices-store/actions/opcua-devices.actions';
import * as PoiGroupActions from 'store-modules/pois-store/actions/poi-groups.actions';
import * as PoiActions from 'store-modules/pois-store/actions/pois.actions';
import * as LoadTypeSettingsActions from 'store-modules/settings-store/actions/load-type-settings.actions';
import * as FactsheetActions from 'store-modules/vehicles-store/actions/factsheet.actions';
import * as VehicleActions from 'store-modules/vehicles-store/actions/vehicles.actions';

import { map } from 'rxjs/operators';

import { unpackMissionListFromSignalR } from 'core/signalR/helpers/unpack-functions.helper';
import * as SignalRActions from '../actions/signalr.actions';

const unexpectedMessageErrorMessage = 'Unexpected message';

@Injectable({ providedIn: 'root' })
export class SignalREffects {
  poiMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.poiMessageReceived),
      map(action => action.poiMessage),
      map(({ type, payload: poi }) => {
        switch (type) {
          case MessageType.Created:
            return PoiActions.signalRCreatePoi({ poi });
          case MessageType.Deleted:
            return PoiActions.signalRDeletePoi({ poi });
          case MessageType.Modified:
            return PoiActions.signalRUpdatePoi({ poi });
        }
      })
    )
  );

  poiGroupMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.poiGroupMessageReceived),
      map(action => action.poiGroupMessage),
      map(({ type, payload: poiGroup }) => {
        switch (type) {
          case MessageType.Created:
            return PoiGroupActions.signalRCreatePoiGroup({ poiGroup });
          case MessageType.Deleted:
            return PoiGroupActions.signalRDeletePoiGroup({ poiGroup });
          case MessageType.Modified:
            return PoiGroupActions.signalRUpdatePoiGroup({ poiGroup });
        }
      })
    )
  );

  poiOccupancyStatusMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.poiOccupancyStatusMessageReceived),
      map(action => action.poiOccupancyStatusMessage),
      map(({ type, payload: poiOccupancyStatus }) => {
        switch (type) {
          case MessageType.Modified:
            return PoiActions.signalRUpdatePoiOccupancyStatus({ change: poiOccupancyStatus });
          default:
            throw new Error(unexpectedMessageErrorMessage);
        }
      })
    )
  );

  poiBookingStatusMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.poiBookingStatusMessageReceived),
      map(action => action.poiBookingStatusMessage),
      map(({ type, payload: poiBookingStatus }) => {
        switch (type) {
          case MessageType.Modified:
            return PoiActions.signalRUpdatePoiBookingStatus({ change: poiBookingStatus });
          default:
            throw new Error(unexpectedMessageErrorMessage);
        }
      })
    )
  );

  poiDeviceOccupancyStatusMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.poiDeviceOccupancyStatusMessageReceived),
      map(action => action.poiDeviceOccupancyMessage),
      map(({ type, payload: poiDeviceOccupancyMessage }) => {
        switch (type) {
          case MessageType.Modified:
            return PoiActions.signalRUpdatePoiDeviceOccupancyStatus({
              change: poiDeviceOccupancyMessage,
            });
          default:
            throw new Error(unexpectedMessageErrorMessage);
        }
      })
    )
  );

  vehicleLocationMapUpdatePolled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.vehicleLocationMapUpdatePolled),
      map(({ vehicleUpdates }) => {
        return VehicleActions.signalRLocationMapUpdatePolled({ vehicleUpdates });
      })
    )
  );

  vehicleListMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.vehicleListMessagesReceived),
      map(({ vehicleMessages }) => {
        return VehicleActions.vehicleListMessagesReceived({ vehicleMessages });
      })
    )
  );

  vehicleMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.vehicleMessageReceived),
      map(action => action.vehicleMessage),
      map(({ type, payload: vehicle }) => {
        switch (type) {
          case MessageType.Created:
            return VehicleActions.signalRAddVehicle({ vehicle });
          case MessageType.Deleted:
            return VehicleActions.signalRDeleteVehicle({ vehicle });
          case MessageType.Modified:
            return VehicleActions.signalRUpdateVehicle({ vehicle });
        }
      })
    )
  );

  vehicleWaitingMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.vehicleWaitingMessageReceived),
      map(action => action.vehicleMessage),
      map(({ type, payload: message }) => {
        switch (type) {
          case MessageType.Created:
          case MessageType.Modified:
            return VehicleActions.signalRVehicleWaiting({ message });
          default:
        }
        throw new Error(unexpectedMessageErrorMessage);
      })
    )
  );

  factsheetMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.factsheetMessageReceived),
      map(action => action.factsheetMessage.payload),
      map(factsheet => FactsheetActions.signalRUpdateVehicleFactsheet({ factsheet }))
    )
  );

  vehicleMapAssociationMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.vehicleMapAssociationMessageReceived),
      map(action => action.vehicleMessage),
      map(({ type, payload: vehicleMapAssociation }) => {
        switch (type) {
          case MessageType.Created:
            return VehicleActions.signalRAddVehicleMapAssociation({ vehicleMapAssociation });
          case MessageType.Deleted:
            return VehicleActions.signalRDeleteVehicleMapAssociation({ vehicleMapAssociation });
          case MessageType.Modified:
            return VehicleActions.signalRUpdateVehicleMapAssociation({ vehicleMapAssociation });
        }
      })
    )
  );

  errorAggregateMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.errorAggregateMessageReceived),
      map(action => action.errorAggregateMessage.payload[0]),
      map(error =>
        error.isDeleted
          ? ErrorsActions.signalRRemoveError({ error })
          : ErrorsActions.signalRUpsertError({ error })
      )
    )
  );

  missionTraceMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.missionTraceMessageReceived),
      map(action => action.missionTraceMessage),
      map(({ type, payload: missionTrace }) => {
        const arg = { missionTrace: convertMissionTraceDtoToModel(missionTrace) };
        switch (type) {
          case MessageType.Created:
            return MissionTraceActions.signalRAddMissionTrace(arg);
          case MessageType.Deleted:
            return MissionTraceActions.signalRDeleteMissionTrace(arg);
          case MessageType.Modified:
            return MissionTraceActions.signalRUpdateMissionTrace(arg);
        }
      })
    )
  );

  activeMissionListMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.activeMissionListMessageReceived),
      map(action => action.message),
      map(({ type, payload: missionListSignalDto }) => {
        const unpackedToMissionListSignalR = unpackMissionListFromSignalR(missionListSignalDto);
        // Created means that the array contains ALL active missions
        // Modified means that the array contains only the modified missions
        if (type === MessageType.Created) {
          return MissionListItemActions.signalRCreateMissionList({
            message: unpackedToMissionListSignalR,
          });
        } else {
          return MissionListItemActions.signalRUpdateMissionList({
            message: unpackedToMissionListSignalR,
          });
        }
      })
    )
  );

  processChainGroupTraceMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.processChainGroupTraceMessageReceived),
      map(action => action.processChainGroupTraceMessage),
      map(({ type, payload: processChainGroupTrace }) => {
        switch (type) {
          case MessageType.Created:
            return ProcessChainGroupTraceActions.signalRAddProcessChainGroupTrace({
              processChainGroupTrace: processChainGroupTrace,
            });
          case MessageType.Deleted:
            return ProcessChainGroupTraceActions.signalRDeleteProcessChainGroupTrace({
              processChainGroupTrace: processChainGroupTrace,
            });
          case MessageType.Modified:
            return ProcessChainGroupTraceActions.signalRUpdateProcessChainGroupTrace({
              processChainGroupTrace: processChainGroupTrace,
            });
        }
      })
    )
  );

  processChainTraceMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.processChainTraceMessageReceived),
      map(action => action.processChainTraceMessage),
      map(({ type, payload: processChainTrace }) => {
        switch (type) {
          case MessageType.Created:
            return ProcessChainGroupTraceActions.signalRAddProcessChainTrace({
              processChainTrace: processChainTrace,
            });
          case MessageType.Deleted:
            return ProcessChainGroupTraceActions.signalRDeleteProcessChainTrace({
              processChainTrace: processChainTrace,
            });
          case MessageType.Modified:
            return ProcessChainGroupTraceActions.signalRUpdateProcessChainTrace({
              processChainTrace: processChainTrace,
            });
        }
      })
    )
  );

  zoneMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.zoneMessageReceived),
      map(action => action.zoneMessage),
      map(({ type, payload: zone }) => {
        switch (type) {
          case MessageType.Created:
            return ZoneActions.signalRCreateZone({ zone });
          case MessageType.Deleted:
            return ZoneActions.signalRDeleteZone({ zone });
          case MessageType.Modified:
            return ZoneActions.signalRUpdateZone({ zone });
        }
      })
    )
  );

  collisionPointMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.collisionPointMessageReceived),
      map(action => action.collisionPointMessage),
      map(({ type, payload: collisionPoint }) => {
        switch (type) {
          case MessageType.Created:
            return CollisionPointActions.signalRCreateCollisionPoint({ collisionPoint });
          case MessageType.Deleted:
            return CollisionPointActions.signalRDeleteCollisionPoint({ collisionPoint });
          case MessageType.Modified:
            return CollisionPointActions.signalRUpdateCollisionPoint({ collisionPoint });
        }
      })
    )
  );

  collisionPointListMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.collisionPointListMessageReceived),
      map(action => action.collisionPointListMessage),
      map(({ payload: collisionPointList }) => {
        return CollisionPointActions.signalRUpdateCollisionPointList({ collisionPointList });
      })
    )
  );

  intersectionCollisionPointMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.intersectionCollisionPointMessageReceived),
      map(action => action.intersectionCollisionPointMessage),
      map(({ type, payload: intersectionCollisionPoints }) => {
        switch (type) {
          case MessageType.Created:
            return IntersectionCollisionPointActions.signalRCreateIntersectionCollisionPoint({
              intersectionCollisionPoints,
            });
          case MessageType.Deleted:
            return IntersectionCollisionPointActions.signalRDeleteIntersectionCollisionPoint({
              intersectionCollisionPoints,
            });
          case MessageType.Modified:
            return IntersectionCollisionPointActions.signalRUpdateIntersectionCollisionPoint({
              intersectionCollisionPoints,
            });
        }
      })
    )
  );

  intersectionCollisionPointListMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.intersectionCollisionPointListMessageReceived),
      map(action => action.intersectionCollisionPointListMessage),
      map(({ payload: intersectionCollisionPointList }) => {
        return IntersectionCollisionPointActions.signalRUpdateIntersectionCollisionPointList({
          intersectionCollisionPointList,
        });
      })
    )
  );

  mapMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.mapMessageReceived),
      map(action => action.mapMessage),
      map(({ type, payload }) => {
        switch (type) {
          case MessageType.Created:
            return MapActions.signalRCreateMap({ map: payload });
          case MessageType.Deleted:
            return MapActions.signalRDeleteMap({ map: payload });
          case MessageType.Modified:
            return MapActions.signalRUpdateMap({ map: payload });
        }
      })
    )
  );

  loadTypeMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.loadTypeMessageReceived),
      map(action => action.loadTypeMessage),
      map(({ payload }) => {
        return LoadTypeSettingsActions.updateLoadTypeSuccess({
          configuration: payload,
          notify: false,
        });
      })
    )
  );

  navigationLayerMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.navigationLayerMessageReceived),
      map(action => action.navigationLayerMessage),
      map(({ type, payload }) => {
        switch (type) {
          case MessageType.Created:
            return NavigationLayerActions.signalRCreateNavigationLayer({
              navigationLayer: payload,
            });
          case MessageType.Deleted:
            return NavigationLayerActions.signalRDeleteNavigationLayer({
              navigationLayer: payload,
            });
          case MessageType.Modified:
            return NavigationLayerActions.signalRUpdateNavigationLayer({
              navigationLayer: payload,
            });
        }
      })
    )
  );

  opcuaTelemetryMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.opcuaNodeTelemetryMessageReceived),
      map(action => action.telemetryMessage),
      map(({ type, payload }) => {
        switch (type) {
          case MessageType.Modified:
            return OpcuaDeviceActions.signalRUpdateOpcuaDeviceNodeState({ telemetry: payload });
          default:
            throw new Error(unexpectedMessageErrorMessage);
        }
      })
    )
  );

  opcuaOnlineStatusMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.opcuaDeviceOnlineStatusMessageReceived),
      map(action => action.statusMessage),
      map(({ type, payload }) => {
        switch (type) {
          case MessageType.Modified:
            return OpcuaDeviceActions.signalRUpdateOpcuaDeviceOnlineState({
              statusMessage: payload,
            });
          default:
            throw new Error(unexpectedMessageErrorMessage);
        }
      })
    )
  );

  opcuaDeviceNotificationReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.opcuaDeviceNotificationMessageReceived),
      map(action => action.notification),
      map(({ type, payload }) => {
        switch (type) {
          case MessageType.Created:
            return OpcuaDeviceActions.signalROpcuaDeviceNotificationTriggered({
              notification: payload,
            });
          case MessageType.Deleted:
            return OpcuaDeviceActions.signalROpcuaDeviceNotificationResolved({
              notification: payload,
            });
          default:
            throw new Error(unexpectedMessageErrorMessage);
        }
      })
    )
  );

  zoneSetMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.zoneSetMessageReceived),
      map(action => action.zoneSetMessage),
      map(({ type, payload }) => {
        switch (type) {
          case MessageType.Created:
            return ZoneSetActions.signalRCreateZoneSet({ zoneSet: payload });
          case MessageType.Deleted:
            return ZoneSetActions.signalRDeleteZoneSet({ zoneSet: payload });
          case MessageType.Modified:
            return ZoneSetActions.signalRUpdateZoneSet({ zoneSet: payload });
        }
      })
    )
  );

  orderMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.orderMessageReceived),
      map(action => action.orderMessage),
      map(({ type, payload: order }) => {
        switch (type) {
          case MessageType.Created:
            return OrderActions.signalRAddOrder({ order });
          case MessageType.Modified:
            return OrderActions.signalRUpdateOrder({ order });
          case MessageType.Deleted:
            return OrderActions.removeOrder({ order });
        }
      })
    )
  );

  tourMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.tourMessageReceived),
      map(action => action.tourMessage),
      map(({ type, payload: tour }) => {
        switch (type) {
          case MessageType.Created:
            return TourActions.signalRAddTour({ tour });
          case MessageType.Modified:
            return TourActions.signalRUpdateTour({ tour });
          case MessageType.Deleted:
            return TourActions.removeTour({ tour });
        }
      })
    )
  );

  nodeOccupancyStatusMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.nodeOccupancyStatusMessageReceived),
      map(action => action.nodeOccupancyStatusMessage),
      map(({ type, payload: nodeOccupancyStatusMessage }) => {
        switch (type) {
          case MessageType.Modified:
            return GraphLayerActions.updateGraphLayerNodeOccupancyStatus({
              nodeOccupancyStatusMessage,
            });
          default:
            throw new Error(unexpectedMessageErrorMessage);
        }
      })
    )
  );

  constructor(private readonly actions$: Actions) {}
}
