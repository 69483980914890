import { LoadTypeConfigurationDto } from 'core/models';

export interface LoadTypeSettingsState {
  loaded: boolean;
  configurations: LoadTypeConfigurationDto[];
}

export function getLoadTypeSettingsStateDefault(): LoadTypeSettingsState {
  return {
    loaded: false,
    configurations: [],
  };
}
