import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormBuilder,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { DsMenuItem } from '@bmw-ds/components/ds-menu/ds-menu.interface';
import { FleetDto, VehicleDto, WorkAreaSettingRecipientKeyDto } from 'core/dtos';
import { AlertNowZoneGroup, EntityList, GuidString, Zone } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ipst-alertnow-group-edit',
  templateUrl: './ipst-alertnow-group-edit.component.html',
  styleUrls: ['./ipst-alertnow-group-edit.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IpstAlertNowGroupEditComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IpstAlertNowGroupEditComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowGroupEditComponent
  implements ControlValueAccessor, Validator, OnInit, OnChanges, OnDestroy
{
  @Input() allFleets: FleetDto[] = [];
  @Input() allRecipientKeys: WorkAreaSettingRecipientKeyDto[] = [];
  @Input() zones: Zone[] = [];
  @Input() vehicles: VehicleDto[] = [];

  @Output() readonly delete = new EventEmitter<string>();

  ngUnsubscribe = new Subject<void>();

  showVehicleAlertNowGroup = false;
  showAddAlertNowGroup = false;
  hasValidZone = false;

  groupName = '';

  zoneGroupForm?: AlertNowZoneGroup;
  zoneGroupFormGroup: FormGroup;
  selectedZone?: Zone;

  menuItems: DsMenuItem[] = [];
  recipientKeyList: EntityList[] = [];
  alertNowZonesList: EntityList[] = [];

  constructor(
    private readonly atsTranslateService: AtsTranslationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.zoneGroupFormGroup = this.createFormGroup();
    this.showAddAlertNowGroup = this.vehicleAlertNowFormFormArray.length < 2;
  }

  get value(): AlertNowZoneGroup | undefined {
    return this.zoneGroupForm;
  }

  set value(val: AlertNowZoneGroup) {
    this.zoneGroupForm = val;
    this.onChanged(val);
    this.onTouched(val);
  }

  get vehicleAlertNowFormFormArray(): UntypedFormArray {
    return this.zoneGroupFormGroup.get('vehicleAlertNowGroup') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.createMenuItems();
  }

  ngOnChanges({ allRecipientKeys, zones }: TypedChanges<IpstAlertNowGroupEditComponent>): void {
    if (allRecipientKeys?.currentValue) {
      this.allRecipientKeys = allRecipientKeys.currentValue;
      this.setRecipientKeyList();
    }

    if (zones?.currentValue) {
      this.zones = zones.currentValue;
      this.setZoneList();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  writeValue(val: AlertNowZoneGroup): void {
    this.zoneGroupFormGroup.patchValue({
      alertNowZone: val.alertNowZone,
      recipientKeys: val.recipientKeys,
    });

    val.vehicleAlertNowGroup.forEach(i => {
      this.vehicleAlertNowFormFormArray.push(this.formBuilder.control(i));
    });

    if (this.vehicleAlertNowFormFormArray.length > 0) {
      this.zoneGroupFormGroup.controls.recipientKeys.clearValidators();
      this.zoneGroupFormGroup.controls.recipientKeys.updateValueAndValidity();
    }

    this.value = this.zoneGroupFormGroup.value;
    this.setGroupName(val.alertNowZone);
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      recipientKeys: [[], [Validators.required]],
      alertNowZone: [null, [Validators.required]],
      vehicleAlertNowGroup: this.formBuilder.array([]),
    });
  }

  createMenuItems(): void {
    this.menuItems = [
      this.createMenuItem(
        'shared.treeTable.actionMenu.delete',
        this.onDelete.bind(this),
        true,
        false
      ),
    ];
  }

  createMenuItem(key: string, command: Function, visible: boolean, disabled: boolean): DsMenuItem {
    return {
      label: this.atsTranslateService.get(key),
      key,
      command,
      visible: visible,
      disabled: disabled,
    };
  }

  getVehicleAlertNowHeader(index: number): string {
    return this.atsTranslateService.get(
      'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroupNum',
      {
        number: index + 1,
      }
    );
  }

  setGroupName(zoneId: GuidString): void {
    this.selectedZone = this.zones.find(i => i.id === zoneId);

    if (!this.selectedZone) {
      this.groupName =
        zoneId === ''
          ? ''
          : this.atsTranslateService.get(
              'settings.ipstAlertNowSettings.alertNowService.nonExistingGroup'
            );

      this.hasValidZone = false;
    } else {
      const group = ` ${this.atsTranslateService.get(
        'settings.ipstAlertNowSettings.alertNowService.group'
      )}`;

      this.groupName = `${this.selectedZone.zoneName}${group}`;
      this.hasValidZone = true;
    }
  }

  setRecipientKeyList(): void {
    this.recipientKeyList = this.allRecipientKeys.map(c => ({
      id: c.id.toString(),
      name: c.recipientKey,
    }));

    this.cdr.markForCheck();
  }

  setZoneList(): void {
    this.alertNowZonesList = this.zones.map(c => ({
      id: c.id.toString() ?? '',
      name: c.zoneName ?? '',
    }));
  }

  addVehicleGroupConfig(): void {
    const control = this.formBuilder.control({
      strVehicles: [],
      forkliftVehicles: [],
      tuggerTrainVehicles: [],
      uAgvVehicles: [],
      recipientKeys: [],
      name: '',
    });

    control.setErrors({ required: true });

    this.vehicleAlertNowFormFormArray.push(control);
  }

  onChangeZone(): void {
    const zone = this.zoneGroupFormGroup.get('alertNowZone')?.value;
    this.setGroupName(zone);
  }

  onDelete(): void {
    this.delete.emit(this.groupName);
  }

  onAddVehicleAlertNowGroup(): void {
    this.showVehicleAlertNowGroup = true;
    this.showAddAlertNowGroup = this.vehicleAlertNowFormFormArray.length < 2;

    this.addVehicleGroupConfig();

    this.zoneGroupFormGroup.controls.recipientKeys.clearValidators();
    this.zoneGroupFormGroup.controls.recipientKeys.updateValueAndValidity();

    this.cdr.markForCheck();
  }

  onRemoveVehicleAlertNowGroup(index: number): void {
    this.vehicleAlertNowFormFormArray.removeAt(index);
    this.showAddAlertNowGroup = this.vehicleAlertNowFormFormArray.length < 2;
    this.showVehicleAlertNowGroup = this.vehicleAlertNowFormFormArray.length > 0;

    if (this.vehicleAlertNowFormFormArray.length === 0) {
      this.zoneGroupFormGroup.controls.recipientKeys.setValidators(Validators.required);
      this.zoneGroupFormGroup.controls.recipientKeys.updateValueAndValidity();
    }
  }

  onChanged = (_value: unknown): void => {};
  onTouched = (_value: unknown): void => {};

  registerOnChange(fn: () => {}): void {
    this.zoneGroupFormGroup.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(fn);
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    const errors: ValidationErrors = {};

    Object.keys(this.vehicleAlertNowFormFormArray.controls).forEach(key => {
      Object.assign(errors, this.vehicleAlertNowFormFormArray.get(key)?.errors);
    });

    Object.keys(this.zoneGroupFormGroup.controls).forEach(key =>
      Object.assign(errors, this.zoneGroupFormGroup.get(key)?.errors)
    );

    return Object.keys(errors).length ? errors : null;
  }
}
