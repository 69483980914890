import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { CreateNodeGroupDto, NodeGroupDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class NodeGroupService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.NodeGroups;

  protected apiUrl = environment.Services.GraphManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getAllNodeGroups(): Observable<NodeGroupDto[]> {
    return this.get<NodeGroupDto[]>(`${this.servicePath}`);
  }

  createNodeGroup(nodeGroup: CreateNodeGroupDto): Observable<NodeGroupDto> {
    return this.post<NodeGroupDto>(`${this.servicePath}`, nodeGroup);
  }

  updateNodeGroup(nodeGroup: NodeGroupDto): Observable<NodeGroupDto> {
    return this.put<NodeGroupDto>(`${this.servicePath}`, nodeGroup);
  }

  deleteNodeGroup(id: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePath}/${id}`);
  }
}
