<ds-menu #dsMenu>
  <ng-container *ngFor="let item of menuItems">
    <ds-list-item *ngIf="item.visible" [disabled]="item.disabled" (click)="item.command()">{{
      item.label
    }}</ds-list-item>
  </ng-container>
</ds-menu>

<form
  ds-form
  class="form-container no-border-top"
  data-cy="zoneGroupForm"
  [formGroup]="zoneGroupFormGroup">
  <ds-box class="no-border-top">
    <ds-box-header>
      <div class="d-flex align-items-center justify-content-between">
        <h3 data-cy="alertNowGroupName" class="mb-0x">{{ groupName }}</h3>
        <i
          class="pi pi-ellipsis-v"
          #contextMenuButton
          data-cy="missionActionDsMenu"
          [ds-menu-trigger-for]="dsMenu"></i>
      </div>
    </ds-box-header>

    <ds-box-content divider="full" class="no-border-top">
      <h4>{{ 'settings.ipstAlertNowSettings.alertNowService.recipientKeys' | translate }}</h4>
      <div>
        <ds-form-field>
          <label ds-label for="recipientKeys">
            {{ 'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate }}
          </label>
          <ds-select
            formControlName="recipientKeys"
            data-cy="recipientKeys"
            id="recipientKeys"
            ds-form-validation
            [isMultiselect]="true"
            [options]="recipientKeyList | dsDropdown"
            [labellingConfig]="{
              placeholder:
                'settings.ipstAlertNowSettings.alertNowService.selectRecipientKey' | translate
            }"></ds-select>
        </ds-form-field>
      </div>

      <hr class="lineSep" />

      <div class="row">
        <div class="col" style="display: flex">
          <h4>{{ 'settings.ipstAlertNowSettings.alertNowService.alertNowZone' | translate }}</h4>
          <ds-icon
            data-cy="infoIcon"
            icon="information"
            class="ms-2x info-icon"
            ds-tooltip="{{
              'settings.ipstAlertNowSettings.alertNowService.zoneTooltip' | translate
            }}"
            size="sm"
            [dsTooltipConfig]="{ origin: 'right' }"></ds-icon>
        </div>
      </div>
      <div>
        <ds-form-field>
          <label ds-label for="alertNowZone">
            {{ 'settings.ipstAlertNowSettings.alertNowService.zone' | translate }}
          </label>

          <ds-select
            ds-form-validation
            required
            formControlName="alertNowZone"
            id="alertNowZone"
            data-cy="alertNowZoneDropdown"
            [options]="alertNowZonesList | dsDropdown"
            [labellingConfig]="{
              placeholder: 'settings.ipstAlertNowSettings.alertNowService.zoneSelect' | translate
            }"
            (optionSelected)="onChangeZone()"></ds-select>
        </ds-form-field>
      </div>

      <hr class="lineSep my-4x" />

      <div class="row">
        <div class="col" style="display: flex">
          <h4>
            {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroup' | translate }}
          </h4>
        </div>
      </div>

      <div
        *ngFor="
          let vehicleAlertNowControl of vehicleAlertNowFormFormArray?.controls;
          let i = index
        ">
        <div class="row" style="width: 100%">
          <h5 [innerHTML]="getVehicleAlertNowHeader(i)"></h5>
        </div>

        <app-ipst-alertnow-vehicle-group-edit
          [formControl]="$any(vehicleAlertNowControl)"
          [recipientKeyList]="recipientKeyList"
          [vehicles]="vehicles"
          [allFleets]="allFleets"
          [selectedZone]="selectedZone"
          (removeVehicleGroup)="
            onRemoveVehicleAlertNowGroup(i)
          "></app-ipst-alertnow-vehicle-group-edit>
      </div>

      <div class="vehicle-group-container">
        <div style="display: flex; justify-content: space-between">
          <button
            *ngIf="showAddAlertNowGroup"
            data-cy="showAddAlertNowGroup"
            ds-button
            [variant]="'ghost'"
            [disabled]="!hasValidZone"
            [icon]="'add'"
            (click)="onAddVehicleAlertNowGroup()">
            {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroup' | translate }}
          </button>
        </div>
        <div *ngIf="!showVehicleAlertNowGroup" class="max-hint">
          <ds-hint>
            {{ 'settings.ipstAlertNowSettings.alertNowService.hintMessage' | translate }}
          </ds-hint>
        </div>
      </div>
    </ds-box-content>
  </ds-box>
</form>
