export enum ToolBarControls {
  Button = 'Button',
  ButtonSecondary = 'ButtonSecondary',
  MenuButton = 'Menu-Button',
  Search = 'Search',
  Toggle = 'Toggle',
  Custom = 'Custom',
}

export enum ToolBarControlKeys {
  New = 'shared.toolBar.new',
  Actions = 'shared.toolBar.actions',
  Search = 'shared.toolBar.search',
  Download = 'shared.toolBar.download',
  TriggerPc = 'shared.toolBar.triggerPc',
  Email = 'shared.toolBar.email',
  Filter = 'shared.toolBar.filter',
  InfoPanels = 'shared.toolBar.infoPanels',
  AddUser = 'shared.toolBar.addUser',
  Refresh = 'shared.toolBar.refresh',
  WakeVehicles = 'shared.toolBar.vehicles.wakeVehicles',
  SendZoneUpdates = 'shared.toolBar.vehicles.sendZoneUpdate',
  NewMapContainer = 'shared.toolBar.maps.new',
  NewZoneAlertNowGroup = 'shared.toolBar.alertNowService.new',
  UploadVehicleMapData = 'shared.toolBar.maps.uploadData',
}
