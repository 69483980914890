import { createAction, props } from '@ngrx/store';
import {
  AtsInternalTourDto,
  CollisionPoint,
  ErrorAggregate,
  Factsheet,
  FleetManagerFeatures,
  InfobarMessageResponse,
  IntersectionCollisionPoint,
  JobManagerFeatures,
  MapDto,
  MapManagerFeatures,
  MapVehicleUpdate,
  MissionListSignalRDto,
  MissionTraceDto,
  NavigationLayerResponseModel,
  NodeOccupancyStatusChangeDto,
  OpcuaDeviceNodeTelemetrySignalR,
  OpcuaDeviceNotification,
  OpcuaDeviceOnlineStatusChangedDto,
  OrderDto,
  PoiBookingStatusChangeDto,
  PoiDeviceOccupancyStatusChangeDto,
  PoiDto,
  PoiGroupDto,
  PoiOccupancyStatusChangeDto,
  ProcessChainGroupTraceDto,
  ProcessChainTraceInGroupDto,
  ShutdownRequestDto,
  TrafficManagementSettingsMessage,
  VehicleDetailsSignalRDto,
  VehicleListSignalrDto,
  VehicleMapAssociation,
  VehicleWaitingDto,
  ZoneSetDto,
} from 'core/dtos';

import { LoadTypeConfigurationDto, SignalRNextMessage, Zone } from 'core/models';

export enum SignalRActionTypes {
  PoiMessageReceived = '[SignalR] Poi Message Received',
  PoiGroupMessageReceived = '[SignalR] PoiGroup Message Received',
  PoiBookingStatusMessageReceived = '[SignalR] Poi Booking Status Message Received',
  PoiOccupancyStatusMessageReceived = '[SignalR] Poi Occupancy Status Message Received',
  PoiDeviceOccupancyMessageReceived = '[SignalR] Poi Device Occupancy Message Received',
  VehicleMessageReceived = '[SignalR] Vehicle Message Received',
  VehicleLocationMapUpdatePolled = '[SignalR] Vehicle Location Update Map Polled',
  VehicleListMessagesReceived = '[SignalR] Vehicle List Messages Received',
  FactsheetMessageReceived = '[SignalR] Vehicle Factsheet Message Received',
  VehicleMapAssociationMessageReceived = '[SignalR] Vehicle Map Association Message Received',
  VehicleWaitingMessageReceived = '[SignalR] Vehicle Waiting Message Received',
  VehicleLocationMessageReceived = '[SignalR] Vehicle Location Message Received',
  VehicleConflictAreaDimensionsMessageReceived = '[SignalR] Vehicle Conflict Area Dimension Message Received',
  ErrorAggregateReceived = '[SignalR] Error Aggregate Received',
  MissionTraceMessageReceived = '[SignalR] Mission Trace Message Received',
  ActiveMissionListMessageReceived = '[SignalR] Mission Trace List Message Received',
  ActiveMissionUpdatedMessage = '[SignalR] Mission Trace Updated Message Received',
  ZoneMessageReceived = '[SignalR] Zone Message Received',
  CollisionPointMessageReceived = '[SignalR] Collision Point Message Received',
  CollisionPointListMessageReceived = '[SignalR] Collision Point List Message Received',
  IntersectionCollisionPointMessageReceived = '[SignalR] Intersection Collision Point Message Received',
  IntersectionCollisionPointListMessageReceived = '[SignalR] Intersection Collision Point List Message Received',
  MapMessageReceived = '[SignalR] Map Message Received',
  NavigationLayerMessageReceived = '[SignalR] Navigation Layer Message Received',
  JobManagerFeaturesMessageReceived = '[SignalR] Job Manager Features Message Received',
  MapManagerFeaturesMessageReceived = '[SignalR] Map Manager Features Message Received',
  TrafficManagerTrafficModeMessageReceived = '[SignalR] Traffic Manager Features Message Received',
  ShutdownModeMessageReceived = '[SignalR] Shutdown Mode Message Received',
  InfobarMessageReceived = '[SignalR] Infobar Message Received',
  FleetManagerFeaturesMessageReceived = '[SignalR] Fleet Manager Features Message Received',
  OpcuaDeviceNodeTelemetryMessageReceived = '[SignalR] Opcua Device Node Telemetry Message Received',
  OpcuaDeviceOnlineStatusMessageReceived = '[SignalR] Opcua Device Online Status Message Received',
  OpcuaDeviceNotificationMessageReceived = '[SignalR] Opcua Device Notification Message Received',
  ZoneSetMessageReceived = '[SignalR] Zone Set Message Received',
  OrderMessageReceived = '[SignalR] Order Message Received',
  TourMessageReceived = '[SignalR] Tour Message Received',
  ProcessChainGroupTraceMessageReceived = '[SignalR] Process Chain Group Trace Message Received',
  ProcessChainTraceMessageReceived = '[SignalR] Process Chain Trace Message Received',
  NodeOccupancyStatusMessageReceived = '[SignalR] Node Occupancy Status Message Received',
  LoadTypeMessageReceived = '[SignalR] Load Type Message Received',
}

export const poiMessageReceived = createAction(
  SignalRActionTypes.PoiMessageReceived,
  props<{ poiMessage: SignalRNextMessage<PoiDto> }>()
);

export const poiGroupMessageReceived = createAction(
  SignalRActionTypes.PoiGroupMessageReceived,
  props<{ poiGroupMessage: SignalRNextMessage<PoiGroupDto> }>()
);

export const poiBookingStatusMessageReceived = createAction(
  SignalRActionTypes.PoiBookingStatusMessageReceived,
  props<{ poiBookingStatusMessage: SignalRNextMessage<PoiBookingStatusChangeDto> }>()
);

export const poiOccupancyStatusMessageReceived = createAction(
  SignalRActionTypes.PoiOccupancyStatusMessageReceived,
  props<{ poiOccupancyStatusMessage: SignalRNextMessage<PoiOccupancyStatusChangeDto> }>()
);

export const poiDeviceOccupancyStatusMessageReceived = createAction(
  SignalRActionTypes.PoiDeviceOccupancyMessageReceived,
  props<{ poiDeviceOccupancyMessage: SignalRNextMessage<PoiDeviceOccupancyStatusChangeDto> }>()
);

export const vehicleLocationMapUpdatePolled = createAction(
  SignalRActionTypes.VehicleLocationMapUpdatePolled,
  props<{ vehicleUpdates: MapVehicleUpdate[] }>()
);

export const vehicleListMessagesReceived = createAction(
  SignalRActionTypes.VehicleListMessagesReceived,
  props<{ vehicleMessages: VehicleListSignalrDto[] }>()
);

export const vehicleMessageReceived = createAction(
  SignalRActionTypes.VehicleMessageReceived,
  props<{ vehicleMessage: SignalRNextMessage<VehicleDetailsSignalRDto> }>()
);

export const factsheetMessageReceived = createAction(
  SignalRActionTypes.FactsheetMessageReceived,
  props<{ factsheetMessage: SignalRNextMessage<Factsheet> }>()
);

export const vehicleMapAssociationMessageReceived = createAction(
  SignalRActionTypes.VehicleMapAssociationMessageReceived,
  props<{ vehicleMessage: SignalRNextMessage<VehicleMapAssociation> }>()
);

export const vehicleWaitingMessageReceived = createAction(
  SignalRActionTypes.VehicleWaitingMessageReceived,
  props<{ vehicleMessage: SignalRNextMessage<VehicleWaitingDto> }>()
);

export const errorAggregateMessageReceived = createAction(
  SignalRActionTypes.ErrorAggregateReceived,
  props<{ errorAggregateMessage: SignalRNextMessage<ErrorAggregate> }>()
);

export const missionTraceMessageReceived = createAction(
  SignalRActionTypes.MissionTraceMessageReceived,
  props<{ missionTraceMessage: SignalRNextMessage<MissionTraceDto> }>()
);

export const activeMissionListMessageReceived = createAction(
  SignalRActionTypes.ActiveMissionListMessageReceived,
  props<{ message: SignalRNextMessage<MissionListSignalRDto[]> }>()
);

export const zoneMessageReceived = createAction(
  SignalRActionTypes.ZoneMessageReceived,
  props<{ zoneMessage: SignalRNextMessage<Zone> }>()
);

export const collisionPointMessageReceived = createAction(
  SignalRActionTypes.CollisionPointMessageReceived,
  props<{ collisionPointMessage: SignalRNextMessage<CollisionPoint> }>()
);

export const collisionPointListMessageReceived = createAction(
  SignalRActionTypes.CollisionPointListMessageReceived,
  props<{ collisionPointListMessage: SignalRNextMessage<CollisionPoint[]> }>()
);

export const intersectionCollisionPointMessageReceived = createAction(
  SignalRActionTypes.IntersectionCollisionPointMessageReceived,
  props<{ intersectionCollisionPointMessage: SignalRNextMessage<IntersectionCollisionPoint> }>()
);

export const intersectionCollisionPointListMessageReceived = createAction(
  SignalRActionTypes.IntersectionCollisionPointListMessageReceived,
  props<{
    intersectionCollisionPointListMessage: SignalRNextMessage<IntersectionCollisionPoint[]>;
  }>()
);

export const mapMessageReceived = createAction(
  SignalRActionTypes.MapMessageReceived,
  props<{ mapMessage: SignalRNextMessage<MapDto> }>()
);

export const navigationLayerMessageReceived = createAction(
  SignalRActionTypes.NavigationLayerMessageReceived,
  props<{ navigationLayerMessage: SignalRNextMessage<NavigationLayerResponseModel> }>()
);

export const jobManagerFeaturesMessageReceived = createAction(
  SignalRActionTypes.JobManagerFeaturesMessageReceived,
  props<{ jobManagerFeatures: JobManagerFeatures }>()
);

export const loadTypeMessageReceived = createAction(
  SignalRActionTypes.LoadTypeMessageReceived,
  props<{ loadTypeMessage: SignalRNextMessage<LoadTypeConfigurationDto> }>()
);

export const mapManagerFeaturesMessageReceived = createAction(
  SignalRActionTypes.MapManagerFeaturesMessageReceived,
  props<{ mapManagerFeatures: MapManagerFeatures }>()
);

export const trafficManagerTrafficModeMessageReceived = createAction(
  SignalRActionTypes.TrafficManagerTrafficModeMessageReceived,
  props<{ trafficManagerFeatures: TrafficManagementSettingsMessage }>()
);

export const opcuaNodeTelemetryMessageReceived = createAction(
  SignalRActionTypes.OpcuaDeviceNodeTelemetryMessageReceived,
  props<{ telemetryMessage: SignalRNextMessage<OpcuaDeviceNodeTelemetrySignalR> }>()
);

export const opcuaDeviceOnlineStatusMessageReceived = createAction(
  SignalRActionTypes.OpcuaDeviceOnlineStatusMessageReceived,
  props<{ statusMessage: SignalRNextMessage<OpcuaDeviceOnlineStatusChangedDto> }>()
);

export const opcuaDeviceNotificationMessageReceived = createAction(
  SignalRActionTypes.OpcuaDeviceNotificationMessageReceived,
  props<{ notification: SignalRNextMessage<OpcuaDeviceNotification> }>()
);

export const shutdownModeMessageReceived = createAction(
  SignalRActionTypes.ShutdownModeMessageReceived,
  props<{ shutdownRequest: ShutdownRequestDto }>()
);

export const infobarMessageReceived = createAction(
  SignalRActionTypes.InfobarMessageReceived,
  props<{ infobarMessage: InfobarMessageResponse }>()
);

export const fleetManagerFeaturesMessageReceived = createAction(
  SignalRActionTypes.FleetManagerFeaturesMessageReceived,
  props<{ fleetManagerFeatures: FleetManagerFeatures }>()
);

export const zoneSetMessageReceived = createAction(
  SignalRActionTypes.ZoneSetMessageReceived,
  props<{ zoneSetMessage: SignalRNextMessage<ZoneSetDto> }>()
);

export const orderMessageReceived = createAction(
  SignalRActionTypes.OrderMessageReceived,
  props<{ orderMessage: SignalRNextMessage<OrderDto> }>()
);

export const tourMessageReceived = createAction(
  SignalRActionTypes.TourMessageReceived,
  props<{ tourMessage: SignalRNextMessage<AtsInternalTourDto> }>()
);

export const nodeOccupancyStatusMessageReceived = createAction(
  SignalRActionTypes.NodeOccupancyStatusMessageReceived,
  props<{ nodeOccupancyStatusMessage: SignalRNextMessage<NodeOccupancyStatusChangeDto> }>()
);

export const processChainGroupTraceMessageReceived = createAction(
  SignalRActionTypes.ProcessChainGroupTraceMessageReceived,
  props<{ processChainGroupTraceMessage: SignalRNextMessage<ProcessChainGroupTraceDto> }>()
);

export const processChainTraceMessageReceived = createAction(
  SignalRActionTypes.ProcessChainTraceMessageReceived,
  props<{ processChainTraceMessage: SignalRNextMessage<ProcessChainTraceInGroupDto> }>()
);
