import { Injectable } from '@angular/core';
import { GuidString } from 'core/models';
import { SignalrRoutes } from '../signalr-routes';

@Injectable()
export class MockSignalRService {
  signalRGroups = new Map<string, Set<string>>();

  registerConnection(): void {}

  async joinGroup(messageGroup: string, componentName: string): Promise<void> {
    messageGroup = replacePlaceHolderWithMockWorkAreaId(messageGroup);

    const value = this.signalRGroups.get(messageGroup) ?? new Set<string>();
    value.add(componentName);
    this.signalRGroups.set(messageGroup, value);
    return Promise.resolve();
  }

  async leaveGroup(messageGroup: string, componentName: string): Promise<void> {
    const value = this.signalRGroups.get(messageGroup) ?? new Set<string>();
    value.delete(componentName);
    if (value.size === 0) this.signalRGroups.delete(messageGroup);
    return Promise.resolve();
  }

  async leaveGroupOfCurrentWorkArea(messageGroup: string): Promise<void> {
    const replacedRoute = replacePlaceHolderWithMockWorkAreaId(messageGroup);
    this.signalRGroups.delete(replacedRoute);
    return Promise.resolve();
  }

  async joinRoute(route: string, args: string, componentName: string): Promise<void> {
    args = args ?? '';
    const replacedRoute = route.replace('{0}', args.toString());
    await this.joinGroup(replacedRoute, componentName);
  }

  async leaveRoute(
    route: string,
    arg: GuidString | string | null,
    componentName: string
  ): Promise<void> {
    arg = arg ?? 'WAID' ?? '';
    const replacedRoute = route.replace('{0}', arg.toString());
    await this.leaveGroup(replacedRoute, componentName);
    return Promise.resolve();
  }

  async leaveAllGroups(): Promise<void> {
    this.signalRGroups.clear();
    return Promise.resolve();
  }

  getConnectionId(): string | null {
    return '';
  }

  setReconnected(value: boolean): boolean {
    return value;
  }

  checkOnlineStatus(): void {}

  hasRouteJoinedGroup(_route: SignalrRoutes, _arg: GuidString | string): boolean {
    return false;
  }

  has(): boolean {
    return true;
  }

  add(): void {}

  remove(): void {}

  logSignalRGroupReport(): void {}

  logSignalRPollMeta(_method: string, _started: boolean, _message?: string): void {}
}

export const replacePlaceHolderWithMockWorkAreaId = (signalrRoute: string): string => {
  return signalrRoute.replace('{0}', 'WAID');
};
