import { NgModule } from '@angular/core';
import {
  DsAccordionModule,
  DsBannerModule,
  DsBoxModule,
  DsBreadcrumbModule,
  DsButtonModule,
  DsFormFieldModule,
  DsFormsModule,
  DsHeaderModule,
  DsIconModule,
  DsImprintModule,
  DsInputMaskModule,
  DsListItemModule,
  DsMenuModule,
  DsMessageModule,
  DsModalModule,
  DsNavigationBarModule,
  DsPopoverModule,
  DsProgressCircleModule,
  DsSegmentedControlModule,
  DsSwitchModule,
  DsTableModule,
  DsTabsModule,
  DsTagModule,
  DsToastModule,
  DsToggleButtonModule,
  DsTooltipDirectiveModule,
  DsTreeModule,
} from '@bmw-ds/components';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';

@NgModule({
  imports: [
    DsBreadcrumbModule,
    DsBoxModule,
    DsToggleButtonModule,
    DsButtonModule,
    DsFormsModule,
    DsFormFieldModule.forRoot({
      markRequiredFormLabels: true,
    }),
    DsIconModule.forRoot({
      sprite: './assets/global/ats-icons.symbol.svg',
    }),
    DsListItemModule,
    DsMenuModule,
    DsMessageModule,
    DsNavigationBarModule,
    DsSegmentedControlModule,
    DsSwitchModule,
    DsTabsModule,
    DsTooltipDirectiveModule.forRoot({
      origin: 'top',
    }),
    DsAccordionModule,
    DsProgressCircleModule,
    DsInputMaskModule,
    DsTagModule,
    DsTableModule,
    DsModalModule,
    DsHeaderModule,
    DsImprintModule.forRoot({
      phone: '+4987317655525',
      electronicContact: ' atsservices@bmw.de',
    }),
    AgGridModule,
    DsToastModule,
    DsTreeModule,
    DsPopoverModule,
    DsBannerModule,
  ],
  exports: [
    DsBreadcrumbModule,
    DsBoxModule,
    DsToggleButtonModule,
    DsButtonModule,
    DsFormsModule,
    DsFormFieldModule,
    DsMenuModule,
    DsMessageModule,
    DsNavigationBarModule,
    DsListItemModule,
    DsSegmentedControlModule,
    DsSwitchModule,
    DsTabsModule,
    DsTooltipDirectiveModule,
    DsAccordionModule,
    DsProgressCircleModule,
    DsIconModule,
    DsInputMaskModule,
    DsTagModule,
    DsTableModule,
    DsModalModule,
    DsHeaderModule,
    DsImprintModule,
    AgGridModule,
    DsToastModule,
    DsTreeModule,
    DsPopoverModule,
    DsBannerModule,
  ],
})
export class DensityModule {}
