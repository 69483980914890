/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  IServerSideDatasource,
  RowModelType,
  ValueFormatterParams,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { OrganizationDto, WorkingAreaDto } from 'core/dtos';
import { CATEGORY, GuidString } from 'core/models';
import { AtsTranslationService, EditBarService, ToolbarService } from 'core/services';
import { takeUntil } from 'rxjs';

import { DATE_FORMAT } from 'core/constants';
import { Icons } from 'library/constants';
import { BaseAgGridTableDirective, GridValueFormatters } from 'shared/components';
import { formatDate } from 'shared/helpers';
@Component({
  selector: 'app-auditlog',
  templateUrl: './auditlog.component.html',
  styleUrls: ['./auditlog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditlogComponent extends BaseAgGridTableDirective implements OnDestroy, OnInit {
  @Input() allWorkingAreas: WorkingAreaDto[] | null = [];
  @Input() allOrganizations: OrganizationDto[] | null = [];
  @Input() selectedWorkingAreaId: GuidString | null = null;
  @Input() datasource: IServerSideDatasource | undefined;
  @Input() pageNo = 1;
  @Input() isLoading = false;

  constructor(
    private readonly editBarService: EditBarService,
    protected readonly translationService: AtsTranslationService,
    protected readonly cdRef: ChangeDetectorRef,
    protected readonly toolbarService: ToolbarService
  ) {
    super(translationService, toolbarService, cdRef);

    this.gridOptions.serverSideSortOnServer = true;
    this.gridOptions.serverSideFilterOnServer = true;
    this.gridOptions.rowModelType = 'serverSide';
    this.gridOptions.serverSideInfiniteScroll = true;
    this.gridOptions.pagination = true;
  }

  translationContext = 'settings.auditlog.list';
  rowModelType: RowModelType = 'serverSide';
  resetIcon = Icons.Reset;
  pageSize = 50;

  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
  };

  readonly columns: ColDef[] = [
    {
      field: 'createdUtc',
      tooltipField: 'createdUtc',
      headerName: 'settings.auditlog.list.createdUtc',
      valueFormatter: GridValueFormatters.dateTimeFormatter,
      filter: 'agDateColumnFilter',
      type: 'dateColumn',
      filterParams: {
        buttons: ['reset'],
        filterOptions: ['inRange'],
        suppressAndOrCondition: true,
      },
      sortable: false,
    },
    {
      field: 'orgName',
      tooltipField: 'orgName',
      headerName: 'settings.auditlog.list.orgName',
      wrapText: true,
      sortable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any): any => {
          return params.success(this.getOrgNameFilterValues());
        },
        refreshValuesOnOpen: true,
      },
    },
    {
      field: 'waName',
      tooltipField: 'waName',
      headerName: 'settings.auditlog.list.waName',
      wrapText: true,
      sortable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any): any => {
          return params.success(this.getWorkingAreaNameFilterValues());
        },
        refreshValuesOnOpen: true,
      },
    },
    {
      field: 'auditTableEventType',
      tooltipField: 'auditTableEventType',
      headerName: 'settings.auditlog.list.auditTableEventType',
      wrapText: true,
      sortable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any): any => {
          return params.success(this.getCategoryFilterValues());
        },
        refreshValuesOnOpen: true,
      },
    },
    {
      field: 'action',
      tooltipField: 'action',
      headerName: 'settings.auditlog.list.action',
      wrapText: true,
      sortable: false,
      filter: 'agTextColumnFilter',
      valueFormatter: AuditlogComponent.actionFormatter,
      filterParams: {
        buttons: ['reset'],
        filterOptions: ['contains'],
        suppressAndOrCondition: true,
      },
    },
  ];

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
    this.translateHeader();
    if (this.datasource) this.gridApi.setServerSideDatasource(this.datasource);
  }

  ngOnInit(): void {
    this.translationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateHeader();
      this.gridApi?.refreshHeader();
      this.gridApi?.refreshServerSide();
    });
  }

  ngOnDestroy(): void {
    this.editBarService.setIsNewObject(false);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getWorkingAreaNameFilterValues(): string[] {
    return this.allWorkingAreas?.map(wa => wa.name) || [];
  }

  getOrgNameFilterValues(): string[] {
    return this.allOrganizations?.map(org => org.name) || [];
  }

  getCategoryFilterValues(): string[] {
    return Object.values(CATEGORY) || [];
  }

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) => params.data.id.toString();

  static actionFormatter(params: ValueFormatterParams<any, string>): string {
    if (!params.value) {
      return '';
    }
    const matches = params.value.match(/\d{4}-\w*-\d{1,2} \d{2}:\d{2}:\d{2}/);
    if (matches && matches[0]) {
      const date = new Date(matches[0] + ' GMT');
      const dateString = formatDate(date, DATE_FORMAT.FULL_DATE_TIME_SECONDS);
      return params.value.replace(/\d{4}-\w*-\d{1,2} \d{2}:\d{2}:\d{2} UTC/, dateString);
    }

    return params.value;
  }
}
