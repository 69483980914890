import { Pipe, PipeTransform } from '@angular/core';
import { GuidString } from 'core/models';
@Pipe({
  name: 'getNameFromId',
  pure: true,
})
export class GetNameFromIdPipe implements PipeTransform {
  transform(
    options: object[],
    id: string | GuidString,
    name = 'name',
    key = 'id'
  ): string | undefined {
    if (options) {
      const option = options.find(i => i[key] === id);

      if (option) return option[name];
      else return;
    } else {
      return;
    }
  }
}
