import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { FeatureToggle } from 'core/dtos';
import { ToggleComponent } from '../toggle/toggle.component';

@Component({
  selector: 'app-parky-chargy-jm-toggle',
  templateUrl: './parky-chargy-jm-toggle.component.html',
  styleUrls: ['./parky-chargy-jm-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkyChargyJobManagerToggleComponent extends ToggleComponent implements OnChanges {
  @Input() loadedVehiclesToggle: FeatureToggle = { isToggledOn: false, dateUpdated: null };
}
