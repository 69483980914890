import { Dictionary } from '@ngrx/entity';
import { MapDto, MapNavigationDto, VehicleDto, ZoneSetDto } from 'core/dtos';
import {
  GuidString,
  MissionFormat,
  MissionStatus,
  MissionTrace,
  ReducedVehicle,
  StepModel,
  StepType,
  VehicleMissionInfo,
  ZoneSetStatus,
} from 'core/models';
import { findLast } from 'lodash';

export function convertToReducedVehicle(
  vehicle: VehicleDto,
  zoneSets: ZoneSetDto[]
): ReducedVehicle {
  const zoneSetId = vehicle.zoneSetId;

  return {
    id: vehicle.id,
    internalIdentifier: vehicle.internalIdentifier,
    name: vehicle.name,
    availability: vehicle.availability,
    initializationDateTime: vehicle.initializationDateTime,
    lastStateMessageProcessedUtc: vehicle.lastStateMessageProcessedUtc,
    ipAddress: vehicle.ipAddress,
    maintenanceModeEnabled: vehicle.maintenanceModeEnabled,
    isErrorForwardingEnabled: vehicle.isErrorForwardingEnabled,
    fleetId: vehicle.fleetId,
    fleetName: vehicle.fleetName,
    isRetired: vehicle.isRetired,
    isConnected: vehicle.isConnected,
    isSwitchedOff: vehicle.isSwitchedOff,
    batteryLevel: vehicle.batteryLevel,
    batteryLevelStatus: vehicle.batteryLevelStatus,
    isLoaded: vehicle.isLoaded,
    softwareVersion: vehicle.softwareVersion,
    softwareVersionChangedDateUtc: vehicle.softwareVersionChangedDateUtc,
    softwareUpdateStatus: vehicle.softwareUpdateStatus,
    softwareDownloadPercentage: vehicle.softwareDownloadPercentage,
    supportedLoadTypes: vehicle.supportedLoadTypes,
    hasError: vehicle.hasError,
    isPaused: vehicle.isPaused,
    isCharging: vehicle.isCharging,
    isBusy: vehicle.isBusy,
    brakeTestRequired: vehicle.brakeTestRequired,
    mode: vehicle.mode,
    pose2D: vehicle.pose2D,
    mapId: vehicle?.map?.id,
    status: vehicle.status,
    vehicleKey: vehicle.vehicleKey,
    workingAreaId: vehicle.workAreaId,
    vehicleType: vehicle.vehicleType,
    trailers: vehicle.trailers ?? null,
    trailerCount: vehicle.trailerCount ?? 0,
    zoneSetId: vehicle.zoneSetId,
    desiredZoneSetId: vehicle.desiredZoneSetId,
    zoneSetName: getZoneSetName(zoneSetId, zoneSets),
    isActiveZoneSet: isActiveZoneSet(zoneSetId, getMapActiveZoneSetId(vehicle?.map?.id, zoneSets)),
    hardwareVersion: vehicle.hardwareVersion,
    metrics: vehicle.metrics,
    orderState: vehicle.orderState,
    loadType: vehicle.loadType,
    loadOrientation: vehicle.loadOrientation,
  };
}

export function mapVehicleMapNavigation(mapId: GuidString): MapNavigationDto {
  return {
    id: mapId,
    navigationLayerId: '',
  };
}

function getMapActiveZoneSetId(mapId: GuidString = '', zoneSets: ZoneSetDto[]): GuidString {
  const activeZoneSet = findLast(
    zoneSets,
    set => set.mapId === mapId && set.status === ZoneSetStatus.Active
  );

  return activeZoneSet?.id || '';
}

function isActiveZoneSet(zoneSetId: GuidString = '', activeZoneSetId: GuidString = ''): boolean {
  return activeZoneSetId !== '' && zoneSetId === activeZoneSetId;
}

export function getMapName(
  mapId: GuidString = '',
  mapEntities: Dictionary<MapDto>
): string | undefined {
  return mapEntities[String(mapId)]?.name;
}

export function getZoneSetName(
  zoneSetId: GuidString = '',
  zoneSetEntities: ZoneSetDto[]
): string | undefined {
  const zoneSet = findLast(zoneSetEntities, set => set.id === zoneSetId);

  return zoneSet?.name;
}

export function getMissionTrace(
  vehicleId: GuidString,
  missionTraces: MissionTrace[] = []
): VehicleMissionInfo | undefined {
  let missionTrace = findLast(
    missionTraces,
    trace => trace.vehicleId === vehicleId && trace.status === MissionStatus.InProgress
  );

  if (missionTrace === undefined) {
    missionTrace = findLast(
      missionTraces,
      trace =>
        trace.vehicleId === vehicleId &&
        (trace.status === MissionStatus.Interrupted ||
          trace.status === MissionStatus.InterruptionFailed ||
          trace.status === MissionStatus.Sleeping ||
          trace.status === MissionStatus.Failed)
    );
  }

  const stepType =
    missionTrace?.missionStep !== undefined ? StepType[missionTrace.missionStep] : '';
  const missionStepDescription = missionTrace?.missionStepNo
    ? `[${missionTrace.missionStepNo}] ${stepType} ${missionTrace.currentStepAttributeText}`
    : '';
  const currentStepNr =
    missionTrace?.missionFormat === MissionFormat.ROS
      ? missionTrace?.currentStepSequenceNumber + 1
      : missionTrace?.currentStepSequenceNumber;

  if (missionTrace) {
    return {
      missionId: missionTrace.id,
      missionName: missionTrace.missionName,
      missionStep: missionTrace.currentStepTypeFrontEnd,
      missionStepString: `enums.stepTypes.${missionTrace.currentStepTypeFrontEnd}`,
      missionStepAttribute: missionTrace.currentStepAttributeText || '',
      missionStepDescription: missionStepDescription,
      missionStepNo: `${currentStepNr}/${missionTrace.stepCount}` ?? '',
    };
  }

  return undefined;
}

export function getStepAttributeText(step: StepModel): string {
  switch (step.type) {
    case StepType.Goto:
    case StepType.Dock:
    case StepType.DockToCharge:
      return step.pointOfInterestName;
    case StepType.Wait:
      return `${step.timeSpanS}s`;
    case StepType.WaitForEndOfStep: {
      const sequence = step.waitForStep.sequenceNumber + 1;
      const type = step.waitForStep.stepType;
      const attribute = step.waitForStep.stepAttribute;
      const mission = step.waitForStep.stepMission;
      return `'${sequence} ${type} ${attribute}' in '${mission}'`;
    }
    default:
      return '';
  }
}
