import {
  ActionDto,
  ActionGroupDto,
  ActionTypeIcon,
  NodeActionType,
  NodeActionTypeGroup,
} from 'core/dtos';
import { isEqual } from 'lodash';

const ActionTypeGroupIcons = {
  [NodeActionTypeGroup.StartStopCharging]: ActionTypeIcon.Battery,
  [NodeActionTypeGroup.PickDrop]: ActionTypeIcon.Maximize,
  [NodeActionTypeGroup.DetectObjectInitPositionFinePositioning]: ActionTypeIcon.Focus,
  [NodeActionTypeGroup.WaitForTrigger]: ActionTypeIcon.Wait,
  [NodeActionTypeGroup.Custom]: ActionTypeIcon.Custom,
};

const ActionTypeGroups = {
  [NodeActionTypeGroup.StartStopCharging]: [
    NodeActionType.StartCharging,
    NodeActionType.StopCharging,
  ],
  [NodeActionTypeGroup.PickDrop]: [NodeActionType.Pick, NodeActionType.Drop],
  [NodeActionTypeGroup.DetectObjectInitPositionFinePositioning]: [
    NodeActionType.DetectObject,
    NodeActionType.InitPosition,
    NodeActionType.FinePositioning,
  ],
  [NodeActionTypeGroup.WaitForTrigger]: [NodeActionType.WaitForTrigger],
};

const groupEnums = Object.keys(NodeActionTypeGroup).map(gyKey => NodeActionTypeGroup[gyKey]);

export function getGroupActionIcon(actionType: NodeActionType): string {
  return ActionTypeGroupIcons[getGroupAction(actionType)] || ActionTypeIcon.Custom;
}

export function getGroupAction(actionType: NodeActionType): NodeActionTypeGroup {
  const foundGroup = groupEnums.find(group => ActionTypeGroups[group]?.includes(actionType));
  return foundGroup ? foundGroup : NodeActionTypeGroup.Custom;
}

export function groupActions(actions: ActionDto[]): ActionGroupDto[] {
  const actionGroups: ActionGroupDto[] = [];

  actions.forEach(action => {
    const actionTypeGroup = getGroupAction(action.actionType);

    if (actionTypeGroup) {
      let actionGroup = actionGroups.find(
        aGroup =>
          aGroup.group === actionTypeGroup &&
          isEqual(aGroup, {
            ...action,
            group: actionTypeGroup,
            actionType: aGroup.actionType,
            isGrouped: aGroup.isGrouped,
          })
      );

      if (!actionGroup) {
        actionGroup = { ...action, group: actionTypeGroup, isGrouped: false };
        actionGroups.push(actionGroup);
      } else {
        actionGroup.isGrouped = true;
      }
    }
  });

  return actionGroups;
}

export function sortActionGroups(actions: ActionGroupDto[]): ActionGroupDto[] {
  const order = {
    [NodeActionTypeGroup.PickDrop]: 1,
    [NodeActionTypeGroup.StartStopCharging]: 2,
    [NodeActionTypeGroup.DetectObjectInitPositionFinePositioning]: 3,
    [NodeActionTypeGroup.WaitForTrigger]: 4,
    [NodeActionTypeGroup.Custom]: 5,
  };
  return actions.sort((a, b) => order[a.group] - order[b.group]);
}

export function cleanAndSortActions(actions: ActionDto[]): ActionGroupDto[] {
  return sortActionGroups(groupActions(removeDuplicateActions(actions)));
}

export function removeDuplicateActions(actions: ActionDto[]): ActionDto[] {
  return actions.filter((action, index, self) => index === self.findIndex(t => isEqual(t, action)));
}
