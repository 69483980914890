import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-not-found-details',
  templateUrl: './not-found-details.component.html',
  styleUrls: ['./not-found-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundDetailsComponent {
  readonly imageUrl = '/assets/global/common/NotFound.svg';
}
