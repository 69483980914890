<div class="d-flex justify-content-end">
  <button
    ds-button
    class="ms-4x"
    [variant]="'ghost'"
    [icon]="resetIcon"
    (click)="resetPersistedFilterAndColumnState()">
    {{ 'shared.tableColumnActions.reset' | translate }}
  </button>
</div>

<ag-grid-angular
  class="ag-theme-density zebra"
  [appPersistTableFilterAndColumnState]="{
    persistKey: persistKey,
    gridOptions,
    rowData: undefined,
  }"
  [columnDefs]="translatedColumnDefs"
  [getRowId]="getRowIdForChangeDetection"
  [gridOptions]="gridOptions"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  [rowSelection]="'single'"
  [rowData]="users"
  [animateRows]="true"
  [searchTerm]="searchTerm"
  (gridReady)="onGridReady($event)"
  data-cy="UsersListTable">
</ag-grid-angular>
