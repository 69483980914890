import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { SelectListOption } from '@bmw-ds/components';
import { EMPTY_GUID } from 'core/constants';
import { FleetDto, VehicleDto } from 'core/dtos';
import { isAlertNowZone } from 'core/guards';
import { EntityList, VehicleGroup, VehicleType, Zone } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { MapVehicleHelper } from 'modules/maps/helpers';
import { Subject, takeUntil } from 'rxjs';

type VehicleGroupForm = {
  strVehicles: string[];
  forkliftVehicles: string[];
  tuggerTrainVehicles: string[];
  uAgvVehicles: string[];
  recipientKeys: string[];
  name: string;
};

@Component({
  selector: 'app-ipst-alertnow-vehicle-group-edit',
  templateUrl: './ipst-alertnow-vehicle-group-edit.component.html',
  styleUrls: ['./ipst-alertnow-vehicle-group-edit.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IpstVehicleGroupsEditComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IpstVehicleGroupsEditComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstVehicleGroupsEditComponent
  implements ControlValueAccessor, Validator, OnChanges, OnDestroy
{
  @Input() allFleets: FleetDto[] = [];
  @Input() recipientKeyList: EntityList[] = [];
  @Input() vehicles: VehicleDto[] = [];
  @Input() selectedZone?: Zone;

  @Output() readonly removeVehicleGroup = new EventEmitter();

  strVehicleOptions: SelectListOption[] = [];
  forkliftVehicleOptions: SelectListOption[] = [];
  tuggerTrainVehicleOptions: SelectListOption[] = [];
  uAgvVehicleOptions: SelectListOption[] = [];

  form: FormGroup;
  formGroup?: VehicleGroupForm;

  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly atsTranslateService: AtsTranslationService,
    protected cdRef: ChangeDetectorRef
  ) {
    this.form = this.createEditForm();
  }

  get value(): VehicleGroupForm | undefined {
    return this.formGroup;
  }

  set value(val: VehicleGroupForm) {
    if (val) {
      this.formGroup = val;
      this.onChange(val);
      this.onTouched(val);
    }
  }

  ngOnChanges({ vehicles, selectedZone }: TypedChanges<IpstVehicleGroupsEditComponent>): void {
    if (vehicles?.currentValue) {
      this.vehicles = vehicles.currentValue;
    }

    if (selectedZone?.currentValue) {
      this.selectedZone = selectedZone.currentValue;
    }

    this.strVehicleOptions = this.createOptions(VehicleType.UnitLoad);
    this.forkliftVehicleOptions = this.createOptions(VehicleType.Forklift);
    this.tuggerTrainVehicleOptions = this.createOptions(VehicleType.TuggerTrain);
    this.uAgvVehicleOptions = this.createOptions(VehicleType.U_AGV);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  writeValue(val: VehicleGroupForm): void {
    this.form.patchValue({
      strVehicles: val.strVehicles,
      forkliftVehicles: val.forkliftVehicles,
      tuggerTrainVehicles: val.tuggerTrainVehicles,
      uAgvVehicles: val.uAgvVehicles,
      recipientKeys: val.recipientKeys,
      name: val.name,
    });

    this.value = val;
  }

  createEditForm(): FormGroup {
    return this.formBuilder.group({
      strVehicles: [[], []],
      forkliftVehicles: [[], []],
      tuggerTrainVehicles: [[], []],
      uAgvVehicles: [[], []],
      recipientKeys: [[], [Validators.required]],
      name: ['', []],
    });
  }

  createOptions(vehicleType: VehicleType): SelectListOption[] {
    const selectedType: VehicleGroup[] = MapVehicleHelper.getVehicleTypeByVehicleGroup(vehicleType);

    if (
      this.selectedZone &&
      isAlertNowZone(this.selectedZone) &&
      !this.selectedZone?.vehicleTypes.some(i => selectedType.includes(i))
    )
      return [];

    const fleets: SelectListOption[] = [];

    const noFleetItem: SelectListOption = {
      label: this.atsTranslateService.get('settings.ipstAlertNowSettings.alertNowService.noFleet'),
      id: EMPTY_GUID.toString(),
      children: [],
      group: true,
    };

    const vehicles = this.vehicles.filter(i => i.vehicleType === vehicleType);
    vehicles.forEach(v => {
      let parentItem = fleets.find(f => f.id === (v.fleetId ?? EMPTY_GUID));

      if (!parentItem) {
        parentItem =
          v.fleetId === undefined || v.fleetId === null
            ? noFleetItem
            : {
                label: this.allFleets.find(i => i.id === v.fleetId)?.name ?? '',
                id: v.fleetId.toString(),
                children: [],
                group: true,
              };

        fleets.push(parentItem);
      }

      parentItem.children?.push({
        label: v.name,
        id: v.id.toString(),
        value: v,
      });
    });

    fleets.sort((a, b) => {
      return a.id === EMPTY_GUID ? 1 : b.id === EMPTY_GUID ? -1 : a.label.localeCompare(b.label);
    });

    fleets.forEach(fleet => {
      fleet.treeView = true;
      fleet.group = false;
      fleet.children?.sort((a: SelectListOption, b: SelectListOption) =>
        a.label.localeCompare(b.label, undefined, { numeric: true })
      );
    });

    return fleets;
  }

  onRemoveVehicleAlertNowGroup(): void {
    this.removeVehicleGroup.emit();
  }

  onChange = (_value: VehicleGroupForm): void => {};
  onTouched = (_value: VehicleGroupForm): void => {};

  registerOnChange(fn: () => {}): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(fn);
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    const errors: ValidationErrors = {};

    Object.keys(this.form.controls).forEach(key => {
      Object.assign(errors, this.form.get(key)?.errors);
    });

    const { strVehicles, forkliftVehicles, tuggerTrainVehicles, uAgvVehicles } = this.form.value;

    if (
      !strVehicles?.length &&
      !forkliftVehicles?.length &&
      !tuggerTrainVehicles?.length &&
      !uAgvVehicles?.length
    ) {
      errors.requiredVehicles = true;
    }

    return Object.keys(errors).length ? errors : null;
  }
}
