import { Dictionary } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { DISTANT_FUTURE } from 'core/constants';
import { FleetDto, MapDto } from 'core/dtos';
import {
  GuidString,
  MissionFormat,
  MissionPanelListData,
  MissionStatus,
  MissionTrace,
  ProcessTrace,
} from 'core/models';
import * as fromMapStore from 'store-modules/maps-store/selectors/maps.selectors';
import * as fromPoiStore from 'store-modules/pois-store/selectors/pois.selectors';
import * as fromVehiclesStore from 'store-modules/vehicles-store/selectors/fleets.selectors';
import { selectAllActiveVehicles } from 'store-modules/vehicles-store/selectors/vehicles.selectors';
import { MissionListInputModel } from '../../../modules/jobs/mission-monitoring/components/mission-list/mission-list-model';
import { getMinutesUntilDelivery } from '../../../modules/jobs/mission-monitoring/helpers/monitoring.helper';
import {
  selectAllMissionTraces,
  selectMissionFilters,
  selectSelectedMissionTraceId,
} from './mission-trace.selectors';
import {
  convertMissionTriggerTypes,
  convertToProcessTrace,
  getFleetName,
} from './mission-trace.selectors.helpers';

export const selectSelectedMissionTrace = createSelector(
  selectSelectedMissionTraceId,
  selectAllMissionTraces,
  (missionTraceId, missionTraces) => missionTraces.filter(m => m.id === missionTraceId)[0]
);

export const selectSelectedProcessTrace = createSelector(
  selectAllMissionTraces,
  selectSelectedMissionTrace,
  fromMapStore.selectMapsEntities,
  fromVehiclesStore.selectFleetEntities,
  fromPoiStore.selectPoiEntities,
  (allMissionTraces, selectedMissionTrace, mapEntities, fleetEntities) => {
    return selectedMissionTrace
      ? convertToProcessTrace(allMissionTraces, selectedMissionTrace, mapEntities, fleetEntities)
      : undefined;
  }
);

export const selectSelectedProcessTraceWithMissionTrace = (
  selectedMissionTrace: MissionTrace
): MemoizedSelector<object, ProcessTrace | undefined> =>
  createSelector(
    fromMapStore.selectMapsEntities,
    fromVehiclesStore.selectFleetEntities,
    fromPoiStore.selectPoiEntities,
    (mapEntities: Dictionary<MapDto>, fleetEntities: Dictionary<FleetDto>) => {
      return selectedMissionTrace
        ? convertToProcessTrace([], selectedMissionTrace, mapEntities, fleetEntities)
        : undefined;
    }
  );

// TODO SERVATS-38029 Remove old endpoints
export const selectActiveMissions = createSelector(
  selectAllMissionTraces,
  (allActiveMissionTraces: MissionTrace[]) =>
    allActiveMissionTraces.filter(
      mission =>
        mission.status === MissionStatus.InProgress ||
        mission.status === MissionStatus.Failed ||
        mission.status === MissionStatus.Interrupted ||
        mission.status === MissionStatus.InterruptionFailed
    )
);
export const selectActiveMissionsByMapId = (
  mapId: GuidString
): MemoizedSelector<object, MissionTrace[]> =>
  createSelector(selectActiveMissions, (allActiveMissionTraces: MissionTrace[]) => {
    return allActiveMissionTraces.filter(m => m.mapId === mapId);
  });

export const selectMissionTracesByProcessChainTraceId = (
  processChainTraceId: GuidString
): MemoizedSelector<object, MissionTrace[]> =>
  createSelector(selectAllMissionTraces, (allActiveMissionTraces: MissionTrace[]) => {
    return allActiveMissionTraces.filter(m => m.processChainTraceId === processChainTraceId);
  });

export const selectToursByTourChainId = (
  tourChainId: GuidString
): MemoizedSelector<object, MissionTrace[]> =>
  createSelector(selectAllMissionTraces, (allActiveMissionTraces: MissionTrace[]) => {
    return allActiveMissionTraces.filter(m => m.tourChainId === tourChainId);
  });

export const selectAllMissionActiveList = createSelector(
  selectAllMissionTraces,
  selectAllActiveVehicles,
  fromVehiclesStore.selectFleetEntities,
  (allMissionTraces, vehicles, fleets): MissionListInputModel[] => {
    return allMissionTraces
      .sort((a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime())
      .map(missionTrace => {
        const deliveryTime = getMinutesUntilDelivery(missionTrace.provisioningTime?.toString());
        const vehicle = vehicles.find(v => v.id === missionTrace.vehicleId);

        const currentStepNr =
          missionTrace.missionFormat === MissionFormat.ROS
            ? missionTrace.currentStepSequenceNumber + 1
            : missionTrace.currentStepSequenceNumber;

        return {
          id: missionTrace.id,
          mapId: missionTrace.mapId,
          missionName: missionTrace.missionName,
          missionId: missionTrace.missionId,
          processChainName: missionTrace.processChainName,
          processChainId: missionTrace.processChainId,
          processChainTraceId: missionTrace.processChainTraceId,
          materialNumber: missionTrace.materialNumber,
          status: missionTrace.status,
          statusEnum: missionTrace.status,
          fleetName: getFleetName(missionTrace.fleetId, fleets),
          vehicleName: missionTrace.vehicleName,
          vehicleId: vehicle?.id,
          vehicleType: missionTrace.vehicleType,
          vehicleStatus: vehicle?.status,
          isVehiclePaused: vehicle?.isPaused,
          isConnected: vehicle?.isConnected,
          isSwitchedOff: vehicle?.isSwitchedOff,
          trigger: convertMissionTriggerTypes(missionTrace.trigger),
          missionTraceTrigger: missionTrace.trigger,
          stepNr: `[${currentStepNr}/${missionTrace.stepCount}]`,
          step: missionTrace.currentStepTypeFrontEnd,
          stepDetail: missionTrace.currentStepAttributeText,
          createdDateTime: missionTrace.createdDateTime,
          deliveryTime: deliveryTime,
          provisioningTime: missionTrace.provisioningTime,
          forecastedEndTime: missionTrace.forecastedEndTime,
          deliveryStatus: missionTrace.deliveryStatus,
          failureComment: missionTrace.failureComment ? [missionTrace.failureComment] : [],
          assignableWaypointName: missionTrace.assignableWaypointName,
          canAbort: missionTrace.canAbort,
          canRetry: !vehicle?.isSwitchedOff && missionTrace.canRetry,
          errorHandlingAllowed: missionTrace.errorHandlingAllowed,
          missionDisplayHighlightLevel: missionTrace.missionDisplayHighlightLevel,
          missionFormat: missionTrace.missionFormat,
          tourSteps: missionTrace.tourSteps,
          priorityLevel: missionTrace.priorityLevel,
          assignmentDelayEndTime: missionTrace.assignmentDelayEndDateTime,
          loadType: missionTrace.loadType,
          scanValue: missionTrace.scanValue,
        };
      });
  }
);

export const selectFilteredMissionActiveList = createSelector(
  // TODO SERVATS-38029 Remove old endpoints
  selectAllMissionActiveList,
  selectMissionFilters,
  (filteredMissionTraces, filters) => {
    let missions: MissionListInputModel[] = [...filteredMissionTraces];

    for (const key in filters) {
      if (!key || !filters[key] || filters[key].length === 0) {
        continue;
      }

      switch (key) {
        case 'missionFailureReasonId':
          missions = missions.filter(mission =>
            filters[key] === '1'
              ? mission.failureComment?.[0].missionFailureReasonId
              : !mission.failureComment?.[0].missionFailureReasonId
          );
          break;
        case 'createdDateTime':
          missions = missions.filter(
            mission =>
              mission.createdDateTime &&
              Date.parse(mission.createdDateTime.toString()) >=
                Date.parse(filters.createdDateTime[0]) &&
              Date.parse(mission.createdDateTime.toString()) <=
                Date.parse(filters.createdDateTime[1])
          );
          break;
        case 'vehicleNames':
          missions = missions.filter(
            m => m.vehicleName && filters.vehicleNames?.includes(m.vehicleName)
          );
          break;
        case 'processChainNames':
          missions = missions.filter(m => filters.processChainNames?.includes(m.processChainName));
          break;
        case 'materialNumbers':
          missions = missions.filter(m => filters.materialNumbers?.includes(m.materialNumber));
          break;
        case 'missionNames':
          missions = missions.filter(m => filters.missionNames?.includes(m.missionName));
          break;
        case 'vehicleTypes':
          missions = missions.filter(
            m => filters.vehicleTypes?.length === 0 || filters.vehicleTypes?.includes(m.vehicleType)
          );
          break;
        default:
          missions = missions.filter(
            mission => mission[key] !== undefined && filters[key]?.includes(mission[key])
          );
      }
    }

    return missions;
  }
);

export const selectFailedMissionTracesCount = createSelector(
  selectActiveMissions,
  activeMissions => {
    return activeMissions.filter(m => m.status === MissionStatus.Failed).length;
  }
);

export const selectMissionPanelData = createSelector(
  selectAllMissionTraces,
  selectAllActiveVehicles,
  fromMapStore.selectSelectedMap,
  (allMissionTraces, vehicles, selectedMap): MissionPanelListData[] => {
    if (selectedMap) {
      const distantFuture = new Date(DISTANT_FUTURE);

      return allMissionTraces
        .filter(m => m.mapId === selectedMap.id || m.missionFormat === MissionFormat.VDA5050)
        .sort((a, b) => {
          const dateA = a.createdDateTime ? new Date(a.createdDateTime) : distantFuture;
          const dateB = b.createdDateTime ? new Date(b.createdDateTime) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        })
        .sort((a, b) => {
          const dateA = a.provisioningTime ? new Date(a.provisioningTime) : distantFuture;
          const dateB = b.provisioningTime ? new Date(b.provisioningTime) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        })
        .sort((a, b) => {
          return (
            Number(b.status === MissionStatus.Failed) - Number(a.status === MissionStatus.Failed)
          );
        })
        .map(missionTrace => {
          const minutesUntilDelivery = getMinutesUntilDelivery(
            missionTrace.provisioningTime?.toString()
          );
          const vehicle = vehicles.find(v => v.id === missionTrace.vehicleId);

          return {
            missionTraceId: missionTrace.id,
            missionStatus: missionTrace.status,
            isFinalState: [
              MissionStatus.Aborted,
              MissionStatus.Completed,
              MissionStatus.CompletedWithSupport,
            ].includes(missionTrace.status),
            missionName: missionTrace.missionName,
            missionId: missionTrace.missionId,
            processChainTraceId: missionTrace.processChainTraceId,
            minutesUntilDelivery: minutesUntilDelivery,
            deliveryStatus: missionTrace.deliveryStatus,
            vehicleId: missionTrace.vehicleId?.toString(),
            vehicleName: missionTrace.vehicleName,
            vehicleStatus: vehicle?.status,
            assignmentDelayEndDateTime: missionTrace.assignmentDelayEndDateTime,
            missionFormat: missionTrace.missionFormat,
            failureComment: missionTrace.failureComment,
          };
        });
    } else {
      return [];
    }
  }
);
