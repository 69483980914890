/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RuleService } from 'core/api-services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions';
import * as RouteCustomizationRuleActions from '../actions/route-customization-rule.actions';

@Injectable()
export class RouteCustomizationRuleEffects {
  loadRouteCustomizationRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteCustomizationRuleActions.loadRouteCustomizationRules),
      concatMap(() =>
        this.ruleService.getAllRules().pipe(
          map(rules => {
            return RouteCustomizationRuleActions.loadRouteCustomizationRulesSuccess({
              rules: rules,
            });
          }),
          catchError(errorMessage =>
            of(RouteCustomizationRuleActions.loadRouteCustomizationRulesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadRouteCustomizationRulesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouteCustomizationRuleActions.loadRouteCustomizationRulesFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadRulesFailure');
        })
      ),
    { dispatch: false }
  );

  createRouteCustomizationRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteCustomizationRuleActions.createRouteCustomizationRule),
      concatMap(({ rule }) =>
        this.ruleService.createRule(rule).pipe(
          map(rule => {
            return RouteCustomizationRuleActions.createRouteCustomizationRuleSuccess({
              rule: rule,
            });
          }),
          catchError(errorMessage =>
            of(RouteCustomizationRuleActions.createRouteCustomizationRuleFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateRouteCustomizationRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteCustomizationRuleActions.updateRouteCustomizationRule),
      concatMap(({ rule }) =>
        this.ruleService.updateRule(rule).pipe(
          map(payload => {
            return RouteCustomizationRuleActions.updateRouteCustomizationRuleSuccess({
              rule: {
                id: payload.id!.toString(),
                changes: rule,
              },
            });
          }),
          catchError(errorMessage =>
            of(RouteCustomizationRuleActions.updateRouteCustomizationRuleFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deleteRouteCustomizationRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteCustomizationRuleActions.deleteRouteCustomizationRule),
      concatMap(({ id }) =>
        this.ruleService.deleteRuleById(id).pipe(
          map(() => {
            return RouteCustomizationRuleActions.deleteRouteCustomizationRuleSuccess({
              id: id,
            });
          }),
          catchError(errorMessage =>
            of(RouteCustomizationRuleActions.deleteRouteCustomizationRuleFailure({ errorMessage }))
          )
        )
      )
    )
  );

  manageRouteCustomizationRuleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RouteCustomizationRuleActions.createRouteCustomizationRuleSuccess,
        RouteCustomizationRuleActions.updateRouteCustomizationRuleSuccess,
        RouteCustomizationRuleActions.deleteRouteCustomizationRuleSuccess
      ),
      concatMap(() => [
        RouteCustomizationRuleActions.loadRouteCustomizationRules(),
        setHasChanges({ hasChanges: false }),
        setIsEditMode({ isEditMode: false }),
      ])
    )
  );

  manageRouteCustomizationRuleFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RouteCustomizationRuleActions.createRouteCustomizationRuleFailure,
          RouteCustomizationRuleActions.updateRouteCustomizationRuleFailure,
          RouteCustomizationRuleActions.deleteRouteCustomizationRuleFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly ruleService: RuleService,
    private readonly toastService: ToastService
  ) {}
}
