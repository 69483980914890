<div class="button-spacing">
  <ds-segmented-control activeItemId="allUsers" class="tab-container">
    <ds-segmented-control-item id="allUsers" (click)="onAllUsersButtonClick()">{{
      'environmentUsersButtons.allUsers' | translate
    }}</ds-segmented-control-item>
    <ds-segmented-control-item id="registeredUsers" (click)="onRegisteredUsersButtonClick()">{{
      'environmentUsersButtons.registeredUsers' | translate
    }}</ds-segmented-control-item>
    <ds-segmented-control-item id="unassignedUsers" (click)="onUnassignedUsersButtonClick()"
      >{{ 'environmentUsersButtons.unassignedUsers' | translate }} ({{
        countUnassignedUsers$ | async
      }})</ds-segmented-control-item
    >
  </ds-segmented-control>
</div>
<div>
  <h5 *ngIf="vm.showRegisteredUsers">
    {{ 'userRolesAndPermissions.registeredUsersTableExplanation' | translate }}
  </h5>
  <h5 *ngIf="vm.showAllUsers">
    {{ 'userRolesAndPermissions.allUsersTableExplanation' | translate }}
  </h5>
  <h5 *ngIf="vm.showUnassignedUsers">
    {{ 'userRolesAndPermissions.unassignedUsersTableExplanation' | translate }}
  </h5>
</div>
<app-user-list
  *ngIf="!vm.showAllUsers && !vm.showUnassignedUsers"
  [menuItems]="menuItems"
  [searchTerm]="searchTerm$ | async"
  [users]="$environmentUsersWithRoles | async"
  (selectedItem)="onSelectedItem($event)">
</app-user-list>

<app-environment-users
  *ngIf="vm.showAllUsers"
  [persistKey]="'all-users-list'"
  [menuItems]="envUserMenuItems"
  [searchTerm]="searchTerm$ | async"
  [users]="environmentUsers"
  (selectedItem)="onSelectedItem($event)">
</app-environment-users>

<app-environment-users
  *ngIf="vm.showUnassignedUsers"
  [persistKey]="'unassigned-users-list'"
  [menuItems]="unassignedUserMenuItems"
  [searchTerm]="searchTerm$ | async"
  [users]="unassignedUsers"
  (selectedItem)="onSelectedItem($event)">
</app-environment-users>

<app-assign-user-role-modal
  [assignUserRoleModalInput]="assignUserRoleModalInput"
  [isModalOpen]="isAssignUserModalOpen"
  (cancelModal)="cancelAssignUserModal()"
  (saveModal)="saveAssignUserModal($event)">
</app-assign-user-role-modal>

<app-user-details-dialog
  [editEnabled]="editEnabled"
  [isModalOpen]="isModalOpen"
  [userDetailsData]="userDetailsData"
  (dismissModal)="onCloseModal()">
</app-user-details-dialog>
