import { ZoneType } from 'core/dtos';

export const DEFAULT_ZONE = ZoneType.Restricted;
export const MINIMUM_ZONE_POINTS = 3;
export const MINIMUM_VALUE = 0;
export const DEFAULT_VALUE_ANTI_ALIGN_COST = 10;
export const DEFAULT_VALUE_ALIGN_COST = 0.1;
export const DEFAULT_VALUE_CROSS_COST = 1;
export const DEFAULT_MAX_NUMBER_OF_VEHICLES = 1;
export const DEFAULT_MAX_WAITING_TIME_MINUTES = '01';
export const DEFAULT_MAX_WAITING_TIME_SECONDS = '00';
export const MINIMUM_OPACITY = 10;
export const MAXIMUM_OPACITY = 100;
export const MINIMUM_COORDINATE = -10000;
export const MAXIMUM_COORDINATE = 10000;
export const MINIMUM_OFFSET = -10000;
export const MAXIMUM_OFFSET = 10000;
export const MINIMUM_ROTATION = -180;
export const MAXIMUM_ROTATION = 180;
export const MINIMUM_RESOLUTION = 1;
export const MAXIMUM_RESOLUTION = 100;
export const MAXIMUM_ZONESETS_LIMIT = 10;

export const NAVIGATION_LAYER_DISTANCE_PRECISION = 2;

export const CANVAS_WAIT = 200;
