import { Dictionary } from '@ngrx/entity';
import { EMPTY_GUID } from 'core/constants';
import { FleetDto, MapDto, PoiDto, ProcessChainGroupDto } from 'core/dtos';
import {
  GuidString,
  MapWithProcessChainChildren,
  Mission,
  MissionDetails,
  PoiStepType,
  ProcessChain,
  ProcessChainDetails,
  StepModel,
  StepModelBase,
  StepType,
  TableProcessChain,
  TreeOverviewProcessChain,
  VehicleType,
} from 'core/models';
import { FAKE_PARENT_ID } from 'library/helpers';
import { groupBy } from 'lodash';

export function convertToTableProcessChain(
  processChain: ProcessChain,
  fleetEntities: Dictionary<FleetDto>,
  mapEntities: Dictionary<MapDto>
): TableProcessChain {
  const tableProcessChain: TableProcessChain = {
    id: processChain.id,
    destination: processChain.destination,
    referenceId: processChain.referenceId,
    materialNumber: processChain.materialNumber,
    source: processChain.source,
    fleetId: processChain.fleetId,
    name: processChain.name,
    mapId: '',
    mapName: '',
    fleetName: '',
    type: processChain.type,
    isActive: processChain.isActive,
    processChainGroupId: processChain.processChainGroupId,
    processChainGroupName: '',
    triggerType: processChain.triggerType,
    vehicleType: VehicleType.UnitLoad,
  };

  setFleetName(tableProcessChain, fleetEntities);
  setMapInformation(tableProcessChain, processChain, mapEntities);
  return tableProcessChain;
}

export function convertToTreeOverviewProcessChain(
  processChains: TableProcessChain[],
  maps: MapDto[]
): MapWithProcessChainChildren[] {
  const groupedProcessChains = groupBy(processChains, 'mapId');

  return maps.map(map => {
    let treeOverviewProcessChains: TreeOverviewProcessChain[] = [];
    if (groupedProcessChains[map.id.toString()]) {
      treeOverviewProcessChains = groupedProcessChains[map.id.toString()].map(pc => {
        return { id: pc.id, name: pc.name };
      });
    } else {
      treeOverviewProcessChains = [{ id: FAKE_PARENT_ID, name: 'shared.treeNav.noProcessChain' }];
    }

    return {
      id: map.id,
      name: map.name,
      children: treeOverviewProcessChains,
    };
  });
}

export function convertToProcessChainDetails(
  processChain: ProcessChain,
  mapEntities: Dictionary<MapDto>,
  fleetEntities: Dictionary<FleetDto>,
  poiEntities: Dictionary<PoiDto>,
  processChainGroupEntities: Dictionary<ProcessChainGroupDto>
): ProcessChainDetails {
  const details: ProcessChainDetails = {
    ...processChain,
    fleetName: '',
    mapName: '',
    mapId: '',
    abortConnectionName: '',
    missionDetails: [],
    processChainGroupName: '',
  };
  setFleetName(details, fleetEntities);
  setMapInformation(details, processChain, mapEntities);
  setMissionDetails(details, poiEntities);
  setProcessChainGroupName(details, processChainGroupEntities);
  return details;
}

export function setFleetName(
  tableProcessChain: TableProcessChain | ProcessChainDetails,
  fleetEntities: Dictionary<FleetDto>
): void {
  if (tableProcessChain.fleetId) {
    const fleet = fleetEntities[String(tableProcessChain.fleetId)];
    tableProcessChain.fleetName = fleet ? fleet.name : '';
  }
}

export function setMissionDetails(
  tableProcessChain: ProcessChainDetails,
  poiEntities: Dictionary<PoiDto>
): void {
  tableProcessChain.missionDetails = tableProcessChain.missions.map(x =>
    convertToMissionDetails(x, poiEntities)
  );
}

function convertToMissionDetails(
  mission: Mission,
  poisEntities: Dictionary<PoiDto>
): MissionDetails {
  let assignableWaypoint: PoiDto | null = null;
  if (mission.assignableWaypointId) {
    assignableWaypoint = poisEntities[String(mission.assignableWaypointId)] ?? null;
  }

  const steps: StepModel[] = mission.steps.map(step => {
    switch (step.type) {
      case StepType.Goto:
      case StepType.Dock:
      case StepType.TurnDolly:
      case StepType.DockToCharge:
        return { ...step, pointOfInterestName: getPoiName(step, poisEntities) };
      default:
        return step;
    }
  });

  return { ...mission, assignableWaypoint, steps };
}

function getPoiName(step: StepModelBase<PoiStepType>, poiEntities: Dictionary<PoiDto>): string {
  if (step.pointOfInterestName?.trim()) {
    return step.pointOfInterestName;
  }

  let id: GuidString;
  if (step.pointOfInterestId && step.pointOfInterestId !== EMPTY_GUID) {
    id = step.pointOfInterestId;
  } else {
    id = step.pointOfInterestGroupId;
  }

  return Object.values(poiEntities).find(p => p?.id === id)?.name ?? '';
}

export function setMapInformation(
  tableProcessChain: TableProcessChain | ProcessChainDetails,
  processChain: ProcessChain,
  mapEntities: Dictionary<MapDto>
): void {
  if (processChain.missions[0]?.mapId) {
    const map = mapEntities[String(processChain.missions[0].mapId)];
    tableProcessChain.mapName = map ? map.name : '';
    tableProcessChain.mapId = map ? map.id : '';
  }
}

export function setProcessChainGroupName(
  tableProcessChain: TableProcessChain | ProcessChainDetails,
  groupEntities: Dictionary<ProcessChainGroupDto>
): void {
  if (tableProcessChain.processChainGroupId) {
    const group = groupEntities[String(tableProcessChain.processChainGroupId)];
    tableProcessChain.processChainGroupName = group?.name ?? '-';
  }
}
