<div class="container list-container">
  <h2 class="title-md" style="margin-bottom: var(--ds-space-3x)">
    {{ 'settings.ipstAlertNowSettings.helpTool.matchedRecipientKeyHeader' | translate }}
  </h2>
  <p
    *ngIf="recipientKeys?.length"
    class="text-regular"
    style="font-weight: var(--ds-typography-weight-strong)">
    {{ 'settings.ipstAlertNowSettings.helpTool.matchedRecipientKeyText' | translate }}
  </p>

  <ul *ngIf="recipientKeys?.length" data-cy="recipientKeyList">
    <li
      *ngFor="let recipientKey of recipientKeys"
      data-cy="recipientKeyItem"
      style="margin-top: var(--ds-space-3x)">
      <p class="text-regular">{{ recipientKey }}</p>
    </li>
  </ul>

  <p *ngIf="!recipientKeys?.length" data-cy="helpToolNoData" class="text-paragraph">
    {{ 'settings.ipstAlertNowSettings.helpTool.noData' | translate }}
  </p>
</div>
