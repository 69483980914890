/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { Store } from '@ngrx/store';
import {
  MissionListSignalRDto,
  PackedMapVehicleSignalrDto,
  VehicleListSignalrDto,
} from 'core/dtos';
import { GuidString, MessageType, SignalRNextMessage } from 'core/models';
import { SessionService } from 'core/services/session.service';
import { ToastService } from 'core/services/toast.service';

import * as fromRoot from 'store/reducers';

import { CSharpTicksToTimestamp } from './helpers/unpack-functions.helper';
import { SignalrRoutes } from './signalr-routes';
import { SignalRService } from './signalr.service';

const LogIntervalMs = 1500;

@Injectable({
  providedIn: 'root',
})
export class SignalRPackedService extends SignalRService {
  constructor(
    protected sessionService: SessionService,
    protected store: Store<fromRoot.RootState>,
    protected toastService: ToastService
  ) {
    super(
      sessionService,
      store,
      toastService,
      environment.Services.Signalr,
      new MessagePackHubProtocol()
    );
  }

  private latencies: number[] = [];
  private lastPrint = new Date().valueOf();

  registerConnectionNext<T>(
    methodName: string,
    callback: (data: SignalRNextMessage<T>) => void
  ): void {
    this.hubConnection.on(methodName, (data: SignalRNextMessage<T>) => {
      callback(data);
      // @ts-expect-error: Unknown property
      const serverTicks: string | undefined = data?.payload?.timestamp;
      if (window.flags.logSignalRLatency && serverTicks) {
        this.logLatency(serverTicks);
      }
      this.logSignalRCall(methodName, data);
    });
  }

  private logLatency(serverTicks: string): void {
    const now = new Date().valueOf();
    const serverTimeMs = CSharpTicksToTimestamp(serverTicks);
    const latencyMs = now - serverTimeMs;
    this.latencies.push(latencyMs);

    if (now - this.lastPrint.valueOf() > LogIntervalMs && this.latencies.length > 0) {
      const meanLatency =
        this.latencies.reduce((prev, curr) => prev + curr, 0) / this.latencies.length;
      console.info(`Latency: ${meanLatency.toFixed(1)} ms`);
      this.lastPrint = now;
      this.latencies = [];
      window.metrics.signalRLatency?.push(meanLatency);
    }
  }

  async fetchVehicleList(
    waId: GuidString,
    mapId: GuidString | null,
    fromTicks: string | null
  ): Promise<VehicleListSignalrDto[]> {
    await this.connect();
    if (!this.isConnectedOrTest()) return [];

    const result = await this.hubConnection.invoke<VehicleListSignalrDto[]>(
      SignalrRoutes.FetchVehicleList,
      waId,
      mapId,
      fromTicks
    );

    if (result.length > 0) this.logSignalRPollingResult(SignalrRoutes.FetchVehicleList, result);
    return result;
  }

  async fetchMapData(
    waId: GuidString,
    mapId: GuidString,
    fromTicks: string | null
  ): Promise<PackedMapVehicleSignalrDto[]> {
    await this.connect();
    if (!this.isConnectedOrTest()) return [];

    const result = await this.hubConnection.invoke<PackedMapVehicleSignalrDto[]>(
      SignalrRoutes.FetchMapData,
      waId,
      mapId,
      fromTicks
    );

    if (result.length > 0) {
      this.logSignalRPollingResult(SignalrRoutes.FetchMapData, result);

      if (window.flags.logSignalRLatency) {
        const maxTimeStampStr = result.reduce((prev, cur) =>
          prev?.fmTime > cur?.fmTime ? prev : cur
        );
        const latestMessageTimeMs = CSharpTicksToTimestamp(maxTimeStampStr.fmTime);
        const now = new Date().valueOf();
        const latencyMs = now - latestMessageTimeMs;
        window['metrics']['signalRLatency'] = window['metrics']['signalRLatency'] || [];
        window['metrics']['signalRLatency'].push(parseFloat((latencyMs / 1000).toFixed(2)));
      }
    }

    return result;
  }

  async fetchActiveMissionList(
    waId: GuidString,
    fromTicks: string | null
  ): Promise<SignalRNextMessage<MissionListSignalRDto[]>> {
    await this.connect();
    if (!this.isConnectedOrTest())
      return {
        type: MessageType.Modified,
        payload: [],
      };

    const result = await this.hubConnection.invoke<SignalRNextMessage<MissionListSignalRDto[]>>(
      SignalrRoutes.FetchActiveMissionList,
      waId,
      fromTicks
    );

    if (result.payload.length > 0)
      this.logSignalRPollingResult(SignalrRoutes.FetchActiveMissionList, result.payload);

    return result;
  }
}
