/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'core/services/toast.service';

import { of } from 'rxjs';
import { catchError, concatMap, filter, map, tap } from 'rxjs/operators';

import { LifService } from 'core/api-services';

import { setHasChanges, setIsEditMode } from 'store/actions';

import { LayoutDto } from 'core/dtos';
import { MapMode } from 'core/models';
import * as GraphLayerActions from '../actions/graph-layer.actions';
import * as MapsActions from '../actions/maps.actions';

@Injectable()
export class GraphLayerEffects {
  loadGraphLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphLayerActions.loadGraphLayers),
      concatMap(action =>
        this.lifService.getGraphLayer(action.mapId).pipe(
          map(layer =>
            GraphLayerActions.loadGraphLayerSuccess({
              graphLayer: layer && layer.length > 0 ? layer[0] : ({} as LayoutDto),
            })
          ),
          catchError(errorMessage => of(GraphLayerActions.loadGraphLayersFailure({ errorMessage })))
        )
      )
    )
  );

  updateGraphLayerNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphLayerActions.updateGraphLayerNode),
      concatMap(action =>
        this.lifService.updateNode(action.node).pipe(
          map(layout => GraphLayerActions.updateGraphLayerNodeSuccess({ layout: layout[0] })),
          catchError(errorMessage => of(GraphLayerActions.loadGraphLayersFailure({ errorMessage })))
        )
      )
    )
  );

  loadGraphLayersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GraphLayerActions.loadGraphLayersFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadGraphLayersFailure');
        })
      ),
    { dispatch: false }
  );

  updateLayerNodeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GraphLayerActions.updateGraphLayerNodeFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.updateGraphLayerNodeFailure');
        })
      ),
    { dispatch: false }
  );

  updateGraphLayerNodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphLayerActions.updateGraphLayerNodeSuccess),
      tap(() => {
        this.toastService.createSuccessToast('shared.actions.updateGraphLayerNodeSuccess');
      }),
      concatMap(() => [
        setHasChanges({ hasChanges: false }),
        setIsEditMode({ isEditMode: false }),
        MapsActions.setMapMode({ mode: MapMode.None }),
      ])
    )
  );

  getNodeOccupancyStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphLayerActions.getNodeOccupancyStatus),
      concatMap(action =>
        this.lifService.getNodeOccupancyStatus(action.nodeId).pipe(
          filter(nodeOccupancyStatusMessage => nodeOccupancyStatusMessage !== null),
          map(nodeOccupancyStatusMessage =>
            GraphLayerActions.updateGraphLayerNodeOccupancyStatus({ nodeOccupancyStatusMessage })
          ),
          catchError(errorMessage =>
            of(GraphLayerActions.updateGraphLayerNodeOccupancyStatusFailure({ errorMessage }))
          )
        )
      )
    )
  );

  unbookNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphLayerActions.unbookNode),
      concatMap(action =>
        this.lifService.unbookNode(action.nodeId).pipe(
          map(result => {
            if (result.isCancelled) {
              return GraphLayerActions.unbookNodeSuccess({ nodeId: action.nodeId });
            }
            return GraphLayerActions.unbookNodeFailure({
              errorMessage: 'shared.actions.unbookNodeFailure',
            });
          }),
          catchError(errorMessage => of(GraphLayerActions.unbookNodeFailure({ errorMessage })))
        )
      )
    )
  );

  unbookNodeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GraphLayerActions.unbookNodeFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.unbookNodeFailure');
        })
      ),
    { dispatch: false }
  );

  unbookNodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphLayerActions.unbookNodeSuccess),
      map(({ nodeId }) => GraphLayerActions.getNodeOccupancyStatus({ nodeId: nodeId })),
      tap(() => {
        this.toastService.createSuccessToast('shared.actions.unbookNodeSuccess');
      })
    )
  );

  resetGraphLayerActionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        GraphLayerActions.loadGraphLayerSuccess,
        GraphLayerActions.loadGraphLayersFailure,
        GraphLayerActions.updateGraphLayerNodeSuccess,
        GraphLayerActions.updateGraphLayerNodeFailure
      ),
      map(() => GraphLayerActions.resetGraphLayerActionStatus())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly lifService: LifService,
    private readonly toastService: ToastService
  ) {}
}
