<form [formGroup]="form">
  <div class="row">
    <ds-form-field class="form-field-spacing col-sm-6">
      <label ds-label for="name">
        {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroupName' | translate }}
      </label>
      <input
        data-cy="vehicleAlertNowGroupName"
        ds-input
        ds-form-validation
        id="name"
        formControlName="name"
        type="text"
        placeholder="{{ 'settings.ipstAlertNowSettings.alertNowService.enterName' | translate }}" />
    </ds-form-field>

    <ds-form-field class="key-container col-sm-6">
      <label ds-label for="recipientKey">
        {{ 'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate }}
      </label>
      <ds-select
        formControlName="recipientKeys"
        data-cy="vehiclesRecipientKeys"
        id="recipientKey"
        required
        ds-form-validation
        [isMultiselect]="true"
        [options]="recipientKeyList | dsDropdown"
        [labellingConfig]="{
          placeholder:
            'settings.ipstAlertNowSettings.alertNowService.selectRecipientKey' | translate
        }"></ds-select>
    </ds-form-field>

    <ds-form-field *ngIf="strVehicleOptions.length > 0" class="vehicle-container col-sm-12">
      <label ds-label for="vehicles">
        {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleGroups.STR' | translate }}
      </label>
      <ds-select
        ds-form-validation
        id="vehicles"
        data-cy="vehicleStrListDropdown"
        formControlName="strVehicles"
        [options]="strVehicleOptions"
        [isMultiselect]="true"
        [labellingConfig]="{
          placeholder: 'settings.ipstAlertNowSettings.alertNowService.selectVehicles' | translate
        }"></ds-select>
    </ds-form-field>

    <ds-form-field *ngIf="forkliftVehicleOptions.length > 0" class="vehicle-container col-sm-12">
      <label ds-label for="vehicles">
        {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleGroups.Forklift' | translate }}
      </label>
      <ds-select
        ds-form-validation
        id="vehicles"
        data-cy="vehicleForkliftListDropdown"
        formControlName="forkliftVehicles"
        [options]="forkliftVehicleOptions"
        [isMultiselect]="true"
        [labellingConfig]="{
          placeholder: 'settings.ipstAlertNowSettings.alertNowService.selectVehicles' | translate
        }"></ds-select>
    </ds-form-field>

    <ds-form-field *ngIf="tuggerTrainVehicleOptions.length > 0" class="vehicle-container col-sm-12">
      <label ds-label for="vehicles">
        {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleGroups.TuggerTrain' | translate }}
      </label>
      <ds-select
        ds-form-validation
        id="vehicles"
        data-cy="vehicleTuggerTrainListDropdown"
        formControlName="tuggerTrainVehicles"
        [options]="tuggerTrainVehicleOptions"
        [isMultiselect]="true"
        [labellingConfig]="{
          placeholder: 'settings.ipstAlertNowSettings.alertNowService.selectVehicles' | translate
        }"></ds-select>
    </ds-form-field>

    <ds-form-field *ngIf="uAgvVehicleOptions.length > 0" class="vehicle-container col-sm-12">
      <label ds-label for="vehicles">
        {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleGroups.UAGV' | translate }}
      </label>
      <ds-select
        ds-form-validation
        id="vehicles"
        data-cy="vehicleUagvListDropdown"
        formControlName="uAgvVehicles"
        [options]="uAgvVehicleOptions"
        [isMultiselect]="true"
        [labellingConfig]="{
          placeholder: 'settings.ipstAlertNowSettings.alertNowService.selectVehicles' | translate
        }"></ds-select>
    </ds-form-field>

    <ds-hint class="pt-2x pb-4x">
      {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleHint' | translate }}
    </ds-hint>
  </div>

  <div style="display: flex; justify-content: flex-end; width: 50%; float: right">
    <button
      data-cy="deleteVehicleAlertNowGroup"
      ds-button
      [variant]="'ghost-muted'"
      [icon]="'minus_circle'"
      (click)="onRemoveVehicleAlertNowGroup()">
      {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroup' | translate }}
    </button>
  </div>
</form>
