/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { RolesService, UserService } from 'core/api-services';
import { RoleDto } from 'core/dtos';
import {
  AssignUserRoleCommand,
  AtsActions,
  BasicMenuItem,
  ExtendedDsMenuItem,
  GuidString,
  RoleScope,
  ToolBarControlKeys,
  ToolBarControls,
  ToolBarItem,
  User,
  UserWithRole,
} from 'core/models';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { PermissionService } from 'core/services/permission.service';
import { ToolbarService } from 'core/services/toolbar.service';
import { ModalDialogService } from 'library/components/modal-dialog';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { AssignUserRoleModalInputModal } from 'shared/components';

import { environment } from '@environment';
import { DEBOUNCE_TIME } from 'core/constants';
import { ToastService } from 'core/services';
import * as fromRoot from 'store/index';

export interface AssignUserRoleDialogInput {
  currentUser?: User;
  translateKey: string;
  roles: RoleDto[];
  currentRole?: RoleDto;
  areaText: string;
  users: User[];
}

type SpecialEnvRoles = 'Environment Admin' | 'Environment Viewer' | 'ATS Support' | 'Fire Fighter';

@Component({
  selector: 'app-all-environment-users-container',
  templateUrl: './all-environment-users-container.component.html',
  styleUrls: ['./all-environment-users-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllEnvironmentUsersContainerComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();

  $environmentUsersWithRoles: Observable<UserWithRole[]> = of([]);
  searchTerm$: Observable<string> = of('');
  countUnassignedUsers$: Observable<number> = of(0);

  environmentUsers: UserWithRole[] = [];
  unassignedUsers: UserWithRole[] = [];
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  menuItems: ExtendedDsMenuItem[] = [];
  envUserMenuItems: ExtendedDsMenuItem[] = [];
  unassignedUserMenuItems: ExtendedDsMenuItem[] = [];

  selectedUser?: UserWithRole;
  userDetailsData?: AssignUserRoleDialogInput;
  assignUserRoleModalInput: AssignUserRoleModalInputModal | undefined;

  activeIndex = 0;

  organizationId: GuidString = '';
  selectionType = '';
  searchForOpenModal = '';
  searchForClosedModal = '';

  vm: {
    showAllUsers: boolean;
    showUnassignedUsers: boolean;
    showRegisteredUsers: boolean;
  } = {
    showAllUsers: true,
    showRegisteredUsers: false,
    showUnassignedUsers: false,
  };

  canCreateViewerSupport = false;
  canCreateEnvironmentAdmin = false;
  canAddEnvironmentUser = false;
  canDeleteEnvironmentUser = false;
  canEditRevokeEnvironmentUser = false;
  isModalOpen = false;
  isAssignUserModalOpen = false;
  editEnabled = false;
  buttonSelected = false;

  private users: User[] = [];
  private environmentRoles: RoleDto[] = [];
  private readonly environmentKey = 'settings.users.environment';
  private readonly viewEditMenuKey = 'shared.treeTable.actionMenu.viewEdit';

  constructor(
    private readonly userService: UserService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly toolbarService: ToolbarService,
    private readonly atsTranslateService: AtsTranslationService,
    private readonly modalService: ModalDialogService,
    private readonly toastService: ToastService,
    private readonly rolesService: RolesService,
    private readonly permissionService: PermissionService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUserPermissions();
    this.buildToolBarItems();
    this.dispatchActionsAndQuerySelectors();
    this.initializeSubscriptions();

    this.atsTranslateService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.buildToolBarItems();
      this.cdRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.toolbarService.configureItems([]);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUserPermissions(): void {
    this.canEditRevokeEnvironmentUser = this.permissionService.actionAllowed(
      AtsActions.Environment_Users_Edit
    );

    this.canDeleteEnvironmentUser = this.permissionService.actionAllowed(
      AtsActions.Environment_Users_Delete
    );

    this.canCreateEnvironmentAdmin = this.permissionService.actionAllowed(
      AtsActions.EnvironmentAdmin_Create
    );

    this.canCreateViewerSupport = this.permissionService.actionAllowed(
      AtsActions.Viewer_Support_Create
    );

    this.canAddEnvironmentUser = this.canCreateEnvironmentAdmin || this.canCreateViewerSupport;

    this.editEnabled =
      this.canAddEnvironmentUser ||
      this.canDeleteEnvironmentUser ||
      this.canEditRevokeEnvironmentUser;
  }

  buildToolBarItems(): void {
    this.menuItems = this.getMenuItems();
    this.envUserMenuItems = this.getEnvUsersMenuItems();
    this.unassignedUserMenuItems = this.getEnvUsersMenuItems();
    this.TOOLBAR_ITEMS = [];

    if (this.activeIndex === 0) {
      this.TOOLBAR_ITEMS.push({
        key: ToolBarControlKeys.AddUser,
        type: ToolBarControls.Button,
        hasSeparatorBorder: true,
        command: this.onAdd.bind(this),
        visible: this.canAddEnvironmentUser,
      });
    }

    this.TOOLBAR_ITEMS.push({
      key: ToolBarControlKeys.Search,
      type: ToolBarControls.Search,
    });

    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }

  private dispatchActionsAndQuerySelectors(): void {
    this.searchTerm$ = this.toolbarService.searchTerm$.pipe(
      debounceTime(DEBOUNCE_TIME),
      distinctUntilChanged()
    );

    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
  }

  private initializeSubscriptions(): void {
    this.loadAllUsers();
    this.loadUnassignedUsers();

    this.userService
      .getUsers()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(users => {
        this.users = users;
      });

    this.rolesService
      .getRolesByScopeWithoutPermissions(RoleScope.Environment)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(roles => {
        const viewerRoles: SpecialEnvRoles[] = [
          'Environment Viewer',
          'ATS Support',
          'Fire Fighter',
        ];
        const viewerRolesSet = new Set<string>(viewerRoles);
        const adminRole: SpecialEnvRoles = 'Environment Admin';

        this.environmentRoles = roles
          .filter(r => this.canCreateViewerSupport && viewerRolesSet.has(r.name))
          .concat(roles.filter(r => this.canCreateEnvironmentAdmin && r.name === adminRole));
      });
  }

  loadEnvironmentUsers(): void {
    this.$environmentUsersWithRoles = this.userService.getEnvironmentUsers().pipe(
      map(a =>
        a.map(o => ({
          ...o,
          role: o.roles[0].name,
          userRoleId: o.roles[0].id,
          roleId: o.roles[0].roleId,
        }))
      )
    );
  }

  private createMenuItem(key: string, command: Function, visible = false): BasicMenuItem {
    return {
      label: this.atsTranslateService.get(key),
      key,
      command,
      visible: visible,
      disabled: false,
      automationId: `menu-${key}`,
    };
  }

  getMenuItems(): ExtendedDsMenuItem[] {
    return [
      this.createMenuItem(this.viewEditMenuKey, this.onEdit.bind(this), true),
      this.createMenuItem(
        'shared.treeTable.actionMenu.revokeAccess',
        this.onDelete.bind(this),
        this.canEditRevokeEnvironmentUser
      ),
    ];
  }

  getEnvUsersMenuItems(): ExtendedDsMenuItem[] {
    return [
      this.createMenuItem(this.viewEditMenuKey, this.onEdit.bind(this), true),
      this.createMenuItem(
        'shared.treeTable.actionMenu.delete',
        this.onDeleteEnvUser.bind(this),
        this.canDeleteEnvironmentUser
      ),
    ];
  }

  onSelectedItem(userData: UserWithRole): void {
    this.selectedUser = userData;
  }

  onAdd(): void {
    const data = {
      users: this.users,
      roles: this.environmentRoles,
      areaText: this.atsTranslateService.get(this.environmentKey),
      translateKey: 'shared.assignUserRoleModal',
    };

    this.assignUserRoleModalInput = data;
    this.isAssignUserModalOpen = true;
    this.cdRef.markForCheck();
  }

  onEdit(): void {
    const data = {
      currentUser: this.users.find(o => o.id === this.selectedUser?.id),
      currentRole: this.environmentRoles.find(r => r.id === this.selectedUser?.roleId),
      users: this.users,
      roles: this.environmentRoles,
      areaText: this.atsTranslateService.get(this.environmentKey),
      translateKey: 'shared.editUserRoleModal',
    };
    this.manageSearchModalState();
    this.isModalOpen = true;
    this.userDetailsData = data;
    this.cdRef.markForCheck();
  }

  manageSearchModalState(): void {
    this.toolbarService.searchTerm$
      .pipe(take(1), takeUntil(this.ngUnsubscribe))
      .subscribe(search => {
        this.searchForClosedModal = search;
      });
    this.toolbarService.search(this.searchForOpenModal);
  }

  onDelete(): void {
    const userData = {
      name: this.selectedUser?.name,
      areaName: environment.config.environment.toLowerCase(),
    };

    this.modalService
      .createModal('settings.users.modalRevokeEnvUserRole', userData, 'critical')
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        try {
          void this.userService.removeUserRole({
            id: this.selectedUser?.id,
            roleId: this.selectedUser?.userRoleId,
          });
          this.toastService.createSuccessToast(
            'settings.users.RemoveUserRoleSuccessMessage',
            userData
          );
          this.loadEnvironmentUsers();
        } catch (error) {
          this.toastService.createErrorToast('settings.users.RemoveUserRoleFailureMessage');
        }
      });
  }

  showUserDetailsDialog(data: AssignUserRoleDialogInput): void {
    this.isModalOpen = true;
    this.userDetailsData = data;
    this.cdRef.markForCheck();
  }

  onCloseModal(): void {
    this.restorePreviousSearchTerm();
    this.isModalOpen = false;
    this.reloadOpenTab();
  }

  restorePreviousSearchTerm(): void {
    this.toolbarService.search(this.searchForClosedModal);
  }

  async saveAssignUserModal(result: AssignUserRoleCommand): Promise<void> {
    if (result) {
      try {
        await this.userService.assignUserRole(result);
        this.toastService.createSuccessToast('settings.users.AssignUserRoleSuccessMessage');
        this.reloadOpenTab();
      } catch (error) {
        this.toastService.createErrorToast('settings.users.AssignUserRoleFailureMessage');
      } finally {
        this.isAssignUserModalOpen = false;
        this.cdRef.markForCheck();
      }
    }
  }

  cancelAssignUserModal(): void {
    this.isAssignUserModalOpen = false;
  }

  reloadOpenTab(): void {
    if (this.vm.showAllUsers) this.loadAllUsers();
    else if (this.vm.showUnassignedUsers) this.loadUnassignedUsers();
    else this.loadEnvironmentUsers();
  }

  onDeleteEnvUser(): void {
    const userData = {
      name: this.selectedUser?.name,
      area: this.atsTranslateService.get(this.environmentKey),
    };
    this.modalService
      .createModal('settings.users.modalDeleteEnvUser', userData, 'critical')
      .pipe(take(1))
      .pipe(filter(Boolean), take(1))
      // eslint-disable-next-line rxjs/no-async-subscribe
      .subscribe(async () => {
        try {
          await this.userService.deleteUser(this.selectedUser?.id ?? 0);
          this.toastService.createSuccessToast('settings.users.DeleteUserSuccessMessage', userData);
          this.reloadOpenTab();
        } catch (error) {
          this.toastService.createErrorToast('settings.users.DeleteUserFailureMessage');
        }
      });
  }

  onSelectionChange(): void {
    this.buildToolBarItems();
  }

  onRegisteredUsersButtonClick(): void {
    this.vm = {
      showAllUsers: false,
      showRegisteredUsers: true,
      showUnassignedUsers: false,
    };
    this.loadEnvironmentUsers();
  }

  onAllUsersButtonClick(): void {
    this.vm = {
      showAllUsers: true,
      showRegisteredUsers: false,
      showUnassignedUsers: false,
    };

    this.loadAllUsers();
  }

  onUnassignedUsersButtonClick(): void {
    this.vm = {
      showAllUsers: false,
      showRegisteredUsers: false,
      showUnassignedUsers: true,
    };

    this.loadUnassignedUsers();
  }

  loadAllUsers(): void {
    this.userService
      .getUsers()
      .pipe(
        map(a =>
          a.map(o => ({
            id: +(o.id || 0),
            name: o.name,
            department: o.department,
            email: o.email,
            role: '',
            userRoleId: 0,
            roleId: 0,
          }))
        ),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        this.environmentUsers = res;
        this.cdRef.markForCheck();
      });

    this.cdRef.markForCheck();
  }

  loadUnassignedUsers(): void {
    this.userService
      .getUsersWithNoRolesAssigned()
      .pipe(
        map(a =>
          a.map(o => ({
            id: +(o.id || 0),
            name: o.name,
            department: o.department,
            email: o.email,
            userRoleId: 0,
          }))
        ),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        this.unassignedUsers = res;
        this.countUnassignedUsers$ = of(this.unassignedUsers.length);
        this.cdRef.markForCheck();
      });

    this.cdRef.markForCheck();
  }
}
