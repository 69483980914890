export enum PoiType {
  DollyPlace = 2,
  TurnTableLarge = 3,
  ChargingStation = 4,
  ParkingSpace = 5,
  WayPoint = 6,
  AutomaticConveyorLoading = 7,
  TurnTableSmall = 8,
  Handover = 9,
  AssignableWaypoint = 10,
  TripleTurnTable = 11,
  ParkingSpaceLarge = 12,
  PalletStationCrosswise = 13,
  PalletStationLengthwise = 14,
  RackChangerDropoff = 15,
  RackChangerPickup = 16,
}

export enum OccupancyStatus {
  Free = 1,
  Booked = 2,
  Occupied = 3,
}

export enum PoiDeviceOccupancy {
  Unknown = 0,
  Empty = 1,
  Loaded = 2,
}

export enum PoiGroupStrategy {
  Single = 0,
  Alternating = 1,
  PoiMonitoring = 2,
}

export enum PoiGroupNewButton {
  NewPoiGroup = 2,
  NewNodeGroup = 3,
}

export enum PoiVectorOrientation {
  Forwards = 0,
  Backwards = 1,
}

export enum PoiIsCloseToWall {
  No = 0,
  Yes = 1,
}

export const poiTypesWithOccupancy = [
  PoiType.DollyPlace,
  PoiType.Handover,
  PoiType.TurnTableSmall,
  PoiType.TurnTableLarge,
  PoiType.TripleTurnTable,
  PoiType.PalletStationCrosswise,
  PoiType.PalletStationLengthwise,
  PoiType.RackChangerDropoff,
  PoiType.RackChangerPickup,
];
