/* eslint-disable max-lines */
import { DateString, GuidString } from 'core/models';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: DateString;
  UUID: GuidString;
  /** The UnsignedInt scalar type represents a unsigned 32-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedInt: number;
};

export type BooleanOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Boolean']>;
  readonly neq?: InputMaybe<Scalars['Boolean']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  readonly __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean'];
};

export type DateTimeOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['DateTime']>;
  readonly gt?: InputMaybe<Scalars['DateTime']>;
  readonly gte?: InputMaybe<Scalars['DateTime']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']>>>;
  readonly lt?: InputMaybe<Scalars['DateTime']>;
  readonly lte?: InputMaybe<Scalars['DateTime']>;
  readonly neq?: InputMaybe<Scalars['DateTime']>;
  readonly ngt?: InputMaybe<Scalars['DateTime']>;
  readonly ngte?: InputMaybe<Scalars['DateTime']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']>>>;
  readonly nlt?: InputMaybe<Scalars['DateTime']>;
  readonly nlte?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceNodeValueFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<DeviceNodeValueFilterInput>>;
  readonly device?: InputMaybe<StringOperationFilterInput>;
  readonly node?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<DeviceNodeValueFilterInput>>;
  readonly value?: InputMaybe<StringOperationFilterInput>;
};

export type EventData = {
  readonly __typename?: 'EventData';
  readonly connectionId?: Maybe<Scalars['UUID']>;
  readonly deviceId?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly processChainId: Scalars['UUID'];
  readonly type: EventType;
};

export type EventDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<EventDataFilterInput>>;
  readonly connectionId?: InputMaybe<UuidOperationFilterInput>;
  readonly deviceId?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<EventDataFilterInput>>;
  readonly processChain?: InputMaybe<ProcessChainDataFilterInput>;
  readonly processChainId?: InputMaybe<UuidOperationFilterInput>;
  readonly type?: InputMaybe<EventTypeOperationFilterInput>;
};

export const enum EventType {
  MissionAbort = 'MISSION_ABORT',
  Process = 'PROCESS',
}

export type EventTypeOperationFilterInput = {
  readonly eq?: InputMaybe<EventType>;
  readonly in?: InputMaybe<ReadonlyArray<EventType>>;
  readonly neq?: InputMaybe<EventType>;
  readonly nin?: InputMaybe<ReadonlyArray<EventType>>;
};

export type FloatOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Float']>;
  readonly gt?: InputMaybe<Scalars['Float']>;
  readonly gte?: InputMaybe<Scalars['Float']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Float']>>>;
  readonly lt?: InputMaybe<Scalars['Float']>;
  readonly lte?: InputMaybe<Scalars['Float']>;
  readonly neq?: InputMaybe<Scalars['Float']>;
  readonly ngt?: InputMaybe<Scalars['Float']>;
  readonly ngte?: InputMaybe<Scalars['Float']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Float']>>>;
  readonly nlt?: InputMaybe<Scalars['Float']>;
  readonly nlte?: InputMaybe<Scalars['Float']>;
};

export type IntOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Int']>;
  readonly gt?: InputMaybe<Scalars['Int']>;
  readonly gte?: InputMaybe<Scalars['Int']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly lt?: InputMaybe<Scalars['Int']>;
  readonly lte?: InputMaybe<Scalars['Int']>;
  readonly neq?: InputMaybe<Scalars['Int']>;
  readonly ngt?: InputMaybe<Scalars['Int']>;
  readonly ngte?: InputMaybe<Scalars['Int']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly nlt?: InputMaybe<Scalars['Int']>;
  readonly nlte?: InputMaybe<Scalars['Int']>;
};

export type ListFilterInputTypeOfEventDataFilterInput = {
  readonly all?: InputMaybe<EventDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<EventDataFilterInput>;
  readonly some?: InputMaybe<EventDataFilterInput>;
};

export type ListFilterInputTypeOfMissionDataFilterInput = {
  readonly all?: InputMaybe<MissionDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<MissionDataFilterInput>;
  readonly some?: InputMaybe<MissionDataFilterInput>;
};

export type ListFilterInputTypeOfMissionPauseConditionDataFilterInput = {
  readonly all?: InputMaybe<MissionPauseConditionDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<MissionPauseConditionDataFilterInput>;
  readonly some?: InputMaybe<MissionPauseConditionDataFilterInput>;
};

export type ListFilterInputTypeOfNotificationMappingDataFilterInput = {
  readonly all?: InputMaybe<NotificationMappingDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<NotificationMappingDataFilterInput>;
  readonly some?: InputMaybe<NotificationMappingDataFilterInput>;
};

export type ListFilterInputTypeOfObjectFilterInput = {
  readonly all?: InputMaybe<ObjectFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<ObjectFilterInput>;
  readonly some?: InputMaybe<ObjectFilterInput>;
};

export type ListFilterInputTypeOfPoiDataFilterInput = {
  readonly all?: InputMaybe<PoiDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<PoiDataFilterInput>;
  readonly some?: InputMaybe<PoiDataFilterInput>;
};

export type ListFilterInputTypeOfProcessChainDataFilterInput = {
  readonly all?: InputMaybe<ProcessChainDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<ProcessChainDataFilterInput>;
  readonly some?: InputMaybe<ProcessChainDataFilterInput>;
};

export type ListFilterInputTypeOfStepDataFilterInput = {
  readonly all?: InputMaybe<StepDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<StepDataFilterInput>;
  readonly some?: InputMaybe<StepDataFilterInput>;
};

export const enum LoadType {
  Glt1400X1250 = 'GLT1400_X1250',
  GltPd800X1200 = 'GLT_PD800_X1200',
  GltPd1200X1000 = 'GLT_PD1200_X1000',
  MSpa1260X1160 = 'M_SPA1260_X1160',
  SeatDeb1000X1600 = 'SEAT_DEB1000_X1600',
  Uglt1680X1250 = 'UGLT1680_X1250',
  Unknown = 'UNKNOWN',
}

export type LoadTypeOperationFilterInput = {
  readonly eq?: InputMaybe<LoadType>;
  readonly in?: InputMaybe<ReadonlyArray<LoadType>>;
  readonly neq?: InputMaybe<LoadType>;
  readonly nin?: InputMaybe<ReadonlyArray<LoadType>>;
};

export type MissionAbortActionData = {
  readonly __typename?: 'MissionAbortActionData';
  readonly device?: Maybe<Scalars['String']>;
  readonly nodeName?: Maybe<Scalars['String']>;
  readonly stepNumber: Scalars['Int'];
  readonly value?: Maybe<Scalars['String']>;
};

export type MissionAbortActionDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<MissionAbortActionDataFilterInput>>;
  readonly device?: InputMaybe<StringOperationFilterInput>;
  readonly nodeName?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<MissionAbortActionDataFilterInput>>;
  readonly stepNumber?: InputMaybe<IntOperationFilterInput>;
  readonly value?: InputMaybe<StringOperationFilterInput>;
};

export type MissionData = {
  readonly __typename?: 'MissionData';
  readonly abortAction?: Maybe<MissionAbortActionData>;
  readonly assignableWaypoint?: Maybe<PoiData>;
  readonly assignableWaypointId?: Maybe<Scalars['UUID']>;
  readonly assignmentDelayTime?: Maybe<Scalars['Int']>;
  readonly id: Scalars['UUID'];
  readonly isAlarmForLateDeliveryActive: Scalars['Boolean'];
  readonly isAssignmentDelayTimeActive: Scalars['Boolean'];
  readonly isMaxExecutionTimeActive: Scalars['Boolean'];
  readonly lateDeliveryAlarmTime?: Maybe<Scalars['Int']>;
  readonly mapId: Scalars['UUID'];
  readonly maxExecutionTime?: Maybe<Scalars['Int']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly pauseConditions?: Maybe<ReadonlyArray<Maybe<MissionPauseConditionData>>>;
  readonly processChainId?: Maybe<Scalars['UUID']>;
  readonly steps?: Maybe<ReadonlyArray<Maybe<StepData>>>;
};

export type MissionDataFilterInput = {
  readonly abortAction?: InputMaybe<MissionAbortActionDataFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<MissionDataFilterInput>>;
  readonly assignableWaypoint?: InputMaybe<PoiDataFilterInput>;
  readonly assignableWaypointId?: InputMaybe<UuidOperationFilterInput>;
  readonly assignmentDelayTime?: InputMaybe<IntOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly isAlarmForLateDeliveryActive?: InputMaybe<BooleanOperationFilterInput>;
  readonly isAssignmentDelayTimeActive?: InputMaybe<BooleanOperationFilterInput>;
  readonly isMaxExecutionTimeActive?: InputMaybe<BooleanOperationFilterInput>;
  readonly lateDeliveryAlarmTime?: InputMaybe<IntOperationFilterInput>;
  readonly mapId?: InputMaybe<UuidOperationFilterInput>;
  readonly maxExecutionTime?: InputMaybe<IntOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<MissionDataFilterInput>>;
  readonly pauseConditions?: InputMaybe<ListFilterInputTypeOfMissionPauseConditionDataFilterInput>;
  readonly processChain?: InputMaybe<ProcessChainDataFilterInput>;
  readonly processChainId?: InputMaybe<UuidOperationFilterInput>;
  readonly steps?: InputMaybe<ListFilterInputTypeOfStepDataFilterInput>;
};

export type MissionPauseConditionData = {
  readonly __typename?: 'MissionPauseConditionData';
  readonly device?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
  readonly missionId: Scalars['UUID'];
  readonly nodeName?: Maybe<Scalars['String']>;
  readonly stepNumber: Scalars['Int'];
  readonly value?: Maybe<Scalars['String']>;
};

export type MissionPauseConditionDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<MissionPauseConditionDataFilterInput>>;
  readonly device?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly mission?: InputMaybe<MissionDataFilterInput>;
  readonly missionId?: InputMaybe<UuidOperationFilterInput>;
  readonly nodeName?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<MissionPauseConditionDataFilterInput>>;
  readonly stepNumber?: InputMaybe<IntOperationFilterInput>;
  readonly value?: InputMaybe<StringOperationFilterInput>;
};

export type NotificationMappingDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<NotificationMappingDataFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly mapId?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<NotificationMappingDataFilterInput>>;
  readonly pois?: InputMaybe<ListFilterInputTypeOfPoiDataFilterInput>;
  readonly recipientKey?: InputMaybe<StringOperationFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type ObjectFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ObjectFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<ObjectFilterInput>>;
};

export type OccupancyData = {
  readonly __typename?: 'OccupancyData';
  readonly occupancyStatus: PointOfInterestOccupancyStatus;
  readonly statusUpdatedOnUtc?: Maybe<Scalars['DateTime']>;
  readonly vehicleId?: Maybe<Scalars['UUID']>;
};

export type OccupancyDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<OccupancyDataFilterInput>>;
  readonly occupancyStatus?: InputMaybe<PointOfInterestOccupancyStatusOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<OccupancyDataFilterInput>>;
  readonly statusUpdatedOnUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly vehicleId?: InputMaybe<UuidOperationFilterInput>;
};

export type PoiData = {
  readonly __typename?: 'PoiData';
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly occupancy?: Maybe<OccupancyData>;
};

export type PoiDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<PoiDataFilterInput>>;
  readonly booked?: InputMaybe<BooleanOperationFilterInput>;
  readonly bookedDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  readonly bookedVehicleId?: InputMaybe<UuidOperationFilterInput>;
  readonly bufferPlacePoiGroupId?: InputMaybe<UuidOperationFilterInput>;
  readonly deviceOccupancy?: InputMaybe<PointOfInterestDeviceLoadedStateOperationFilterInput>;
  readonly entryVectorDistance?: InputMaybe<FloatOperationFilterInput>;
  readonly events?: InputMaybe<ListFilterInputTypeOfObjectFilterInput>;
  readonly exitVectorDistance?: InputMaybe<FloatOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly isCloseToWall?: InputMaybe<BooleanOperationFilterInput>;
  readonly loadType?: InputMaybe<LoadTypeOperationFilterInput>;
  readonly mainPose?: InputMaybe<Pose2DDataFilterInput>;
  readonly mapId?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notificationMappings?: InputMaybe<ListFilterInputTypeOfNotificationMappingDataFilterInput>;
  readonly occupancy?: InputMaybe<OccupancyDataFilterInput>;
  readonly occupancyDevice?: InputMaybe<DeviceNodeValueFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PoiDataFilterInput>>;
  readonly poiGroup?: InputMaybe<PoiGroupDataFilterInput>;
  readonly poiGroupId?: InputMaybe<UuidOperationFilterInput>;
  readonly poiGroupOrder?: InputMaybe<IntOperationFilterInput>;
  readonly poseAccuracy?: InputMaybe<UnsignedIntOperationFilterInputType>;
  readonly type?: InputMaybe<PointOfInterestTypeOperationFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type PoiGroupDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<PoiGroupDataFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly mapId?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PoiGroupDataFilterInput>>;
  readonly pointsOfInterest?: InputMaybe<ListFilterInputTypeOfPoiDataFilterInput>;
  readonly strategy?: InputMaybe<PointOfInterestGroupStrategyOperationFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export const enum PointOfInterestDeviceLoadedState {
  Empty = 'EMPTY',
  Loaded = 'LOADED',
  Unknown = 'UNKNOWN',
}

export type PointOfInterestDeviceLoadedStateOperationFilterInput = {
  readonly eq?: InputMaybe<PointOfInterestDeviceLoadedState>;
  readonly in?: InputMaybe<ReadonlyArray<PointOfInterestDeviceLoadedState>>;
  readonly neq?: InputMaybe<PointOfInterestDeviceLoadedState>;
  readonly nin?: InputMaybe<ReadonlyArray<PointOfInterestDeviceLoadedState>>;
};

export const enum PointOfInterestGroupStrategy {
  Alternating = 'ALTERNATING',
  PoiMonitoring = 'POI_MONITORING',
  Single = 'SINGLE',
}

export type PointOfInterestGroupStrategyOperationFilterInput = {
  readonly eq?: InputMaybe<PointOfInterestGroupStrategy>;
  readonly in?: InputMaybe<ReadonlyArray<PointOfInterestGroupStrategy>>;
  readonly neq?: InputMaybe<PointOfInterestGroupStrategy>;
  readonly nin?: InputMaybe<ReadonlyArray<PointOfInterestGroupStrategy>>;
};

export const enum PointOfInterestOccupancyStatus {
  Booked = 'BOOKED',
  Free = 'FREE',
  Occupied = 'OCCUPIED',
  Unbooked = 'UNBOOKED',
}

export type PointOfInterestOccupancyStatusOperationFilterInput = {
  readonly eq?: InputMaybe<PointOfInterestOccupancyStatus>;
  readonly in?: InputMaybe<ReadonlyArray<PointOfInterestOccupancyStatus>>;
  readonly neq?: InputMaybe<PointOfInterestOccupancyStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<PointOfInterestOccupancyStatus>>;
};

export const enum PointOfInterestType {
  AssignableWaypoint = 'ASSIGNABLE_WAYPOINT',
  AutomaticConveyorLoading = 'AUTOMATIC_CONVEYOR_LOADING',
  ChargingStation = 'CHARGING_STATION',
  DollyPlace = 'DOLLY_PLACE',
  Handover = 'HANDOVER',
  PalletStationCrosswise = 'PALLET_STATION_CROSSWISE',
  PalletStationLengthwise = 'PALLET_STATION_LENGTHWISE',
  ParkingSpace = 'PARKING_SPACE',
  ParkingSpaceLarge = 'PARKING_SPACE_LARGE',
  RackChangerDropoff = 'RACK_CHANGER_DROPOFF',
  RackChangerPickup = 'RACK_CHANGER_PICKUP',
  /** @deprecated Not used anymore. */
  Simple = 'SIMPLE',
  TripleTurnTable = 'TRIPLE_TURN_TABLE',
  TurnTableLarge = 'TURN_TABLE_LARGE',
  TurnTableSmall = 'TURN_TABLE_SMALL',
  WayPoint = 'WAY_POINT',
}

export type PointOfInterestTypeOperationFilterInput = {
  readonly eq?: InputMaybe<PointOfInterestType>;
  readonly in?: InputMaybe<ReadonlyArray<PointOfInterestType>>;
  readonly neq?: InputMaybe<PointOfInterestType>;
  readonly nin?: InputMaybe<ReadonlyArray<PointOfInterestType>>;
};

export type Pose2DDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<Pose2DDataFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<Pose2DDataFilterInput>>;
  readonly orientation?: InputMaybe<FloatOperationFilterInput>;
  readonly x?: InputMaybe<FloatOperationFilterInput>;
  readonly y?: InputMaybe<FloatOperationFilterInput>;
};

export type ProcessChainData = {
  readonly __typename?: 'ProcessChainData';
  readonly abortConnectionId?: Maybe<Scalars['UUID']>;
  readonly destination?: Maybe<Scalars['String']>;
  readonly deviceTriggerBlockingTimeSeconds: Scalars['Int'];
  readonly deviceTriggerDevice?: Maybe<Scalars['String']>;
  readonly deviceTriggerNode?: Maybe<Scalars['String']>;
  readonly deviceTriggerValue?: Maybe<Scalars['String']>;
  readonly enableRepetitiveProcess: Scalars['Boolean'];
  readonly events: ReadonlyArray<EventData>;
  readonly fleetId: Scalars['UUID'];
  readonly id: Scalars['UUID'];
  readonly isActive: Scalars['Boolean'];
  readonly loadType: LoadType;
  readonly materialNumber?: Maybe<Scalars['String']>;
  readonly maxQueueLength?: Maybe<Scalars['Int']>;
  readonly missionPrioritizationTime: Scalars['Int'];
  readonly missions: ReadonlyArray<MissionData>;
  readonly name: Scalars['String'];
  readonly processChainGroup?: Maybe<ProcessChainGroupData>;
  readonly processChainGroupId?: Maybe<Scalars['UUID']>;
  readonly referenceId?: Maybe<Scalars['String']>;
  readonly replenishmentTime?: Maybe<Scalars['Int']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly triggerType: ProcessChainTriggerType;
  readonly type: ProcessChainType;
  readonly workAreaId: Scalars['UUID'];
};

export type ProcessChainDataFilterInput = {
  readonly abortConnectionId?: InputMaybe<UuidOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<ProcessChainDataFilterInput>>;
  readonly destination?: InputMaybe<StringOperationFilterInput>;
  readonly deviceTriggerBlockingTimeSeconds?: InputMaybe<IntOperationFilterInput>;
  readonly deviceTriggerDevice?: InputMaybe<StringOperationFilterInput>;
  readonly deviceTriggerNode?: InputMaybe<StringOperationFilterInput>;
  readonly deviceTriggerValue?: InputMaybe<StringOperationFilterInput>;
  readonly enableRepetitiveProcess?: InputMaybe<BooleanOperationFilterInput>;
  readonly events?: InputMaybe<ListFilterInputTypeOfEventDataFilterInput>;
  readonly fleetId?: InputMaybe<UuidOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly isActive?: InputMaybe<BooleanOperationFilterInput>;
  readonly loadType?: InputMaybe<LoadTypeOperationFilterInput>;
  readonly materialNumber?: InputMaybe<StringOperationFilterInput>;
  readonly maxQueueLength?: InputMaybe<IntOperationFilterInput>;
  readonly missionPrioritizationTime?: InputMaybe<IntOperationFilterInput>;
  readonly missions?: InputMaybe<ListFilterInputTypeOfMissionDataFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ProcessChainDataFilterInput>>;
  readonly processChainGroup?: InputMaybe<ProcessChainGroupDataFilterInput>;
  readonly processChainGroupId?: InputMaybe<UuidOperationFilterInput>;
  readonly referenceId?: InputMaybe<StringOperationFilterInput>;
  readonly replenishmentTime?: InputMaybe<IntOperationFilterInput>;
  readonly source?: InputMaybe<StringOperationFilterInput>;
  readonly triggerType?: InputMaybe<ProcessChainTriggerTypeOperationFilterInput>;
  readonly type?: InputMaybe<ProcessChainTypeOperationFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type ProcessChainDataSortInput = {
  readonly abortConnectionId?: InputMaybe<SortEnumType>;
  readonly destination?: InputMaybe<SortEnumType>;
  readonly deviceTriggerBlockingTimeSeconds?: InputMaybe<SortEnumType>;
  readonly deviceTriggerDevice?: InputMaybe<SortEnumType>;
  readonly deviceTriggerNode?: InputMaybe<SortEnumType>;
  readonly deviceTriggerValue?: InputMaybe<SortEnumType>;
  readonly enableRepetitiveProcess?: InputMaybe<SortEnumType>;
  readonly fleetId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isActive?: InputMaybe<SortEnumType>;
  readonly loadType?: InputMaybe<SortEnumType>;
  readonly materialNumber?: InputMaybe<SortEnumType>;
  readonly maxQueueLength?: InputMaybe<SortEnumType>;
  readonly missionPrioritizationTime?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly processChainGroup?: InputMaybe<ProcessChainGroupDataSortInput>;
  readonly processChainGroupId?: InputMaybe<SortEnumType>;
  readonly referenceId?: InputMaybe<SortEnumType>;
  readonly replenishmentTime?: InputMaybe<SortEnumType>;
  readonly source?: InputMaybe<SortEnumType>;
  readonly triggerType?: InputMaybe<SortEnumType>;
  readonly type?: InputMaybe<SortEnumType>;
  readonly workAreaId?: InputMaybe<SortEnumType>;
};

export type ProcessChainFilterValues = {
  readonly __typename?: 'ProcessChainFilterValues';
  readonly destinations: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly fleetIds: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly matNumbers: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly processChainNames: ReadonlyArray<Scalars['String']>;
  readonly referenceIds: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly sources: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type ProcessChainGroupData = {
  readonly __typename?: 'ProcessChainGroupData';
  readonly executionMode: ProcessChainGroupExecutionMode;
  readonly id: Scalars['UUID'];
  readonly mapId: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly processChain?: Maybe<ReadonlyArray<Maybe<ProcessChainData>>>;
  readonly workAreaId: Scalars['UUID'];
};

export type ProcessChainGroupDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ProcessChainGroupDataFilterInput>>;
  readonly executionMode?: InputMaybe<ProcessChainGroupExecutionModeOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly mapId?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ProcessChainGroupDataFilterInput>>;
  readonly processChain?: InputMaybe<ListFilterInputTypeOfProcessChainDataFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type ProcessChainGroupDataSortInput = {
  readonly executionMode?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly mapId?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly workAreaId?: InputMaybe<SortEnumType>;
};

export const enum ProcessChainGroupExecutionMode {
  Parallel = 'PARALLEL',
  Sequential = 'SEQUENTIAL',
}

export type ProcessChainGroupExecutionModeOperationFilterInput = {
  readonly eq?: InputMaybe<ProcessChainGroupExecutionMode>;
  readonly in?: InputMaybe<ReadonlyArray<ProcessChainGroupExecutionMode>>;
  readonly neq?: InputMaybe<ProcessChainGroupExecutionMode>;
  readonly nin?: InputMaybe<ReadonlyArray<ProcessChainGroupExecutionMode>>;
};

export const enum ProcessChainTriggerType {
  Device = 'DEVICE',
  System = 'SYSTEM',
  TransportOrder = 'TRANSPORT_ORDER',
}

export type ProcessChainTriggerTypeOperationFilterInput = {
  readonly eq?: InputMaybe<ProcessChainTriggerType>;
  readonly in?: InputMaybe<ReadonlyArray<ProcessChainTriggerType>>;
  readonly neq?: InputMaybe<ProcessChainTriggerType>;
  readonly nin?: InputMaybe<ReadonlyArray<ProcessChainTriggerType>>;
};

export const enum ProcessChainType {
  Basic = 'BASIC',
  WaitingQueue = 'WAITING_QUEUE',
}

export type ProcessChainTypeOperationFilterInput = {
  readonly eq?: InputMaybe<ProcessChainType>;
  readonly in?: InputMaybe<ReadonlyArray<ProcessChainType>>;
  readonly neq?: InputMaybe<ProcessChainType>;
  readonly nin?: InputMaybe<ReadonlyArray<ProcessChainType>>;
};

/** A segment of a collection. */
export type ProcessChainsCollectionSegment = {
  readonly __typename?: 'ProcessChainsCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<ProcessChainData>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly processChainFilters: ProcessChainFilterValues;
  readonly processChains?: Maybe<ProcessChainsCollectionSegment>;
};

export type QueryProcessChainsArgs = {
  order?: InputMaybe<ReadonlyArray<ProcessChainDataSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProcessChainDataFilterInput>;
};

export const enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StepData = {
  readonly __typename?: 'StepData';
  readonly id: Scalars['UUID'];
  readonly missionId: Scalars['UUID'];
  readonly sequenceNumber?: Maybe<Scalars['UnsignedInt']>;
};

export type StepDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<StepDataFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly mission?: InputMaybe<MissionDataFilterInput>;
  readonly missionId?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<StepDataFilterInput>>;
  readonly sequenceNumber?: InputMaybe<UnsignedIntOperationFilterInputType>;
};

export type StringOperationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly contains?: InputMaybe<Scalars['String']>;
  readonly endsWith?: InputMaybe<Scalars['String']>;
  readonly eq?: InputMaybe<Scalars['String']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly ncontains?: InputMaybe<Scalars['String']>;
  readonly nendsWith?: InputMaybe<Scalars['String']>;
  readonly neq?: InputMaybe<Scalars['String']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly nstartsWith?: InputMaybe<Scalars['String']>;
  readonly or?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly startsWith?: InputMaybe<Scalars['String']>;
};

export type UnsignedIntOperationFilterInputType = {
  readonly eq?: InputMaybe<Scalars['UnsignedInt']>;
  readonly gt?: InputMaybe<Scalars['UnsignedInt']>;
  readonly gte?: InputMaybe<Scalars['UnsignedInt']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UnsignedInt']>>>;
  readonly lt?: InputMaybe<Scalars['UnsignedInt']>;
  readonly lte?: InputMaybe<Scalars['UnsignedInt']>;
  readonly neq?: InputMaybe<Scalars['UnsignedInt']>;
  readonly ngt?: InputMaybe<Scalars['UnsignedInt']>;
  readonly ngte?: InputMaybe<Scalars['UnsignedInt']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UnsignedInt']>>>;
  readonly nlt?: InputMaybe<Scalars['UnsignedInt']>;
  readonly nlte?: InputMaybe<Scalars['UnsignedInt']>;
};

export type UuidOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['UUID']>;
  readonly gt?: InputMaybe<Scalars['UUID']>;
  readonly gte?: InputMaybe<Scalars['UUID']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly lt?: InputMaybe<Scalars['UUID']>;
  readonly lte?: InputMaybe<Scalars['UUID']>;
  readonly neq?: InputMaybe<Scalars['UUID']>;
  readonly ngt?: InputMaybe<Scalars['UUID']>;
  readonly ngte?: InputMaybe<Scalars['UUID']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly nlt?: InputMaybe<Scalars['UUID']>;
  readonly nlte?: InputMaybe<Scalars['UUID']>;
};

export type ProcessChainPageQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProcessChainDataFilterInput>;
  order?: InputMaybe<ReadonlyArray<ProcessChainDataSortInput> | ProcessChainDataSortInput>;
}>;

export type ProcessChainPageQuery = {
  readonly __typename?: 'Query';
  readonly processChains?: {
    readonly __typename?: 'ProcessChainsCollectionSegment';
    readonly totalCount: number;
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'ProcessChainData';
      readonly id: GuidString;
      readonly name: string;
      readonly materialNumber?: string | null;
      readonly source?: string | null;
      readonly destination?: string | null;
      readonly referenceId?: string | null;
      readonly replenishmentTime?: number | null;
      readonly fleetId: GuidString;
      readonly workAreaId: GuidString;
      readonly abortConnectionId?: GuidString | null;
      readonly processChainGroupId?: GuidString | null;
      readonly triggerType: ProcessChainTriggerType;
      readonly type: ProcessChainType;
      readonly deviceTriggerDevice?: string | null;
      readonly deviceTriggerNode?: string | null;
      readonly deviceTriggerValue?: string | null;
      readonly deviceTriggerBlockingTimeSeconds: number;
      readonly maxQueueLength?: number | null;
      readonly enableRepetitiveProcess: boolean;
      readonly isActive: boolean;
      readonly missionPrioritizationTime: number;
      readonly loadType: LoadType;
      readonly missions: ReadonlyArray<{
        readonly __typename?: 'MissionData';
        readonly mapId: GuidString;
      }>;
      readonly processChainGroup?: {
        readonly __typename?: 'ProcessChainGroupData';
        readonly id: GuidString;
        readonly name?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type FilterValuesQueryVariables = Exact<{ [key: string]: never }>;

export type FilterValuesQuery = {
  readonly __typename?: 'Query';
  readonly processChainFilters: {
    readonly __typename?: 'ProcessChainFilterValues';
    readonly fleetIds: ReadonlyArray<string | null>;
    readonly processChainNames: ReadonlyArray<string>;
    readonly matNumbers: ReadonlyArray<string | null>;
    readonly destinations: ReadonlyArray<string | null>;
    readonly sources: ReadonlyArray<string | null>;
    readonly referenceIds: ReadonlyArray<string | null>;
  };
};

export type ProcessChainEntryFragment = {
  readonly __typename?: 'ProcessChainData';
  readonly id: GuidString;
  readonly name: string;
  readonly materialNumber?: string | null;
  readonly source?: string | null;
  readonly destination?: string | null;
  readonly referenceId?: string | null;
  readonly replenishmentTime?: number | null;
  readonly fleetId: GuidString;
  readonly workAreaId: GuidString;
  readonly abortConnectionId?: GuidString | null;
  readonly processChainGroupId?: GuidString | null;
  readonly triggerType: ProcessChainTriggerType;
  readonly type: ProcessChainType;
  readonly deviceTriggerDevice?: string | null;
  readonly deviceTriggerNode?: string | null;
  readonly deviceTriggerValue?: string | null;
  readonly deviceTriggerBlockingTimeSeconds: number;
  readonly maxQueueLength?: number | null;
  readonly enableRepetitiveProcess: boolean;
  readonly isActive: boolean;
  readonly missionPrioritizationTime: number;
  readonly loadType: LoadType;
  readonly missions: ReadonlyArray<{
    readonly __typename?: 'MissionData';
    readonly mapId: GuidString;
  }>;
  readonly processChainGroup?: {
    readonly __typename?: 'ProcessChainGroupData';
    readonly id: GuidString;
    readonly name?: string | null;
  } | null;
};

export const ProcessChainEntryFragmentDoc = gql`
  fragment processChainEntry on ProcessChainData {
    id
    name
    materialNumber
    source
    destination
    referenceId
    replenishmentTime
    fleetId
    workAreaId
    abortConnectionId
    processChainGroupId
    triggerType
    type
    deviceTriggerDevice
    deviceTriggerNode
    deviceTriggerValue
    deviceTriggerBlockingTimeSeconds
    maxQueueLength
    enableRepetitiveProcess
    isActive
    missionPrioritizationTime
    missions {
      mapId
    }
    processChainGroup {
      id
      name
    }
    loadType
  }
`;
export const ProcessChainPageDocument = gql`
  query processChainPage(
    $offset: Int
    $pageSize: Int
    $filter: ProcessChainDataFilterInput
    $order: [ProcessChainDataSortInput!]
  ) {
    processChains(skip: $offset, take: $pageSize, where: $filter, order: $order) {
      totalCount
      items {
        ...processChainEntry
      }
    }
  }
  ${ProcessChainEntryFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ProcessChainPageGQL extends Apollo.Query<
  ProcessChainPageQuery,
  ProcessChainPageQueryVariables
> {
  document = ProcessChainPageDocument;
  client = 'jobManagerClient';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FilterValuesDocument = gql`
  query filterValues {
    processChainFilters {
      fleetIds
      processChainNames
      matNumbers
      destinations
      sources
      referenceIds
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FilterValuesGQL extends Apollo.Query<FilterValuesQuery, FilterValuesQueryVariables> {
  document = FilterValuesDocument;
  client = 'jobManagerClient';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
