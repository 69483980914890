import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-ipst-alertnow-configuration-view',
  templateUrl: './ipst-alertnow-configuration-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowConfigurationViewComponent {
  @Input() ipstAlertNowUrl?: string;
}
