import {
  Mission,
  MissionStatus,
  StepModel,
  StepType,
  WaitForEndOfStepStepModel,
} from 'core/models';
import { isEqual } from 'lodash';

const sortSteps = (step: StepModel[]) => {
  return step.sort((a, b) => (a.sequenceNumber > b.sequenceNumber ? 1 : -1));
};

const isMissionEqual = (m1: Mission, m2: Mission): boolean => {
  const { steps: stepsM1, ...m1ExcludingSteps } = m1;
  const { steps: stepsM2, ...m2ExcludingSteps } = m2;
  const areStepsEqual = isEqual(sortSteps(stepsM1), sortSteps(stepsM2));

  return isEqual(m1ExcludingSteps, m2ExcludingSteps) && areStepsEqual;
};

export function isMissionStatusFinal(status: MissionStatus): boolean {
  return [
    MissionStatus.Aborted,
    MissionStatus.Completed,
    MissionStatus.CompletedWithSupport,
    MissionStatus.AbortedWithSupport,
  ].includes(status);
}

export function isMissionAborted(status: MissionStatus): boolean {
  return [MissionStatus.Aborted, MissionStatus.AbortedWithSupport].includes(status);
}

export function areProcessChainMissionsEqual(m1: Mission[], m2: Mission[]): boolean {
  if (m1.length !== m2.length) {
    return false;
  }

  for (let i = 0; i < m1.length; i++) {
    if (!isMissionEqual(m1[i], m2[i])) {
      return false;
    }
  }

  return true;
}

export function getWaitForEndOfStepSteps(
  missions: { steps: StepModel[] }[],
  missionIndex: number
): WaitForEndOfStepStepModel[] {
  return missions.flatMap(mission =>
    mission.steps
      .filter((s): s is WaitForEndOfStepStepModel => s.type === StepType.WaitForEndOfStep)
      .filter(s => s.waitForStep?.missionIndex === missionIndex)
  );
}
