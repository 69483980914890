import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  FeatureToggle,
  GmParkingAndChargingSettingsDto,
  getDefaultGraphManagerParkingAndChargingSettings,
} from 'core/dtos';
import { AtsActions } from 'core/models';
import { Subject, takeUntil } from 'rxjs';
import { ParkyChargyToggleForm } from './parky-chargy-toggle.model';
import { PermissionService } from 'core/services';

@Component({
  selector: 'app-parky-chargy-toggle',
  templateUrl: './parky-chargy-toggle.component.html',
  styleUrls: ['./parky-chargy-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkyChargyToggleComponent implements OnInit, OnChanges, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  canEdit = false;

  @Input() parkingAndChargingSettings: GmParkingAndChargingSettingsDto =
    getDefaultGraphManagerParkingAndChargingSettings();
  @Input() isLoaded = false;

  @Output() readonly updateParkyChargyVehicleToggle =
    new EventEmitter<GmParkingAndChargingSettingsDto>();

  parkingAndChargingToggle: FeatureToggle = {
    isToggledOn: false,
    dateUpdated: null,
  };

  parkyChargyForm = new FormGroup<ParkyChargyToggleForm>({
    enableParkyChargy: new FormControl(false, { nonNullable: true }),
    enableDsTugger: new FormControl(false, { nonNullable: true }),
    enableSchillerForklift: new FormControl(false, { nonNullable: true }),
    enableDsUagv: new FormControl(false, { nonNullable: true }),
  });

  constructor(private readonly permissionService: PermissionService) {}

  checkPermissions(): void {
    this.canEdit = this.permissionService.actionAllowed(AtsActions.ToggleParkyChargy);

    const controls = this.parkyChargyForm.controls;

    if (!this.canEdit) {
      controls.enableParkyChargy.disable({ emitEvent: false });
      controls.enableDsTugger.disable({ emitEvent: false });
      controls.enableSchillerForklift.disable({ emitEvent: false });
      controls.enableDsUagv.disable({ emitEvent: false });
    } else if (!controls.enableParkyChargy.value) {
      controls.enableParkyChargy.enable({ emitEvent: false });
      controls.enableDsTugger.disable({ emitEvent: false });
      controls.enableSchillerForklift.disable({ emitEvent: false });
      controls.enableDsUagv.disable({ emitEvent: false });
    } else {
      controls.enableParkyChargy.enable({ emitEvent: false });
      controls.enableDsTugger.enable({ emitEvent: false });
      controls.enableSchillerForklift.enable({ emitEvent: false });
      controls.enableDsUagv.enable({ emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.subscribeToFormChange('enableParkyChargy');
    this.subscribeToFormChange('enableDsTugger');
    this.subscribeToFormChange('enableSchillerForklift');
    this.subscribeToFormChange('enableDsUagv');
  }

  ngOnChanges({ parkingAndChargingSettings }: TypedChanges<ParkyChargyToggleComponent>): void {
    if (parkingAndChargingSettings?.currentValue) {
      const { isEnabled, enabledUpdatedTimeUtc } = parkingAndChargingSettings.currentValue;
      const { dsTugger, dsUagv, schillerForklift } =
        parkingAndChargingSettings.currentValue.vehicleToggles;

      this.updateParkyChargyToggleForm({
        parkyChargyValue: isEnabled,
        dsTuggerValue: dsTugger,
        schillerForkliftValue: schillerForklift,
        dsUagvValue: dsUagv,
      });
      this.parkingAndChargingToggle = {
        isToggledOn: isEnabled,
        dateUpdated: enabledUpdatedTimeUtc,
      };
    }

    this.checkPermissions();
  }

  subscribeToFormChange(formControlName: keyof ParkyChargyToggleForm): void {
    this.parkyChargyForm.controls[formControlName].valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateParkyChargyToggleRequest();
      });
  }

  updateEnableParkyChargy(): void {
    const controls = this.parkyChargyForm.controls;

    if (controls.enableParkyChargy.value) {
      controls.enableDsTugger.enable({ emitEvent: false });
      controls.enableSchillerForklift.enable({ emitEvent: false });
      controls.enableDsUagv.enable({ emitEvent: false });
    } else {
      controls.enableDsTugger.setValue(false, { emitEvent: false });
      controls.enableSchillerForklift.setValue(false, { emitEvent: false });
      controls.enableDsUagv.setValue(false, { emitEvent: false });
      controls.enableDsTugger.disable({ emitEvent: false });
      controls.enableSchillerForklift.disable({ emitEvent: false });
      controls.enableDsUagv.disable({ emitEvent: false });
    }
  }

  onChangeGraphManagerParkingAndCharging(isEnabled: boolean): void {
    this.parkyChargyForm.controls.enableParkyChargy.setValue(isEnabled, { emitEvent: false });
    this.updateEnableParkyChargy();
    this.updateParkyChargyToggleRequest();
  }

  updateParkyChargyToggleRequest(): void {
    const toggle = this.transformFormToDto(this.parkyChargyForm.controls);
    this.updateParkyChargyVehicleToggle.emit(toggle);
  }

  updateParkyChargyToggleForm({
    parkyChargyValue,
    dsTuggerValue,
    schillerForkliftValue,
    dsUagvValue,
  }: {
    parkyChargyValue: boolean;
    dsTuggerValue: boolean;
    schillerForkliftValue: boolean;
    dsUagvValue: boolean;
  }): void {
    this.parkyChargyForm.setValue(
      {
        enableParkyChargy: parkyChargyValue,
        enableDsTugger: dsTuggerValue,
        enableSchillerForklift: schillerForkliftValue,
        enableDsUagv: dsUagvValue,
      },
      { emitEvent: false }
    );
  }

  transformFormToDto(form: ParkyChargyToggleForm): GmParkingAndChargingSettingsDto {
    const parkyChargySettings: GmParkingAndChargingSettingsDto = {
      ...this.parkingAndChargingSettings,
      isEnabled: form.enableParkyChargy.value,
      vehicleToggles: {
        dsTugger: form.enableDsTugger.value,
        dsUagv: form.enableDsUagv.value,
        schillerForklift: form.enableSchillerForklift.value,
      },
    };

    return parkyChargySettings;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
