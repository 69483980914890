import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CellClickedEvent, ColDef, GetRowIdFunc, GetRowIdParams } from 'ag-grid-community';
import { ExtendedDsMenuItem, UserWithRole } from 'core/models';
import { Icons } from 'library/constants';
import { AgGridActionDirective } from 'shared/components';

@Component({
  selector: 'app-environment-users',
  templateUrl: './environment-users.component.html',
  styleUrls: ['./environment-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentUsersComponent extends AgGridActionDirective<UserWithRole> {
  @Input() persistKey = '';
  @Input() searchTerm = '';
  @Input() users: UserWithRole[] = [];
  @Input() menuItems: ExtendedDsMenuItem[] = [];

  @Output() readonly selectedItem = new EventEmitter<UserWithRole>();

  readonly columns: ColDef[] = [
    {
      field: 'name',
      headerName: 'settings.users.name',
    },
    { field: 'department', headerName: 'settings.users.department' },
    { field: 'email', headerName: 'settings.users.email' },
  ];

  translationContext = 'settings.users';
  resetIcon = Icons.Reset;

  onCellSelected(event: CellClickedEvent<UserWithRole>): void {
    this.selectedItem.emit(event.data);
  }

  setGridMenuItems(): void {}

  updateGridMenuItems(_item: UserWithRole): void {}

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) => params.data.id.toString();

  addActionMenuColumn(): void {
    this.setGridMenuItems();
    this.actionMenuColumn.cellRendererParams = {
      value: this.menuItems,
    };

    this.columns.push(this.actionMenuColumn);

    this.translateHeader();

    this.gridOptions = {
      ...this.gridOptions,
      onCellClicked: row => {
        this.onSelectedAction(row);
      },
    };
  }

  onSelectedAction(event: CellClickedEvent): void {
    if (event.colDef.colId === this.actionMenuColumn.colId) {
      this.updateGridMenuItems(event.data);
      this.gridApi.refreshCells({ force: true });
      this.onCellSelected(event);
    }
    this.cdRef.markForCheck();
  }
}
