import { MemoizedSelector, createSelector } from '@ngrx/store';
import { DISTANT_FUTURE } from 'core/constants';
import { MissionListSignalr } from 'core/dtos';
import {
  GuidString,
  MaxDateString,
  MissionFormat,
  MissionPanelListData,
  MissionStatus,
  MissionTrace,
} from 'core/models';
import { MissionListInputModel } from 'modules/jobs/mission-monitoring/components/mission-list/mission-list-model';
import { getMinutesUntilDelivery } from 'modules/jobs/mission-monitoring/helpers/monitoring.helper';
import { selectSelectedMap } from 'store-modules/maps-store/selectors/maps.selectors';
import { selectFleetEntities } from 'store-modules/vehicles-store/selectors/fleets.selectors';
import {
  selectAllActiveVehicles,
  selectSelectedVehicle,
  selectSelectedVehicleId,
} from 'store-modules/vehicles-store/selectors/vehicles.selectors';
import * as fromMonitoringFeatureState from '../reducers/index';
import * as fromMissionListItem from '../reducers/mission-list-item.reducer';
import { selectMissionFilters } from './mission-trace.selectors';
import { convertMissionTriggerTypes, getFleetName } from './mission-trace.selectors.helpers';

const selectMissionListItemState = createSelector(
  fromMonitoringFeatureState.getMonitoringFeatureState,
  fromMonitoringFeatureState.getMissionListItemState
);

export const visibleMissionTraceStates = [
  MissionStatus.InProgress,
  MissionStatus.Failed,
  MissionStatus.Interrupted,
  MissionStatus.InterruptionFailed,
];

export const completedMissionTraceStates = [
  MissionStatus.Aborted,
  MissionStatus.Completed,
  MissionStatus.CompletedWithSupport,
];

export const selectAllMissionListItems = createSelector(
  selectMissionListItemState,
  fromMissionListItem.getAllMissionListItems
);

// selector for active mission page showing active and
// recently completed missions only
export const selectAllMissionActiveList2 = createSelector(
  selectAllMissionListItems,
  selectAllActiveVehicles,
  selectFleetEntities,
  (allMissionTraces, vehicles, fleets): MissionListInputModel[] => {
    return allMissionTraces
      .filter(m => canMissionBeRemoved(m))
      .sort((a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime())
      .map(missionTrace => {
        const deliveryTime = getMinutesUntilDelivery(missionTrace.provisioningTime?.toString());
        const vehicle = vehicles.find(v => v.id === missionTrace.vehicleId);
        const currentStepNr =
          missionTrace.missionFormat === MissionFormat.ROS
            ? missionTrace.currentStepSequenceNumber + 1
            : missionTrace.currentStepSequenceNumber;

        return {
          id: missionTrace.id,
          mapId: missionTrace.mapId,
          missionName: missionTrace.missionName,
          missionId: missionTrace.missionId,
          processChainName:
            missionTrace.missionFormat === MissionFormat.ROS
              ? missionTrace.processChainName
              : missionTrace.tourChainName ?? '',
          processChainId: missionTrace.processChainId,
          processChainTraceId:
            missionTrace.missionFormat === MissionFormat.ROS
              ? missionTrace.processChainTraceId
              : missionTrace.tourChainId ?? '',
          materialNumber: missionTrace.materialNumber,
          status: missionTrace.status,
          statusEnum: missionTrace.status,
          fleetName: getFleetName(missionTrace.fleetId, fleets),
          vehicleName: vehicle?.name ?? missionTrace.vehicleName,
          vehicleId: missionTrace.vehicleId?.toString(),
          vehicleType: missionTrace.vehicleType,
          vehicleStatus: vehicle?.status,
          isVehiclePaused: vehicle?.isPaused,
          isConnected: vehicle?.isConnected,
          isSwitchedOff: vehicle?.isSwitchedOff,
          trigger: convertMissionTriggerTypes(missionTrace.trigger),
          missionTraceTrigger: missionTrace.trigger,
          stepNr: `[${currentStepNr}/${missionTrace.stepCount}]`,
          step: missionTrace.currentStepTypeFrontEnd,
          stepDetail: missionTrace.currentStepAttributeText,
          createdDateTime: missionTrace.createdDateTime,
          deliveryTime: deliveryTime,
          provisioningTime: missionTrace.provisioningTime ?? MaxDateString,
          forecastedEndTime: missionTrace.forecastedEndTime,
          deliveryStatus: missionTrace.deliveryStatus,
          failureComment: [missionTrace.failureComment],
          assignableWaypointName: missionTrace.assignableWaypointName,
          canAbort: missionTrace.canAbort,
          canRetry: !vehicle?.isSwitchedOff && missionTrace.canRetry,
          errorHandlingAllowed: missionTrace.errorHandlingAllowed,
          missionDisplayHighlightLevel: missionTrace.missionDisplayHighlightLevel,
          missionFormat: missionTrace.missionFormat,
          tourSteps: missionTrace.tourSteps,
          priorityLevel: missionTrace.priorityLevel,
          assignmentDelayEndTime: missionTrace.assignmentDelayEndDateTime,
          loadType: missionTrace.loadType,
          scanValue: missionTrace.scanValue,
        };
      });
  }
);

// selector for maps tab active missions list only showing active missions
export const selectMissionPanelData2 = createSelector(
  selectAllMissionListItems,
  selectAllActiveVehicles,
  selectSelectedMap,
  (allMissionTraces, vehicles, selectedMap): MissionPanelListData[] => {
    let results: MissionPanelListData[] = [];
    if (!selectedMap) return results;

    const distantFuture = new Date(DISTANT_FUTURE);

    results = allMissionTraces
      .filter(
        m =>
          canMissionBeRemoved(m) &&
          (m.mapId === selectedMap.id || m.missionFormat === MissionFormat.VDA5050)
      )
      .sort((a, b) => {
        const dateA = a.createdDateTime ? new Date(a.createdDateTime) : distantFuture;
        const dateB = b.createdDateTime ? new Date(b.createdDateTime) : distantFuture;
        return dateA.getTime() - dateB.getTime();
      })
      .sort((a, b) => {
        const dateA = a.provisioningTime ? new Date(a.provisioningTime) : distantFuture;
        const dateB = b.provisioningTime ? new Date(b.provisioningTime) : distantFuture;
        return dateA.getTime() - dateB.getTime();
      })
      .sort((a, b) => {
        return (
          Number(b.status === MissionStatus.Failed) - Number(a.status === MissionStatus.Failed)
        );
      })
      .map(missionTrace => {
        const minutesUntilDelivery = getMinutesUntilDelivery(
          missionTrace.provisioningTime?.toString()
        );
        const vehicle = vehicles.find(v => v.id === missionTrace.vehicleId);

        return {
          missionTraceId: missionTrace.id,
          missionStatus: missionTrace.status,
          isFinalState: completedMissionTraceStates.includes(missionTrace.status),
          missionName: missionTrace.missionName,
          missionId: missionTrace.missionId,
          processChainTraceId: missionTrace.processChainTraceId,
          minutesUntilDelivery: minutesUntilDelivery,
          deliveryStatus: missionTrace.deliveryStatus,
          vehicleId: missionTrace.vehicleId?.toString(),
          vehicleName: vehicle?.name ?? missionTrace.vehicleName,
          vehicleStatus: vehicle?.status,
          assignmentDelayEndDateTime: missionTrace.assignmentDelayEndDateTime,
          menuItems: [],
          missionFormat: missionTrace.missionFormat,
          failureComment: missionTrace.failureComment,
        };
      });
    return results;
  }
);

export const selectFilteredMissionActiveList2 = createSelector(
  selectAllMissionActiveList2,
  selectMissionFilters,
  (filteredMissionTraces, filters) => {
    let missions: MissionListInputModel[] = [...filteredMissionTraces];

    for (const key in filters) {
      if (!key || !filters[key] || filters[key].length === 0) {
        continue;
      }

      switch (key) {
        case 'missionFailureReasonId':
          missions = missions.filter(mission =>
            filters[key] === '1'
              ? mission.failureComment?.[0].missionFailureReasonId
              : !mission.failureComment?.[0].missionFailureReasonId
          );
          break;
        case 'createdDateTime':
          missions = missions.filter(
            mission =>
              mission.createdDateTime &&
              Date.parse(mission.createdDateTime.toString()) >=
                Date.parse(filters.createdDateTime[0]) &&
              Date.parse(mission.createdDateTime.toString()) <=
                Date.parse(filters.createdDateTime[1])
          );
          break;
        case 'vehicleNames':
          missions = missions.filter(
            m => m.vehicleName && filters.vehicleNames?.includes(m.vehicleName)
          );
          break;
        case 'processChainNames':
          missions = missions.filter(m => filters.processChainNames?.includes(m.processChainName));
          break;
        case 'materialNumbers':
          missions = missions.filter(m => filters.materialNumbers?.includes(m.materialNumber));
          break;
        case 'missionNames':
          missions = missions.filter(m => filters.missionNames?.includes(m.missionName));
          break;
        case 'vehicleTypes':
          missions = missions.filter(
            m => filters.vehicleTypes?.length === 0 || filters.vehicleTypes?.includes(m.vehicleType)
          );
          break;
        default:
          missions = missions.filter(
            mission => mission[key] !== undefined && filters[key]?.includes(mission[key])
          );
      }
    }

    return missions;
  }
);

export const selectActiveMissions2 = createSelector(
  selectAllMissionListItems,
  (allActiveMissionTraces: MissionTrace[]) =>
    allActiveMissionTraces.filter(mission => visibleMissionTraceStates.includes(mission.status))
);

export const selectActiveVehicleMissions = createSelector(
  selectActiveMissions2,
  selectSelectedVehicleId,
  (missions, vehicleId) => {
    return missions.filter(
      m => m.vehicleId === vehicleId && visibleMissionTraceStates.includes(m.status)
    )[0];
  }
);

export const selectFailedMissionsCount = createSelector(
  selectAllMissionListItems,
  (allActiveMissionTraces: MissionTrace[]) =>
    allActiveMissionTraces.filter(mission => mission.status === MissionStatus.Failed).length
);

export const selectActiveMissionsByMapId2 = (
  mapId: GuidString
): MemoizedSelector<object, MissionTrace[]> =>
  createSelector(selectActiveMissions2, (allActiveMissionTraces: MissionTrace[]) => {
    return allActiveMissionTraces.filter(m => m.mapId === mapId);
  });

export const selectMissionByVehicle = createSelector(
  selectAllMissionListItems,
  selectSelectedVehicle,
  (missions: MissionTrace[], vehicle): MissionTrace | undefined => {
    return missions.find(m => m.vehicleId === vehicle?.id);
  }
);

export const selectMissionTracesByProcessChainTraceId2 = (
  processChainTraceId: GuidString
): MemoizedSelector<object, MissionTrace[]> =>
  createSelector(selectActiveMissions2, (allActiveMissionTraces: MissionTrace[]) => {
    return allActiveMissionTraces.filter(m => m.processChainTraceId === processChainTraceId);
  });

// remove missions from display when they are finalized state and
// last update was over 10 seconds ago
function canMissionBeRemoved(mission: MissionListSignalr): boolean {
  const tenSecondsInMs = 10000;
  return (
    !completedMissionTraceStates.includes(mission.status) ||
    (completedMissionTraceStates.includes(mission.status) &&
      new Date(new Date().getTime() - tenSecondsInMs) < new Date(mission.updatedDateTime))
  );
}
