/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  INoRowsOverlayParams,
} from 'ag-grid-community';
import { UserService } from 'core/api-services';
import { OrganizationDto, RoleDto, UserRoleDto } from 'core/dtos';
import { filterUndefined } from 'core/helpers';
import {
  AssignUserRoleCommand,
  AtsTreeRoleItem,
  GuidString,
  LevelType,
  RoleAction,
  RoleBaseObj,
  RoleScope,
  User,
  UserRoleModelWithChildren,
  UserWithUserRoles,
} from 'core/models';
import { AtsTranslationService, ToastService, ToolbarService } from 'core/services';
import { firstValueFrom, take } from 'rxjs';
import { AssignUserRoleDialogInput, BaseAgGridTableDirective } from 'shared/components';
import { OrganizationChartTableCellComponent } from '../ag-grid/organization-chart-table-cell/organization-chart-table-cell.component';
import { PermissionHelper } from './user-details-permission-helper';

import * as fromRoot from 'store/index';

@Component({
  selector: 'app-organization-chart-table',
  templateUrl: './organization-chart-table.component.html',
  styleUrls: ['./organization-chart-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationChartTableComponent
  extends BaseAgGridTableDirective
  implements OnChanges, OnDestroy
{
  @Input() isVisible = false;
  @Input() userDetailsData?: AssignUserRoleDialogInput;
  @Input() selectedNode?: AtsTreeRoleItem;
  @Input() organizationList: OrganizationDto[] = [];
  @Input() currentUserRoles: UserWithUserRoles[] = [];
  @Input() envUsers: UserWithUserRoles[] = [];
  @Input() allRoles: RoleDto[] = [];

  @Output() readonly selectedRole = new EventEmitter<string | string[] | null>();
  @Output() readonly refreshOrganogram = new EventEmitter<boolean>();

  userAccessList: UserRoleModelWithChildren[] = [];
  modifiedUserAccessList: UserRoleModelWithChildren[] = [];

  selectedOrganization?: OrganizationDto;
  currentUser?: User;
  translateKey!: string;

  currentlySelectedNode?: AtsTreeRoleItem = undefined;
  selectedRoleWaId: GuidString = '';
  isHeadersHidden = false;
  userId = 0;

  readonly editTranslateKey = 'shared.editUserRoleModal';

  readonly columns: ColDef[] = [
    {
      field: 'level',
      headerName: 'shared.users.userDetailsDialog.list.level',
    },
    {
      field: 'roleId',
      headerName: 'shared.users.userDetailsDialog.list.roleId',
      cellEditor: 'agSelectCellEditor',
      cellRenderer: OrganizationChartTableCellComponent,
      cellEditorParams: {
        formatValue: (value: string) => value.toUpperCase(),
      },
    },
    {
      field: 'roleAssigned',
      headerName: 'shared.users.userDetailsDialog.list.roleAssigned',
    },
    {
      field: 'reasonForAccess',
      headerName: 'shared.users.userDetailsDialog.list.reasonForAccess',
      hide: true,
    },
  ];

  constructor(
    protected readonly atsTranslationService: AtsTranslationService,
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef,
    private readonly userService: UserService,
    private readonly toastService: ToastService,
    private readonly permissionHelper: PermissionHelper,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {
    super(atsTranslationService, toolbarService, cdRef);

    this.gridOptions.noRowsOverlayComponent = CustomNoRowsOverlayComponent;
    this.gridOptions.noRowsOverlayComponentParams = {
      noRowsMessageFunc: () =>
        this.translationService.get('shared.users.userDetailsDialog.list.unselectedMessage'),
    };
  }

  ngOnChanges({ selectedNode }: TypedChanges<OrganizationChartTableComponent>): void {
    Object.assign(this, this.userDetailsData);

    this.userId = Number(this.userDetailsData?.currentUser?.id) ?? 0;

    if (selectedNode?.currentValue) {
      this.currentlySelectedNode = selectedNode?.currentValue;
      this.populateRolesTable(selectedNode?.currentValue);
    }

    this.setColumnHeaderVisibility(!!this.userAccessList.length);
  }

  ngOnDestroy(): void {
    this.userAccessList = [];
    this.modifiedUserAccessList = [];
  }

  setColumnHeaderVisibility(isVisible: boolean): void {
    if (this.gridOptions && this.gridOptions.columnApi) this.isHeadersHidden = !isVisible;
    else this.isHeadersHidden = isVisible;
  }

  async reloadUsers(): Promise<void> {
    this.currentUserRoles = await firstValueFrom(
      this.userService.getUserWithRoles(this.userDetailsData?.currentUser?.id)
    );

    this.envUsers = await firstValueFrom(this.userService.getEnvironmentUsers());
  }

  setSelectedOrganization(organizationId: GuidString | undefined): OrganizationDto | undefined {
    if (organizationId) {
      this.rootStore
        .pipe(select(fromRoot.selectOrganizationById(organizationId)), filterUndefined(), take(1))
        .subscribe(org => {
          this.selectedOrganization = org;
          this.cdRef.markForCheck();
        });
    }

    return this.selectedOrganization;
  }

  async save(): Promise<void> {
    this.modifiedUserAccessList = this.mergeArrays(
      this.userAccessList,
      this.modifiedUserAccessList
    );

    const rolesToBeChanged: UserRoleModelWithChildren[] = this.modifiedUserAccessList.filter(
      role => role.isDirty
    );
    const promiseList: Promise<void>[] = rolesToBeChanged.map(element => {
      return this.changeRole(element);
    });

    await Promise.all(promiseList);

    this.refreshOrganogram.emit(true);

    this.userAccessList = [];
    this.gridApi.setRowData(this.userAccessList);
  }

  Cancel(): void {
    this.userAccessList = [];
    this.modifiedUserAccessList = [];
  }

  getDropdownOptions(rowData: UserRoleModelWithChildren): RoleDto[] | undefined {
    let permissions: RoleDto[] | undefined = [];

    if (rowData.levelType === LevelType.Organization) {
      permissions = this.permissionHelper.getOrgRoles(rowData.orgId);
    } else if (rowData.levelType === LevelType.WorkingArea)
      permissions = this.permissionHelper.getWaRoles(rowData.orgId, rowData.waId);
    else if (rowData.levelType === LevelType.Environment)
      permissions = this.permissionHelper.getEnvRoles();
    else permissions = [];

    this.cdRef.markForCheck();
    return permissions;
  }

  async changeRole(role: UserRoleModelWithChildren): Promise<void> {
    if (role.roleAction === RoleAction.CreateOrUpdate) await this.createOrUpdate(role);
    else if (role.roleAction === RoleAction.Delete) await this.deleteRole(role);

    await this.reloadUsers();

    if (this.currentlySelectedNode) this.populateRolesTable(this.currentlySelectedNode);
  }

  async createOrUpdate(role: UserRoleModelWithChildren): Promise<void> {
    const assignRole: AssignUserRoleCommand = {
      id: this.userId,
      roleId: role.roleId,
      workingAreaId: role.waId,
      organizationId: role.orgId,
    };

    try {
      await this.userService.assignUserRole(assignRole);
      this.toastService.createSuccessToast('shared.users.AssignUserRoleSuccessMessage');
    } catch (error) {
      this.toastService.createErrorToast('shared.users.AssignUserRoleFailureMessage');
    }
  }

  async deleteRole(role: UserRoleModelWithChildren): Promise<void> {
    try {
      const roleExists = this.currentUserRoles.find(c => c.roles.find(r => r.id === role.id));

      if (roleExists) {
        await this.userService.removeUserRole({
          id: this.userId,
          roleId: role.id,
          workingAreaId: role.waId ?? this.setSelectedOrganization(role.orgId)?.workAreas[0].id,
          organizationId: role.orgId,
        });
        this.toastService.createSuccessToast('shared.users.RemoveUserRoleSuccessMessage');
      }
    } catch (error) {
      this.toastService.createErrorToast('shared.users.RemoveUserRoleFailureMessage');
    }
  }

  populateRolesTable(node: AtsTreeRoleItem): void {
    if (node.type === 'environmentWithPermission' || node.type === 'environment') {
      this.generateEnvTable(node);
    }

    if (node.type === 'organizationWithPermission' || node.type === 'organization') {
      this.generateOrgTable(node);
    }

    if (node.type === 'workingAreaWithPermission' || node.type === 'workingArea') {
      this.generateWaTable(node);
    }

    this.modifiedUserAccessList = this.mergeArrays(
      this.userAccessList,
      this.modifiedUserAccessList
    );
  }

  mergeArrays(
    arr1: UserRoleModelWithChildren[],
    arr2: UserRoleModelWithChildren[]
  ): UserRoleModelWithChildren[] {
    const arr1Level = new Set(arr1.map(x => x.level));
    arr2 = [...arr1, ...arr2.filter(x => !arr1Level.has(x.level))];

    return arr2;
  }

  generateEnvTable(node: AtsTreeRoleItem): void {
    const envUser = this.envUsers.find(u => u.id === this.userId);

    let envObj: UserRoleModelWithChildren = {
      level: node.label,
      hierarchy: [LevelType.Environment],
      roleName: envUser?.roles[0]
        ? this.atsTranslationService.get('shared.roles.list.' + envUser?.roles[0].name)
        : '',
      roleId: envUser?.roles[0].roleId ?? -1,
      reasonForAccess: '',
      roleAssigned: envUser?.roles[0]?.createdUtc
        ? new Date(envUser?.roles[0]?.createdUtc).toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : '',
      envId: envUser ? envUser?.id : 1,
      isDirty: false,
      id: envUser?.roles[0].id,
      editable: false,
      creatable: false,
      deletable: false,
      levelType: LevelType.Environment,
    };

    envObj = this.permissionHelper.checkEnvBasedRole(envObj);

    this.userAccessList = [envObj];
    this.cdRef.markForCheck();
  }

  generateOrgTable(node: AtsTreeRoleItem): void {
    const org = this.organizationList.find(o => o.id.toString() === node.id);

    if (!org) return;

    let orgObj = this.generateObject(org, this.currentUserRoles, 'organizationId');

    orgObj = this.permissionHelper.checkOrgBasedRole(orgObj);
    orgObj.levelType = LevelType.Organization;

    this.userAccessList = [orgObj];
    this.cdRef.markForCheck();
  }

  generateWaTable(node: AtsTreeRoleItem): void {
    const org = this.organizationList.find(o => o.workAreas.find(w => w.id === node.id));
    const wa = org?.workAreas.find(w => w.id === node.id);

    if (!wa) return;

    let waObj = this.generateObject(wa, this.currentUserRoles, 'workingAreaId');
    waObj.orgId = org?.id;

    waObj = this.permissionHelper.checkWaBasedRole(waObj);
    waObj.levelType = LevelType.WorkingArea;
    waObj.hierarchy = [LevelType.Organization, LevelType.WorkingArea];

    this.userAccessList = [waObj];
    this.cdRef.markForCheck();
  }

  generateObject(
    obj: RoleBaseObj,
    userRoles: UserWithUserRoles[],
    idField: string
  ): UserRoleModelWithChildren {
    const userRole = userRoles.find(u => u.id === this.userId);
    const role: UserRoleDto | undefined = userRole?.roles
      .filter(r => r[idField] === obj.id)
      .sort(function (a: UserRoleDto, b: UserRoleDto) {
        return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
      })[0];

    const rowRole: UserRoleModelWithChildren = {
      level: obj.name,
      hierarchy: [LevelType.Environment],
      roleId: role?.roleId ?? -1,
      roleName: role ? this.atsTranslationService.get('shared.roles.list.' + role.name) : '',
      reasonForAccess: '',
      roleAssigned: role?.createdUtc
        ? new Date(role?.createdUtc).toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : '',

      isDirty: false,
      id: role?.id ?? -1,
      editable: false,
      creatable: false,
      deletable: false,
    };

    if (idField === 'workingAreaId') rowRole.waId = obj.id;
    else rowRole.orgId = obj.id;

    return rowRole;
  }

  getSelectedRole(selectedRole: string | string[] | null): void {
    if (typeof selectedRole === 'number') {
      const role = this.allRoles.find(r => r.id === selectedRole);
      const updatedUserRoles: UserRoleDto = {
        id: this.userId,
        roleId: role?.id ?? 0,
        organizationId: this.currentlySelectedNode?.id,
        workingAreaId: this.selectedRoleWaId,
        scope: role?.scope ?? RoleScope.WorkingArea,
        name: role?.name ?? '',
        createdUtc: new Date().toDateString(),
        department: '',
      };

      this.currentUserRoles.forEach(m => {
        m.roles.push(updatedUserRoles);
      });
    }

    this.selectedRole.emit(selectedRole);
    this.cdRef.markForCheck();
  }

  getSelectedRoleWaId(selectedRoleWaId: GuidString): void {
    this.selectedRoleWaId = selectedRoleWaId;
  }

  generateGridTreeNode(role: UserRoleModelWithChildren): UserRoleModelWithChildren {
    return {
      ...role,
    };
  }

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) =>
    params.data.level.toString();

  onRowSelected(): void {}

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
  }
}

@Component({
  selector: 'app-no-rows-overlay',
  template: ` <div class="row ms-2x" style="font-size: var(--ds-typography-size-regular);">
    {{ params?.noRowsMessageFunc() }}
  </div>`,
})
export class CustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  params: (INoRowsOverlayParams & { noRowsMessageFunc: () => string }) | undefined;

  agInit(params: INoRowsOverlayParams & { noRowsMessageFunc: () => string }): void {
    this.params = params;
  }
}
