import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as LayerActions from '../../maps-store/actions/layers.actions';
import * as PoisActions from '../actions/pois.actions';

@Injectable()
export class PoiLayerEffects {
  layerPoiCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.signalRCreatePoi, PoisActions.addPoiSuccess),
      map(a => a.poi.id),
      distinctUntilChanged(),
      map(poiId => LayerActions.layerPoiCreated({ id: poiId }))
    )
  );

  layerPoiDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.signalRDeletePoi, PoisActions.deletePoiSuccess),
      map(({ poi }) => LayerActions.layerPoiDeleted({ id: poi.id }))
    )
  );

  layerPoiUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PoisActions.signalRUpdatePoi),
      map(({ poi }) => LayerActions.layerPoiUpdate({ id: poi.id }))
    )
  );

  layerPoiStatusUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PoisActions.signalRUpdatePoiOccupancyStatus,
        PoisActions.signalRUpdatePoiDeviceOccupancyStatus
      ),
      map(({ change }) => LayerActions.layerPoiStatusUpdate({ id: change.poiId }))
    )
  );

  constructor(private readonly actions$: Actions) {}
}
