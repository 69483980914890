import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DsSelectLabellingConfiguration } from '@bmw-ds/components/ds-interfaces/select/config.interface';
import { EMPTY_GUID } from 'core/constants';
import { PoiDto } from 'core/dtos';
import { GuidString, PoiType, StepType } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { Subject } from 'rxjs';
import { StepAttributePoi } from './step-attribute-poi.model';

@Component({
  selector: 'app-step-attribute-poi',
  templateUrl: './step-attribute-poi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StepAttributePoiComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepAttributePoiComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input() stepType: StepType = StepType.Unknown;
  @Input() disabled = false;
  @Input() mapId?: GuidString = EMPTY_GUID;
  @Input() allPois: PoiDto[] = [];

  selectablePois: PoiDto[] = [];
  options: StepAttributePoi[] = [];
  selectedPoiId?: GuidString;

  labellingConfig: DsSelectLabellingConfiguration = {
    noResultsMessage: this.translationService.get('placeholders.selectEmpty'),
    placeholder: this.translationService.get('placeholders.select'),
    selectAll: this.translationService.get('placeholders.selectAll'),
  };

  protected readonly ngUnsubscribe = new Subject<void>();
  protected readonly poiTypesForDock = [
    PoiType.DollyPlace,
    PoiType.AutomaticConveyorLoading,
    PoiType.TurnTableLarge,
    PoiType.TurnTableSmall,
    PoiType.Handover,
    PoiType.TripleTurnTable,
  ];

  constructor(private readonly translationService: AtsTranslationService) {}

  ngOnChanges({ stepType }: TypedChanges<StepAttributePoiComponent>): void {
    if (stepType?.currentValue !== stepType?.previousValue) {
      this.setPoiOptions();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  writeValue(selectedPointOfInterestGroupId: StepAttributePoi | undefined): void {
    this.selectedPoiId = selectedPointOfInterestGroupId?.pointOfInterestId;
    this.setPoiOptions();
  }

  registerOnChange(fn: () => {}): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  onChanged = (_value: StepAttributePoi): void => {};
  onTouched = (_value: StepAttributePoi): void => {};

  onPoiSelectionChange(): void {
    const selectedPoi = this.options.find(o => o.pointOfInterestId === this.selectedPoiId);
    if (selectedPoi) {
      this.onTouched(selectedPoi);
      this.onChanged(selectedPoi);
    }
  }

  setPoiOptions(): void {
    this.selectablePois = this.filterPoisBasedOnStepType();
    this.sortPois();
    this.createOptions();
  }

  filterPoisBasedOnStepType(): PoiDto[] {
    if (this.mapId !== EMPTY_GUID) {
      this.allPois = this.allPois.filter(x => x.mapId === this.mapId);
    }
    switch (this.stepType) {
      case StepType.DockToCharge:
        return this.allPois.filter((poi: PoiDto) => poi.type === PoiType.ChargingStation);
      case StepType.TurnDolly:
        return this.allPois.filter((poi: PoiDto) => poi.type === PoiType.DollyPlace);
      case StepType.Dock:
      case StepType.DockToMapping:
        return this.allPois.filter((poi: PoiDto) => this.poiTypesForDock.includes(poi.type));
      case StepType.Goto:
      case StepType.GoToMapping:
      case StepType.GotoAndPushSideButton:
        return this.allPois.filter((poi: PoiDto) => poi.type !== PoiType.ChargingStation);
      default:
        return [];
    }
  }

  sortPois(): void {
    if (this.selectablePois.length > 0) {
      this.selectablePois.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  createOptions(): void {
    this.options = this.selectablePois.map((poi: PoiDto) => ({
      pointOfInterestGroupId: poi.poiGroupId,
      pointOfInterestId: poi.id,
      pointOfInterestName: poi.name,
    }));
  }
}
