<div class="container center">
  <div class="vertical-center">
    <h1 class="title-lg mb-18x notFoundDetailsMessage1">
      {{ 'shared.notFoundDetails.message1' | translate }}
    </h1>

    <img class="mt-18x" [src]="imageUrl" alt="Not Found" />

    <div class="mt-8x">
      <p class="text-paragraph messageText notFoundDetailsMessage2">
        {{ 'shared.notFoundDetails.message2' | translate }}
      </p>
    </div>
  </div>
</div>
