/* eslint-disable max-lines */
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {
  MissionTraceService,
  ProcessChainTraceService,
  TourChainService,
  VehicleService,
} from 'core/api-services';
import { EMPTY_GUID } from 'core/constants';
import { MissionTraceAbortOptionsDto, getMissionErrorHandlingDefaultsDto } from 'core/dtos';
import {
  ErrorHandlingOptions,
  GuidString,
  MissionErrorHandlingModalInputModel,
  MissionErrorHandlingModalResponseModel,
  MissionFormat,
  MissionTrace,
  ProcessChainTraceModel,
  StepType,
} from 'core/models';
import { ToastService } from 'core/services';
import { BehaviorSubject, Subject, firstValueFrom, forkJoin } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import * as fromMissionTrace from 'store-modules/mission-monitoring-store';
import * as fromSettings from 'store-modules/settings-store';
import * as fromVehicles from 'store-modules/vehicles-store';

@Injectable({
  providedIn: 'root',
})
export class MissionErrorHandlingDialogService implements OnDestroy {
  private readonly errorHandlingModalIsOpen = new Subject<boolean>();
  errorHandlingModalIsOpen$ = this.errorHandlingModalIsOpen.asObservable();

  private readonly isErrorHandlingConfirmed = new Subject<
    MissionErrorHandlingModalResponseModel | false
  >();
  isErrorHandlingConfirmed$ = this.isErrorHandlingConfirmed.asObservable();

  private readonly errorHandlingModalData =
    new BehaviorSubject<MissionErrorHandlingModalInputModel | null>(null);
  errorHandlingModalData$ = this.errorHandlingModalData.asObservable();

  constructor(
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>,
    private readonly vehiclesStore: Store<fromVehicles.VehiclesFeatureState>,
    private readonly missionTraceStore: Store<fromMissionTrace.MonitoringFeatureState>,
    private readonly toastService: ToastService,
    private readonly processChainTraceService: ProcessChainTraceService,
    private readonly missionTraceService: MissionTraceService,
    private readonly tourChainService: TourChainService,
    private readonly vehicleService: VehicleService,
    private readonly loadingBarService: LoadingBarService
  ) {
    this.setDefaults();
  }

  ngUnsubscribe = new Subject<void>();

  openDialogTourErrorHandling(
    tourChainId: GuidString | undefined | null,
    tourId: GuidString,
    missionFormat: MissionFormat
  ): void {
    if (tourChainId) {
      const responses$ = forkJoin([
        this.tourChainService.getTourChainById(tourChainId),
        this.tourChainService.raisesInterlock(tourId),
      ]);
      responses$.subscribe(([tourChain, raisesInterlock]) => {
        void this.callOpenDialog(tourId, raisesInterlock, missionFormat, tourChain?.tours);
      });
    } else {
      const responses$ = forkJoin([
        this.tourChainService.getTourById(tourId),
        this.tourChainService.raisesInterlock(tourId),
      ]);
      responses$.subscribe(([tour, raisesInterlock]) => {
        void this.callOpenDialog(tour.id, raisesInterlock, tour.missionFormat, [tour]);
      });
    }
  }

  openDialogMissionErrorHandling(
    missionTraceId: GuidString,
    processChainTraceId: GuidString | undefined,
    missionId: GuidString,
    missionFormat: MissionFormat
  ): void {
    if (processChainTraceId) {
      this.processChainTraceService
        .getProcessChainTraceById(processChainTraceId)
        .pipe(take(1))
        .subscribe({
          next: pc => {
            const raisesInterlock = this.checkForInterLockMissions(pc, missionTraceId, missionId);
            void this.callOpenDialog(
              missionTraceId,
              raisesInterlock,
              missionFormat,
              pc.missionTraces
            );
          },
          error: () => {
            void this.errorDialogOpen(missionTraceId, missionFormat);
          },
        });
    } else {
      this.missionTraceService
        .getMissionTraceById(missionTraceId)
        .pipe(take(1))
        .subscribe({
          next: mt => {
            void this.callOpenDialog(missionTraceId, false, missionFormat, [mt]);
          },
          error: () => {
            void this.errorDialogOpen(missionTraceId, missionFormat);
          },
        });
    }
  }

  defaults = getMissionErrorHandlingDefaultsDto();
  async callOpenDialog(
    missionTraceId: GuidString,
    raisesInterlock: boolean,
    missionFormat: MissionFormat,
    allMissionTracesInPc?: MissionTrace[]
  ): Promise<void> {
    const modalInput: MissionErrorHandlingModalInputModel = {
      confirmInterlock: this.defaults.releaseInterlock,
      raisesInterlock: raisesInterlock,
      confirmToCallOffSystem: this.defaults.confirmAbortToSapAs,
      selectedOption: this.defaults.defaultErrorHandlingOption,
      allMissionTracesInPc: allMissionTracesInPc,
      missionTraceId: missionTraceId,
      missionFormat: missionFormat,
    };

    const result = await this.createErrorHandlingModal(modalInput);

    if (result) {
      switch (result.selectedOption) {
        case ErrorHandlingOptions.Abort: {
          if (missionFormat === MissionFormat.ROS) {
            await this.abortMission(result);
          } else if (missionFormat === MissionFormat.VDA5050) {
            const missionName = allMissionTracesInPc?.find(
              mt => mt.id === missionTraceId
            )?.missionName;
            this.abortMissionVDA(missionTraceId, missionName, result);
          }

          break;
        }
        case ErrorHandlingOptions.ReassignMission:
          await this.reassignMission(
            result.missionTraceId,
            result.vehicleId,
            result.shouldSwitchVehicleIntoMaintenanceMode
          );
          break;
        case ErrorHandlingOptions.ContinueFromStep:
          this.continueFromStep(result);
          break;
        default:
          return;
      }
    }

    this.errorHandlingModalIsOpen.next(false);
  }

  async errorDialogOpen(missionTraceId: GuidString, missionFormat: MissionFormat): Promise<void> {
    this.toastService.createErrorToast('shared.actions.errorLoading');
    await this.callOpenDialog(missionTraceId, false, missionFormat, undefined);
  }

  protected async abortMission(data: MissionErrorHandlingModalResponseModel): Promise<void> {
    const abortOptions: MissionTraceAbortOptionsDto = {
      releaseInterlock: data.confirmInterlock,
      sapConfirmationOption: data.confirmToCallOffSystem,
      reason: data.reason,
    };
    await this.standardAbortMission(
      data.missionTraceId,
      data.vehicleId,
      abortOptions,
      data.shouldSwitchVehicleIntoMaintenanceMode
    );
  }

  protected async standardAbortMission(
    missionTraceId: GuidString,
    vehicleId: GuidString | undefined,
    abortOptions: MissionTraceAbortOptionsDto,
    shouldSwitchVehicleIntoMaintenanceMode: boolean
  ): Promise<void> {
    try {
      await this.conditionallyUpdateMaintenanceMode(
        vehicleId,
        shouldSwitchVehicleIntoMaintenanceMode,
        'shared.actions.errorAbortMissionFailedMaintenanceMode'
      );
    } catch {
      return;
    }

    this.missionTraceService
      .abortMission(missionTraceId, abortOptions)
      .pipe(take(1))
      .subscribe({
        next: missionAbortResult => {
          if (missionAbortResult.missionAlreadyInFinalState) {
            this.toastService.createInfoToast('shared.actions.missionAlreadyInFinalState');
            this.missionTraceStore.dispatch(fromMissionTrace.loadMissionTraces());
          } else {
            this.toastService.createSuccessToast('shared.actions.missionAborted');
          }
          this.loadingBarService.useRef().complete();
        },
        error: () => {
          this.toastService.createErrorToast('shared.actions.errorAbortingMission');
          this.loadingBarService.useRef().complete();
        },
      });
  }

  protected checkForInterLockMissions(
    pc: ProcessChainTraceModel,
    selectedMissionTraceId: GuidString,
    missionId: GuidString
  ): boolean {
    const otherMissions = pc.missionTraces.filter(
      missionTrace => missionTrace.id !== selectedMissionTraceId
    );

    for (const mission of otherMissions) {
      const waitStep = mission.stepTraces.find(
        step =>
          !step.succeeded &&
          step.sourceStep.type === StepType.WaitForEndOfStep &&
          step.sourceStep.waitForStep.missionId === missionId
      );

      if (waitStep) {
        return true;
      }
    }

    return false;
  }

  protected async reassignMission(
    missionTraceId: GuidString,
    vehicleId: GuidString | undefined,
    shouldSwitchVehicleIntoMaintenanceMode: boolean
  ): Promise<void> {
    try {
      await this.conditionallyUpdateMaintenanceMode(
        vehicleId,
        shouldSwitchVehicleIntoMaintenanceMode,
        'shared.actions.errorReassignMissionFailedMaintenanceMode'
      );
    } catch {
      return;
    }

    this.missionTraceService
      .reassignMission(missionTraceId)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastService.createSuccessToast('shared.actions.successReassignMission');
          this.loadingBarService.useRef().complete();
        },
        error: () => {
          this.toastService.createErrorToast('shared.actions.errorReassignMission');
          this.loadingBarService.useRef().complete();
        },
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private setDefaults() {
    this.settingsStore
      .pipe(select(fromSettings.selectJobSettings), takeUntil(this.ngUnsubscribe))
      .subscribe(jobManagerSettings => {
        this.defaults =
          jobManagerSettings.missionErrorHandlingDefaultsToggle ??
          getMissionErrorHandlingDefaultsDto();
      });
  }

  protected continueFromStep(data: MissionErrorHandlingModalResponseModel): void {
    if (data.selectedStepNumber !== undefined) {
      this.missionTraceService
        .continueFromStep(data.missionTraceId, data.selectedStepNumber)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.toastService.createSuccessToast('shared.actions.successContinueFromStep');
          },
          error: () => {
            this.toastService.createErrorToast('shared.actions.errorContinueFromStep');
          },
        });
    }
  }

  async createErrorHandlingModal(
    data: MissionErrorHandlingModalInputModel
  ): Promise<MissionErrorHandlingModalResponseModel | false> {
    this.errorHandlingModalIsOpen.next(true);
    this.createDataForErrorHandlingModal(data);
    return firstValueFrom(this.isErrorHandlingConfirmed$.pipe(take(1)));
  }

  protected createDataForErrorHandlingModal(data: MissionErrorHandlingModalInputModel): void {
    this.errorHandlingModalData.next(data);
  }

  confirmErrorHandlingModalClose(result: MissionErrorHandlingModalResponseModel | false): void {
    this.isErrorHandlingConfirmed.next(result);
  }

  async conditionallyUpdateMaintenanceMode(
    vehicleId: GuidString | undefined,
    shouldSwitchVehicleIntoMaintenanceMode: boolean,
    errorToastMessagePath: string
  ): Promise<void> {
    const hasVehicleAssignedToMission = vehicleId && vehicleId !== EMPTY_GUID;
    const maintenanceModeEnabled = await firstValueFrom(
      this.vehiclesStore.pipe(
        select(fromVehicles.selectSelectedVehicle),
        map(vehicle => vehicle?.maintenanceModeEnabled),
        takeUntil(this.ngUnsubscribe)
      )
    );
    const maintenanceModeShouldChange =
      shouldSwitchVehicleIntoMaintenanceMode !== maintenanceModeEnabled;
    if (hasVehicleAssignedToMission && maintenanceModeShouldChange) {
      try {
        await firstValueFrom(
          this.vehicleService.updateMaintenanceMode({
            id: vehicleId,
            maintenanceModeEnabled: shouldSwitchVehicleIntoMaintenanceMode,
          })
        );
      } catch (error) {
        this.toastService.createErrorToast(errorToastMessagePath);
        this.loadingBarService.useRef().complete();
        throw error;
      }
    }
  }

  private abortMissionVDA(
    missionTraceId: GuidString,
    missionName: string | undefined,
    data: MissionErrorHandlingModalResponseModel
  ): void {
    const abortOptions: MissionTraceAbortOptionsDto = {
      releaseInterlock: data.confirmInterlock,
      sapConfirmationOption: data.confirmToCallOffSystem,
      reason: data.reason,
    };

    this.tourChainService
      .cancelTour(missionTraceId, abortOptions)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastService.createSuccessToast('shared.missionAbortBar.cancelTourSuccess', {
            tourName: missionName,
          });
        },
        error: () =>
          this.toastService.createErrorToast('shared.missionAbortBar.cancelTourFailure', {
            tourName: missionName,
          }),
      });
  }
}
