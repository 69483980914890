<ds-menu #dsmenu appendTo="body">
  <ng-container *ngFor="let item of items">
    <ds-list-item
      *ngIf="item.visible !== false"
      class="menu-button-critical"
      [ngClass]="item.tone ? 'menu-button-list-item-' + item.tone : ''"
      [icon]="item.icon"
      [disabled]="item.disabled"
      [dsTooltipConfig]="{ width: '200px', disabled: !item.disabled }"
      (click)="item.command()">
      <ng-container *ngTemplateOutlet="dropdownTemplate; context: { $implicit: item }">
      </ng-container>
    </ds-list-item>
  </ng-container>
</ds-menu>
<button
  *ngIf="items.length"
  #btn
  ds-button
  data-cy="menu-button"
  [ds-menu-trigger-for]="dsmenu"
  [variant]="variant"
  [icon]="icon"
  [ngClass]="tone">
  {{ label }}
</button>

<ng-template #dropdownTemplate let-option>
  <div (click)="onItemClick($event, option.disabled)">
    <span class="seperate-content">
      {{ option.label }}
      <i *ngIf="option.disabled" class="icon-spacing">
        <ds-icon
          data-cy="infoIcon"
          icon="information"
          class="info-icon ds-icon--sm"
          [ds-tooltip]="
            option.disabled && option.disabledText ? option.disabledText : null
          "></ds-icon>
      </i>
    </span>
  </div>
</ng-template>
