<div class="row">
  <div *ngFor="let alertNowGroup of alertNowGroups; let i = index" class="col-lg-6 col-md-12 pt-4x">
    <div [ngClass]="{ left: i % 2 !== 0, right: i % 2 === 0 }">
      <ds-box>
        <ds-box-header>
          <h3 class="mb-0x">{{ alertNowGroup.name }}</h3>
        </ds-box-header>
        <ds-box-content divider="full">
          <div class="row">
            <div class="col" style="display: flex">
              <h4>
                {{ 'settings.ipstAlertNowSettings.alertNowService.recipientKeys' | translate }}
              </h4>
            </div>
          </div>
          <div class="row">
            <div>
              <app-labeled-text
                label=" {{
                  'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate
                }}">
              </app-labeled-text>

              <ds-tag
                *ngFor="let recipientKey of alertNowGroup?.recipientList"
                data-cy="recipientKey-one"
                style="margin-right: 3px">
                {{ allRecipientKeys | getNameFromId : recipientKey : 'recipientKey' }}
              </ds-tag>
            </div>
          </div>

          <hr class="lineSep" />

          <div class="row">
            <div class="col" style="display: flex">
              <h4>
                {{ 'settings.ipstAlertNowSettings.alertNowService.alertNowZone' | translate }}
              </h4>
            </div>
          </div>
          <div>
            <app-labeled-text
              label="{{ 'settings.ipstAlertNowSettings.alertNowService.zone' | translate }}"
              value="{{ zones | getNameFromId : alertNowGroup.alertNowZoneId : 'zoneName' }}">
            </app-labeled-text>
          </div>
          <div>
            <div
              *ngFor="
                let alertNowVehicleGroup of alertNowGroup?.alertNowVehicleGroups;
                let index = index
              ">
              <hr class="lineSep" />

              <div class="row">
                <div class="col" style="display: flex">
                  <h4>
                    {{
                      'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroup'
                        | translate
                    }}
                  </h4>
                </div>
              </div>
              <div class="row mb-4x" style="width: 100%">
                <h5 [innerHTML]="getVehicleAlertNowHeader(index)"></h5>
              </div>

              <div class="row">
                <div class="form-field-spacing col-sm-6">
                  <app-labeled-text
                    label="{{
                      'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroupName'
                        | translate
                    }}"
                    value="{{ alertNowVehicleGroup.name }}">
                  </app-labeled-text>
                </div>

                <div class="key-container col-sm-6">
                  <app-labeled-text
                    label="{{
                      'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate
                    }}">
                  </app-labeled-text>

                  <ds-tag
                    *ngFor="let recipientKey of alertNowVehicleGroup?.recipientList"
                    data-cy="recipientKey-two"
                    style="margin-right: 3px">
                    {{ allRecipientKeys | getNameFromId : recipientKey : 'recipientKey' }}
                  </ds-tag>
                </div>

                <div class="vehicle-container col-sm-12">
                  <app-labeled-text
                    label="{{
                      'settings.ipstAlertNowSettings.alertNowService.vehicles' | translate
                    }}">
                  </app-labeled-text>

                  <ds-tag
                    *ngFor="let alertNowVehicle of alertNowVehicleGroup?.alertNowVehicles"
                    data-cy="vehicles"
                    style="margin-right: 3px">
                    {{ vehicles | getNameFromId : alertNowVehicle.id : 'name' }}
                  </ds-tag>
                </div>
              </div>
            </div>
          </div>
        </ds-box-content>
      </ds-box>
    </div>
  </div>
</div>
