import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ExpandCollapseButtonsService } from 'library/components/expand-collapse-buttons/service/expand-collapse-buttons.service';

import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-plain-layout',
  templateUrl: './plain-layout.component.html',
  styleUrls: ['./plain-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainLayoutComponent implements OnInit {
  @Input() pageHeading = '';
  @Input() contentHeading = '';
  @Input() hasToolbar = true;
  @Input() scrollChild = false; // Please leave it false by default
  @Input() isNewLayout = false;

  showCollapseAndExpandButtons$: Observable<boolean> = of();

  constructor(private readonly expandCollapseButtonsService: ExpandCollapseButtonsService) {}

  ngOnInit(): void {
    this.showCollapseAndExpandButtons$ =
      this.expandCollapseButtonsService.getShowExpandCollapseAllButtons();
  }
}
