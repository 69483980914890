import { createSelector } from '@ngrx/store';

import * as fromSettings from '../reducers/index';
import * as fromSettingsReducer from '../reducers/settings.reducer';

import * as fromOpcuaDevices from 'store-modules/opcua-devices-store/index';

const selectSettingsState = createSelector(
  fromSettings.getSettingsFeatureState,
  fromSettings.getSettingsState
);

export const selectFleetSettingsLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getFleetLoaded
);

export const selectIpstFeaturesLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getIpstLoaded
);

export const selectShutdownRequestLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getShutdownRequestLoaded
);

export const selectInfobarMessageLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getInfobarMessageLoaded
);

export const selectTrafficSettingsLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getTrafficLoaded
);

export const selectEmulatorSettingsLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getEmulatorLoaded
);

export const selectJobSettingsLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getJobManagerLoaded
);

export const selectLoadTypesLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getLoadTypesLoaded
);

export const selectMapSettingsLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getMapManagerLoaded
);

export const selectFleetSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getFleetManagerFeatures
);

export const selectIpstFeatures = createSelector(
  selectSettingsState,
  fromSettingsReducer.getIpstServiceFeatures
);

export const selectIpstLastMessageResolved = createSelector(selectSettingsState, settingsState => {
  return fromSettingsReducer.getIpstLastMessageResolved(settingsState);
});

export const selectOrderGatewayFeatures = createSelector(
  selectSettingsState,
  fromSettingsReducer.getOrderGatewayFeatures
);

export const selectOrderGatewayFeaturesLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getOrderGatewayFeaturesLoaded
);

export const selectShutdownRequest = createSelector(
  selectSettingsState,
  fromSettingsReducer.getShutdownRequest
);

export const selectInfobarMessage = createSelector(
  selectSettingsState,
  fromSettingsReducer.getInfobarMessage
);

export const selectTrafficSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getTrafficManagerFeatures
);

export const selectEmulatorSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getEmulatorManagerFeatures
);

export const selectJobSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getJobFeatures
);

export const selectLoadTypeSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getLoadTypeSettings
);

export const selectActiveLoadTypeConfigurations = createSelector(
  selectLoadTypeSettings,
  loadTypeSettings => loadTypeSettings.configurations.filter(x => x.isActive)
);

export const selectActiveLoadTypes = createSelector(
  selectActiveLoadTypeConfigurations,
  configurations => configurations.map(x => x.loadType)
);

export const selectMapSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getMapFeatures
);

export const selectSettingsErrorMessage = createSelector(
  selectSettingsState,
  fromSettingsReducer.getErrorMessage
);

export const isEndOfShiftModeActive = createSelector(
  selectJobSettings,
  jobSettings => jobSettings.endOfShiftModeToggle.isToggledOn
);

export const selectEvacuationDeviceSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getEvacuationDeviceSettings
);

export const selectEvacuationModeTriggerActive = createSelector(
  selectEvacuationDeviceSettings,
  fromOpcuaDevices.selectAllOpcuaDevices,
  (evacuationDevices, allDevices) => {
    const result = evacuationDevices.filter(triggerDevice => {
      const device = allDevices.find(d => d.name === triggerDevice.device);
      if (device) {
        for (const trigger of triggerDevice.triggers) {
          const node = device.nodes.find(n => n.name === trigger.node);
          if (node?.state?.value === trigger.value) {
            return true;
          }
        }
      }
      return false;
    });

    return result !== null && result.length > 0;
  }
);

export const selectEvacuationDevicesLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getEvacuationDeviceSettingsLoaded
);

export const selectIsFleetSettingsFailed = createSelector(
  selectSettingsState,
  fromSettingsReducer.getIsFleetSettingsUpdateFailed
);

export const selectIsJobSettingsFailed = createSelector(
  selectSettingsState,
  fromSettingsReducer.getIsJobSettingsUpdateFailed
);

export const selectIsMapSettingsFailed = createSelector(
  selectSettingsState,
  fromSettingsReducer.getIsMapSettingsUpdateFailed
);

export const selectIsTrafficSettingsFailed = createSelector(
  selectSettingsState,
  fromSettingsReducer.getIsTrafficSettingsUpdateFailed
);

export const selectIsShutdownModeFailed = createSelector(
  selectSettingsState,
  fromSettingsReducer.getIsShutdownModeUpdateFailed
);

export const selectGraphManagerFeatureSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getGraphManagerFeatureSettings
);

export const selectGraphManagerFeatureSettingLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getGraphManagerFeatureSettingsLoaded
);

export const selectGraphManagerParkingAndChargingSettings = createSelector(
  selectSettingsState,
  fromSettingsReducer.getGraphManagerParkingAndChargingSettings
);

export const selectGraphManagerParkingAndChargingSettingsLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getGraphManagerParkingAndChargingSettingsLoaded
);

export const selectEvacuationModeFireFighter = createSelector(
  selectSettingsState,
  fromSettingsReducer.getEvacuationModeFireFighter
);

export const selectEvacuationModeFireFighterTogglesLoaded = createSelector(
  selectSettingsState,
  fromSettingsReducer.getEvacuationModeFireFighterLoaded
);

export const selectBeginShiftModeActive = createSelector(
  selectGraphManagerFeatureSettings,
  graphManagerSettings =>
    (graphManagerSettings.settings.isBeginShiftModeEnabled ?? false) &&
    (graphManagerSettings.settings.enableGraphManager ?? false)
);
