<ng-container>
  <div>
    <div class="positionDescription" content>
      <table class="table" data-cy="loadTypeTable">
        <thead>
          <tr>
            <th class="col-2">
              {{
                'settings.featureToggles.jobAssignmentSection.loadingTypeTable.title.activation'
                  | translate
              }}
            </th>
            <th class="col-2">
              {{
                'settings.featureToggles.jobAssignmentSection.loadingTypeTable.title.loadingType'
                  | translate
              }}
            </th>
            <ng-container *ngFor="let poi of loadTypePois">
              <th class="col-2">
                <span [attr.data-cy]="'loadTypeTableColumnTitle-' + poi.types[0]">{{
                  'settings.featureToggles.jobAssignmentSection.loadingTypeTable.title.' +
                    poi.translate | translate
                }}</span>
              </th>
            </ng-container>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let model of configurationModels">
            <tr>
              <td class="columnSeparator">
                <div class="toggle">
                  <ds-switch
                    [attr.data-cy]="'loadTypeTableActiveSwitch-' + model.identifier"
                    (ngModelChange)="onToggleLoadTypeActivation(model.loadType)"
                    [ngModel]="model.isActive"
                    ngDefaultControl></ds-switch>
                </div>
              </td>
              <td class="columnSeparator">
                <span
                  class="align-sub"
                  [attr.data-cy]="'loadTypeTableRowTitle-' + model.identifier"
                  >{{ model.identifier }}</span
                >
              </td>
              <ng-container *ngFor="let poi of loadTypePois">
                <td class="columnSeparator">
                  <span
                    class="align-sub"
                    [attr.data-cy]="'loadTypeTableValue-' + model.identifier + '-' + poi.types[0]">
                    <ds-icon
                      *ngIf="isPoiEnabled(model.poiTypes, poi.types)"
                      [attr.data-cy]="
                        'loadTypeTableEnabledIcon-' + model.identifier + '-' + poi.types[0]
                      "
                      icon="checkbox_circle"
                      tone="positive"
                      size="sm"
                      class="me-1x"></ds-icon
                  ></span>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
