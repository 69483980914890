import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ErrorForwardingModel } from 'core/models';

import * as ErrorForwardingActions from '../actions/error-forwarding.actions';

export const featureKey = 'errorForwarding';

export interface ErrorForwardingState extends EntityState<ErrorForwardingModel> {
  loading: boolean;
  loaded: boolean;
  errorMessage: string;
}

export const errorForwardingAdapter: EntityAdapter<ErrorForwardingModel> =
  createEntityAdapter<ErrorForwardingModel>({
    selectId: (model: ErrorForwardingModel) => `${model.workAreaId}_${model.type}`,
  });

export const initialState: ErrorForwardingState = errorForwardingAdapter.getInitialState({
  loading: false,
  loaded: false,
  errorMessage: '',
});

export const errorForwardingReducer = createReducer(
  initialState,

  on(ErrorForwardingActions.loadErrorForwarding, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(ErrorForwardingActions.loadErrorForwardingSuccess, (state, { errorForwardings }) =>
    errorForwardingAdapter.setAll(errorForwardings, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(ErrorForwardingActions.loadErrorForwardingFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(ErrorForwardingActions.saveErrorForwarding, state => ({
    ...state,
  })),

  on(ErrorForwardingActions.saveErrorForwardingSuccess, (state, { errorForwardingModel }) =>
    errorForwardingAdapter.updateMany(
      errorForwardingModel.map(p => {
        return {
          id: `${p.workAreaId}_${p.type}`,
          changes: { recipientKeyIds: p.recipientKeyIds, sendNotification: p.sendNotification },
        };
      }),
      state
    )
  ),

  on(ErrorForwardingActions.saveErrorForwardingFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(
  state: ErrorForwardingState | undefined,
  action: Action
): ErrorForwardingState {
  return errorForwardingReducer(state, action);
}

export const { selectEntities, selectAll } = errorForwardingAdapter.getSelectors();

export const getLoaded = (state: ErrorForwardingState): boolean => state.loaded;
export const getLoading = (state: ErrorForwardingState): boolean => state.loading;
export const getErrorMessage = (state: ErrorForwardingState): string => state.errorMessage;
export const getEntities = selectEntities;
export const getAll = selectAll;
