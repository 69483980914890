<div class="d-flex justify-content-end">
  <button
    ds-button
    [variant]="'ghost'"
    [icon]="resetIcon"
    class="ms-4x"
    (click)="resetPersistedFilterAndColumnState()">
    {{ 'shared.tableColumnActions.reset' | translate }}
  </button>
</div>
<ag-grid-angular
  data-cy="auditLogListTable"
  class="ag-theme-density zebra"
  [appPersistTableFilterAndColumnState]="{
    persistKey: 'audit-log-list',
    selectedWorkingAreaId: selectedWorkingAreaId,
    gridOptions,
    rowData: undefined
  }"
  [columnDefs]="translatedColumnDefs"
  [cacheBlockSize]="pageSize"
  [getRowId]="getRowIdForChangeDetection"
  [gridOptions]="gridOptions"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  [rowSelection]="'single'"
  [rowModelType]="'serverSide'"
  [pagination]="true"
  [paginationPageSize]="pageSize"
  [animateRows]="true"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
