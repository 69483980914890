import { GuidString } from 'core/models';

export interface HuLookupMapping {
  device: string;
  actionType: WarehouseActionType;
  sourceBinType: string;
  destinationBinType: string;
  statusFilter: WarehouseTaskStatus;
  workAreaId: GuidString;
}

export enum WarehouseActionType {
  GetDestination = 1,
}

export enum WarehouseTaskStatus {
  Confirmed = 'C',
  Open = '',
  OpenOrConfirmed = 'OpenOrConfirmed',
}
