import { createAction, props } from '@ngrx/store';
import { GuidString } from 'core/models';

export enum LayerTypes {
  ItemCreatePoi = '[Layers] Created Poi',
  ItemDeletePoi = '[Layers] Deleted Poi',
  ItemUpdateStatusPoi = '[Layers] Update Poi Status',
  ItemUpdatePoi = '[Layers] Update Poi',
}

export const layerPoiCreated = createAction(LayerTypes.ItemCreatePoi, props<{ id: GuidString }>());
export const layerPoiDeleted = createAction(LayerTypes.ItemDeletePoi, props<{ id: GuidString }>());
export const layerPoiUpdate = createAction(LayerTypes.ItemUpdatePoi, props<{ id: GuidString }>());

export const layerPoiStatusUpdate = createAction(
  LayerTypes.ItemUpdateStatusPoi,
  props<{ id: GuidString }>()
);
