/* eslint-disable sonarjs/cognitive-complexity*/
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { environment } from '@environment';
import { EMPTY_GUID } from 'core/constants';
import {
  FeatureToggle,
  GmParkingAndChargingSettingsDto,
  JobManagerFeatures,
  LoadTypeSettingsState,
  MapManagerFeatures,
  MissionErrorHandlingDefaultsDto,
  TrafficManagerFeatures,
  getDefaultGraphManagerParkingAndChargingSettings,
  getDefaultJobManagerFeatures,
  getDefaultMapManagerFeatures,
  getDefaultTrafficManagerFeatures,
  getLoadTypeSettingsStateDefault,
  getMissionErrorHandlingDefaultsDto,
} from 'core/dtos';

import objectHelper from 'core/helpers/object.helper';
import { AtsActions, GuidString } from 'core/models';
import { PermissionService } from 'core/services';
import { ConfigurationViewModel } from 'modules/settings/components/configuration/configuration.viewmodel';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationComponent implements OnChanges {
  ngUnsubscribe = new Subject<void>();
  viewModel: ConfigurationViewModel;

  @Input() readonly jobManagerSettings: JobManagerFeatures = getDefaultJobManagerFeatures();
  @Input() jobManagerSettingsLoaded = false;
  @Input() readonly loadTypeSettings: LoadTypeSettingsState = getLoadTypeSettingsStateDefault();

  @Input()
  readonly trafficManagerSettings: TrafficManagerFeatures = getDefaultTrafficManagerFeatures();
  @Input() trafficManagerSettingsLoaded = false;
  @Input() requiredPermission: string = AtsActions.General_View;
  @Input() readonly graphManagerSettings: FeatureToggle = {
    isToggledOn: false,
    dateUpdated: null,
  };
  @Input() graphManagerSettingsLoaded = false;

  @Input() readonly graphManagerParkingAndChargingSettings: GmParkingAndChargingSettingsDto =
    getDefaultGraphManagerParkingAndChargingSettings();
  @Input() graphManagerParkingAndChargingSettingsLoaded = false;

  @Input() workAreaId: GuidString = EMPTY_GUID;

  @Input() mapManagerSettings: MapManagerFeatures = getDefaultMapManagerFeatures();
  @Input() mapManagerSettingsLoaded = false;

  @Output() readonly saveParkingAndCharging = new EventEmitter<boolean>();
  @Output() readonly saveWaitOnPoi = new EventEmitter<boolean>();
  @Output() readonly saveAmaSettings = new EventEmitter<boolean>();
  @Output() readonly saveWaitingQueueSettings = new EventEmitter<boolean>();
  @Output() readonly saveGraphManagerSettings = new EventEmitter<boolean>();
  @Output() readonly saveGraphManagerParkingAndChargingSettings =
    new EventEmitter<GmParkingAndChargingSettingsDto>();
  @Output() readonly saveVehicleSmoothingSettings = new EventEmitter<boolean>();

  @Output() readonly saveTrafficManagementSettings = new EventEmitter<boolean>();
  @Output() readonly saveBackwardDrivingPathBased = new EventEmitter<boolean>();
  @Output() readonly saveAvoidConflictAtPois = new EventEmitter<boolean>();
  @Output()
  readonly saveMissionErrorHandlingDefaults = new EventEmitter<MissionErrorHandlingDefaultsDto>();

  constructor(
    private readonly permissionService: PermissionService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.viewModel = this.generateViewModel();
  }

  hasPermission = true;
  isModalOpen = false;

  production = environment.production;

  ngOnChanges({
    jobManagerSettings,
    trafficManagerSettings,
    requiredPermission,
    graphManagerSettings,
    mapManagerSettings,
    loadTypeSettings,
    graphManagerParkingAndChargingSettings,
  }: TypedChanges<ConfigurationComponent>): void {
    if (jobManagerSettings?.currentValue) {
      this.viewModel.parkingAndCharging =
        objectHelper.cloneDeep(jobManagerSettings.currentValue.parkingAndChargingModeToggle) ??
        this.viewModel.parkingAndCharging;

      this.viewModel.parkingAndChargingLoadedVehicles =
        objectHelper.cloneDeep(
          jobManagerSettings.currentValue.parkingAndChargingLoadedVehiclesToggle
        ) ?? this.viewModel.parkingAndChargingLoadedVehicles;

      this.viewModel.waitOnPoi =
        objectHelper.cloneDeep(jobManagerSettings.currentValue.waitOnPoiToggle) ??
        this.viewModel.waitOnPoi;

      this.viewModel.amaSettings =
        objectHelper.cloneDeep(jobManagerSettings.currentValue.amaSettingModeToggle) ??
        this.viewModel.amaSettings;

      this.viewModel.waitingQueueSettings =
        objectHelper.cloneDeep(jobManagerSettings.currentValue.waitingQueueModeToggle) ??
        this.viewModel.waitingQueueSettings;

      this.viewModel.missionErrorHandlingDefaults =
        objectHelper.cloneDeep(
          jobManagerSettings.currentValue.missionErrorHandlingDefaultsToggle
        ) ?? this.viewModel.missionErrorHandlingDefaults;

      this.viewModel.loadTypeSettings =
        objectHelper.cloneDeep(this.loadTypeSettings) ?? this.viewModel.loadTypeSettings;
    }

    if (loadTypeSettings?.currentValue) {
      this.viewModel.loadTypeSettings =
        objectHelper.cloneDeep(loadTypeSettings.currentValue) ?? this.viewModel.loadTypeSettings;
    }

    if (graphManagerSettings?.currentValue) {
      this.viewModel.graphManagerSettings =
        objectHelper.cloneDeep(graphManagerSettings.currentValue) ??
        this.viewModel.graphManagerSettings;
    }

    if (graphManagerParkingAndChargingSettings?.currentValue) {
      this.viewModel.graphManagerParkingAndChargingSettings =
        objectHelper.cloneDeep(graphManagerParkingAndChargingSettings.currentValue) ??
        this.viewModel.graphManagerParkingAndChargingSettings;
    }

    if (mapManagerSettings?.currentValue) {
      const vehicleSmoothingSettings = mapManagerSettings.currentValue.enableVehicleSmoothingToggle;
      this.viewModel.vehicleSmoothingSettings =
        objectHelper.cloneDeep(vehicleSmoothingSettings) ?? this.viewModel.vehicleSmoothingSettings;
    }

    if (trafficManagerSettings?.currentValue) {
      this.updateTrafficManagerSettings(trafficManagerSettings.currentValue);
    }

    if (requiredPermission?.currentValue) {
      this.hasPermission = this.permissionService.actionAllowed(requiredPermission?.currentValue);
    }
  }

  updateTrafficManagerSettings(currentValue: TrafficManagerFeatures): void {
    this.viewModel.trafficManagementSettings =
      objectHelper.cloneDeep(currentValue.trafficManagementToggle) ??
      this.viewModel.trafficManagementSettings;

    this.viewModel.avoidConflictsAtPoisToggle =
      objectHelper.cloneDeep(currentValue.avoidConflictsAtPoisToggle) ??
      this.viewModel.avoidConflictsAtPoisToggle;

    this.viewModel.backwardDrivingByPathToggle =
      objectHelper.cloneDeep(currentValue.backwardDrivingByPathToggle) ??
      this.viewModel.backwardDrivingByPathToggle;
  }

  generateViewModel(): ConfigurationViewModel {
    return {
      parkingAndCharging: { isToggledOn: false, dateUpdated: null },
      parkingAndChargingLoadedVehicles: { isToggledOn: false, dateUpdated: null },
      waitOnPoi: { isToggledOn: false, dateUpdated: null },
      amaSettings: { isToggledOn: false, dateUpdated: null },
      waitingQueueSettings: { isToggledOn: false, dateUpdated: null },
      graphManagerSettings: { isToggledOn: false, dateUpdated: null },
      graphManagerParkingAndChargingSettings: getDefaultGraphManagerParkingAndChargingSettings(),
      vehicleSmoothingSettings: { isToggledOn: true, dateUpdated: null },
      trafficManagementSettings: { isToggledOn: false, dateUpdated: null },
      backwardDrivingByPathToggle: { isToggledOn: false, dateUpdated: null },
      avoidConflictsAtPoisToggle: { isToggledOn: false, dateUpdated: null },
      missionErrorHandlingDefaults: getMissionErrorHandlingDefaultsDto(),
      loadTypeSettings: getLoadTypeSettingsStateDefault(),
    };
  }

  onChangeParkingAndCharging(value: boolean): void {
    this.saveParkingAndCharging.emit(value);

    if (!value && this.viewModel.waitOnPoi.isToggledOn) {
      this.viewModel.waitOnPoi.isToggledOn = false;
      this.saveWaitOnPoi.emit(this.viewModel.waitOnPoi.isToggledOn);
    }
  }

  onChangeWaitOnPoi(value: boolean): void {
    this.saveWaitOnPoi.emit(value);
  }

  onChangeAmaSettings(value: boolean): void {
    this.saveAmaSettings.emit(value);
  }

  onChangeWaitingQueueSettings(value: boolean): void {
    this.saveWaitingQueueSettings.emit(value);
  }

  onChangeGraphManagerSettings(value: boolean): void {
    this.saveGraphManagerSettings.emit(value);
  }

  onChangeGraphManagerParkingAndChargingSettings(value: GmParkingAndChargingSettingsDto): void {
    this.saveGraphManagerParkingAndChargingSettings.emit(value);
  }

  onChangeVehicleSmoothingSettings(value: boolean): void {
    this.saveVehicleSmoothingSettings.emit(value);
  }

  onChangeTrafficManagementSettings(value: boolean): void {
    this.saveTrafficManagementSettings.emit(value);
  }

  onChangeBackwardDrivingPathBased(value: boolean): void {
    this.saveBackwardDrivingPathBased.emit(value);
  }

  onChangeAvoidConflictAtPois(value: boolean): void {
    this.saveAvoidConflictAtPois.emit(value);
  }

  onEditMissionErrorHandlingDefaults(): void {
    if (this.hasPermission) {
      this.isModalOpen = true;
      this.cdRef.markForCheck();
    }
  }

  onConfirm(data: MissionErrorHandlingDefaultsDto): void {
    this.saveMissionErrorHandlingDefaults.emit(data);
    this.dismissModal();
  }

  dismissModal(): void {
    this.isModalOpen = false;
  }
}
