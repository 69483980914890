/* eslint-disable max-lines */
import { EMPTY_GUID } from 'core/constants';
import {
  AlertNowZoneDto,
  DirectedZoneDto,
  EraserZoneDto,
  InteractionType,
  InteractionZoneDto,
  InteractionZoneTrigger,
  IntersectionZoneDto,
  LimitedCapacityZoneDto,
  NoReplanningZoneDto,
  RestrictedZoneDto,
  SpeedZoneDto,
  TrafficManagementCustomZoneDto,
  ZoneSetEditOption,
  ZoneType,
} from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { GuidString, VehicleGroup, Zone } from 'core/models';
import { Point } from 'pixi.js';
import { ZoneSetMockData } from './zone-sets-mock-data';

const mapId = '03840cd1-a25a-4c33-a7ba-1fc05e3f0212';
const interactionsReadConst = [
  { node: 'Robot Move State', value: 'Idle', type: InteractionType.Read, isMet: true },
];
const interactionWriteConst = [
  { node: 'Robot Interaction', value: 'Idle', type: InteractionType.Write, isMet: true },
];

const zones: [
  RestrictedZoneDto,
  InteractionZoneDto,
  InteractionZoneDto,
  LimitedCapacityZoneDto,
  IntersectionZoneDto,
  InteractionZoneDto,
  TrafficManagementCustomZoneDto
] = [
  {
    id: '1',
    zoneType: ZoneType.Restricted,
    polygon: [
      { x: 0, y: 0 },
      { x: 100, y: 0 },
      { x: 100, y: 100 },
      { x: 0, y: 100 },
    ],
    mapId,
    zoneSetId: ZoneSetMockData.getZoneSetForMap1().id,
    masterZoneId: '1',
  },
  {
    id: '2',
    zoneType: ZoneType.Interaction,
    polygon: [
      { x: 50, y: 21 },
      { x: 36, y: 0 },
      { x: 61, y: 0 },
    ],
    mapId,
    device: 'Device1',
    isActive: true,
    maxNumberOfVehicles: 1,
    allConditionsMet: true,
    deviceIsOnline: true,
    interactions: interactionsReadConst,
    zoneSetId: EMPTY_GUID,
    masterZoneId: '2',
    feedback: [
      {
        node: 'Robot Move State',
        value: 'Idle',
        trigger: InteractionZoneTrigger.ZoneFreed,
      },
    ],
    alertWhenDeactivated: false,
    considerManualVehicles: false,
  },
  {
    id: '3',
    zoneType: ZoneType.Interaction,
    polygon: [
      { x: 50, y: 110 },
      { x: 70, y: 110 },
      { x: 50, y: 50 },
    ],
    mapId,
    device: 'Device1',
    isActive: true,
    maxNumberOfVehicles: 1,
    allConditionsMet: true,
    deviceIsOnline: false,
    interactions: interactionWriteConst,
    zoneSetId: ZoneSetMockData.getZoneSetForMap2().id,
    masterZoneId: '3',
    feedback: [
      {
        node: 'Robot Interaction',
        value: 'Idle',
        trigger: InteractionZoneTrigger.ZoneFreed,
      },
    ],
    alertWhenDeactivated: false,
    considerManualVehicles: false,
  },
  {
    id: '4',
    zoneType: ZoneType.LimitedCapacity,
    polygon: [
      { x: 50, y: 21 },
      { x: 36, y: 0 },
      { x: 61, y: 0 },
    ],
    mapId,
    isActive: true,
    maxNumberOfVehicles: 1,

    zoneSetId: ZoneSetMockData.getZoneSetForMap2().id,
    masterZoneId: '4',
    considerManualVehicles: false,
  },
  {
    id: '5',
    zoneType: ZoneType.Intersection,
    polygon: [
      { x: 50, y: 21 },
      { x: 36, y: 0 },
      { x: 61, y: 0 },
    ],
    mapId,
    isActive: true,
    deviceIsOnline: true,
    device: 'Device1',
    interactions: interactionsReadConst,
    zoneSetId: ZoneSetMockData.getZoneSetForMap2().id,
    masterZoneId: '4',
    maximumWaitingTimeSeconds: 5,
    feedback: [
      {
        node: 'Robot Move State 1',
        value: 'Idle',
        trigger: InteractionZoneTrigger.ZoneFreed,
      },
    ],
    alertWhenDeactivated: false,
    ignoresConvoyCollisions: false,
    onlyAllowVehiclesStoppedOutside: false,
    ignorePriorities: false,
    considerManualVehicles: false,
  },
  {
    id: '6',
    zoneType: ZoneType.Interaction,
    polygon: [
      { x: 50, y: 21 },
      { x: 36, y: 0 },
      { x: 61, y: 0 },
    ],
    mapId,
    device: 'Device1',
    isActive: true,
    maxNumberOfVehicles: 1,
    allConditionsMet: true,
    deviceIsOnline: true,
    interactions: interactionsReadConst,
    zoneSetId: ZoneSetMockData.getZoneSetForMap1().id,
    masterZoneId: '6',
    feedback: [
      {
        node: 'Robot Move State 1',
        value: 'Idle',
        trigger: InteractionZoneTrigger.ZoneFreed,
      },
    ],
    alertWhenDeactivated: false,
    considerManualVehicles: false,
  },
  {
    id: '60',
    zoneType: ZoneType.TrafficManagementCustom,
    polygon: [
      { x: 50, y: 21 },
      { x: 36, y: 0 },
      { x: 61, y: 0 },
    ],
    mapId,
    isActive: true,
    zoneSetId: ZoneSetMockData.getZoneSetForMap1().id,
    masterZoneId: '77',
    ignoresConvoyCollisions: true,
    deactivateTrafficManager: true,
    ignoresDeadlockAreaCollisions: true,
    ignoresForklifts: true,
    ignoresStandingForklifts: true,
    ignoresTuggerTrains: true,
    ignoresStandingTuggerTrains: true,
    ignoresStandingUnitLoads: true,
  },
];

export class ZoneMockData {
  static getZone(): Zone {
    return objectHelper.cloneDeep(zones[0]);
  }

  static getZoneWithZoneSet(): Zone {
    return objectHelper.cloneDeep(zones[2]);
  }

  static getLimitedCapacityZone(): LimitedCapacityZoneDto {
    return objectHelper.cloneDeep(zones[3]);
  }

  static getIntersectionZone(): IntersectionZoneDto {
    return objectHelper.cloneDeep(zones[4]);
  }

  static getTrafficManagementCustomZone(): TrafficManagementCustomZoneDto {
    return objectHelper.cloneDeep(zones[6]);
  }

  static getInteractionZone(): InteractionZoneDto {
    return objectHelper.cloneDeep(zones[1]);
  }

  static getDisconnectedInteractionZone(): InteractionZoneDto {
    return objectHelper.cloneDeep(zones[2]);
  }

  static getDisconnectedInteractionZone2(): InteractionZoneDto {
    return objectHelper.cloneDeep(zones[5]);
  }

  static getSortedZones(): Zone[] {
    return [
      this.getAlertNowZone(),
      this.getEraserZone(),
      this.getRestrictedZone(),
      this.getNoReplanningZone(),
      this.getSpeedLimitZone(),
      this.getDirectedZone(),
      this.getLimitedCapacityZone(),
      this.getInteractionZone(),
      this.getTrafficManagementCustomZone(),
      this.getIntersectionZone(),
    ];
  }

  static getUnorderedZones(): Zone[] {
    return [
      this.getAlertNowZone(),
      this.getInteractionZone(),
      this.getRestrictedZone(),
      this.getSpeedLimitZone(),
      this.getDirectedZone(),
      this.getLimitedCapacityZone(),
      this.getEraserZone(),
      this.getIntersectionZone(),
      this.getNoReplanningZone(),
      this.getTrafficManagementCustomZone(),
    ];
  }

  static getOverlappingZone(): Zone[] {
    return [zones[2]];
  }

  static getInteractionZonePoints(): Point[] {
    return zones[1].polygon.map(p => new Point(p.x, p.y));
  }

  static getZones(): Zone[] {
    return zones;
  }

  static getZonesMasterIds(): GuidString[] {
    return zones.map(masterId => masterId.masterZoneId);
  }

  static getDirectedZone(): DirectedZoneDto {
    return {
      ...zones[0],
      zoneType: ZoneType.PreferredDirection,
      id: '88478204-3a05-4174-a800-6a10e6d8e114',
      orientation: Math.PI,
      alignCost: 1,
      antiAlignCost: 1,
      crossCost: 1,
    };
  }

  static getZoneSetEditOption(): ZoneSetEditOption {
    return {
      id: EMPTY_GUID,
      mapId: EMPTY_GUID,
      isValid: true,
      updateCurrentSet: false,
      description: '',
      name: '',
      actionType: 'save',
    };
  }

  static getAlertNowZone(): AlertNowZoneDto {
    return {
      ...zones[0],
      id: '447cfff0-ddd9-4a4c-97db-933ac9da71e6',
      zoneType: ZoneType.AlertNow,
      zoneName: 'New AlertNow Zone',
      vehicleTypes: [VehicleGroup.Forklift, VehicleGroup.Str],
    };
  }

  static getEraserZone(): EraserZoneDto {
    return {
      ...zones[0],
      id: '448cfff0-ddd9-4a5c-97db-943ac9da71e6',
      zoneType: ZoneType.Eraser,
    };
  }

  static getRestrictedZone(): RestrictedZoneDto {
    return {
      ...zones[0],
      id: '8252a563-f5d0-4bf1-b501-c61e4e89e283',
      zoneType: ZoneType.Restricted,
    };
  }

  static getSpeedLimitZone(): SpeedZoneDto {
    return {
      ...zones[0],
      id: '725448e6-1e8f-4114-8aff-1b4a7be0ddda',
      zoneType: ZoneType.SpeedLimit,
      speed: 1,
    };
  }
  static getNoReplanningZone(): NoReplanningZoneDto {
    return {
      ...zones[0],
      id: 'e83336cc-1ccc-4c9d-9942-16ee31964daf',
      zoneType: ZoneType.NoReplanning,
      isWaitingArea: false,
      ignoresConvoyCollisions: false,
    };
  }
  static getNewZone(): Zone {
    return {
      id: EMPTY_GUID,
      zoneType: ZoneType.Interaction,
      polygon: [
        { x: 50, y: 21 },
        { x: 36, y: 0 },
        { x: 61, y: 0 },
      ],
      mapId,
      isActive: true,
      maxNumberOfVehicles: 0,
      interactions: [],
      device: '',
      zoneSetId: EMPTY_GUID,
      masterZoneId: EMPTY_GUID,
      feedback: [],
      alertWhenDeactivated: false,
      considerManualVehicles: false,
    };
  }
}
