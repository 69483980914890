import { createSelector, MemoizedSelector } from '@ngrx/store';
import { VehicleDto } from 'core/dtos';
import { GuidString, VehicleGroup, VehicleType } from 'core/models';
import { MapVehicleHelper } from 'modules/maps/helpers';
import { HARDWARE_VERSIONS_SUPPORTING_UPDATES } from 'modules/vehicles/vehicle-contants';

import * as fromVehicleFeatureState from '../reducers/index';
import * as fromVehiclesReducer from '../reducers/vehicles.reducer';

const selectVehiclesState = createSelector(
  fromVehicleFeatureState.getVehiclesFeatureState,
  fromVehicleFeatureState.getVehiclesState
);

export const selectVehicleEntities = createSelector(
  selectVehiclesState,
  fromVehiclesReducer.getEntities
);

export const selectAllVehicles = createSelector(
  selectVehiclesState,
  fromVehiclesReducer.getVehicles
);

export const selectVehiclesLoading = createSelector(
  selectVehiclesState,
  fromVehiclesReducer.getLoading
);

export const selectVehiclesLoaded = createSelector(
  selectVehiclesState,
  fromVehiclesReducer.getLoaded
);

export const selectSelectedVehicleId = createSelector(
  selectVehiclesState,
  fromVehiclesReducer.getSelectedVehicleId
);

export const selectSelectedVehicle = createSelector(
  selectSelectedVehicleId,
  selectVehicleEntities,
  (vehicleId, vehicles) => vehicles[String(vehicleId)]
);

export const selectUpdatableVehicles = createSelector(selectAllVehicles, vehicles =>
  vehicles.filter(v => HARDWARE_VERSIONS_SUPPORTING_UPDATES.includes(v.hardwareVersion))
);

export const selectVehicleEntitiesCount = createSelector(selectAllVehicles, vehicles => {
  return vehicles.reduce(
    (acc, vehicle) => {
      if (!vehicle.isSwitchedOff && vehicle.vehicleType !== VehicleType.Forklift) {
        acc.totalNumberOfVehicles++;
        if (vehicle.vehicleType === VehicleType.UnitLoad) {
          acc.totalNumberOfUnitLoads++;
          if (vehicle.isConnected) {
            acc.numberOfConnectedUnitLoads++;
          }
        }
        if (vehicle.vehicleType === VehicleType.TuggerTrain) {
          acc.totalNumberOfTuggertrains++;
          if (vehicle.isConnected) {
            acc.numberOfConnectedTuggertrains++;
          }
        }
      }
      return acc;
    },
    {
      totalNumberOfVehicles: 0,
      totalNumberOfTuggertrains: 0,
      totalNumberOfUnitLoads: 0,
      numberOfConnectedTuggertrains: 0,
      numberOfConnectedUnitLoads: 0,
    }
  );
});

export const selectVehicleConnectionHealthRatio = createSelector(
  selectVehicleEntitiesCount,
  ({
    totalNumberOfTuggertrains,
    totalNumberOfUnitLoads,
    numberOfConnectedTuggertrains,
    numberOfConnectedUnitLoads,
  }) => {
    const OUT_OF_RANGE_NUMBER = 101;
    const MULTIPLIER = 100;

    let connectedUnitLoadRatio = Math.round(
      (numberOfConnectedUnitLoads / totalNumberOfUnitLoads) * MULTIPLIER
    );

    let connectedTuggertrainRatio = Math.round(
      (numberOfConnectedTuggertrains / totalNumberOfTuggertrains) * MULTIPLIER
    );

    connectedUnitLoadRatio = !isNaN(connectedUnitLoadRatio)
      ? connectedUnitLoadRatio
      : OUT_OF_RANGE_NUMBER;

    connectedTuggertrainRatio = !isNaN(connectedTuggertrainRatio)
      ? connectedTuggertrainRatio
      : OUT_OF_RANGE_NUMBER;

    return { connectedUnitLoadRatio, connectedTuggertrainRatio };
  }
);

export const selectVehicleConnectionHealthState = createSelector(
  selectVehicleConnectionHealthRatio,
  ({ connectedUnitLoadRatio, connectedTuggertrainRatio }) => {
    const lowerVehicleConnectionHealth = Math.min(
      connectedTuggertrainRatio,
      connectedUnitLoadRatio
    );

    return MapVehicleHelper.determineVehicleConnectionHealth(lowerVehicleConnectionHealth);
  }
);

export const selectAllActiveVehicles = createSelector(selectAllVehicles, vehicles =>
  vehicles.filter(x => !x.isRetired)
);

export const selectAllActiveVDAVehicles = createSelector(selectAllActiveVehicles, vehicles =>
  vehicles.filter(
    v =>
      MapVehicleHelper.getVehicleGroupByHardwareVersion(v.hardwareVersion) ===
      VehicleGroup.TuggerTrainDs
  )
);

export const selectAllActiveUnitLoadVehicles = createSelector(selectAllActiveVehicles, vehicles =>
  vehicles.filter(v => v.vehicleType === VehicleType.UnitLoad)
);

export const selectAllActiveIdleUnitLoadVehicles = createSelector(
  selectAllActiveUnitLoadVehicles,
  vehicles => vehicles.filter(v => !v.isBusy && v.isConnected)
);

export const selectAllActiveUnitLoadVehiclesWithoutFleet = createSelector(
  selectAllActiveUnitLoadVehicles,
  vehicles => vehicles.filter(v => !v.fleetId)
);

export const selectVehicleById = (
  id: string | GuidString
): MemoizedSelector<fromVehicleFeatureState.VehiclesFeatureState, VehicleDto> =>
  createSelector(
    selectAllActiveVehicles,
    (vehicles: VehicleDto[]) => vehicles.filter(v => v.id === id)[0]
  );

export const selectVehicleErrorMessage = createSelector(
  selectVehiclesState,
  fromVehiclesReducer.getErrorMessage
);
