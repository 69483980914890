<ng-container *ngIf="ipstIsEnabledInWa; else tagTemplate">
  <ds-select
    *ngIf="isEditMode && !isDevice && !noRecipientKeyNoZoneALertNowGroup"
    [isMultiselect]="true"
    [isFilterable]="false"
    [showSelectAll]="false"
    [isLoading]="recipientKeyDropdownLoading"
    [value]="initialDropdownValue"
    [options]="optionList"
    panelStyleClass="recipient-table-dropdown"
    data-cy="recipientDropdown"
    appendTo="body"
    optionValue="id"
    [ds-tooltip]="
      displayTooltip
        ? translationService.get(
            'settings.ipstAlertNowSettings.errorForwardingRecipientKey.errorForwardingEmptyRecipientKeyError'
          )
        : null
    "
    (valueChange)="onChange($event)">
  </ds-select>

  <span *ngIf="!isEditMode">{{ displayNames }}</span>
  <ng-container *ngIf="isDevice && !noRecipientKeyNoZoneALertNowGroup">
    <ds-tag class="ms-3x" [isDismissable]="false" [isInteractive]="false" data-cy="deviceTag">
      {{
        'settings.ipstAlertNowSettings.errorForwardingRecipientKey.devicesDefaultRecipientKey'
          | translate
      }}
    </ds-tag>
  </ng-container>
  <ng-container *ngIf="noRecipientKeyNoZoneALertNowGroup">
    <ds-tag
      class="ms-3x"
      [isDismissable]="false"
      [isInteractive]="false"
      data-cy="noRecipientKeyCreatedTag">
      {{
        'settings.ipstAlertNowSettings.errorForwardingRecipientKey.noRecipientKeyCreated'
          | translate
      }}
    </ds-tag>
  </ng-container>
</ng-container>
<ng-template #tagTemplate>
  <ds-tag class="ms-3x" [isDismissable]="false" [isInteractive]="false" data-cy="defaultTag">
    {{
      'settings.ipstAlertNowSettings.errorForwardingRecipientKey.errorForwardingDeactivated'
        | translate
    }}
  </ds-tag>
</ng-template>
