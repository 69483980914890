import { VehicleDto } from 'core/dtos';
import {
  HardwareVersion,
  HardwareVersionVehicleType,
  VehicleConnectionHealth,
  VehicleGroup,
  VehicleType,
} from 'core/models';
import { DefaultVersionItems } from '../layers';

type ConfiguredMapHardwareVersions = Exclude<HardwareVersion, HardwareVersion.Unknown>;

const VehicleGroupByHardwareVersion: Record<ConfiguredMapHardwareVersions, VehicleGroup> = {
  [HardwareVersion.StrVersion2]: VehicleGroup.Str,
  [HardwareVersion.StrVersion3]: VehicleGroup.Str,
  [HardwareVersion.StrVersion4]: VehicleGroup.Str,
  [HardwareVersion.TuggerTrainFourAmV2]: VehicleGroup.TuggerTrainFourAmV2,
  [HardwareVersion.TuggerTrainDsV1]: VehicleGroup.TuggerTrainDs,
  [HardwareVersion.ForkliftFourAm_15t]: VehicleGroup.Forklift,
  [HardwareVersion.ForkliftFourAm_20t]: VehicleGroup.Forklift,
  [HardwareVersion.TuggerTrainManual]: VehicleGroup.Manual,
  [HardwareVersion.ForkliftManual]: VehicleGroup.Manual,
  [HardwareVersion.DSType1]: VehicleGroup.U_AGV,
  [HardwareVersion.DSType2]: VehicleGroup.U_AGV,
  [HardwareVersion.DSType3]: VehicleGroup.U_AGV,
  [HardwareVersion.DSType4]: VehicleGroup.U_AGV,
  [HardwareVersion.DSType5]: VehicleGroup.U_AGV,
};

const VehicleGroupsByVehicleType: Record<VehicleType, VehicleGroup[]> = {
  [VehicleType.UnitLoad]: [VehicleGroup.USTR, VehicleGroup.Str],
  [VehicleType.Forklift]: [VehicleGroup.Forklift],
  [VehicleType.TuggerTrain]: [VehicleGroup.TuggerTrainDs, VehicleGroup.TuggerTrainFourAmV2],
  [VehicleType.U_AGV]: [VehicleGroup.U_AGV],
};

export class MapVehicleHelper {
  static getDefaultHardwareVersion(vehicle: VehicleDto): HardwareVersion {
    if (
      vehicle.hardwareVersion === undefined ||
      vehicle.hardwareVersion === HardwareVersion.Unknown ||
      HardwareVersionVehicleType[vehicle.hardwareVersion] !== vehicle.vehicleType
    ) {
      console.warn(`Vehicle ${vehicle.name} has unknown Hardware Version`);

      return DefaultVersionItems[vehicle.vehicleType];
    }

    return vehicle.hardwareVersion;
  }

  static getVehicleGroupByHardwareVersion(hardwareVersion: HardwareVersion): VehicleGroup {
    return VehicleGroupByHardwareVersion[hardwareVersion] ?? VehicleGroup.Unknown;
  }

  static getVehicleTypeByVehicleGroup(VehicleType: VehicleType): VehicleGroup[] {
    return VehicleGroupsByVehicleType[VehicleType] ?? [VehicleGroup.Unknown];
  }

  static compareHardwareVersionAndVehicleGroup(
    hardwareVersion: HardwareVersion,
    vehicleGroup: VehicleGroup
  ): boolean {
    return vehicleGroup === MapVehicleHelper.getVehicleGroupByHardwareVersion(hardwareVersion);
  }

  static determineVehicleConnectionHealth(percentage: number): VehicleConnectionHealth {
    const CRITICAL_THRESHOLD = 80;
    const CAUTION_THRESHOLD = 90;

    if (percentage >= CRITICAL_THRESHOLD && percentage < CAUTION_THRESHOLD) {
      return VehicleConnectionHealth.Cautious;
    }
    if (percentage < CRITICAL_THRESHOLD) {
      return VehicleConnectionHealth.Critical;
    }

    return VehicleConnectionHealth.Default;
  }
}
