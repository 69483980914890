import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { Store, select } from '@ngrx/store';
import { ErrorForwardingService, ZonesService } from 'core/api-services';
import { VehicleDto, ZoneSetDto } from 'core/dtos';
import { HelpToolFilterDto, Zone, ZoneSetStatus } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { Icons } from 'library/constants';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import * as fromMaps from 'store-modules/maps-store';
import * as fromVehicles from 'store-modules/vehicles-store';
import * as fromRoot from 'store/reducers';

@Component({
  selector: 'app-ipst-alert-now-help-tool',
  templateUrl: './ipst-alert-now-help-tool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './ipst-alert-now-help-tool.component.scss',
})
export class IpstAlertNowHelpToolComponent implements OnInit, OnDestroy {
  resetIcon = Icons.Reset;
  resetValues = false;
  allVehicles$: Observable<VehicleDto[]> = of([]);
  zones$: Observable<(Zone | undefined)[]> = of([]);
  tableErrorForwarding$: Observable<SelectListOption[]> = of([]);
  allZoneSets$: Observable<ZoneSetDto[] | undefined>;
  recipientKeys: Observable<string[]> = of([]);

  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly vehiclesStore: Store<fromVehicles.VehiclesFeatureState>,
    protected readonly rootStore: Store<fromRoot.RootState>,
    private readonly errorForwardingService: ErrorForwardingService,
    private readonly zonesService: ZonesService,
    private readonly atsTranslateService: AtsTranslationService
  ) {
    this.allZoneSets$ = this.rootStore.pipe(select(fromMaps.selectAllZoneSets));
  }

  ngOnInit(): void {
    this.atsTranslateService.currentLanguage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadData();
    });
  }

  resetDropdowns(): void {
    this.resetValues = !this.resetValues;
    this.recipientKeys = of([]);
  }

  loadData(): void {
    this.allVehicles$ = this.vehiclesStore.pipe(select(fromVehicles.selectAllActiveVehicles));
    this.tableErrorForwarding$ = this.errorForwardingService.getErrorForwardings().pipe(
      map(error => {
        return error.map(m => {
          return {
            disabled: !m.sendNotification,
            label:
              this.atsTranslateService.currentLang === 'en'
                ? m.descriptionEn ?? m.description
                : m.descriptionDe ?? m.description,
            id: m.type,
          };
        });
      })
    );

    this.zones$ = this.allZoneSets$.pipe(
      map(zoneSets => zoneSets?.filter(zoneSet => zoneSet.status === ZoneSetStatus.Active) || []),
      switchMap(activeZoneSets => {
        const zoneObservables = activeZoneSets.map(zoneSet =>
          this.zonesService.getZonesByMapId(zoneSet.mapId)
        );
        return forkJoin(zoneObservables);
      }),
      map(zonesArray => zonesArray.map(z => z).flat())
    );
  }

  onRetrieveRecipientKeys(filter: HelpToolFilterDto): void {
    this.recipientKeys = this.errorForwardingService.getHelpToolRecipientKeys({
      zoneId: filter.zoneId,
      vehicleId: filter.vehicleId,
      errorNotificationType: filter.errorNotificationType,
      workingAreaId: filter.workingAreaId,
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

export interface ErrorForwardingOptionListModel {
  disabled?: boolean;
}
