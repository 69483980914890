export enum MissionTraceTrigger {
  None = 0,
  JobInstruction = 1,
  AdhocInstructionFromMission = 2,
  AdhocInstructionFromStep = 3,
  SystemParkingInstruction = 4,
  SystemChargingInstruction = 5,
  SystemWaitingQueueInstruction = 6,
  AdhocInstructionFromProcessChain = 7,
  Sap = 8,
  SystemBreakTestInstruction = 9,
  AdHocFromTourConfig = 10,
  BeginShift = 11,
  LongRunning = 12,
}

export enum MissionTraceTriggerDisplayFilter {
  Production = 0,
  Manual = 1,
  ParkingCharging = 2,
  WaitingQueue = 3,
  LongRunning = 4,
  BeginShift = 5,
}

export enum MissionStatus {
  WaitingForAssignment = -3,
  QueuedOnJobProcessor = -2,
  QueuedOnSystem = -1,
  PendingDispatch = 7,
  SentToRobot = 8,
  QueuedOnRobot = 0,
  InProgress = 2,
  Completed = 3,
  Failed = 4,
  Aborted = 5,
  CompletedWithSupport = 6,
  Interrupted = 9,
  InterruptionFailed = 10,
  Sleeping = 11,
  WaitingForZoneSetUpdate = 14,
  AbortedWithSupport = 55,
}

export enum EventType {
  Process = 0,
  Connection = 1,
  Ama = 2,
  MissionAbort = 3,
}

export enum MissionDeliveryStatus {
  Overdue = -1,
  DueShortly = 0,
  Due = 1,
  NotUrgent = 2,
  Normal = 3,
  NotUrgentPriority = 4,
  DuePriority = 5,
  DueShortlyPriority = 6,
  ValidationError = 7,
  UnsupportedVehicleAction = 8,
  VehicleOutDatedOrder = 9,
}

export enum MissionPriorityLevel {
  None = 0,
  Normal = 5,
  Interlock = 10,
  Group = 20,
}

export enum MissionAssignmentConditions {
  Unknown = 0,
  VehicleOnAssignableWaypoint = 1,
  VehicleBelongsToFleet = 2,
  WaitingQueue = 3,
}

export enum MissionDisplayHighlightLevel {
  None = 0,
  Info = 1,
  Warning = 2,
}

export enum MissionCategory {
  Organizational = 1,
  CallOffSystem = 2,
  Vehicle = 3,
  Services = 4,
  NotRelevant = 6,
  SystemOptimization = 7,
  FacilityMaterialsHandlingTechnology = 8,
}

export enum MissionLogSeverity {
  Info = 'Info',
  Error = 'Error',
}

export enum MissionToolbarButton {
  AbortMissions = 0,
  CommentOnMissions = 1,
}

export enum CommentModalType {
  Tugger = 1,
  Other = 2,
  Default = 3,
  DsTugger = 4,
  Forklift = 5,
  None = 6,
}

export enum MissionFailureReasonStatus {
  Inactive = 0,
  Active = 1,
}

export enum MissionFormat {
  ROS = 0,
  VDA5050 = 1,
}
