import { createAction, props } from '@ngrx/store';
import { ErrorForwardingModel } from 'core/models';

export enum ErrorForwardingTypes {
  LoadErrorForwarding = '[ErrorForwarding] Load ErrorForwarding',
  LoadErrorForwardingSuccess = '[ErrorForwarding] Load ErrorForwarding Success',
  LoadErrorForwardingFailure = '[ErrorForwarding] Load ErrorForwarding Fail',

  SaveErrorForwarding = '[ErrorForwarding] Save ErrorForwarding',
  SaveErrorForwardingSuccess = '[ErrorForwarding] Save ErrorForwarding Success',
  SaveErrorForwardingFailure = '[ErrorForwarding] Save ErrorForwarding Fail',
}

export const loadErrorForwarding = createAction(ErrorForwardingTypes.LoadErrorForwarding);

export const loadErrorForwardingSuccess = createAction(
  ErrorForwardingTypes.LoadErrorForwardingSuccess,
  props<{ errorForwardings: ErrorForwardingModel[] }>()
);

export const loadErrorForwardingFailure = createAction(
  ErrorForwardingTypes.LoadErrorForwardingFailure,
  props<{ errorMessage: string }>()
);

export const saveErrorForwarding = createAction(
  ErrorForwardingTypes.SaveErrorForwarding,
  props<{ errorForwardingModel: ErrorForwardingModel[] }>()
);

export const saveErrorForwardingSuccess = createAction(
  ErrorForwardingTypes.SaveErrorForwardingSuccess,
  props<{ errorForwardingModel: ErrorForwardingModel[] }>()
);

export const saveErrorForwardingFailure = createAction(
  ErrorForwardingTypes.SaveErrorForwardingFailure,
  props<{ errorMessage: string }>()
);
