/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  ArchivedTourDto,
  LayoutDto,
  MissionTraceDto,
  NodeDto,
  ODataDto,
  RouteConfigurationDto,
  RuleDto,
  TourChain,
  TourChainConfigurationDto,
  TourChainDto,
  TourChainStatus,
  TourStepType,
  TourTriggerType,
} from 'core/dtos';
import { convertMissionTraceDtoToModel } from 'core/helpers';
import objectHelper from 'core/helpers/object.helper';
import {
  DeviceSubStepType,
  GuidString,
  HardwareVersion,
  LoadType,
  MissionDeliveryStatus,
  MissionDisplayHighlightLevel,
  MissionFormat,
  MissionPriorityLevel,
  MissionStatus,
  MissionTrace,
  MissionTraceTrigger,
  NodeOccupancyStatus,
  RuleType,
  StepType,
  StepTypeBackend,
  TourConfigurationModel,
  VehicleType,
} from 'core/models';
import { cloneDeep } from 'lodash';
import { MissionActionConditionForm } from 'modules/jobs/process-configurator/components/process-chain-details-edit/process-chain-details-edit.model';
import {
  DropStepForm,
  GotoStepForm,
  LiftStepForm,
  NodeControlFormModel,
  StepForm,
  TourChainConfigForm,
  TourConfigurationForm,
  WaitForEndOfStepStepForm,
} from 'modules/jobs/tour-chain-configurator/components/tour-chain-config-details-edit/tour-chain-config-details-edit.model';

const allTourChainConfigs: TourChainConfigurationDto[] = [
  {
    id: 'tourChainId',
    workAreaId: 'wa',
    name: 'Tour Chain 1',
    sapInternalRoute: 'sap route',
    enableRepetitiveTour: false,
    triggerType: TourTriggerType.SAP,

    vehicleType: VehicleType.TuggerTrain,
    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    hardwareVersion: HardwareVersion.TuggerTrainDsV1,
    tourConfigurations: [
      {
        id: 'tourId',
        name: 'Tour 1',
        routeConfigurationId: 'routeConfigurationId',
        assignableNodeId: '',
        fuelingConfiguration: null,
        pauseConditions: [],
        steps: [
          {
            sequenceId: 0,
            nodeId: 'nodeId1',
            stepType: 1,
            nodeGroupId: '',
            hasArrivalNotificationEnabled: false,
            raiseEventId: null,
          },
          {
            sequenceId: 1,
            stepType: 2,
            hasArrivalNotificationEnabled: false,
            raiseEventId: null,
          },
        ],
      },
    ],
  },
  {
    id: 'tourChainId2',
    workAreaId: 'wa',
    name: 'Tour Chain 12',
    sapInternalRoute: 'sap route',
    enableRepetitiveTour: false,
    triggerType: TourTriggerType.SAP,

    vehicleType: VehicleType.Forklift,

    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    hardwareVersion: HardwareVersion.TuggerTrainDsV1,
    tourConfigurations: [
      {
        id: 'tourId2',
        name: 'Tour 12',
        routeConfigurationId: 'routeConfigurationId',
        assignableNodeId: '',
        fuelingConfiguration: null,
        steps: [
          {
            sequenceId: 0,
            nodeId: 'nodeId1',
            stepType: 1,
            nodeGroupId: '',
            hasArrivalNotificationEnabled: false,
            raiseEventId: null,
          },
          {
            sequenceId: 1,
            stepType: 2,
            hasArrivalNotificationEnabled: false,
            raiseEventId: null,
          },
        ],
        pauseConditions: [
          {
            device: 'Device1',
            nodeName: 'nodeId1',
            stepNumber: 1,
            value: 'True',
          },
        ],
      },
    ],
  },
  {
    id: 'tourChainId3',
    workAreaId: 'wa',
    name: 'Tour Chain 123',
    sapInternalRoute: 'sap route',
    enableRepetitiveTour: false,
    triggerType: TourTriggerType.BeginShiftTour,

    vehicleType: VehicleType.TuggerTrain,

    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    hardwareVersion: HardwareVersion.TuggerTrainDsV1,
    tourConfigurations: [
      {
        id: 'tourId3',
        name: 'Tour 123',
        routeConfigurationId: 'routeConfigurationId',
        assignableNodeId: '',
        fuelingConfiguration: null,
        steps: [
          {
            sequenceId: 0,
            nodeId: 'nodeId1',
            stepType: 1,
            nodeGroupId: '',
            hasArrivalNotificationEnabled: false,
            raiseEventId: null,
          },
          {
            sequenceId: 1,
            stepType: 2,
            hasArrivalNotificationEnabled: false,
            raiseEventId: null,
          },
        ],
        pauseConditions: [],
      },
    ],
  },
];

const allTourChainConfiguration: TourChainConfigurationDto[] = [
  {
    id: 'f535e70b-8f53-4be5-7299-08dc177e587c',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: 'ForkliftTour1',
    sapInternalRoute: '',
    deviceName: 'device-xray',
    deviceNodeName: 'Signal1',
    deviceNodeValue: 'TRUE',
    enableRepetitiveTour: false,
    triggerType: 1,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: 'f535e70b-8f53-4be5-7299-08dc177e587c',
        name: 'ForkliftTour1',
        assignableNodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        routeConfigurationId: null,
        fuelingConfiguration: null,
        steps: [
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-tower-3_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 3,
            sequenceId: 1,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-container-lane-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 2,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 4,
            sequenceId: 3,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-tower-3_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 4,
            raiseEventId: null,
          },
        ],
        pauseConditions: [
          {
            stepNumber: 1,
            device: 'device-xray',
            nodeName: 'Signal1',
            value: 'TRUE',
          },
          {
            stepNumber: 1,
            device: 'device-xray',
            nodeName: 'Signal2',
            value: 'FALSE',
          },
        ],
      },
    ],
  },
  {
    id: '86e916d5-5337-4726-7538-08dc1cb558e3',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: 'ObsidianDemo_Device',
    sapInternalRoute: '',
    deviceName: 'device-xray',
    deviceNodeName: 'Output 1',
    deviceNodeValue: 'TRUE',
    enableRepetitiveTour: false,
    triggerType: 4,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: '86e916d5-5337-4726-7538-08dc1cb558e3',
        name: 'ObsidianDemo_Device',
        routeConfigurationId: null,
        assignableNodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        fuelingConfiguration: null,
        steps: [
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-tower-4_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
          {
            device: 'device-xray',
            streamingService: '',
            substeps: [
              {
                nodeName: 'Signal1',
                value: 'TRUE',
                type: 0,
                state: 0,
                updatedDateTime: '2024-02-27T10:13:52.7411332Z',
                sequenceNumber: 1,
              },
            ],
            stepType: 5,
            sequenceId: 1,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 3,
            sequenceId: 2,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-dispose-area_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 3,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 4,
            sequenceId: 4,
            raiseEventId: null,
          },
          {
            preStorageLocation: 'dispose_station_0',
            stepType: 6,
            sequenceId: 5,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 6,
            raiseEventId: null,
          },
        ],
        pauseConditions: [
          {
            stepNumber: 3,
            device: 'device-xray',
            nodeName: 'Signal2',
            value: 'TRUE',
          },
        ],
      },
    ],
  },
  {
    id: '8912718c-3cd1-43a3-f5c7-08dc1d638ad4',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: 'ObsidianDemo_Manual',
    sapInternalRoute: '',
    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    enableRepetitiveTour: false,
    triggerType: 1,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: '8912718c-3cd1-43a3-f5c7-08dc1d638ad4',
        name: 'ObsidianDemo_Manual',
        routeConfigurationId: null,
        assignableNodeId: 'N_waypoint-4_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        fuelingConfiguration: null,
        steps: [
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
        ],
        pauseConditions: [],
      },
    ],
  },
  {
    id: '0e4a352f-e37a-4d5b-d12c-08dc2400b387',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: '_heightCheck',
    sapInternalRoute: '',
    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    enableRepetitiveTour: false,
    triggerType: 1,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: '0e4a352f-e37a-4d5b-d12c-08dc2400b387',
        name: '_heightCheck',
        routeConfigurationId: null,
        assignableNodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        fuelingConfiguration: null,
        steps: [
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-container-lane-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 3,
            sequenceId: 1,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-container-lane-2_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 2,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 4,
            sequenceId: 3,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-dispose-area_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 4,
            raiseEventId: null,
          },
          {
            height: 2.25,
            stepType: 3,
            sequenceId: 5,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-container-lane-3_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 6,
            raiseEventId: null,
          },
          {
            height: 5.0,
            stepType: 4,
            sequenceId: 7,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: true,
            nodeId: 'N_node-container-lane-4_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 8,
            raiseEventId: null,
          },
        ],
        pauseConditions: [],
      },
    ],
  },
  {
    id: '2d15d952-b850-4f12-ceae-08dc3dd72da4',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: 'LogCheck',
    sapInternalRoute: '',
    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    enableRepetitiveTour: false,
    triggerType: 1,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: '2d15d952-b850-4f12-ceae-08dc3dd72da4',
        name: 'LogCheck',
        routeConfigurationId: null,
        assignableNodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        fuelingConfiguration: null,
        steps: [
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-tower-4_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 3,
            sequenceId: 1,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-dispose-area_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 2,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 4,
            sequenceId: 3,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 4,
            raiseEventId: null,
          },
        ],
        pauseConditions: [],
      },
    ],
  },
  {
    id: '13292a29-6e69-468e-1aec-08dc4348dccc',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: '_reset_forklift',
    sapInternalRoute: '',
    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    enableRepetitiveTour: false,
    triggerType: 1,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: '13292a29-6e69-468e-1aec-08dc4348dccc',
        name: '_reset_forklift',
        assignableNodeId: 'N_waypoint-4_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        routeConfigurationId: null,
        fuelingConfiguration: null,
        steps: [
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
        ],
        pauseConditions: [],
      },
    ],
  },
  {
    id: '540e9443-e9ff-49ee-a988-08dc4a51780e',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: 'VehicleType',
    sapInternalRoute: '',
    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    enableRepetitiveTour: false,
    triggerType: 1,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: '540e9443-e9ff-49ee-a988-08dc4a51780e',
        name: 'VehicleType',
        assignableNodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        fuelingConfiguration: null,
        routeConfigurationId: null,
        steps: [
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-container-lane-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
        ],
        pauseConditions: [
          {
            stepNumber: 0,
            device: 'device-xray',
            nodeName: 'Signal1',
            value: 'TRUE',
          },
        ],
      },
    ],
  },
  {
    id: 'b3f3cbe9-ad94-4fe3-9d3f-08dc555cb7a4',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: 'DoubleLift',
    sapInternalRoute: '',
    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    enableRepetitiveTour: false,
    triggerType: 1,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: 'b3f3cbe9-ad94-4fe3-9d3f-08dc555cb7a4',
        name: 'DoubleLift',
        assignableNodeId: 'N_waypoint-4_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        fuelingConfiguration: null,
        routeConfigurationId: null,
        steps: [
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 3,
            sequenceId: 1,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 3,
            sequenceId: 2,
            raiseEventId: null,
          },
        ],
        pauseConditions: [],
      },
    ],
  },
  {
    id: 'ee7226db-0105-42de-f0d9-08dc5956475e',
    workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
    name: 'FailDueToLift',
    sapInternalRoute: '',
    deviceName: null,
    deviceNodeName: null,
    deviceNodeValue: null,
    enableRepetitiveTour: false,
    triggerType: 1,
    vehicleType: 2,
    hardwareVersion: 0,
    tourConfigurations: [
      {
        id: 'ee7226db-0105-42de-f0d9-08dc5956475e',
        name: 'FailDueToLift',
        assignableNodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
        fuelingConfiguration: null,
        routeConfigurationId: null,
        steps: [
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-container-lane-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 0,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 3,
            sequenceId: 1,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 3,
            sequenceId: 2,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-dispose-area_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 3,
            raiseEventId: null,
          },
          {
            height: null,
            stepType: 4,
            sequenceId: 4,
            raiseEventId: null,
          },
          {
            hasArrivalNotificationEnabled: false,
            nodeId: 'N_node-charging-1_d60e745b-704a-4fe8-bd12-612b5a0699a5',
            nodeGroupId: null,
            stepType: 0,
            sequenceId: 5,
            raiseEventId: null,
          },
        ],
        pauseConditions: [],
      },
    ],
  },
];

const forkliftDeviceTriggerTourConfiguration: TourChainConfigurationDto = {
  id: '5dfb265f-a8bc-4786-b944-55900b49468a',
  workAreaId: 'e42b1bdd-02f0-4a13-8fc3-4a7de682d675',
  name: 'Forklift_DeviceTrigger_TourConfiguration',
  sapInternalRoute: '',
  enableRepetitiveTour: false,
  triggerType: TourTriggerType.Device,
  vehicleType: VehicleType.Forklift,
  deviceName: 'Ama2',
  deviceNodeName: 'AMA Ping',
  deviceNodeValue: 'false',
  hardwareVersion: null,
  tourConfigurations: [
    {
      id: '5dfb265f-a8bc-4786-b944-55900b49468q',
      name: 'Forklift_DeviceTrigger_TourConfiguration',
      routeConfigurationId: '',
      assignableNodeId: 'N_node-charging-1_f27bde80-52b1-4758-af76-7e150a354bb1',
      fuelingConfiguration: null,
      steps: [
        {
          sequenceId: 0,
          stepType: TourStepType.WaitForDevice,
          substeps: [
            {
              sequenceNumber: 0,
              type: DeviceSubStepType.Read,
              nodeName: 'PLC_OUT[1]__GATE_OPEN',
              value: 'true',
            },
          ],
          device: 'container-tower',
          streamingService: 'streaming_service',
          raiseEventId: null,
        },
        {
          sequenceId: 1,
          stepType: TourStepType.Goto,
          nodeId: 'N_node-tower-1_2a0e9d75-f6e3-44f0-8e3b-d83e5688f9f2',
          nodeGroupId: null,
          hasArrivalNotificationEnabled: false,
          raiseEventId: null,
        },
        {
          sequenceId: 2,
          stepType: TourStepType.Pick,
          height: null,
          raiseEventId: null,
        },
        {
          sequenceId: 3,
          stepType: TourStepType.Goto,
          nodeId: 'N_node-dispose-area_2a0e9d75-f6e3-44f0-8e3b-d83e5688f9f2',
          nodeGroupId: null,
          hasArrivalNotificationEnabled: false,
          raiseEventId: null,
        },
        {
          sequenceId: 4,
          stepType: TourStepType.Drop,
          height: null,
          raiseEventId: null,
        },
        {
          sequenceId: 5,
          stepType: TourStepType.WaitForSapAcknowledgement,
          preStorageLocation: 'pre_storage_location',
          raiseEventId: null,
        },
      ],
      pauseConditions: [],
    },
  ],
};

const dto: MissionTraceDto = {
  id: 'da4efbc7-11b3-45bc-b05b-11b9a8693415',
  missionId: '00000000-0000-0000-0000-000000000005',
  vehicleId: 'dq1230bb-f15a-4fc9-a163-8133471fe4e7',
  vehicleName: 'DS Tugger',
  vehicleType: VehicleType.TuggerTrain,
  missionName: 'Blue Route',
  status: 2,
  currentStepSequenceNumber: 0,
  stepTraces: [
    {
      sourceStep: {
        pointOfInterestName: 'Park 04',
        sequenceNumber: 0,
        type: StepTypeBackend.Goto,
        hasStepCompletionNotificationEnabled: false,
        pointOfInterestGroupId: ``,
        trajectory: [],
        mappingType: undefined,
        pointOfInterestId: undefined,
      },
      succeeded: false,
      startedDateTime: '2021-02-04T14:38:01.9838377Z',
      stoppedDateTime: '2021-02-04T14:38:59.1290833Z',
      attributeText: '',
    },
  ],
  missionStepNo: '1/5',
  createdDateTime: '2020-06-30T15:43:24.6975014Z',
  updatedDateTime: '2020-06-30T15:43:24.6975014Z',
  forecastedEndTime: '2020-07-31T15:43:24.6975014Z',
  trigger: 8,
  materialNumber: '',
  processChainTraceId: '',
  processChainName: '',
  mapId: '7e42a595-074e-4a55-8831-57dd626bea7f',
  failureComment: {
    missionFailureReasonId: '',
    missionFailureReasonEn: '',
    missionFailureReasonDe: '',
    missionFailureMinutesToSolve: 0,
    missionFailureReasonComments: '',
    missionFailureMinutesForEmergencyProcess: null,
    missionFailureLocationId: null,
    missionFailureShiftGroupId: null,
    durationOfMissionInFailed: null,
    missionFailureEndDateTime: null,
    missionFailureStartDateTime: null,
    missionFailureErrorClass: undefined,
    missionFailureReasonDate: undefined,
  },

  deliveryStatus: 3,
  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  currentStepType: 0,
  stepCount: 2,
  currentStepAttributeText: 'Park 04',
  assignableWaypointId: null,
  assignmentConditions: [],
  canBeContinuedFromStep: false,
  canBeReAssigned: false,
  isAlarmForLateDeliveryActive: false,
  isAssignedToVehicle: false,
  isMaxExecutionTimeActive: false,
  lateDeliveryAlarmTime: 0,
  maxExecutionTime: 0,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
  processChainId: ``,
  assignableWaypointName: undefined,
  destination: undefined,
  fleetId: undefined,
  fleetName: undefined,
  missionStep: undefined,
  provisioningTime: ``,
  source: undefined,
  tourSteps: [],
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndDateTime: '',
  missionFormat: MissionFormat.VDA5050,
  eventTraces: [],
  interlockDetails: [],
  loadType: LoadType.Glt1400X1250,
  tourChainId: null,
  tourChainName: null,
  scanValue: null,
};

const missionTraceModels: MissionTrace[] = [
  {
    id: 'da4efbc7-11b3-45bc-b05b-11b9a8693415',
    missionId: '00000000-0000-0000-0000-000000000005',
    vehicleId: 'dq1230bb-f15a-4fc9-a163-8133471fe4e7',
    vehicleName: 'DS Tugger',
    vehicleType: VehicleType.TuggerTrain,
    missionName: 'Blue Route',
    status: 2,
    currentStepSequenceNumber: 0,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: StepType.Goto,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: ``,
          trajectory: [],
          mappingType: undefined,
          pointOfInterestId: undefined,
        },
        succeeded: false,
        startedDateTime: '2021-02-04T14:38:01.9838377Z',
        stoppedDateTime: '2021-02-04T14:38:59.1290833Z',
        attributeText: '',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: '2020-06-30T15:43:24.6975014Z',
    updatedDateTime: '2020-06-30T15:43:24.6975014Z',
    forecastedEndTime: '2020-07-31T15:43:24.6975014Z',
    trigger: 8,
    materialNumber: '',
    processChainTraceId: '',
    processChainName: '',
    mapId: '7e42a595-074e-4a55-8831-57dd626bea7f',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: null,
      missionFailureEndDateTime: null,
      missionFailureStartDateTime: null,
      missionFailureErrorClass: undefined,
      missionFailureReasonDate: undefined,
    },

    deliveryStatus: 3,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    currentStepType: 0,
    stepCount: 2,
    currentStepAttributeText: 'Park 04',
    assignableWaypointId: null,
    assignmentConditions: [],
    canBeContinuedFromStep: false,
    canBeReAssigned: false,
    isAlarmForLateDeliveryActive: false,
    isAssignedToVehicle: false,
    isMaxExecutionTimeActive: false,
    lateDeliveryAlarmTime: 0,
    maxExecutionTime: 0,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
    processChainId: ``,
    assignableWaypointName: undefined,
    destination: undefined,
    fleetId: undefined,
    fleetName: undefined,
    missionStep: undefined,
    provisioningTime: ``,
    source: undefined,
    tourSteps: [],
    completedDateTime: `2020-06-30T15:43:24.6975014Z`,
    currentStepTypeFrontEnd: StepType.Goto,
    hasManualSupport: false,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.VDA5050,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
    scanValue: null,
  },
];

const tours: MissionTraceDto[] = [
  {
    id: 'da4efbc7-11b3-45bc-b05b-11b9a8693415',
    missionId: '00000000-0000-0000-0000-000000000005',
    vehicleId: 'dq1230bb-f15a-4fc9-a163-8133471fe4e7',
    vehicleName: 'Forklift',
    vehicleType: VehicleType.Forklift,
    missionName: 'Tour Chain Completed',
    status: MissionStatus.Completed,
    currentStepSequenceNumber: 0,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: StepTypeBackend.Goto,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: ``,
          trajectory: [],
          mappingType: undefined,
          pointOfInterestId: undefined,
        },
        succeeded: false,
        startedDateTime: '2021-02-04T14:38:01.9838377Z',
        stoppedDateTime: '2021-02-04T14:38:59.1290833Z',
        attributeText: '',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: '2020-06-30T15:43:24.6975014Z',
    updatedDateTime: '2020-06-30T15:43:24.6975014Z',
    forecastedEndTime: '2020-07-31T15:43:24.6975014Z',
    trigger: MissionTraceTrigger.Sap,
    materialNumber: '',
    processChainTraceId: '',
    processChainName: '',
    mapId: '7e42a595-074e-4a55-8831-57dd626bea7f',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: null,
      missionFailureEndDateTime: null,
      missionFailureStartDateTime: null,
      missionFailureErrorClass: undefined,
      missionFailureReasonDate: undefined,
    },

    deliveryStatus: MissionDeliveryStatus.Normal,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    currentStepType: 0,
    stepCount: 2,
    currentStepAttributeText: 'Park 04',
    assignableWaypointId: null,
    assignmentConditions: [],
    canBeContinuedFromStep: false,
    canBeReAssigned: false,
    isAlarmForLateDeliveryActive: false,
    isAssignedToVehicle: false,
    isMaxExecutionTimeActive: false,
    lateDeliveryAlarmTime: 0,
    maxExecutionTime: 0,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
    processChainId: ``,
    assignableWaypointName: undefined,
    destination: undefined,
    fleetId: undefined,
    fleetName: undefined,
    missionStep: undefined,
    provisioningTime: ``,
    source: undefined,
    tourSteps: [],
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.VDA5050,
    eventTraces: [],
    interlockDetails: [],
    loadType: LoadType.Unknown,
    tourChainId: 'da4efbc7-11b3-45bc-b05b-11b9a8693415',
    tourChainName: 'Fork Lift Tour Chain',
    scanValue: null,
  },
  {
    id: 'da4efbc7-11b3-45bc-b05b-11b9a8693416',
    missionId: '00000000-0000-0000-0000-000000000006',
    vehicleId: 'dq1230bb-f15a-4fc9-a163-8133471fe4e8',
    vehicleName: 'Forklift 2',
    vehicleType: VehicleType.Forklift,
    missionName: 'Tour Chain In Progress',
    status: 2,
    currentStepSequenceNumber: 0,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: StepTypeBackend.Goto,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: ``,
          trajectory: [],
          mappingType: undefined,
          pointOfInterestId: undefined,
        },
        succeeded: false,
        startedDateTime: '2021-02-04T14:38:01.9838377Z',
        stoppedDateTime: '2021-02-04T14:38:59.1290833Z',
        attributeText: '',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: '2020-06-30T15:43:24.6975014Z',
    updatedDateTime: '2020-06-30T15:43:24.6975014Z',
    forecastedEndTime: '2020-07-31T15:43:24.6975014Z',
    trigger: 8,
    materialNumber: '',
    processChainTraceId: '',
    processChainName: '',
    mapId: '7e42a595-074e-4a55-8831-57dd626bea7f',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: null,
      missionFailureEndDateTime: null,
      missionFailureStartDateTime: null,
      missionFailureErrorClass: undefined,
      missionFailureReasonDate: undefined,
    },
    deliveryStatus: 3,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    currentStepType: 0,
    stepCount: 2,
    currentStepAttributeText: 'Park 04',
    assignableWaypointId: null,
    assignmentConditions: [],
    canBeContinuedFromStep: false,
    canBeReAssigned: false,
    isAlarmForLateDeliveryActive: false,
    isAssignedToVehicle: false,
    isMaxExecutionTimeActive: false,
    lateDeliveryAlarmTime: 0,
    maxExecutionTime: 0,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
    processChainId: ``,
    assignableWaypointName: undefined,
    destination: undefined,
    fleetId: undefined,
    fleetName: undefined,
    missionStep: undefined,
    provisioningTime: ``,
    source: undefined,
    tourSteps: [],
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.VDA5050,
    eventTraces: [],
    interlockDetails: [],
    loadType: LoadType.Unknown,
    tourChainId: 'da4efbc7-11b3-45bc-b05b-11b9a8693415',
    tourChainName: 'Fork Lift Tour Chain',
    scanValue: null,
  },
  {
    id: 'da4efbc7-11b3-45bc-b05b-11b9a8693417',
    missionId: '00000000-0000-0000-0000-000000000007',
    vehicleId: 'dq1230bb-f15a-4fc9-a163-8133471fe4e9',
    vehicleName: 'Forklift 3',
    vehicleType: VehicleType.Forklift,
    missionName: 'Tour Chain In Progress 2',
    status: 2,
    currentStepSequenceNumber: 0,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: StepTypeBackend.Goto,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: ``,
          trajectory: [],
          mappingType: undefined,
          pointOfInterestId: undefined,
        },
        succeeded: false,
        startedDateTime: '2021-02-04T14:38:01.9838377Z',
        stoppedDateTime: '2021-02-04T14:38:59.1290833Z',
        attributeText: '',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: '2020-06-30T15:43:24.6975014Z',
    updatedDateTime: '2020-06-30T15:43:24.6975014Z',
    forecastedEndTime: '2020-07-31T15:43:24.6975014Z',
    trigger: 8,
    materialNumber: '',
    processChainTraceId: '',
    processChainName: '',
    mapId: '7e42a595-074e-4a55-8831-57dd626bea7f',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: null,
      missionFailureEndDateTime: null,
      missionFailureStartDateTime: null,
      missionFailureErrorClass: undefined,
      missionFailureReasonDate: undefined,
    },
    deliveryStatus: 3,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    currentStepType: 0,
    stepCount: 2,
    currentStepAttributeText: 'Park 04',
    assignableWaypointId: null,
    assignmentConditions: [],
    canBeContinuedFromStep: false,
    canBeReAssigned: false,
    isAlarmForLateDeliveryActive: false,
    isAssignedToVehicle: false,
    isMaxExecutionTimeActive: false,
    lateDeliveryAlarmTime: 0,
    maxExecutionTime: 0,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
    processChainId: ``,
    assignableWaypointName: undefined,
    destination: undefined,
    fleetId: undefined,
    fleetName: undefined,
    missionStep: undefined,
    provisioningTime: ``,
    source: undefined,
    tourSteps: [],
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.VDA5050,
    eventTraces: [],
    interlockDetails: [],
    loadType: LoadType.Unknown,
    tourChainId: 'da4efbc7-11b3-45bc-b05b-11b9a8693415',
    tourChainName: 'Fork Lift Tour Chain',
    scanValue: null,
  },
];

const tourChainDto: TourChainDto = {
  id: 'da4efbc7-11b3-45bc-b05b-11b9a8693415',
  name: 'Fork Lift Tour Chain',
  sapInternalRoute: '',
  enableRepetitiveTour: false,
  tourTrigger: TourTriggerType.AdHocFromTourConfiguration,
  vehicleType: VehicleType.Forklift,
  deviceName: null,
  deviceNodeName: null,
  deviceNodeValue: null,
  hardwareVersion: null,
  workAreaId: 'dde4fa82-8883-4336-ae85-e0e72a81e9f0',
  tourChainStatus: TourChainStatus.InProgress,
  tourChainConfigurationId: 'tourChain-11b3-45bc-b05b-11b9a8693415',
  tours: tours,
};
const interlockTourChainConfigForm = new FormGroup<TourChainConfigForm>({
  name: new FormControl<string>('Interlock', { nonNullable: true }),
  sapInternalRoute: new FormControl<string | null>(null),
  enableRepetitiveTour: new FormControl<boolean>(false, { nonNullable: true }),
  triggerType: new FormControl<string>(TourTriggerType.AdHocFromTourConfiguration.toString(), {
    nonNullable: true,
  }),
  vehicleType: new FormControl<string>(VehicleType.Forklift.toString(), {
    nonNullable: true,
  }),
  deviceName: new FormControl<string | null>(null),
  deviceNodeName: new FormControl<string | null>(null),
  deviceNodeValue: new FormControl<string | null>(null),
  tourConfigs: new FormArray<FormGroup<TourConfigurationForm>>([
    new FormGroup<TourConfigurationForm>({
      id: new FormControl<GuidString>('18f96b36-2730-4f41-8538-04ed5a9d566e', {
        nonNullable: true,
      }),
      name: new FormControl<string>('Raising', { nonNullable: true }),
      routeConfigName: new FormControl<string | null>(null),
      assignableNodeId: new FormControl<string | null>(null),
      fuelingNodeId: new FormControl<string | null>(null),
      fuelingThresholdInPercent: new FormControl<number | null>(null),
      fuelingHasArrivalNotificationEnabled: new FormControl<boolean>(false, {
        nonNullable: true,
      }),
      steps: new FormArray<StepForm>([
        new FormGroup<LiftStepForm>({
          displayStepType: new FormControl<StepType.Lift>(StepType.Lift, {
            nonNullable: true,
          }),
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          height: new FormControl<number | null>(3.5),
          sequenceNumber: new FormControl<number>(0, { nonNullable: true }),
          type: new FormControl<StepType.Lift>(StepType.Lift, { nonNullable: true }),
        }),
        new FormGroup<DropStepForm>({
          displayStepType: new FormControl<StepType.Drop>(StepType.Drop, {
            nonNullable: true,
          }),
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          height: new FormControl<number | null>(0.5),
          sequenceNumber: new FormControl<number>(1, { nonNullable: true }),
          type: new FormControl<StepType.Drop>(StepType.Drop, { nonNullable: true }),
        }),
      ]),
      pauseConditions: new FormArray<FormGroup<MissionActionConditionForm>>([]),
    }),
    new FormGroup<TourConfigurationForm>({
      id: new FormControl<GuidString>('18f96b36-2730-4f41-8538-04ed5a9d566e', {
        nonNullable: true,
      }),
      name: new FormControl<string>('Waiting', { nonNullable: true }),
      routeConfigName: new FormControl<string | null>(null),
      assignableNodeId: new FormControl<string | null>(null),
      fuelingNodeId: new FormControl<string | null>(null),
      fuelingThresholdInPercent: new FormControl<number | null>(null),
      fuelingHasArrivalNotificationEnabled: new FormControl<boolean>(false, {
        nonNullable: true,
      }),
      steps: new FormArray<StepForm>([
        new FormGroup<WaitForEndOfStepStepForm>({
          displayStepType: new FormControl<StepType.WaitForEndOfStep>(StepType.WaitForEndOfStep, {
            nonNullable: true,
          }),
          sequenceNumber: new FormControl<number>(0, { nonNullable: true }),
          type: new FormControl<StepType.WaitForEndOfStep>(StepType.WaitForEndOfStep, {
            nonNullable: true,
          }),
          waitForStepId: new FormControl<string>('0-1', { nonNullable: true }),
        }),
        new FormGroup<GotoStepForm>({
          displayStepType: new FormControl<StepType.Goto>(StepType.Goto, {
            nonNullable: true,
          }),
          hasStepCompletionNotificationEnabled: new FormControl<boolean>(false, {
            nonNullable: true,
          }),
          nodesControl: new FormControl<NodeControlFormModel>(
            {
              nodeId: '884e860d-5f70-4187-9ee5-5b695102edf4',
            },
            { nonNullable: true }
          ),
          sequenceNumber: new FormControl<number>(1, { nonNullable: true }),
          type: new FormControl<StepType.Goto>(StepType.Goto, { nonNullable: true }),
        }),
      ]),
      pauseConditions: new FormArray<FormGroup<MissionActionConditionForm>>([]),
    }),
  ]),
});

const pauseConditionTourChainConfigForm = new FormGroup<TourChainConfigForm>({
  name: new FormControl<string>('PauseConditionTourChain', { nonNullable: true }),
  sapInternalRoute: new FormControl<string | null>(null),
  enableRepetitiveTour: new FormControl<boolean>(false, { nonNullable: true }),
  triggerType: new FormControl<string>(TourTriggerType.AdHocFromTourConfiguration.toString(), {
    nonNullable: true,
  }),
  vehicleType: new FormControl<string>(VehicleType.Forklift.toString(), {
    nonNullable: true,
  }),
  deviceName: new FormControl<string | null>('device'),
  deviceNodeName: new FormControl<string | null>('node'),
  deviceNodeValue: new FormControl<string | null>('value'),
  tourConfigs: new FormArray<FormGroup<TourConfigurationForm>>([
    new FormGroup<TourConfigurationForm>({
      id: new FormControl<GuidString>('f75d25ca-9ce7-4598-a272-9b804f4e6c98', {
        nonNullable: true,
      }),
      name: new FormControl<string>('Tour with PauseCondition', { nonNullable: true }),
      routeConfigName: new FormControl<string | null>(null),
      assignableNodeId: new FormControl<string | null>(null),
      fuelingNodeId: new FormControl<string | null>(null),
      fuelingThresholdInPercent: new FormControl<number | null>(null),
      fuelingHasArrivalNotificationEnabled: new FormControl<boolean>(false, {
        nonNullable: true,
      }),
      steps: new FormArray<StepForm>([
        new FormGroup<GotoStepForm>({
          displayStepType: new FormControl<StepType.Goto>(StepType.Goto, {
            nonNullable: true,
          }),
          hasStepCompletionNotificationEnabled: new FormControl<boolean>(false, {
            nonNullable: true,
          }),
          nodesControl: new FormControl<NodeControlFormModel>(
            {
              nodeId: '884e860d-5f70-4187-9ee5-5b695102edf4',
            },
            { nonNullable: true }
          ),
          sequenceNumber: new FormControl<number>(0, { nonNullable: true }),
          type: new FormControl<StepType.Goto>(StepType.Goto, { nonNullable: true }),
        }),
        new FormGroup<LiftStepForm>({
          displayStepType: new FormControl<StepType.Lift>(StepType.Lift, {
            nonNullable: true,
          }),
          height: new FormControl<number | null>(null),
          sequenceNumber: new FormControl<number>(1, { nonNullable: true }),
          type: new FormControl<StepType.Lift>(StepType.Lift, { nonNullable: true }),
        }),
        new FormGroup<GotoStepForm>({
          displayStepType: new FormControl<StepType.Goto>(StepType.Goto, {
            nonNullable: true,
          }),
          hasStepCompletionNotificationEnabled: new FormControl<boolean>(false, {
            nonNullable: true,
          }),
          nodesControl: new FormControl<NodeControlFormModel>(
            {
              nodeId: 'a9db9aa3-9c7f-41c5-92ba-cf98c844bac7',
            },
            { nonNullable: true }
          ),
          sequenceNumber: new FormControl<number>(2, { nonNullable: true }),
          type: new FormControl<StepType.Goto>(StepType.Goto, { nonNullable: true }),
        }),
        new FormGroup<DropStepForm>({
          displayStepType: new FormControl<StepType.Drop>(StepType.Drop, {
            nonNullable: true,
          }),
          height: new FormControl<number | null>(null),
          sequenceNumber: new FormControl<number>(3, { nonNullable: true }),
          type: new FormControl<StepType.Drop>(StepType.Drop, { nonNullable: true }),
        }),
      ]),
      pauseConditions: new FormArray<FormGroup<MissionActionConditionForm>>([
        new FormGroup<MissionActionConditionForm>({
          device: new FormControl<string>('device', { nonNullable: true }),
          nodeName: new FormControl<string>('node', { nonNullable: true }),
          value: new FormControl<string>('value', { nonNullable: true }),
          stepNumber: new FormControl<number>(3, { nonNullable: true }),
        }),
      ]),
    }),
  ]),
});

const interLockTourConfigData: TourConfigurationModel[] = [
  {
    assignableNodeId: 'N_node-charging-1_5444b43b-a563-4cc4-9501-6843ae72daad',
    fuelingHasArrivalNotificationEnabled: false,
    fuelingNodeId: null,
    fuelingThresholdInPercent: 20,
    id: '00000000-0000-0000-0000-000000000000',
    name: 'End of step',
    pauseConditions: [],
    routeConfigName: null,
    steps: [
      {
        sequenceNumber: 0,
        type: 100,
        nodeId: 'N_node-container-lane-2_5444b43b-a563-4cc4-9501-6843ae72daad',
        hasArrivalNotificationEnabled: false,
        raiseEventId: '',
      },
      {
        sequenceNumber: 1,
        type: StepType.Goto,
        nodeId: 'N_node-charging-2_5444b43b-a563-4cc4-9501-6843ae72daad',
        hasArrivalNotificationEnabled: true,
        raiseEventId: '',
      },
      {
        sequenceNumber: 2,
        type: StepType.Goto,
        nodeId: 'N_node-container-lane-3_5444b43b-a563-4cc4-9501-6843ae72daad',
        hasArrivalNotificationEnabled: false,
        raiseEventId: '',
      },
      {
        sequenceNumber: 3,
        type: StepType.WaitForEndOfStep,
        waitForStepId: '1-0',
        raiseEventId: '',
        waitEventId: '',
      },
    ],
  },
  {
    assignableNodeId: 'N_node-charging-2_5444b43b-a563-4cc4-9501-6843ae72daad',
    fuelingHasArrivalNotificationEnabled: false,
    fuelingNodeId: null,
    fuelingThresholdInPercent: 20,
    id: '00000000-0000-0000-0000-000000000000',
    name: 'Wait for step',
    pauseConditions: [],
    routeConfigName: null,
    steps: [
      {
        hasArrivalNotificationEnabled: false,
        nodeId: 'N_node-container-lane-1_5444b43b-a563-4cc4-9501-6843ae72daad',
        sequenceNumber: 0,
        type: StepType.Goto,
        raiseEventId: '',
      },
      {
        nodeId: 'N_waypoint-37_5444b43b-a563-4cc4-9501-6843ae72daad',
        sequenceNumber: 1,
        type: StepType.Goto,
        raiseEventId: '',
        hasArrivalNotificationEnabled: false,
      },
    ],
  },
];

export class TourConfigurationTestHelper {
  static getTourConfigurations(): TourChainConfigurationDto[] {
    return objectHelper.cloneDeep(allTourChainConfigs);
  }

  static getAllTourChainConfigurations(): TourChainConfigurationDto[] {
    return objectHelper.cloneDeep(allTourChainConfiguration);
  }

  static getTourTraceHistoryViaOdata(): ODataDto<ArchivedTourDto> {
    return { ...archivedTours };
  }

  static getActiveTours(): MissionTrace[] {
    return objectHelper.cloneDeep(missionTraceModels);
  }
  static getActiveTourBackend(): MissionTraceDto {
    return cloneDeep(dto);
  }

  static getForkliftDeviceTriggerTourConfigurationDto(): TourChainConfigurationDto {
    return objectHelper.cloneDeep(forkliftDeviceTriggerTourConfiguration);
  }

  static getTourChainById(): TourChain {
    const tourChain = {
      ...tourChainDto,
      tours: tourChainDto.tours.map(convertMissionTraceDtoToModel.bind(this)),
    };
    return objectHelper.cloneDeep(tourChain);
  }

  static getAllActiveTours(): MissionTrace[] {
    const tours = tourChainDto.tours.map(convertMissionTraceDtoToModel.bind(this));
    return objectHelper.cloneDeep(tours.filter(tour => tour.status === MissionStatus.InProgress));
  }

  static getAllCompletedTours(): MissionTrace[] {
    const tours = tourChainDto.tours.map(convertMissionTraceDtoToModel.bind(this));
    return objectHelper.cloneDeep(tours.filter(tour => tour.status === MissionStatus.Completed));
  }

  static getInterlockTourChainConfigForm(): FormGroup<TourChainConfigForm> {
    return cloneDeep(interlockTourChainConfigForm);
  }

  static getRaisingTourConfigForm(): FormGroup<TourConfigurationForm> {
    return cloneDeep(interlockTourChainConfigForm.controls.tourConfigs.controls[0]);
  }

  static getWaitingTourConfigForm(): FormGroup<TourConfigurationForm> {
    return cloneDeep(interlockTourChainConfigForm.controls.tourConfigs.controls[1]);
  }

  static getPauseConditionTourConfigForm(): FormGroup<TourConfigurationForm> {
    return cloneDeep(pauseConditionTourChainConfigForm.controls.tourConfigs.controls[0]);
  }

  static getInterLockTourConfigData(): TourConfigurationModel[] {
    return cloneDeep(interLockTourConfigData);
  }
}

const lifConfig: LayoutDto[] = [
  {
    id: 'layout123',
    mapId: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
    navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    edges: [
      { edgeId: 'e_1', edgeName: 'Edge 1', startNodeId: 'n_0', endNodeId: 'n_1' },
      { edgeId: 'e_7', edgeName: 'Edge 7', startNodeId: 'n_6', endNodeId: 'n_7' },
      { edgeId: 'e_8', edgeName: 'Edge 8', startNodeId: 'n_7', endNodeId: 'n_8' },
      { edgeId: 'e_4', edgeName: 'Edge 4', startNodeId: 'n_3', endNodeId: 'n_4' },
      { edgeId: 'e_3', edgeName: 'Edge 3', startNodeId: 'n_2', endNodeId: 'n_3' },
      { edgeId: 'e_9', edgeName: 'Edge 9', startNodeId: 'n_8', endNodeId: 'n_9' },
      { edgeId: 'e_10', edgeName: 'Edge 10', startNodeId: 'n_9', endNodeId: 'n_10' },
      { edgeId: 'e_5', edgeName: 'Edge 5', startNodeId: 'n_4', endNodeId: 'n_5' },
      { edgeId: 'e_2', edgeName: 'Edge 2', startNodeId: 'n_1', endNodeId: 'n_2' },
      { edgeId: 'e_6', edgeName: 'Edge 6', startNodeId: 'n_5', endNodeId: 'n_6' },
      {
        edgeId: 'e_split_1',
        edgeName: 'Edge Segment 1',
        startNodeId: 'n_3',
        endNodeId: 'n_segment_1',
      },
      {
        edgeId: 'e_split_2',
        edgeName: 'Edge Segment 2',
        startNodeId: 'n_segment_1',
        endNodeId: 'n_6',
      },
      {
        edgeId: 'e_round_1',
        edgeName: 'Edge Round 1',
        startNodeId: 'n_9',
        endNodeId: 'n_round_1',
      },
      {
        edgeId: 'e_round_2',
        edgeName: 'Edge Round 2',
        startNodeId: 'n_round_1',
        endNodeId: 'n_2',
      },
    ],
    nodes: [
      {
        nodeId: 'n_0',
        nodeName: 'Node 0',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 0, y: 0, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_1',
        nodeName: 'Node 1',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 35, y: 40, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_2',
        nodeName: 'Node 2',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 70, y: 80, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_3',
        nodeName: 'Node 3',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 105, y: 120, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_4',
        nodeName: 'Node 4',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 140, y: 160, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_5',
        nodeName: 'Node 5',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 175, y: 200, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_6',
        nodeName: 'Node 6',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 210, y: 240, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_7',
        nodeName: 'Node 7',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 245, y: 280, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_8',
        nodeName: 'Node 8',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 280, y: 320, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_9',
        nodeName: 'Node 9',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 315, y: 360, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_10',
        nodeName: 'Node 10',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 350, y: 400, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_segment_1',
        nodeName: 'Node Segment 1',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 70, y: 160, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_segment_1',
        nodeName: 'Node Segment 1',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 105, y: 240, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_round_1',
        nodeName: 'Node Round 1',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 350, y: 120, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
    ],
  },
];

const lifConfig1: LayoutDto[] = [
  {
    id: 'layout1234',
    mapId: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
    navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    edges: [
      { edgeId: 'e_1', edgeName: 'Edge 1', startNodeId: 'n_0', endNodeId: 'n_1' },
      { edgeId: 'e_7', edgeName: 'Edge 7', startNodeId: 'n_6', endNodeId: 'n_7' },
      { edgeId: 'e_8', edgeName: 'Edge 8', startNodeId: 'n_7', endNodeId: 'n_8' },
      { edgeId: 'e_4', edgeName: 'Edge 4', startNodeId: 'n_3', endNodeId: 'n_4' },
      { edgeId: 'e_3', edgeName: 'Edge 3', startNodeId: 'n_2', endNodeId: 'n_3' },
      { edgeId: 'e_9', edgeName: 'Edge 9', startNodeId: 'n_8', endNodeId: 'n_9' },
      { edgeId: 'e_10', edgeName: 'Edge 10', startNodeId: 'n_9', endNodeId: 'n_10' },
      { edgeId: 'e_5', edgeName: 'Edge 5', startNodeId: 'n_4', endNodeId: 'n_5' },
      { edgeId: 'e_2', edgeName: 'Edge 2', startNodeId: 'n_1', endNodeId: 'n_2' },
      { edgeId: 'e_6', edgeName: 'Edge 6', startNodeId: 'n_5', endNodeId: 'n_6' },
      {
        edgeId: 'e_split_1',
        edgeName: 'Edge Segment 1',
        startNodeId: 'n_3',
        endNodeId: 'n_segment_1',
      },
      {
        edgeId: 'e_split_2',
        edgeName: 'Edge Segment 2',
        startNodeId: 'n_segment_1',
        endNodeId: 'n_6',
      },
      {
        edgeId: 'e_round_1',
        edgeName: 'Edge Round 1',
        startNodeId: 'n_9',
        endNodeId: 'n_round_1',
      },
      {
        edgeId: 'e_round_2',
        edgeName: 'Edge Round 2',
        startNodeId: 'n_round_1',
        endNodeId: 'n_2',
      },
    ],
    nodes: [
      {
        nodeId: 'n_0',
        nodeName: 'Node 0',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 0, y: 0, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: true,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_1',
        nodeName: 'Node 1',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 35, y: 40, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: true,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_2',
        nodeName: 'Node 2',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 70, y: 80, theta: 0, orientation: 0 },
        nodeType: 1,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_3',
        nodeName: 'Node 3',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 105, y: 120, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_4',
        nodeName: 'Node 4',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 140, y: 160, theta: 0, orientation: 0 },
        nodeType: 2,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_5',
        nodeName: 'Node 5',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 175, y: 200, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_6',
        nodeName: 'Node 6',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 210, y: 240, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_7',
        nodeName: 'Node 7',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 245, y: 280, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_8',
        nodeName: 'Node 8',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 280, y: 320, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_9',
        nodeName: 'Node 9',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 315, y: 360, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_10',
        nodeName: 'Node 10',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 350, y: 400, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_segment_1',
        nodeName: 'Node Segment 1',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 70, y: 160, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_segment_1',
        nodeName: 'Node Segment 1',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 105, y: 240, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
      {
        nodeId: 'n_round_1',
        nodeName: 'Node Round 1',
        map: {
          id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
          navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
        },
        nodePosition: { x: 350, y: 120, theta: 0, orientation: 0 },
        nodeType: 0,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
        occupancy: {
          status: NodeOccupancyStatus.Free,
        },
      },
    ],
  },
];

const nodes: NodeDto[] = [
  {
    nodeId: 'n_0',
    nodeName: 'Node 0',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 0, y: 0, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_1',
    nodeName: 'Node 1',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 35, y: 40, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_2',
    nodeName: 'Node 2',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 70, y: 80, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_3',
    nodeName: 'Node 3',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 105, y: 120, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_4',
    nodeName: 'Node 4',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 140, y: 160, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_5',
    nodeName: 'Node 5',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 175, y: 200, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_6',
    nodeName: 'Node 6',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 210, y: 240, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_7',
    nodeName: 'Node 7',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 245, y: 280, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_8',
    nodeName: 'Node 8',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 280, y: 320, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_9',
    nodeName: 'Node 9',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 315, y: 360, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_10',
    nodeName: 'Node 10',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 350, y: 400, theta: 0, orientation: 0 },
    nodeType: 2,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_segment_1',
    nodeName: 'Node Segment 1',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 70, y: 160, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_segment_1',
    nodeName: 'Node Segment 1',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 105, y: 240, theta: 0, orientation: 0 },
    nodeType: 1,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
  {
    nodeId: 'n_round_1',
    nodeName: 'Node Round 1',
    map: {
      id: 'd61d8ae9-992f-4a87-b805-e10d774d165a',
      navigationLayerId: '1130839c-9f5d-4524-90e7-008dbfd975dg',
    },
    nodePosition: { x: 350, y: 120, theta: 0, orientation: 0 },
    nodeType: 0,
    isReTrackingPoint: true,
    reTrackingOrientation: 0,
    occupancy: {
      status: NodeOccupancyStatus.Free,
    },
  },
];

export class LifTestHelper {
  static getGraphLayer(): LayoutDto[] {
    return objectHelper.cloneDeep(lifConfig);
  }

  static getLifConfig1(): LayoutDto[] {
    return objectHelper.cloneDeep(lifConfig1);
  }
}

export class NodeTestHelper {
  static getNodes(): NodeDto[] {
    return objectHelper.cloneDeep(nodes);
  }
}

const archivedTours: ODataDto<ArchivedTourDto> = {
  '@odata.count': 150,
  odata: undefined,
  value: [
    {
      createdDateUtc: '2020-07-31T15:43:24.6975014Z',
      successfulSteps: 1,
      completedDateUtc: '2020-07-31T15:43:24.6975014Z',
      hasManualSupport: false,
      id: '7c85318a-8414-4ab9-81e6-e4d3b1d81852',
      sapInternalRoute: 'Blue Route',
      missionFailureReasonId: 'fc8b5ec0-f726-4bab-ba93-71294003490e',
      missionId: '4ec6c6a4-581f-484d-b269-c3140cdef662',
      name: 'SAP Route',
      tourConfigurationName: 'Tour Blue Route',
      tourconfigurationId: 'fe7b5c18-0b60-4402-b60b-4d065f37f3a5',
      tourconfigTraceId: '4f0f9082-cd41-4771-a4a6-69c5d4042947',
      routeConfigurationId: 'eaa36e39-5311-48cf-a36c-e0f511bf388e',
      trigger: 'AdHocFromTourConfiguration',
      status: MissionStatus.QueuedOnRobot,
      totalSteps: 1,
      vehicleId: '6e274609-38d3-4215-80b3-3997d8a819dc',
      vehicleName: 'Tugger DS',
      vehicleType: VehicleType.TuggerTrain,
      lastStepType: 'Goto',
      lastStepDetail: '18',
      provisioningTime: '2020-07-31T15:43:24.6975014Z',
    },
    {
      createdDateUtc: '2020-07-31T15:43:24.6975014Z',
      successfulSteps: 1,
      completedDateUtc: '2020-07-31T15:43:24.6975014Z',
      hasManualSupport: false,
      id: '019d9024-a1bb-4a48-b977-769ffa30a41d',
      sapInternalRoute: 'Yellow Route',
      missionFailureReasonId: '67e731f5-c0a8-4034-85f8-c9d6a7d18daa',
      missionId: '298f7a41-597f-4a0e-bed8-71c7ae0de2d9',
      name: 'SAP Yellow Route',
      tourConfigurationName: 'Tour Yellow Route',
      tourconfigurationId: '5c92f8af-4658-4a4e-9c17-fa3d00b22d97',
      tourconfigTraceId: '76f117fa-9e83-4a30-b60f-70ab0a6c596b',
      routeConfigurationId: 'e805551c-1830-48b8-a9c2-40995326d63d',
      trigger: 'SapInternalTour',
      status: 8,
      totalSteps: 1,
      vehicleId: '61e862f7-fc8b-4e92-9601-95e27f05281b',
      vehicleName: 'Tugger DS',
      vehicleType: VehicleType.TuggerTrain,
      lastStepType: 'Goto',
      lastStepDetail: '18',
      provisioningTime: '2020-07-31T15:43:24.6975014Z',
    },
    {
      createdDateUtc: '2020-07-31T15:43:24.6975014Z',
      successfulSteps: 1,
      completedDateUtc: '2020-07-31T15:43:24.6975014Z',
      hasManualSupport: false,
      id: '7c85318a-8414-4ab9-81e6-e4d3b1d81844',
      sapInternalRoute: 'Blue Route',
      missionFailureReasonId: 'fc8b5ec0-f726-4bab-ba93-71294003490e',
      missionId: '4ec6c6a4-581f-484d-b269-c3140cdef662',
      name: 'SAP Route',
      tourConfigurationName: 'Tour Blue Route',
      tourconfigurationId: 'fe7b5c18-0b60-4402-b60b-4d065f37f3a5',
      tourconfigTraceId: '4f0f9082-cd41-4771-a4a6-69c5d4042947',
      routeConfigurationId: 'eaa36e39-5311-48cf-a36c-e0f511bf388e',
      trigger: 'SystemParkingInstruction',
      status: 0,
      totalSteps: 1,
      vehicleId: '6e274609-38d3-4215-80b3-3997d8a819dc',
      vehicleName: 'Tugger DS',
      vehicleType: VehicleType.TuggerTrain,
      lastStepType: 'Goto',
      lastStepDetail: '18',
      provisioningTime: '2020-07-31T15:43:24.6975014Z',
    },
  ],
};

const allRouteConfigs: RouteConfigurationDto[] = [
  {
    id: 'routeId1',
    workAreaId: 'wa',
    name: 'Route 1',
    switches: [],
    color: 0,
    endNodeId: 'end node',
  },
];

export class RouteConfigurationTestHelper {
  static getRouteConfigurations(): RouteConfigurationDto[] {
    return objectHelper.cloneDeep(allRouteConfigs);
  }
}

const allRules: RuleDto[] = [
  {
    id: 'ruleId',
    stopNodeId: 'stopNodeId',
    switchNodeId: 'switchNodeId',
    edgeId: 'edgeId',
    ruleType: RuleType.RouteCustomization,
  },
];

export class RouteCustomizationRuleTestHelper {
  static getRouteCustomizationRules(): RuleDto[] {
    return objectHelper.cloneDeep(allRules);
  }
}
