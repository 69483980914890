import { createAction, props } from '@ngrx/store';
import {
  PoiBookingStatusChangeDto,
  PoiDeviceOccupancyStatusChangeDto,
  PoiDto,
  PoiOccupancyStatusChangeDto,
} from 'core/dtos';
import { GuidString } from 'core/models';

export enum PoiTypes {
  LoadPois = '[Pois] Load Pois',
  LoadPoisSuccess = '[Pois] Load Pois Success',
  LoadPoisFailure = '[Pois] Load Pois Failure',

  LoadPoiBookingAndDeviceOccupancy = '[Pois] Load Poi Booking And Device Occupancy',
  LoadPoiBookingAndDeviceOccupancySuccess = '[Pois] Load Poi Booking And Device Occupancy Success',
  LoadPoiBookingAndDeviceOccupancyFailure = '[Pois] Load Poi Booking And Device Occupancy Failure',

  AddPoi = '[Pois] Add Poi',
  AddPoiSuccess = '[Pois] Add Poi Success',
  AddPoiFailure = '[Pois] Add Poi Failure',

  UpdatePoi = '[Pois] Update Poi',
  UpdatePoiSuccess = '[Pois] Update Poi Success',
  UpdatePoiFailure = '[Pois] Update Poi Failure',

  DeletePoi = '[Pois] Delete Poi',
  DeletePoiSuccess = '[Pois] Delete Poi Success',
  DeletePoiFailure = '[Pois] Delete Poi Failure',

  UngroupPoi = '[Pois] Ungroup Poi',
  UngroupPoiSuccess = '[Pois] Ungroup Poi Success',
  UngroupPoiFailure = '[Pois] Ungroup Poi Failure',
  SelectPoi = '[Pois] Select Poi',
  ResetActionStatus = '[Pois] Reset Action Status',
}

export enum SignalRPoiTypes {
  SignalRCreatePoi = '[SignalR] Create Poi',
  SignalRUpdatePoi = '[SignalR] Update Poi',
  SignalRDeletePoi = '[SignalR] Delete Poi',

  SignalRUpdatePoiBookingStatus = '[SignalR] Update Poi Booking Status',
  SignalRUpdatePoiOccupancyStatus = '[SignalR] Update Poi Occupancy Status',
  SignalRUpdatePoiDeviceOccupancyStatus = '[SignalR] Update Poi Device Occupancy Status',
}

export const loadPois = createAction(PoiTypes.LoadPois);

export const loadPoisSuccess = createAction(PoiTypes.LoadPoisSuccess, props<{ pois: PoiDto[] }>());

export const loadPoisFailure = createAction(
  PoiTypes.LoadPoisFailure,
  props<{ errorMessage: string }>()
);

export const addPoi = createAction(PoiTypes.AddPoi, props<{ poi: PoiDto }>());

export const addPoiSuccess = createAction(PoiTypes.AddPoiSuccess, props<{ poi: PoiDto }>());

export const addPoiFailure = createAction(
  PoiTypes.AddPoiFailure,
  props<{ errorMessage: string }>()
);

export const updatePoi = createAction(PoiTypes.UpdatePoi, props<{ poi: PoiDto }>());

export const updatePoiSuccess = createAction(PoiTypes.UpdatePoiSuccess, props<{ poi: PoiDto }>());

export const updatePoiFailure = createAction(
  PoiTypes.UpdatePoiFailure,
  props<{ errorMessage: string }>()
);

export const deletePoi = createAction(PoiTypes.DeletePoi, props<{ poi: PoiDto }>());

export const deletePoiSuccess = createAction(PoiTypes.DeletePoiSuccess, props<{ poi: PoiDto }>());

export const deletePoiFailure = createAction(
  PoiTypes.DeletePoiFailure,
  props<{ errorMessage: string }>()
);

export const ungroupPoi = createAction(PoiTypes.UngroupPoi, props<{ poi: PoiDto }>());

export const ungroupPoiSuccess = createAction(PoiTypes.UngroupPoiSuccess, props<{ poi: PoiDto }>());

export const ungroupPoiFailure = createAction(
  PoiTypes.UngroupPoiFailure,
  props<{ errorMessage: string }>()
);

export const selectPoi = createAction(PoiTypes.SelectPoi, props<{ poiId: GuidString }>());

export const resetActionStatus = createAction(PoiTypes.ResetActionStatus);

export const signalRCreatePoi = createAction(
  SignalRPoiTypes.SignalRCreatePoi,
  props<{ poi: PoiDto }>()
);

export const signalRUpdatePoi = createAction(
  SignalRPoiTypes.SignalRUpdatePoi,
  props<{ poi: PoiDto }>()
);

export const signalRDeletePoi = createAction(
  SignalRPoiTypes.SignalRDeletePoi,
  props<{ poi: PoiDto }>()
);

export const signalRUpdatePoiBookingStatus = createAction(
  SignalRPoiTypes.SignalRUpdatePoiBookingStatus,
  props<{ change: PoiBookingStatusChangeDto }>()
);

export const signalRUpdatePoiOccupancyStatus = createAction(
  SignalRPoiTypes.SignalRUpdatePoiOccupancyStatus,
  props<{ change: PoiOccupancyStatusChangeDto }>()
);

export const signalRUpdatePoiDeviceOccupancyStatus = createAction(
  SignalRPoiTypes.SignalRUpdatePoiDeviceOccupancyStatus,
  props<{ change: PoiDeviceOccupancyStatusChangeDto }>()
);
