import { EdgeDto, LayoutDto, RuleDto } from 'core/dtos';
import { Dictionary, first, groupBy, uniqBy } from 'lodash';
import { GuidString } from '../../../core/string-typings';

import { Nurb } from '../nurb.model';
import { EdgeSegment, GraphEdge, GraphNode, Node } from './graph-layer.models';

export class GraphLayout {
  id: GuidString;
  mapId: GuidString = '';
  navigationLayerId?: GuidString;

  nodeMap = new Map<GuidString, Node>();
  edges: GraphEdge[] = [];
  segments: EdgeSegment[] = [];
  startEdges: Dictionary<GraphEdge[]>;
  endEdges: Dictionary<GraphEdge[]>;

  get nodes(): Node[] {
    return Array.from(this.nodeMap.values());
  }

  constructor(layout: LayoutDto, public useSegments?: boolean, rules?: RuleDto[]) {
    this.id = layout.id;
    this.nodeMap = this.createNodes(layout);
    this.edges = layout.edges.map(e => this.createEdge(e)).filter(isEdgeComplete);
    this.startEdges = groupBy(this.edges, 'startNodeId');
    this.endEdges = groupBy(this.edges, 'endNodeId');

    if (rules) {
      this.setNodesWithRules(rules);
    }

    if (useSegments) {
      this.segments = this.createSegments();
    }
  }

  // #region Nodes
  getNode(nodeId: GuidString): Node | undefined {
    return this.nodeMap.get(nodeId);
  }

  getInitialNode(dto: Node): GraphNode {
    return dto;
  }

  saveNode(node: Node): void {
    this.nodeMap.set(node.nodeId, node);
  }

  createNodes(layout: LayoutDto): Map<GuidString, Node> {
    const mapped: Map<GuidString, Node> = new Map(layout.nodes.map(i => [i.nodeId, { ...i }]));
    const startEdges = groupBy(layout.edges, 'startNodeId');
    const endEdges = groupBy(layout.edges, 'endNodeId');

    uniqBy(
      Object.values(startEdges)
        .filter(d => d.length > 1)
        .flat(),
      'startNodeId'
    ).forEach(e => {
      const node = mapped.get(e.startNodeId);
      if (node) {
        node.isSwitchNode = (endEdges[node.nodeId.toString()] ?? []).length > 0;
      }
    });

    mapped.forEach(n => {
      mapped.set(n.nodeId, this.getInitialNode(n));
    });
    return mapped;
  }

  setNodesWithRules(rules: RuleDto[]): void {
    this.nodes.forEach(node => {
      const rule = rules.filter(r => r.stopNodeId === node.nodeId);
      node.hasRule = rule.length > 0;
    });
  }
  // #endregion

  // #region Edges
  getEdge(edgeId: GuidString): GraphEdge | undefined {
    return this.edges.find(e => e.edgeId === edgeId);
  }

  getInitialEdge(dto: GraphEdge): GraphEdge {
    return dto;
  }

  createEdge = (edgeDto: EdgeDto): GraphEdge | undefined => {
    const startNode = this.nodeMap.get(edgeDto.startNodeId);
    const endNode = this.nodeMap.get(edgeDto.endNodeId);

    if (startNode && endNode) {
      let nurb: Nurb | undefined;
      if (edgeDto.trajectory) {
        nurb = new Nurb(startNode.nodePosition, endNode.nodePosition, edgeDto.trajectory);
      }

      return this.getInitialEdge({
        ...edgeDto,
        startPosition: startNode.nodePosition,
        endPosition: endNode.nodePosition,
        isIncluded: false,
        nurb: nurb,
      });
    }

    return undefined;
  };

  getStartEdgesFromNode(node: Node): GraphEdge[] | undefined {
    return this.startEdges[node.nodeId.toString()];
  }

  getEndEdgesFromNode(node: Node): GraphEdge[] | undefined {
    return this.endEdges[node.nodeId.toString()];
  }
  // #endregion

  // #region Segments
  createSegments(): EdgeSegment[] {
    const segments: EdgeSegment[] = [];
    const edgesByStart = groupBy(this.edges, 'startNodeId');
    const switchNode = first(this.nodes.filter(n => n.isSwitchNode));

    if (switchNode) {
      const edges = edgesByStart[switchNode.nodeId.toString()];

      edges
        .filter(i => !i.isIncluded)
        .forEach(e => {
          segments.push(...this.createSegment(e, edgesByStart));
        });
    }

    const notIncluded = this.edges.filter(e => !e.isIncluded);

    if (notIncluded.length > 0) {
      notIncluded.forEach(e => {
        if (!e.isIncluded) {
          e.isDisconnected = true;
          console.warn(`Edge is disconnected from Segment ${e.startNodeId} - ${e.endNodeId}`);
          segments.push(...this.createSegment(e, edgesByStart));
        }
      });
    }

    return segments;
  }

  private createSegment(
    startEdge: GraphEdge,
    edgesByStart: Dictionary<GraphEdge[]>
  ): EdgeSegment[] {
    if (startEdge.isIncluded) return [];

    const list: EdgeSegment[] = [];
    const segment = this.addToSegment(startEdge);

    let edge: GraphEdge | undefined = startEdge;
    while (edge) {
      const edges: GraphEdge[] | undefined = edgesByStart[edge.endNodeId.toString()];

      if (edges) {
        if (edges.length > 1) {
          edge = undefined; // New split, so switch point
          list.push(segment);

          edges
            .filter(i => !i.isIncluded)
            .forEach(e => list.push(...this.createSegment(e, edgesByStart))); // Do edges that has not been included
        } else {
          edge = edges[0];
          this.addEdgeToSegmentEnd(segment, edge);
        }
      } else {
        edge = undefined;
        list.push(segment);
      }
    }

    return list;
  }

  private addToSegment(edge: GraphEdge): EdgeSegment {
    const segment: EdgeSegment = {
      startEdgeId: edge.edgeId,
      startNodeId: edge.startNodeId,
      endNodeId: edge.endNodeId,
      positions: [edge.startPosition, edge.endPosition],
      edges: [edge.edgeId],
    };

    edge.isIncluded = true;
    return segment;
  }

  private addEdgeToSegmentEnd(segment: EdgeSegment, edge: GraphEdge): void {
    segment.endNodeId = edge.endNodeId;
    segment.positions.push(edge.endPosition);
    segment.edges?.push(edge.edgeId);

    edge.isShared = edge.isIncluded;
    edge.isIncluded = true;
  }
  // #endregion
}

export const isEdgeComplete = (item: GraphEdge | undefined): item is GraphEdge => {
  return !!item && item.startPosition !== undefined && item.endPosition !== undefined;
};
