<div class="row">
  <app-ipst-alertnow-group-edit
    *ngFor="let vehicleAlertNowControl of alertNowZoneGroupFormArray?.controls; let i = index"
    class="col-lg-6 col-md-12"
    [formControl]="$any(vehicleAlertNowControl)"
    [zones]="zones"
    [allFleets]="allFleets"
    [allRecipientKeys]="allRecipientKeys"
    [vehicles]="vehicles"
    [ngClass]="{ left: i % 2 !== 0, right: i % 2 === 0 }"
    (delete)="onDelete(i, $event)"></app-ipst-alertnow-group-edit>
</div>
