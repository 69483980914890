import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { Store, select } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { WorkAreaSettingRecipientKeyDto } from 'core/dtos';
import { ErrorForwardingModel } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { clone } from 'lodash';
import { DefaultRecipientKeyLabel } from 'modules/settings/components/error-forwarding-list/error-forwarding-list.component';
import { ZONE_ALERT_NOW_GUID } from 'modules/settings/constants/settings.constant';
import { Subject, takeUntil } from 'rxjs';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-recipient-key-selection-cell',
  templateUrl: './recipient-key-selection-cell.component.html',
  styleUrl: './recipient-key-selection-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientKeySelectionCellComponent
  implements ICellRendererAngularComp, OnDestroy, OnChanges, OnInit
{
  params?: ICellRendererParams<ErrorForwardingModel>;
  optionList: SelectListOption[] = [];
  list: ErrorForwardingModel[] = [];
  canEdit?: boolean;
  editEnabled?: boolean = false;
  ngUnsubscribe = new Subject<void>();
  isEditMode = false;
  ipstIsEnabledInWa = false;
  noRecipientKeyNoZoneALertNowGroup = false;

  data: ErrorForwardingModel | undefined = {
    id: 0,
    recipientKeyIds: [],
    description: '',
    descriptionDe: '',
    descriptionEn: '',
    errorSource: '',
    level: '',
    sendNotification: false,
    type: '',
    workAreaId: '',
    zoneAlertNowGroupEnabled: null,
  };
  isATS = false;
  isDevice = false;
  zoneAlertNowGroupTranslation =
    'settings.ipstAlertNowSettings.errorForwardingRecipientKey.zoneAlertNowGroup';
  recipientKeyDropdownLoading = true;
  atLeastOneSelected = false;
  displayNames = [''];
  zoneAlertNowGroup: DefaultRecipientKeyLabel = {
    id: ZONE_ALERT_NOW_GUID.toString(),
    name: this.zoneAlertNowGroupTranslation,
    isDefault: true,
  };
  ungroupedAllList: SelectListOption[] = [];
  displayTooltip = false;
  initialDropdownValue: string[] = [];

  get initialDropdownValueAsString(): string[] {
    return this.initialDropdownValue.map(item => item.toString());
  }

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<fromRoot.RootState>,
    protected readonly translationService: AtsTranslationService
  ) {
    this.translationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.updateLabels();
    });
  }

  ngOnInit(): void {
    this.rootStore
      .pipe(select(fromRoot.selectIsEditMode), takeUntil(this.ngUnsubscribe))
      .subscribe(isEditMode => {
        this.isEditMode = isEditMode;
        this.setEditMode();
      });
  }

  ngOnChanges(): void {
    this.setEditMode();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  agInit(params: ICellRendererParams<ErrorForwardingModel>): void {
    this.params = params;
    this.canEdit = this.params.context.componentParent.canEdit;
    const dropdownOptions = this.params.context.componentParent.getDropdownOptions(params.data);

    this.data = this.params.data;
    this.isATS = this.data?.errorSource === 'ATS';
    this.isDevice = this.data?.errorSource === 'Device';

    this.ipstIsEnabledInWa = this.params.context.componentParent.ipstIsEnabledInWa;
    this.noRecipientKeyNoZoneALertNowGroup = this.noRecipientKeys(
      this.params.context.componentParent.recipientList
    );

    this.zoneAlertNowGroup.name = this.translationService.get(this.zoneAlertNowGroupTranslation);
    this.optionList = this.groupByOptionList(dropdownOptions);
    this.recipientKeyDropdownLoading = false;
    this.initialDropdownValue = this.noRecipientKeyNoZoneALertNowGroup
      ? []
      : this.data?.recipientKeyIds.map(s => s.toString()) ?? [];
    if (this.data && !this.data?.recipientKeyIds.length) {
      this.onChange(
        this.initialDropdownValue.map(s => {
          return s?.toString();
        })
      );
    }

    if (this.params?.data?.recipientKeyIds) this.displayNames = this.getRecipientNames();
    this.refresh();
  }

  getRecipientNames(): string[] {
    return this.initialDropdownValue.map(p => {
      return this.ungroupedAllList.find(o => o.id === p)?.label ?? '';
    });
  }

  noRecipientKeys(recipientList: WorkAreaSettingRecipientKeyDto[]): boolean {
    return recipientList.length === 0;
  }

  groupByOptionList(dropdownOptions: WorkAreaSettingRecipientKeyDto[]): SelectListOption[] {
    const defaultKey = dropdownOptions.find(f => f.isDefault);

    const recipientKeyOptions = this.isATS
      ? dropdownOptions.filter(option => !option.isDefault)
      : dropdownOptions;

    const defaultRecipientKey = this.isATS
      ? { id: defaultKey?.id, name: defaultKey?.recipientKey }
      : this.isDevice
      ? {}
      : this.zoneAlertNowGroup;

    const defaultKeys = [
      { id: String(defaultRecipientKey?.id), label: defaultRecipientKey.name ?? '' },
    ];

    const recipientKeys = recipientKeyOptions.map(option => ({
      id: String(option.id),
      label: option.recipientKey,
    }));

    this.ungroupedAllList = [...defaultKeys, ...recipientKeys];

    const defaultKeyLabel = this.isATS
      ? 'settings.ipstAlertNowSettings.errorForwardingRecipientKey.waDefaultRecipientKey'
      : 'settings.ipstAlertNowSettings.errorForwardingRecipientKey.alertNowGroup';

    const recipientKeysLabel =
      'settings.ipstAlertNowSettings.errorForwardingRecipientKey.recipientKeys';

    return [
      {
        id: defaultKeyLabel,
        label: this.translationService.get(defaultKeyLabel),
        group: true,
        children: defaultKeys,
      },
      {
        id: recipientKeysLabel,
        label: this.translationService.get(recipientKeysLabel),
        group: true,
        children: recipientKeys,
      },
    ];
  }

  refresh(): boolean {
    this.editEnabled = this.canEdit;
    this.cdRef.markForCheck();
    return true;
  }

  updateLabels(): void {
    this.optionList.forEach(item => {
      if (item.id) {
        item.label = this.translationService.get(item.id);
        item?.children?.find(i => {
          if (i.id === ZONE_ALERT_NOW_GUID.toString()) {
            i.label = this.translationService.get(this.zoneAlertNowGroupTranslation);
          }
        });
      }
    });
    this.displayNames = this.getRecipientNames();
    this.optionList = clone(this.optionList);
  }

  setEditMode(): void {
    this.cdRef.markForCheck();
  }

  onChange(selectedRecipientKeys: string | string[] | null): void {
    if (!selectedRecipientKeys) this.displayTooltip = true;
    else this.displayTooltip = false;
    const data = { ...this.data };
    if (!data || typeof selectedRecipientKeys === 'string') return;
    data.recipientKeyIds = selectedRecipientKeys ?? [];
    if (selectedRecipientKeys?.includes(ZONE_ALERT_NOW_GUID)) data.zoneAlertNowGroupEnabled = true;
    else {
      if (this.isATS) data.zoneAlertNowGroupEnabled = null;
      else data.zoneAlertNowGroupEnabled = false;
    }
    this.params?.context.componentParent.updateData(data);
  }
}
