<ag-grid-angular
  data-cy="userRolesTable"
  style="width: 100%; height: 100%"
  class="ag-theme-density zebra"
  rowSelection="single"
  translationContext="shared.users.userDetailsDialog"
  [class.hide-column-names]="isHeadersHidden"
  [rowData]="userAccessList"
  [rowBuffer]="200"
  [sideBar]="false"
  [gridOptions]="gridOptions"
  [getRowId]="getRowIdForChangeDetection"
  [columnDefs]="translatedColumnDefs"
  (gridReady)="onGridReady($event)"
  (selectionChanged)="onRowSelected()"></ag-grid-angular>
