import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ColDef, GetRowIdFunc, GetRowIdParams, GridReadyEvent } from 'ag-grid-community';
import { TreeTableComponent } from 'components/tree-table/tree-table.component';
import {
  BatteryThresholdPerVehicle,
  GmParkingAndChargingSettingsDto,
  getDefaultGraphManagerParkingAndChargingSettings,
} from 'core/dtos';
import { AtsTranslationService, ToolbarService } from 'core/services';
import { takeUntil } from 'rxjs';
import { BaseAgGridTableDirective } from 'shared/components';
import { ParkyChargyConfigurationTableEntry } from './parky-chargy-configuration.model';

@Component({
  selector: 'app-parky-chargy-configuration',
  templateUrl: './parky-chargy-configuration.component.html',
  styleUrls: ['./parky-chargy-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkyChargyConfigurationComponent
  extends BaseAgGridTableDirective
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild(TreeTableComponent) child!: TreeTableComponent;
  @Input() parkingAndChargingSettings: GmParkingAndChargingSettingsDto =
    getDefaultGraphManagerParkingAndChargingSettings();
  @Input() isLoaded = false;

  rowData: ParkyChargyConfigurationTableEntry[] = [];

  readonly MINIMUM = 'minimum';
  readonly MAXIMUM = 'maximum';
  readonly ADEQUATE = 'adequate';
  readonly FULL = 'full';

  readonly columns: ColDef[] = [
    {
      field: 'setting',
      headerName: 'settings.functions.graphManager.parkingCharging.threshold',
      wrapText: true,
      minWidth: 600,
    },
    {
      field: 'dsTugger',
      headerName: 'settings.functions.graphManager.parkingCharging.dsTugger',
      wrapText: true,
      flex: 4,
    },
    {
      field: 'dsUagv',
      headerName: 'settings.functions.graphManager.parkingCharging.dsUagv',
      wrapText: true,
      flex: 4,
    },
    {
      field: 'schillerForklift',
      headerName: 'settings.functions.graphManager.parkingCharging.schillerForklift',
      wrapText: true,
      flex: 4,
    },
  ];

  constructor(
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef,
    protected readonly translationService: AtsTranslationService
  ) {
    super(translationService, toolbarService, cdRef);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.translationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.rowData = this.transformSettingsToRowData(this.parkingAndChargingSettings);
    });

    super.ngOnInit();
  }

  ngOnChanges({
    parkingAndChargingSettings,
  }: TypedChanges<ParkyChargyConfigurationComponent>): void {
    this.cdRef.detectChanges();
    if (parkingAndChargingSettings?.currentValue) {
      this.rowData = this.transformSettingsToRowData(parkingAndChargingSettings.currentValue);
    }
  }

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) => params.data.setting;

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
    this.translateHeader();
  }

  transformSettingsToRowData(
    settings: GmParkingAndChargingSettingsDto
  ): ParkyChargyConfigurationTableEntry[] {
    const thresholds = settings.thresholds;
    const rowData: ParkyChargyConfigurationTableEntry[] = [];
    rowData.push(
      this.transformBatteryThresholdToTableEntry(this.MINIMUM, thresholds.minimumBatteryThreshold)
    );
    rowData.push(
      this.transformBatteryThresholdToTableEntry(this.ADEQUATE, thresholds.adequateBatteryThreshold)
    );
    rowData.push(
      this.transformBatteryThresholdToTableEntry(this.MAXIMUM, thresholds.maximumBatteryThreshold)
    );
    rowData.push(
      this.transformBatteryThresholdToTableEntry(this.FULL, thresholds.fullBatteryThreshold)
    );

    return rowData;
  }

  transformBatteryThresholdToTableEntry(
    name: string,
    threshold?: BatteryThresholdPerVehicle
  ): ParkyChargyConfigurationTableEntry {
    return {
      setting: this.translationService.get(
        'settings.functions.graphManager.parkingCharging.batteryThresholds.' + name
      ),
      dsTugger: threshold?.dsTugger,
      dsUagv: threshold?.dsUagv,
      schillerForklift: threshold?.schillerForklift,
    };
  }
}
