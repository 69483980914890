import { BMWColorsNumber, OtherColorsNumber } from './colors.enum';

export const GraphLayerColors = {
  Edges: OtherColorsNumber.OceanBlue200,
  DisconnectedEdge: OtherColorsNumber.Red,
  Arrow: OtherColorsNumber.OceanBlue250,
  NodeColor: OtherColorsNumber.LightBlue,
  NodeColorSwitch: OtherColorsNumber.OceanBlue400,
  NodePoiColor: OtherColorsNumber.GreyBlue,
  NodeOutline: OtherColorsNumber.Orange,
  NodeSelection: BMWColorsNumber.SignalBlue,
  ActionNodeColor: BMWColorsNumber.HintBlue,
};

export const RouteConfigStateColors = {
  Selected: BMWColorsNumber.HintBlue,
  Unavailable: 0xb8b8b8,
  Available: 0xabd350,
};

export enum RouteColors {
  Brown = 0x523000,
  MuddyBrown = 0x976900,
  Yellow = 0xf6c800,
  LightYellow = 0xffe767,
  Orange = 0xe96200,
  Peach = 0xff8761,
  DarkGreen = 0x084114,
  Green = 0x2a813f,
  LightGreen = 0x55c474,
  Cyan = 0x035970,
  OceanBlue = 0x0291b7,
  Blue = 0x0071c5,
  LightBlue = 0x62acff,
  Violet = 0xea7eec,
  Fuchsia = 0xc117b9,
  Purple = 0x7547ff,
  Magenta = 0x6c0061,
  Indigo = 0x4e009c,
  Lavender = 0x9f9dff,
  Red = 0xaa0014,
  LightRed = 0xff454a,
}

export const RouteConfigPickerColors: Record<RouteColors, string> = {
  [RouteColors.Yellow]: '#F6C800',
  [RouteColors.LightYellow]: '#FFE767',
  [RouteColors.Orange]: '#e96200',
  [RouteColors.Peach]: '#ff8761',
  [RouteColors.Brown]: '#523000',
  [RouteColors.MuddyBrown]: '#976900',
  [RouteColors.DarkGreen]: '#084114',
  [RouteColors.Green]: '#2A813F',
  [RouteColors.LightGreen]: '#55c474',
  [RouteColors.Cyan]: '#035970',
  [RouteColors.OceanBlue]: '#0291b7',
  [RouteColors.Blue]: '#0071C5',
  [RouteColors.LightBlue]: '#62acff',
  [RouteColors.Violet]: '#EA7EEC',
  [RouteColors.Fuchsia]: '#C117B9',
  [RouteColors.Purple]: '#7547FF',
  [RouteColors.Magenta]: '#6c0061',
  [RouteColors.Indigo]: '#4e009c',
  [RouteColors.Lavender]: '#9f9dff',
  [RouteColors.Red]: '#aa0014',
  [RouteColors.LightRed]: '#ff454a',
};
